import { useMutation, useQuery, useQueryCache } from "react-query";

import network from "./network";
import { DeviceDetails, DeviceType } from "../packages/interfaces/device";

const devicesUri = "devicemgmt";
// const devicesUri = "superadmin/devices";

interface GetDeviceResponse {
  success: boolean;
  data: Array<any>;
}

async function getDevices(params: Object): Promise<any> {
  const resp = await (
    await network()
  ).get(`/${devicesUri}/getDeviceListWithDetails`, {
    params: {
      ...params
    }
  });
  return resp.data;
}

export function useDevices(params: { filterBy: string }, initialize?: boolean) {
  const queryCache = useQueryCache();
  if(params.filterBy === 'gateway_bridge_t') {
    params.filterBy = "gateway";
  }

  return useQuery(["devices", params.filterBy], () => getDevices(params), {
    onSuccess: (data) => {
      const devicesList: Array<any> = data.data.devices ?? [];
      devicesList?.map((device) =>
        queryCache.setQueryData(["devices", device.deviceID], device)
      );
    },
    enabled: initialize,
  });
}

async function getSensorsByGateway(gateway: string): Promise<any> {
  const resp = await (
    await network()
  ).get(`${devicesUri}/getdeviceinfo`, {
    params: {
      filterBy: "sensor",
      gateway,
    }
  });
  return resp.data;
}

export function useGetSensorsByGateway(gateway: string, initialize?: boolean) {
  const queryCache = useQueryCache();
  return useQuery(["sensors", gateway], () => getSensorsByGateway(gateway), {
    onSuccess: (data) => {
      const sensors: Array<any> = data.data.sensor ?? [];
      sensors?.map((sensor) =>
        queryCache.setQueryData(["sensor", sensor], sensor)
      );
    },
    enabled: gateway && initialize,
  });
}

async function getUnassignedGateways(filterBy: string): Promise<any> {
  const resp = await (
    await network()
  ).get(`/${devicesUri}/getlistofdevices`, {
    params: {
      filterBy: "hardwareType",
      deviceAssignment: "unassigned",
      hardwareType: filterBy,
    }
  });
  return resp?.data?.data?.devices;
}

export function useGetUnassignedGateways(
  filterBy: string,
  initialize?: boolean
) {
  return useQuery(
    ["gateways", filterBy],
    () => getUnassignedGateways(filterBy),
    {
      enabled: initialize && !!filterBy,
    }
  );
}

const getDevicesListWithDetails = async (filterBy: DeviceType) => {
  if (filterBy === "gateway_bridge_t") filterBy = "gateway";
  const resp = await (
    await network()
  ).get(`/${devicesUri}/getDeviceListWithDetails`, {
    params: {
      filterBy,
    },
  });

  return resp?.data;
};

export const useGetDeviceListWithDetails = (deviceType: DeviceType) => {
  return useQuery(["deviceListWithDetails", deviceType], async () => {
    const res = await getDevicesListWithDetails(deviceType);

    return res;
  });
};

interface DeviceAssignment {
  action: string;
  gateway?: string[];
  sensor?: string[];
  businessKey: string;
}

async function setDeviceAssigment(details: DeviceAssignment) {
  return (await network()).post(
    `/${devicesUri}/setdevicetreeassignment`,
    details
  );
}

export function useSetDeviceAssignment() {
  const queryCache = useQueryCache();

  return useMutation(
    async (details: DeviceAssignment) => setDeviceAssigment(details),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["devices"]);
        queryCache.invalidateQueries(["deviceListWithDetails"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}
const fetchBusinessDevices = async (businessKey: string) => {
  return (await network()).get(
    `/${devicesUri}/getBusinessDeviceMapping?businessKey=${businessKey}`
  );
};

export const useBusinessDevices = (businessKey: string) => {
  return useQuery(["businessDevices", businessKey], () =>
    fetchBusinessDevices(businessKey)
  );
};
