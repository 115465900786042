import { useEffect, useState } from "react";

import { useQueryCache } from "react-query";
import { MdArrowBack } from "react-icons/md";
import {
  Box,
  Flex,
  Text,
  Radio,
  Stack,
  Checkbox,
  useToast,
  useRadio,
  IconButton,
  useRadioGroup,
} from "@chakra-ui/react";

import { superAdminProduct } from "../Users/AddUser";
import SDialog from "../../packages/components/SDialog";
import { Role, User } from "../../packages/interfaces/user";
import {
  useEntityUsers,
  assignRoleToUsers,
  removeRoleFromUsers,
} from "../../apis/users";
import {
  toastConfigs,
  arrayToObjects,
  objectsToArrays,
} from "../../packages/helpers/extra";

interface ChangeUserRoleProps {
  show: boolean;
  role: Role | undefined;
  setShow: Function;
  businessKeys: string[];
  roles: Role[];
  superadminBusinessKey: string;
}

const usersPageTitle = "Select user to change role";
const positiveLabel = {
  users: "Change to",
  roles: "Save",
};

export const ChangeUserRole = (props: ChangeUserRoleProps) => {
  const toast = useToast(toastConfigs);
  const queryCache = useQueryCache();

  const { show, setShow, role, roles, superadminBusinessKey } = props;

  const [view, setView] = useState<"users" | "roles">("users");

  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any>({});
  const [selectedRole, setSelectedRole] = useState(role?.roleKey);
  const { getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: selectedRole,
    onChange: (value) => setSelectedRole(value),
  });

  const roleKeys = [role?.roleKey ?? ""];
  const { data: roleUsers = [] } = useEntityUsers(roleKeys, "role");
  const { data } = useEntityUsers([superadminBusinessKey], "business");
  const users: User[] = data ?? [];

  useEffect(() => {
    const usersUnderRole = arrayToObjects(roleUsers, "userKey");
    setSelectedUsers(usersUnderRole);
  }, [roleUsers.length]);

  function handleCancelAction() {
    setSelectedUsers({});
    setShow(!show);
  }

  function handleCheckChange(user: User) {
    if (user?.userKey) {
      const selectUsers = { ...selectedUsers };
      if (selectedUsers[user.userKey]) {
        delete selectUsers[user.userKey];
      } else {
        selectUsers[user.userKey] = user;
      }
      setSelectedUsers({ ...selectUsers });
    }
  }

  function handleAllCheckChange(e: any) {
    if (e.target.checked) {
      const selectUsers: any = {};
      users.forEach((user: User) => {
        if (user.userKey) {
          selectUsers[user.userKey] = user;
        }
      });
      setSelectedUsers(selectUsers);
    } else {
      setSelectedUsers({});
    }
  }

  function getRoleKey(user: User) {
    const superadminBusiness = user.business.find(
      (business) => business.businessKey === superadminBusinessKey
    );
    const roleKey = superadminBusiness?.[superAdminProduct].roleKey ?? "";
    return roleKey;
  }

  async function changeRole() {
    const userKeys = objectsToArrays(selectedUsers, "key");
    const roleUserKeysMap = userKeys.reduce((acc: any, key) => {
      const roleKey = getRoleKey(selectedUsers[key]);
      acc[roleKey] = acc[roleKey] || [];
      acc[roleKey].push(selectedUsers[key].userKey);
      return acc;
    }, {});

    await Promise.all(
      objectsToArrays(roleUserKeysMap, "both").map(async (roleUserPair) => {
        await removeRoleFromUsers(roleUserPair[0], roleUserPair[1]);
        await assignRoleToUsers(selectedRole ?? "", roleUserPair[1]);
      })
    );
  }

  async function handleChangeRole() {
    if (view === "roles") {
      try {
        setLoading(true);
        await changeRole();
        queryCache.invalidateQueries(["users"]);
        handleCancelAction();
        toast({
          description: "Changed successfully",
          status: "success",
        });
        setLoading(false);
      } catch (err) {
        toast({
          duration: 3000,
          status: "error",
          description: err?.response?.data?.message ?? "Cannot change role.",
        });
        setLoading(false);
      }
    }
  }

  function renderDialogView() {
    switch (view) {
      case "users":
        return (
          <Box>
            <Flex mb="6">
              <Checkbox onChange={(e) => handleAllCheckChange(e)} mr="2" />
              <Text fontWeight="semibold">Name</Text>
            </Flex>
            {roleUsers.map((user) => (
              <Flex key={user.userKey}>
                <Checkbox
                  isChecked={!!selectedUsers[user.userKey ?? ""]}
                  onChange={() => handleCheckChange(user)}
                  mr="2"
                />
                <Text>{`${user.firstName} ${user.lastName}`}</Text>
              </Flex>
            ))}
          </Box>
        );
      case "roles":
        return (
          <Stack spacing="6">
            <Flex justify="flext-start" align="center">
              <IconButton
                aria-label="back"
                colorScheme="gray"
                onClick={() => setView("users")}
              >
                <MdArrowBack />
              </IconButton>
              <Text ml="4" fontWeight="semibold" color="text.500">
                Select user role
              </Text>
            </Flex>
            <Stack spacing="4" height="60vh" overflowY="scroll" py="2" px="1">
              {roles.map((role) => {
                const radio = getRadioProps({ value: role.roleKey });
                return (
                  <RadioCard
                    key={role.roleKey}
                    {...radio}
                    role={role.roleKey}
                    selectedRole={selectedRole}
                    onRadioButtonClick={(roleKey: string) =>
                      setSelectedRole(roleKey)
                    }
                  >
                    <Stack>
                      <Text fontWeight="bold" color="text.500">
                        {role.name}
                      </Text>
                      <Text fontSize="sm">{role.description}</Text>
                    </Stack>
                  </RadioCard>
                );
              })}
            </Stack>
          </Stack>
        );
    }
  }

  function title() {
    if (view === "users") {
      return usersPageTitle;
    }
    return "";
  }

  return (
    <SDialog
      size="lg"
      open={true}
      loading={loading}
      body=""
      title={title()}
      handleNegativeAction={handleCancelAction}
      handlePositiveAction={() => {
        setView("roles");
        handleChangeRole();
      }}
      positiveLabel={positiveLabel[view]}
    >
      {renderDialogView()}
    </SDialog>
  );
};

const RadioCard = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const { selectedRole, role, onRadioButtonClick } = props;

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const isChecked = selectedRole === role;

  const handleRadioChange = () => {
    onRadioButtonClick(role);
  };

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        style={{
          boxShadow: isChecked ? "0px 0px 0px 3px #006EB8" : "",
        }}
        px={5}
        py={3}
      >
        <Stack direction="row" spacing="6">
          <Radio isChecked={isChecked} onChange={handleRadioChange} />
          {props.children}
        </Stack>
      </Box>
    </Box>
  );
};
