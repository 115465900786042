import { useEffect, useState, useContext, useRef } from "react";

import { useForm } from "react-hook-form";
import PhoneInput, {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Text,
  Stack,
  Button,
  HStack,
  Select,
  useToast,
  Heading,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";

import { useBusiness } from "../../apis/business";
import { AuthContext } from "../Login/AuthContext";
import { SInput } from "../../packages/components/SInput";
import dummyProfileImage from "../../assets/dummyImage.png";
import { splitStrings } from "../../packages/helpers/strings";
import { Role, UpdateUser, User } from "../../packages/interfaces/user";
import { ActionInterface, useGetRolesByBusiness } from "../../apis/role";
import {
  arrayToObjects,
  getAccessDeniedErrorMessage,
  objectsToArrays,
  sanitizeObject,
  toastConfigs,
} from "../../packages/helpers/extra";
import {
  useEditUser,
  useCreateUser,
  useAssignRoleToUser,
  useChangeRoleOfUsers,
} from "../../apis/users";
import ImageContainer from "../../packages/components/ImageContainer";

const settingsResourceId = "roles";
export const superadminBusiness = "stratosfy";
export const superAdminProduct = "superadmin";

interface AddUserProps {
  handleAddRole: () => void;
  handleCancelAction: () => void;
  user?: User;
}

const initialUser: User = {
  email: "",
  lastName: "",
  firstName: "",
  address: "Address",
  loginMethod: "email",
  phoneNumber: "",
  isCustomerSuperAdmin: false,
  isCompanySuperAdmin: false,
  business: [],
  settingsSuperadmin: {
    language: "en",
  },
};

export const AddUser = (props: AddUserProps) => {
  const { user: selectedUser, handleAddRole, handleCancelAction } = props;

  const { userKey: loggedInUserKey, userRoles } = useContext(AuthContext);

  const canAddUserRole = userRoles?.[settingsResourceId]?.some(
    (action: ActionInterface) => action.create
  );

  const uploadImageRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>();
  const [removeImage, setRemoveImage] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [image, setImage] = useState(
    selectedUser?.imageURL ?? dummyProfileImage
  );
  const [superadminBusinessKey, setSuperadminBusinessKey] = useState<string>();
  const [user, setUser] = useState<User>({ ...(selectedUser || initialUser) });

  const [createUser, createUserInfo] = useCreateUser();
  const [editUser, editUserInfo] = useEditUser();
  const [assignRole, assignRoleInfo] = useAssignRoleToUser();
  const [changeRole, changeRoleInfo] = useChangeRoleOfUsers();
  const [countryCode, setCountryCode] = useState("");
  const [editedPhoneNumber, setEditedPhoneNumber] = useState("");

  const { data: businesses } = useBusiness();
  const businessList = businesses?.data ?? [];
  const keyValueBusinessMap = arrayToObjects(businessList);
  const businessKeys = objectsToArrays(keyValueBusinessMap, "key");

  const { data } = useGetRolesByBusiness(businessKeys ?? []);
  const businessRoles = data?.data.businessRoles ?? {};

  const roles = Object.values(businessRoles).reduce(
    (acc: Role[], roles: any) => {
      return [...acc, ...roles];
    },
    []
  );

  const toast = useToast(toastConfigs);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: initialUser,
  });

  useEffect(() => {
    const superadminBusinessDetail = businessList.find(
      (business) => business.businessName === superadminBusiness
    );
    setSuperadminBusinessKey(superadminBusinessDetail?._id);
  }, []);

  useEffect(() => {
    if (selectedUser) {
      const roleKey = getRoleKey();
      setSelectedRole(roleKey);
    }
  }, [superadminBusinessKey]);

  useEffect(() => {
    if (selectedUser?.phoneNumber) {
      const phone = selectedUser.phoneNumber;
      const countryCode = phone.slice(0, phone.length - 10);
      setCountryCode(countryCode);
      const phoneNumber = phone.slice(countryCode.length);
      setEditedPhoneNumber(phoneNumber);
    }
  }, [selectedUser]);

  function handleInputChange(field: string, value: string) {
    setUser({ ...user, [field]: value });
  }

  function addBusinessObject() {
    const role = roles.find((role) => role.roleKey === selectedRole);
    if (role) {
      const { keycloakRoleName } = role;
      const keys = splitStrings(keycloakRoleName, "-");
      const businessKey = keys[1];

      const businessDetails = {
        businessKey,
        superadmin: {
          roleKey: selectedRole,
        },
      };

      return businessDetails;
    }
  }

  async function handleSubmitAction() {
    if (selectedUser) {
      try {
        setLoading(true);
        const {
          userKey: id,
          firstName,
          lastName,
          address,
          email,
          phoneNumber,
        } = user as any;

        let updatedUser: UpdateUser = {
          email,
          address,
          lastName,
          firstName,
          phoneNumber,
          updatedBy: loggedInUserKey ?? "",
        };
        if (file) {
          updatedUser.image = file;
        }
        updatedUser = sanitizeObject(updatedUser);
        if (countryCode && editedPhoneNumber) {
          updatedUser.phoneNumber = `${countryCode}${editedPhoneNumber}`;
        } else {
          updatedUser.phoneNumber = ``;
        }

        await editUser({
          id,
          updatedUser: {
            ...updatedUser,
            removeImage,
          },
        });
        await changedRoleBusiness(selectedRole);
        handleCancelAction();
        toast({
          duration: 3000,
          status: "success",
          description: "User updated successfully",
        });
      } catch (err: any) {
        editUserInfo.reset();
        changeRoleInfo.reset();
        setLoading(false);
        toast({
          status: "error",
          description:
            err?.response?.data?.message ?? "Cannot update the user.",
        });
      }
    } else {
      try {
        const { phoneNumber, ...newUser } = user;
        const businessDetails = addBusinessObject();
        const resp = await createUser({
          ...newUser,
          phoneNumber: `${countryCode}${phoneNumber}`,
          business: [businessDetails],
          updatedBy: loggedInUserKey,
        });

        const userKey = resp?.data.data.userKey;

        await assignRole({ roleKey: selectedRole, userKeys: [userKey] });

        handleCancelAction();
        toast({
          duration: 3000,
          status: "success",
          description: "User created successfully",
        });
      } catch (err: any) {
        createUserInfo.reset();
        assignRoleInfo.reset();
        const { message } = err?.response?.data ?? {};
        toast({
          description: typeof message === "string" ? message : "Error occured!",
          status: "error",
        });
      }
    }
  }

  function submitActionLoader() {
    return (
      loading ||
      changeRoleInfo.isLoading ||
      createUserInfo.isLoading ||
      assignRoleInfo.isLoading
    );
  }

  function getRoleKey() {
    const superadminBusiness = user.business.find(
      (business) => business.businessKey === superadminBusinessKey
    );
    const roleKey = superadminBusiness?.[superAdminProduct].roleKey ?? "";
    return roleKey;
  }

  async function changedRoleBusiness(newRoleKey: string) {
    const oldRoleKey = getRoleKey();
    if (oldRoleKey === newRoleKey) {
      return;
    }
    return changeRole({
      oldRoleKey,
      newRoleKey,
      userKeys: [user.userKey ?? ""],
    });
  }

  function changeProfilePicture(file: FileList | null) {
    if (file) {
      setFile(file[0]);
      setImage(URL.createObjectURL(file[0]));
      setRemoveImage(false);
    }
  }

  function handleAddRoleClick() {
    if (!canAddUserRole) {
      return toast(getAccessDeniedErrorMessage("add", "role"));
    }
    handleAddRole();
  }

  function validateEditedPhoneNumber() {
    if (countryCode) {
      if (
        editedPhoneNumber &&
        editedPhoneNumber?.length !== 10 &&
        !isValidPhoneNumber(editedPhoneNumber)
      ) {
        return "Invalid phone number(phone number must be 10 digits).";
      }
    }

    return true;
  }

  function handleRemoveImage() {
    setFile(null);
    setRemoveImage(true);
    setImage("");
  }

  const validateName = () => {
    const { firstName, lastName } = user ?? {};

    const fullName = `${firstName}${lastName}`;
    if (fullName.length > 80) {
      return "Name cannot be longer than 80 characters.";
    }
  };

  function inputs() {
    if (selectedUser) {
      return (
        <Stack spacing="6">
          <Box>
            <Text color="primary" fontWeight="500" mb="4">
              People photo
            </Text>
            <HStack spacing="10">
              <ImageContainer
                size="100"
                imageURL={image ?? dummyProfileImage}
                externalRef={uploadImageRef}
                onChange={(e) => changeProfilePicture(e.target.files)}
              />
              <Button
                variant="outline"
                colorScheme="blue"
                onClick={() => uploadImageRef.current.click()}
              >
                Choose Image
              </Button>
              {image && (
                <Button
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => handleRemoveImage()}
                >
                  Remove Image
                </Button>
              )}
            </HStack>
          </Box>
          <SInput
            type="email"
            placeholder="Email *"
            name="email"
            isDisabled
            value={user?.email}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          />
          <SInput
            type="text"
            placeholder="First Name *"
            name="firstName"
            value={user?.firstName}
            isRequired
            register={{
              ...register("firstName", {
                validate: validateName,
              }),
            }}
            error={errors?.firstName}
            errorMessage={errors?.firstName?.message}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          />
          <SInput
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={user?.lastName}
            register={{
              ...register("lastName", {
                validate: validateName,
              }),
            }}
            error={user?.lastName && errors?.lastName}
            errorMessage={user?.lastName ? errors?.lastName?.message : ""}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          />
          <Flex>
            <PhoneInput
              height="45px"
              international
              // @ts-ignore
              countryCallingCodeEditable={false}
              // @ts-ignore
              withCountryCallingCode
              value={countryCode}
              onChange={(e) => setCountryCode(e)}
            />
            <SInput
              type="number"
              isDisabled={countryCode ? false : true}
              placeholder="Phone"
              name="phoneNumber"
              value={editedPhoneNumber}
              register={{
                ...register("phoneNumber", {
                  validate: validateEditedPhoneNumber,
                }),
              }}
              onChange={(e) => setEditedPhoneNumber(e.target.value)}
              error={errors?.phoneNumber}
              errorMessage={errors?.phoneNumber?.message}
              countryCode={countryCode}
            />
          </Flex>
          {!user?.isCompanySuperAdmin && (
            <SInput
              name="role"
              placeholder="Role *"
              type="children"
              value="role"
              onChange={() => {}}
            >
              <Select
                variant="unstyled"
                value={selectedRole}
                onChange={(e) => {
                  setSelectedRole(e.target.value);
                }}
              >
                {roles.map((role) => (
                  <option key={role.roleKey} value={role.roleKey}>
                    {role.name}
                  </option>
                ))}
              </Select>
            </SInput>
          )}
        </Stack>
      );
    }
    return (
      <>
        <Text fontWeight="bold" color="gray">
          Select user role
        </Text>

        <Select
          placeholder="Select User Role"
          onChange={(e) => setSelectedRole(e.target.value)}
          isRequired
        >
          {roles?.map((role) => (
            <option key={role.roleKey} value={role.roleKey}>
              {role.name}
            </option>
          ))}
        </Select>
        <Button
          maxW="30%"
          leftIcon={<AddIcon />}
          variant="ghost"
          size="md"
          color="text"
          onClick={() => handleAddRoleClick()}
        >
          Add role
        </Button>
        <Stack spacing="4">
          <SInput
            type="text"
            placeholder="First Name *"
            name="firstName"
            value={user?.firstName}
            register={{
              ...register("firstName", {
                validate: validateName,
              }),
            }}
            error={errors?.firstName}
            errorMessage={errors?.firstName?.message}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            isRequired
          />
          <SInput
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={user?.lastName}
            register={{
              ...register("lastName", {
                validate: validateName,
              }),
            }}
            error={user?.lastName && errors?.lastName}
            errorMessage={user?.lastName ? errors?.lastName?.message : ""}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          />
          <SInput
            name="email"
            type="email"
            placeholder="Email *"
            value={user?.email}
            isRequired
            register={{
              ...register("email", {
                validate: (_) => (user.email?.length || 0) < 80,
              }),
            }}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          />
        </Stack>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitAction)}>
      <Stack>
        {inputs()}
        <Flex justify="flex-end">
          <Button
            variant="ghost"
            mr={3}
            onClick={() => handleCancelAction()}
            color="text"
            fontWeight="600"
          >
            Cancel
          </Button>
          <Button
            colorScheme="primary"
            type="submit"
            isLoading={submitActionLoader()}
          >
            {selectedUser ? "Update" : "Invite User"}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};
