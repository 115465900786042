import { useContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import { Home } from "../components/Home";
import { ActionInterface } from "../apis/role";
import { Users } from "../components/Users/Users";
import { Devices } from "../components/Devices/Devices";
import { Product } from "../components/Products/Product";
import { Business } from "../components/Business/Business";
import { Settings } from "../components/Settings/Settings";
import { RouteProps } from "../packages/interfaces/routes";
import { AuthContext } from "../components/Login/AuthContext";
import { SuperAdmin } from "../components/Superadmin/SuperAdmin";

import users from "../assets/users.png";
import devices from "../assets/devices.png";
import superadmin from "../assets/users.png";
import business from "../assets/business.png";
import products from "../assets/products.png";
import settings from "../assets/settings.png";
import dashboard from "../assets/dashboard.png";
import { ReactComponent as UsersActive } from "../assets/users_focused.svg";
import { ReactComponent as DevicesActive } from "../assets/devices_focused.svg";
import { ReactComponent as ProductsActive } from "../assets/products_focused.svg";
import { ReactComponent as BusinessActive } from "../assets/business_focused.svg";
import { ReactComponent as SettingsActive } from "../assets/settings_focused.svg";
import { ReactComponent as DashboardActive } from "../assets/dashboard_focused.svg";
import { ReactComponent as SuperadminActive } from "../assets/superadmin_focused.svg";

export const routes: Array<RouteProps> = [
  {
    path: "/",
    name: "Dashboard",
    Component: Home,
    exact: true,
    icon: dashboard,
    ActiveIcon: DashboardActive,
    role: "dashboard",
  },
  {
    path: "/superadmin",
    name: "Customer admin",
    Component: SuperAdmin,
    exact: true,
    icon: superadmin,
    ActiveIcon: SuperadminActive,
    role: "superadmin",
  },
  {
    path: "/business",
    name: "Business",
    Component: Business,
    exact: true,
    icon: business,
    ActiveIcon: BusinessActive,
    role: "business",
  },
  {
    path: "/products",
    name: "Products",
    Component: Product,
    exact: true,
    icon: products,
    ActiveIcon: ProductsActive,
    role: "products",
  },
  {
    exact: true,
    icon: devices,
    name: "Devices",
    role: "devices",
    path: "/devices",
    Component: Devices,
    ActiveIcon: DevicesActive,
  },
  {
    exact: true,
    icon: users,
    name: "Users",
    role: "users",
    path: "/users",
    Component: Users,
    ActiveIcon: UsersActive,
  },
  {
    path: "/settings",
    name: "Settings",
    Component: Settings,
    exact: true,
    icon: settings,
    ActiveIcon: SettingsActive,
    role: "settings",
  },
];

export function Routes() {
  const { userRoles } = useContext(AuthContext);

  if (!userRoles) {
    return null;
  }

  return (
    <Switch>
      {routes.map((route: RouteProps) => {
        const { Component, path, exact, role } = route;

        const permissions = userRoles?.[role ?? ""];

        const isViewable = permissions?.some(
          (action: ActionInterface) => action.view
        );

        if (isViewable || doesUserHaveSettingsPermission(permissions, role)) {
          return (
            <Route path={path} key={path} exact={exact} component={Component} />
          );
        }
        return null;
      })}
      <Redirect to="/" />
    </Switch>
  );
}

export const doesUserHaveSettingsPermission = (
  permissions: Array<any>,
  role?: string
) => {
  return role === "settings";
};
