import * as React from "react";

import { Image } from "@chakra-ui/image";
import { CircularProgress } from "@chakra-ui/progress";
import { Box, Center, Flex, Text } from "@chakra-ui/layout";

import { CustomButton } from "./CustomButton";
import { ActionInterface } from "../../apis/role";
import { AuthContext } from "../../components/Login/AuthContext";
import { doesUserHaveSettingsPermission } from "../../routes/Routes";
import { RouteProps, SiderBarContentProps } from "../interfaces/routes";

interface SideBarProps {
  companyLogo: string;
  buttonColor: string;
  activePath: string;
  routes: Array<RouteProps>;
  onItemClick: (route: RouteProps) => void;
  alt?: string;
}

const isEqualPath = (activePath: string, routePath: string) => {
  const actualActivePath = activePath.split("/")[1];
  const mainPath = routePath.split("/")[1];

  return actualActivePath === mainPath;
};

function SiderBarContent(props: SiderBarContentProps) {
  const { buttonColor, route, activePath, onItemClick, icon, ActiveIcon } =
    props;
  const { path } = route;

  const getBgGradient = () => {
    if (activePath === "/" && path === "/") {
      return "linear(to-r, primary.200, primary.700)";
    }

    if (isEqualPath(activePath, route.path)) {
      return "linear(to-r, primary.200, primary.700)";
    }
    return undefined;
  };

  const getColor = () => {
    if (isEqualPath(activePath, route.path)) {
      return "white";
    }
    return "text.700";
  };

  const getFontWeight = () => {
    if (isEqualPath(activePath, route.path)) {
      return "600";
    }
    return "medium";
  };

  const getIcon = () => {
    if (isEqualPath(activePath, route.path)) {
      return <ActiveIcon />;
    }
    return <img src={icon} alt="" />;
  };

  const getShadow = () => {
    if (isEqualPath(activePath, route.path)) {
      return "lg";
    }
    return "";
  };

  return (
    <Flex gridGap="8px" borderRadius="4px" shadow={getShadow()}>
      <CustomButton
        colorScheme={buttonColor}
        bgGradient={getBgGradient()}
        onClick={() => onItemClick(route)}
      >
        <Box d="flex" width="100%" alignItems="center" gridGap="10px">
          {getIcon()}
          <Text fontSize="16px" color={getColor()} fontWeight={getFontWeight()}>
            {route.name}
          </Text>
        </Box>
      </CustomButton>
    </Flex>
  );
}

export function SideBar(props: SideBarProps) {
  const { companyLogo, alt, buttonColor, routes, activePath, onItemClick } =
    props;

  const { userRoles } = React.useContext(AuthContext);

  if (!userRoles) {
    return (
      <Center h="100vh">
        <CircularProgress isIndeterminate />
      </Center>
    );
  }

  return (
    <Box
      minH="100vh"
      borderTopRightRadius="25px"
      boxShadow="0px 12px 24px #eceef5"
    >
      <Box>
        <Image
          src={companyLogo}
          alt={alt}
          width="100%"
          padding={12}
          paddingLeft={9}
          pt={16}
          objectFit="cover"
          cursor="pointer"
          margin="auto"
        />
      </Box>

      <Flex gridGap="16px" padding="4px" flexDirection="column" margin="12px">
        {routes?.map((route: RouteProps) => {
          const { role, isInSideBar } = route;

          if (isInSideBar === false) {
            return null;
          }

          const roleOfUser = userRoles?.[role ?? ""];

          const isViewable =
            roleOfUser?.some((role: ActionInterface) => role.view) ?? false;

          if (isViewable || doesUserHaveSettingsPermission(roleOfUser, role)) {
            return (
              <SiderBarContent
                route={route}
                key={route.path}
                icon={route.icon}
                activePath={activePath}
                buttonColor={buttonColor}
                onItemClick={onItemClick}
                ActiveIcon={route.ActiveIcon}
              />
            );
          }
          return null;
        })}
      </Flex>
    </Box>
  );
}
