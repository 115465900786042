import dayjs from "dayjs";
import { ToastPosition, UseToastOptions } from "@chakra-ui/react";

export const removeDuplicatesFromArrayOfObjects = (
  array: any[],
  key?: string
) => {
  return arrayToObjects(array, key).values();
};

export const objectsToArrays = (
  object: any,
  property?: "key" | "both"
): any[] => {
  if (property === "key") {
    return Object.keys(object);
  } else if (property === "both") {
    return Object.entries(object ?? {});
  } else {
    return Object.values(object ?? {});
  }
};

export const arrayToObjects = (array: any[], key?: string) => {
  const objects = array.reduce((acc, item) => {
    acc[item[key ?? "_id"]] = item;
    return acc;
  }, {});
  return objects;
};

export function getFormattedStringDate(date: Date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
}

export const epochToDateFormat = (millisecond: number) => {
  return dayjs(millisecond).format("YYYY-MM-DD");
};

export const changeIntoEpochTime = (date: string) => {
  return dayjs(date).valueOf();
};

export const getAccessDeniedErrorMessage = (
  actionType: string,
  type: string
): UseToastOptions => {
  const description = `Not Allowed to ${actionType} ${type}.`;

  return {
    description,
    duration: 3000,
    status: "warning",
  };
};

export const differenceSets = (firstArray: string[], secondArray: string[]) => {
  const set1 = new Set(firstArray);
  const set2 = new Set(secondArray);

  const difference = new Set([...set1].filter((current) => !set2.has(current)));
  return Array.from(difference);
};

export const getCurrentDate = (
  type: "number" | "formatted",
  overload?: number
) => {
  switch (type) {
    case "formatted":
      if (overload) {
        return dayjs().add(1, "day").startOf("day").format("YYYY-MM-DD");
      }
      return dayjs().format("YYYY-MM-DD");
    case "number":
      if (overload) {
        return dayjs().add(1, "day").startOf("day").valueOf();
      }
      return dayjs().valueOf();
  }
};

export const sanitizeObject = (object: any) => {
  const cleanObject = objectsToArrays(object, "key").reduce(
    (sanitizedObject: any, field: string) => {
      if (object[field] === undefined || object[field] === null) {
        return sanitizedObject;
      }
      sanitizedObject[field] = object[field];
      return sanitizedObject;
    },
    {}
  );
  return cleanObject;
};

export const minToMilliSeconds = (value: number) => {
  const minutesInSeconds = 60;
  const secondsInMilliSeconds = 1000;
  return value * minutesInSeconds * secondsInMilliSeconds;
};

interface ToastProps {
  variant: string;
  duration: number;
  isClosable: boolean;
  position: ToastPosition;
}

export const toastConfigs: ToastProps = {
  duration: 3000,
  isClosable: true,
  variant: "solid",
  position: "bottom",
};
