import { useContext } from "react";

import { useHistory } from "react-router-dom";
import { Grid, Center, Heading, CircularProgress } from "@chakra-ui/react";

import { useProducts } from "../apis/product";
import Page from "../packages/components/Page";
import { useBusiness } from "../apis/business";
import { useEntityUsers } from "../apis/users";
import { ActionInterface } from "../apis/role";
import SCard from "../packages/components/SCard";
import { useCustomers } from "../apis/customers";
import { AuthContext } from "./Login/AuthContext";

const routes = ["superadmin", "products", "business", "users"];

export function Home() {
  const history = useHistory();

  const { userRoles } = useContext(AuthContext);

  const customers = useCustomers();
  const business = useBusiness();
  const products = useProducts();

  const stratosfyBusiness = (business?.data?.data ?? []).find(
    (business) => business.businessName === "stratosfy"
  );
  const stratofyBusinessKey = stratosfyBusiness?._id ?? "";
  const users = useEntityUsers([stratofyBusinessKey], "business");

  const userKey = "users";
  const productKey = "products";
  const businessKey = "business";
  const customerKey = "superadmin";

  const details = {
    [customerKey]: getCustomersCount() ?? "0",
    [productKey]: getProducstCount() ?? "0",
    [businessKey]: getBusinessCount() ?? "0",
    [userKey]: getUsersCount() ?? "0",
  };

  const canView: { [key: string]: any } = {
    [customerKey]:
      userRoles?.[customerKey]?.some(
        (action: ActionInterface) => action.view
      ) ?? false,
    [businessKey]:
      userRoles?.[businessKey]?.some(
        (action: ActionInterface) => action.view
      ) ?? false,
    [productKey]:
      userRoles?.[productKey]?.some((action: ActionInterface) => action.view) ??
      false,
    [userKey]:
      userRoles?.[userKey]?.some((action: ActionInterface) => action.view) ??
      false,
  };

  function getCustomersCount() {
    const customerCount = customers.data?.data.length;
    return customerCount;
  }

  function getBusinessCount() {
    const businessCount = business.data?.data.length;
    return businessCount;
  }

  function getProducstCount() {
    const productsCount = products.data?.data.length;
    return productsCount;
  }

  function getUsersCount() {
    const { data = [] } = users;
    const usersCount = data.length;
    return usersCount;
  }

  function mapCards() {
    return Object.entries(details).map((detail, index) => {
      const title = detail[0];
      const subtitle = detail[1];
      const link = routes[index];

      if (canView[title]) {
        return (
          <SCard
            title={title === "superadmin" ? "Customer admin" : title}
            subtitle={subtitle}
            link={() => history.push(link)}
            key={title}
          />
        );
      }
      return null;
    });
  }

  if (
    customers.isLoading ||
    business.isLoading ||
    products.isLoading
    // userRoles === undefined
  ) {
    return (
      <Center h="100%">
        <CircularProgress isIndeterminate color="blue.500" />
      </Center>
    );
  }

  return (
    <Page>
      <Heading as="h1" fontSize="36px">
        Dashboard
      </Heading>
      <Grid gridGap="4" templateColumns="repeat(auto-fill, minmax(350px, 1fr))">
        {mapCards()}
      </Grid>
    </Page>
  );
}
