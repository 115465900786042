import "../../App.css";

import { useRef } from "react";
import {
  Text,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Divider,
} from "@chakra-ui/react";

interface DialogProps {
  open: boolean;
  body: string;
  title: string;
  size?: string;
  loading?: boolean;
  subTitle?: string;
  isDisabled?: boolean;
  positiveLabel?: string;
  negativeLabel?: string;
  children?: React.ReactChild;
  positiveLabelColor?: string;
  handlePositiveAction: Function;
  handleNegativeAction: Function;
}

const SDialog = (props: DialogProps) => {
  const {
    open,
    body,
    title,
    loading,
    subTitle,
    isDisabled,
    handlePositiveAction,
    handleNegativeAction,
    positiveLabel,
    negativeLabel,
    size = "md",
    positiveLabelColor = "primary",
  } = props;
  const cancelRef: any = useRef();

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={open}
      onClose={() => handleNegativeAction()}
      isCentered
      size={size}
    >
      <AlertDialogOverlay>
        <AlertDialogContent p="4">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <Divider w="90%" mx="auto" />
          <AlertDialogBody>
            <Text fontSize="sm">{body}</Text>
            <Text color="altText.500" fontSize="xs">
              {subTitle}
            </Text>
            {props.children}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={() => handleNegativeAction()}>
              {negativeLabel ?? "Cancel"}
            </Button>
            <Button
              isDisabled={isDisabled}
              isLoading={loading}
              colorScheme={positiveLabelColor}
              onClick={() => handlePositiveAction()}
              ml={3}
            >
              {positiveLabel ?? "Submit"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default SDialog;
