import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Flex,
  Wrap,
  Stack,
  Select,
  Button,
  Heading,
  useToast,
  Grid,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import PhoneInput, {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

import PassChange from "./PassChange";
import { useEditProfile } from "../../apis/users";
import { AuthContext } from "../Login/AuthContext";
import { SInput } from "../../packages/components/SInput";
import { toastConfigs } from "../../packages/helpers/extra";
import { UpdateUser, User } from "../../packages/interfaces/user";
import ImageContainer from "../../packages/components/ImageContainer";
import { firstLetterUpperCase } from "../../packages/helpers/strings";
import ClickableContainer from "../../packages/components/ClickableContainer";
import Image from "../../assets/svgs/blueMarker.png";
const initialUser: User = {
  firstName: "",
  email: "",
  phoneNumber: "",
  settingsSuperadmin: {
    language: "en",
  },
  business: [],
  isCustomerSuperAdmin: false,
  isDisabled: false,
  lastName: "",
  address: "",
  isCompanySuperAdmin: false,
  loginMethod: "email",
};

const profileUpdateToastId = "profile-update-toast";

const languages = [
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
];
const fallBackImage = "https://bit.ly/dan-abramov";

const Profile = () => {
  const toast = useToast(toastConfigs);
  const { userDetails, userKey } = useContext(AuthContext);

  const [file, setFile] = useState<File | null>();
  const [image, setImage] = useState(userDetails?.imageURL ?? fallBackImage);
  const [editUser, editUserInfo] = useEditProfile();
  const [openPassChange, setOpenPassChange] = useState(false);
  const [user, setUser] = useState<User>({
    ...(initialUser ?? {}),
    ...(userDetails ?? {}),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: initialUser,
  });
  const history = useHistory();

  function handleInputChange(field: string, value: string) {
    if (field === "language") {
      setUser({
        ...user,
        settingsSuperadmin: {
          ...user.settingsSuperadmin,
          language: value,
        },
      });
    } else {
      setUser({ ...user, [field]: value });
    }
  }

  function handleCancelAction() {
    setUser({ ...(userDetails || initialUser) });
    history.push("/");
  }

  async function handleSubmitAction() {
    const {
      firstName,
      address,
      lastName,
      phoneNumber,
      userKey: id = "",
      email,
      settingsSuperadmin,
    } = user;
    let updatedUser: UpdateUser = {
      address,
      lastName,
      firstName,
      updatedBy: userKey ?? "",
      phoneNumber: phoneNumber ?? "",
      email,
      settingsSuperadmin,
    };
    if (file) {
      updatedUser.image = file;
    }
    try {
      await editUser({
        id,
        updatedUser: { ...updatedUser, removeImage: false },
      });
      if (!toast.isActive(profileUpdateToastId)) {
        toast({
          status: "success",
          id: profileUpdateToastId,
          description: "User details has been updated.",
        });
      }
    } catch (err: any) {
      editUserInfo.reset();
      return toast({
        description:
          err?.response?.data?.message ??
          "Error occured while updating user details.",
        status: "error",
        duration: 3000,
      });
    }
  }

  function toggleShowPassChange() {
    setOpenPassChange(!openPassChange);
  }

  function changeProfilePicture(file: FileList | null) {
    if (file) {
      setFile(file[0]);
      setImage(URL.createObjectURL(file[0]));
    }
  }

  function validatePhoneNumber() {
    const { phoneNumber } = user;
    if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
      const formatedPhoneNumber = formatPhoneNumber(phoneNumber);
      const localPhoneNumber = formatedPhoneNumber.split(" ").splice(0, 1);
      const checkPhoneNumber = localPhoneNumber.join();

      if (checkPhoneNumber?.length !== 10) return "Invalid phone number";
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitAction)}>
        <Grid templateColumns="repeat(auto-fill, minmax(500px, 1fr))">
          <Box h="746px">
            <Box h="92%" boxShadow="xl" p="6" rounded="md" bg="white">
              <Stack spacing="8">
                <Flex justify="space-between" align="center">
                  <Heading as="h1" size="lg" color="text.500">
                    Profile
                  </Heading>
                </Flex>
                <Flex gridGap="8" direction="column">
                  <ImageContainer
                    size="200px"
                    imageURL={image}
                    objectFit="cover"
                    onChange={(e) => changeProfilePicture(e.target.files)}
                  />
                  <Stack spacing="6">
                    <Heading as="h1" size="md" color="text.500">
                      Details
                    </Heading>
                    <Flex gridGap="2">
                      <SInput
                        isRequired
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={user?.firstName}
                        onChange={(e) =>
                          handleInputChange(e.target.name, e.target.value)
                        }
                      />
                      <SInput
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={user.lastName}
                        onChange={(e) =>
                          handleInputChange(e.target.name, e.target.value)
                        }
                      />
                    </Flex>
                    <SInput
                      isRequired
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={user.email}
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                      error={errors.email}
                      errorMessage="Please enter valid email."
                      isDisabled
                    />
                    <Flex>
                      <PhoneInput
                        international
                        // @ts-ignore
                        countryCallingCodeEditable={false}
                        // @ts-ignore
                        withCountryCallingCode
                        defaultCountry="CA"
                        value={user?.phoneNumber ?? ""}
                        onChange={(e) => handleInputChange("phoneNumber", e)}
                      />
                      <SInput
                        type="number"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        value={user?.phoneNumber}
                        onChange={(e) =>
                          handleInputChange(e.target.name, e.target.value)
                        }
                        register={{
                          ...register("phoneNumber", {
                            validate: validatePhoneNumber,
                          }),
                        }}
                        error={errors?.phoneNumber}
                        errorMessage={errors?.phoneNumber?.message}
                      />
                    </Flex>
                    <SInput
                      type="children"
                      name="language"
                      placeholder="Language"
                      value={firstLetterUpperCase(
                        user?.settingsSuperadmin?.language
                      )}
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value)
                      }
                    >
                      <Select
                        name="language"
                        defaultValue={firstLetterUpperCase(
                          user?.settingsSuperadmin?.language ?? "en"
                        )}
                        onChange={(e) =>
                          handleInputChange(e.target.name, e.target.value)
                        }
                        variant="unstyled"
                        value={user?.settingsSuperadmin?.language}
                      >
                        {languages.map((language) => (
                          <option key={language.label} value={language.value}>
                            {language.label}
                          </option>
                        ))}
                      </Select>
                    </SInput>
                    <Flex justifyContent="space-between" alignItems="center">
                      <ClickableContainer
                        onClick={toggleShowPassChange}
                        isDisabled={editUserInfo.isLoading}
                      >
                        <Heading size="xs" color="text.800">
                          Change Password
                        </Heading>
                      </ClickableContainer>
                      <Wrap justify="flex-end">
                        <Button
                          variant="ghost"
                          isDisabled={editUserInfo.isLoading}
                          onClick={() => handleCancelAction()}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="solid"
                          colorScheme="blue"
                          isLoading={editUserInfo.isLoading}
                        >
                          Update Changes
                        </Button>
                      </Wrap>
                    </Flex>
                  </Stack>
                </Flex>
              </Stack>
            </Box>
          </Box>
        </Grid>
      </form>
      {openPassChange && (
        <PassChange
          username={user?.email ?? ""}
          userKey={user?.userKey ?? ""}
          handleCancel={toggleShowPassChange}
        />
      )}
    </>
  );
};

export default Profile;
