export const firstLetterUpperCase = (text: string) => {
  const firstLetterCapitalize = text.charAt(0).toUpperCase();
  const remainingLetterLowerCased = text.slice(1).toLowerCase();
  return `${firstLetterCapitalize}${remainingLetterLowerCased}`;
};

export const lowerCaseLetter = (text: string) => {
  return text.toLowerCase();
};

export const concatStrings = (joinBy: string, ...args: string[]) => {
  return args.join(joinBy);
};

export const splitStrings = (text: string, splitBy?: string) => {
  return text.split(splitBy ?? " ");
};

export const upperCaseLetter = (text: string) => {
  return text.toUpperCase();
};

export const isUpperCase = (text: string) => {
  return text === text.toUpperCase();
};

export const regExpForEmail = new RegExp(
  /[a-zA-Z0-9._-]{3,}@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,4}/
);
