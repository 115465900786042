import { useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Stack,
  Button,
  Heading,
  useToast,
  IconButton,
} from "@chakra-ui/react";

import { AddRole } from "./AddRole";
import { ChangeUserRole } from "./ChangeRole";
import { useBusiness } from "../../apis/business";
import { useEntityUsers } from "../../apis/users";
import { AuthContext } from "../Login/AuthContext";
import { Role } from "../../packages/interfaces/user";
import SDialog from "../../packages/components/SDialog";
import LongText from "../../packages/components/LongText";
import { SDrawer } from "../../packages/components/SDrawer";
import { PlusIcon, EditIcon, DelIcon, ChangeRoleIcon } from "../../utils/Icons";
import {
  toastConfigs,
  objectsToArrays,
  getAccessDeniedErrorMessage,
} from "../../packages/helpers/extra";
import {
  useDeleteRole,
  ActionInterface,
  useGetRolesByBusiness,
} from "../../apis/role";

const roleResourceId = "roles";
const superadminBusiness = "stratosfy";

const UserRoles = () => {
  const { userRoles } = useContext(AuthContext);

  const toast = useToast(toastConfigs);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showChangeUserRole, setShowChangeUserRole] = useState(false);
  const [superadminBusinessKey, setSuperadminBusinessKey] =
    useState<string>("");

  const [selectedUserRole, setSelectedUserRole] = useState<Role>();

  const [deleteRole, deleteRoleInfo] = useDeleteRole();
  const { data: businesses } = useBusiness();
  const businessList = businesses?.data ?? [];
  const businessKeys = businessList.map((business) => business._id ?? "");

  const { data } = useGetRolesByBusiness(businessKeys ?? []);
  const roles = data?.data.businessRoles ?? {};

  const roleKey = selectedUserRole?.roleKey ? [selectedUserRole?.roleKey] : [];
  const { data: roleUsers, isLoading: fetchUsersUnderRoleLoading } =
    useEntityUsers(roleKey, "role");

  useEffect(() => {
    const superadminBusinessDetail = businessList?.find(
      (business) => business.businessName === superadminBusiness
    );
    setSuperadminBusinessKey(superadminBusinessDetail?._id ?? "");
  }, [businessList.length]);

  function handleCancelAction() {
    setOpenDrawer(false);
    setSelectedUserRole(undefined);
  }

  const usersResourceId = "users";

  function handleChangeUser(role: Role) {
    const canUserEditUser =
      userRoles?.[usersResourceId]?.some(
        (role: ActionInterface) => role.edit
      ) ?? false;

    if (!canUserEditUser) {
      toast(getAccessDeniedErrorMessage("change", "role of users"));
      return;
    }
    setSelectedUserRole(role);
    setShowChangeUserRole(true);
  }

  function handleEditUser(role: Role) {
    const canUserEditRole =
      userRoles?.[roleResourceId]?.some((role: ActionInterface) => role.edit) ??
      false;

    if (!canUserEditRole) {
      return toast(getAccessDeniedErrorMessage("edit", "role"));
    }
    setSelectedUserRole({ ...role });
    setOpenDrawer(true);
  }

  function handleDeleteUser(role: Role) {
    const canUserDeleteTheRole =
      userRoles?.[roleResourceId]?.some(
        (role: ActionInterface) => role.delete
      ) ?? false;
    if (!canUserDeleteTheRole) {
      return toast(getAccessDeniedErrorMessage("delete", "the role"));
    }
    setSelectedUserRole(role);
    setOpenDialog(true);
  }

  function getRolesObject() {
    const allRoles = objectsToArrays(roles).reduce((acc, roles: Role[]) => {
      return [...acc, ...roles];
    }, []);
    return allRoles;
  }

  async function handleDeleteUserAction() {
    const usersUnderRole = getAssignedUsers();
    if (usersUnderRole && usersUnderRole > 0) {
      return toast({
        duration: 3000,
        status: "error",
        description: "Role has users assigned. Can't delete this role",
      });
    }
    try {
      const { roleKey = "" } = selectedUserRole as Role;
      await deleteRole(roleKey);
      setOpenDialog(false);
      setSelectedUserRole(undefined);
      return toast({
        description: "Role deleted successfully!",
        status: "success",
      });
    } catch (e: any) {
      return toast({
        description: e?.response?.data?.message ?? "Cannot delete role.",
        status: "error",
      });
    }
  }

  function getAssignedUsers() {
    return roleUsers?.length ?? 0;
  }

  function mapRolesList(roles: any) {
    return roles.map((role: Role) => (
      <Box
        key={role.roleKey}
        d="flex"
        justifyContent="space-between"
        boxShadow="xl"
        p="8"
        rounded="md"
        bg="white"
      >
        <Flex flexDir="column">
          <Heading as="h3" size="md" mb="2">
            {role.name}
          </Heading>
          <LongText content={role?.description ?? ""} />
        </Flex>
        <Flex dir="row">
          <ActionButton
            role={role}
            editUser={handleEditUser}
            changeUser={handleChangeUser}
            deleteUser={handleDeleteUser}
          />
        </Flex>
      </Box>
    ));
  }

  const handleAddUserRole = () => {
    setSelectedUserRole(undefined);
    const canUserAddRole =
      userRoles?.[roleResourceId]?.some(
        (permission: ActionInterface) => permission?.create
      ) ?? false;

    if (!canUserAddRole) {
      toast(getAccessDeniedErrorMessage("Add", "role"));
      return;
    }
    setOpenDrawer(true);
  };
  function handleCancel() {
    setOpenDialog(false);
    setSelectedUserRole(undefined);
  }

  return (
    <>
      <Box h="100%" boxShadow="xl" p="6" rounded="md" bg="white">
        <Flex justify="space-between" mb="4">
          <Heading as="h1" size="lg">
            User role
          </Heading>
          <Button
            leftIcon={<PlusIcon />}
            size="sm"
            colorScheme="blue"
            onClick={() => handleAddUserRole()}
          >
            Add User Role
          </Button>
        </Flex>
        <Stack
          p="4"
          maxH="lg"
          spacing="4"
          overflowX="hidden"
          overflowY="scroll"
        >
          {Object.values(roles).map((rolesList: any) =>
            mapRolesList(rolesList)
          )}
        </Stack>
      </Box>
      <SDrawer
        open={openDrawer}
        title={selectedUserRole?.name ? "Edit User Role" : "Add User Role"}
        showActionButtons={false}
        negativeAction={handleCancelAction}
      >
        <AddRole
          role={selectedUserRole}
          roles={getRolesObject()}
          handleCancel={handleCancelAction}
        />
      </SDrawer>
      <SDialog
        open={openDialog}
        loading={deleteRoleInfo.isLoading || fetchUsersUnderRoleLoading}
        title={`Delete User role ? `}
        body=""
        subTitle=""
        handleNegativeAction={handleCancel}
        handlePositiveAction={() => {
          handleDeleteUserAction();
        }}
        positiveLabelColor="red"
        positiveLabel="Delete"
        size="lg"
      >
        <Box mt="2">
          <Flex align="center">
            <Text fontSize="sm">Are you sure want to delete user role - </Text>
            <Text ml="2" fontWeight="semibold">
              {selectedUserRole?.name}
            </Text>
          </Flex>
          <Text fontSize="sm" color="altText.500">
            ({getAssignedUsers()} people are under this role)
          </Text>
          <Text mt="2" fontSize="xs" color="altText.500">
            Remember, the action cannot be undone and also it removes people
            under the user role
          </Text>
          <Flex align="center" gridGap="2">
            <Text fontSize="xs" color="altText.500">
              Do you want to move them to a new role?
            </Text>
            <Text
              fontSize="sm"
              color="primary.500"
              cursor="pointer"
              onClick={() => setShowChangeUserRole(true)}
            >
              Click here
            </Text>
          </Flex>
        </Box>
      </SDialog>
      {showChangeUserRole && (
        <ChangeUserRole
          show={showChangeUserRole}
          role={selectedUserRole}
          roles={getRolesObject()}
          businessKeys={businessKeys}
          setShow={setShowChangeUserRole}
          superadminBusinessKey={superadminBusinessKey}
        />
      )}
    </>
  );
};

export default UserRoles;

interface ActionButtonProps {
  role: any;
  changeUser: Function;
  editUser: Function;
  deleteUser: Function;
}

const ActionButton = (props: ActionButtonProps) => {
  const { changeUser, editUser, deleteUser, role } = props;
  return (
    <>
      <IconButton
        icon={<ChangeRoleIcon />}
        aria-label="change"
        bg="white"
        onClick={() => changeUser(role)}
      />
      <IconButton
        icon={<EditIcon />}
        aria-label="edit"
        bg="white"
        onClick={() => editUser(role)}
      />
      <IconButton
        icon={<DelIcon />}
        aria-label="delete"
        bg="white"
        onClick={() => deleteUser(role)}
      />
    </>
  );
};
