import { useEffect, useState } from "react";
import { Button, Flex } from "@chakra-ui/react";

interface LongTextProps {
  content: string;
}

const maxLetters = 100;

export default function LongText(props: LongTextProps) {
  const { content } = props;

  const [show, setShow] = useState(content.length <= 70);
  const [isTruncated, setIsTruncated] = useState(false);
  const [filteredContent, setFilteredContent] = useState("");

  useEffect(() => {
    handleClippingText();
  }, []);

  function handleClippingText() {
    if (content.length > maxLetters) {
      const clippedContent = content.slice(0, maxLetters);
      setFilteredContent(`${clippedContent}.....`);
      setIsTruncated(true);
    } else {
      setFilteredContent(content);
    }
  }

  function showMore() {
    if (show) {
      handleClippingText();
    } else {
      setFilteredContent(content);
    }
    setShow(!show);
  }

  return (
    <Flex gridGap="4" flexDir="column">
      {filteredContent}
      {isTruncated && (
        <Button
          size="xs"
          bg="transparent"
          color="primary.500"
          fontWeight="semibold"
          _focus={{ outline: "none" }}
          onClick={() => showMore()}
        >
          {show ? "Show less" : "Show more"}
        </Button>
      )}
    </Flex>
  );
}
