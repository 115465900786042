import { useEffect, useState } from "react";

import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

import { Notification } from "../../packages/interfaces/product";
import { NotificationInSubscription } from "../../packages/interfaces/subscription";

interface Props {
  notifications: Notification[];
  selectedNotifications: NotificationInSubscription[];
  handleInputChange: (
    field: string,
    value: NotificationInSubscription[]
  ) => void;
}

interface MultiSelect {
  label: string;
  value: string;
}

export const Notifications = ({
  notifications,
  handleInputChange,
  selectedNotifications,
}: Props) => {
  const [options, setOptions] = useState<MultiSelect[]>([]);
  const [notificationsThatSelected, setNotificationSelected] = useState<
    MultiSelect[]
  >([]);

  useEffect(() => {
    const alreadySelectedNotifications = selectedNotifications?.map(
      (notification) => ({
        label: notification.id ?? notification,
        value: notification.id ?? notification,
      })
    );
    setNotificationSelected(alreadySelectedNotifications);
  }, [selectedNotifications]);

  useEffect(() => {
    const notificationOptions = notifications?.map((notification) => ({
      label: notification.notificationId,
      value: notification.notificationId,
    }));
    setOptions(notificationOptions);
  }, [notifications]);

  const handleChange = (data: MultiSelect[]) => {
    const actualNotifications =
      data?.map((notification) => notification.value) ?? [];

    const notificationWithName = actualNotifications?.map((notificationId) => {
      const notificationDetails = notifications?.find(
        (notification) => notification.notificationId === notificationId
      );

      return {
        id: notificationId,
        name: notificationDetails?.name ?? "",
      };
    });
    handleInputChange("notifications", notificationWithName);
  };

  return (
    <ReactMultiSelectCheckboxes
      options={options}
      menuPlacement="top"
      styles={customStyles}
      onChange={handleChange}
      value={notificationsThatSelected}
    />
  );
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "normal",
  }),
  menu: () => ({
    bottom: 90,
    width: 432,
    borderRadius: 8,
    position: "absolute",
    backgroundColor: "white",
    boxShadow: "0px 1px 4px gray",
  }),
  control: () => ({
    width: 264,
    display: "flex",
    borderRadius: 8,
    backgroundColor: "white",
    border: "solid 1px #E2E8F0",
  }),
  dropdownButton: (provided) => ({
    ...provided,
    width: "100%",
  }),
};
