import "@reach/combobox/styles.css";

import { ChangeEvent, useEffect, useState } from "react";
import {
  Combobox,
  ComboboxList,
  ComboboxInput,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

interface AddressSearchProps {
  defaultValue: string;
  onAddressChange: (value: string, latLongRes: Object) => void;
}

export const AddressSearch = ({
  defaultValue,
  onAddressChange,
}: AddressSearchProps) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({ debounce: 300 });

  const [showSuggestions, setShowSuggestions] = useState(
    defaultValue ? false : true
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const address = e.target.value?.trim();
    onAddressChange(address, {});
    setShowSuggestions(true);
    setValue(e.target.value);
  };

  const handleSelect = async (val: string) => {
    setValue(val, false);
    clearSuggestions();

    const result = await getGeocode({ address: val });

    try {
      const latLngRes = await getLatLng(result[0]);

      onAddressChange(val, latLngRes);
    } catch (error) {
      console.error({ error });
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, []);

  return (
    <StyledPaper>
      <Combobox
        style={{ width: "100%" }}
        onSelect={handleSelect}
        aria-labelledby="demo"
      >
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Enter an address *"
          style={{
            width: "100%",
            height: 24,
            outline: "none",
          }}
        />
        {showSuggestions && (
          <ComboboxPopover style={{ zIndex: 2000 }}>
            <ComboboxList>
              {data.map(({ place_id: placeId, description }) => {
                return (
                  <ComboboxOption
                    key={placeId}
                    value={description}
                    style={{
                      listStyle: "none",
                      margin: "10px 0",
                      fontSize: 12,
                      fontWeight: "bold",
                      cursor: "pointer",
                      zIndex: 1000,
                    }}
                  />
                );
              })}
            </ComboboxList>
          </ComboboxPopover>
        )}
      </Combobox>
    </StyledPaper>
  );
};

interface StyledPaperProps {
  children: JSX.Element;
}
const StyledPaper = (props: StyledPaperProps) => {
  return (
    <div
      style={{
        margin: "auto",
        width: "100%",
        padding: "4px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {props?.children}
    </div>
  );
};
