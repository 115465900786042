import { useState, useEffect } from "react";
import {
  Box,
  Td,
  Flex,
  Icon,
  Text,
  Avatar,
  Tooltip,
  Checkbox,
} from "@chakra-ui/react";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";

import Collapse from "./Collapse";
import { AddSubType, DisabledIcon } from "../../utils/Icons";
import { STableRow } from "../../packages/components/STable";
import { Business } from "../../packages/interfaces/business";
import { Customer } from "../../packages/interfaces/customer";
import { upperCaseLetter } from "../../packages/helpers/strings";
import { Subscription } from "../../packages/interfaces/subscription";
import ClickableContainer from "../../packages/components/ClickableContainer";

interface CustomRowProps {
  checked: boolean;
  business: Business;
  onNameClick?: () => void;
  selectedSubscriptions: any;
  onAddSubscription: Function;
  handleChangeCheck: () => void;
  selectSubscription: (
    subscription: Subscription | null,
    business?: any
  ) => void;
  selectCustomer: (customer: Customer) => void;
  selectedCustomers: SelectedCustomers;
  setBusinessKey: (key: string) => void;
  businessKey: string;
}
export interface SelectedCustomers {
  [userKey: string]: Customer;
}

const CustomRow = (props: CustomRowProps) => {
  const {
    business,
    checked,
    onNameClick,
    handleChangeCheck,
    onAddSubscription,
    selectSubscription,
    selectedSubscriptions,
    selectCustomer,
    selectedCustomers,
    setBusinessKey,
    businessKey,
  } = props;

  const {
    subscriptions = [],
    businessName,
    businessAddress,
    enabled = true,
    imageUrl,
    customerSuperAdminUsers,
    _id: businessID,
  } = business;

  //TODO: check permissions

  // const { userRoles } = useContext(AuthContext);

  const action = "Subscriptions";

  // const canUserViewSubscription = userRoles?.[action]?.some(
  //   (action: ActionInterface) => action.view
  // );

  const [openCollapse, setOpenCollapse] = useState<
    "superAdmin" | "subscriptions" | string
  >("");

  useEffect(() => {
    if (businessKey !== businessID) {
      setOpenCollapse("");
    }
  }, [businessKey]);
  const noOfSubscriptions = subscriptions?.length;

  function handleToggleCollapse(
    selectedEntity: "superAdmin" | "subscriptions",
    selectedBusinessKey: string
  ) {
    if (businessKey !== businessID) {
      selectSubscription(null);
    }
    if (openCollapse === selectedEntity) {
      setOpenCollapse("");
    } else if (selectedEntity === "subscriptions" && !noOfSubscriptions) {
      onAddSubscription();
    } else {
      setOpenCollapse(selectedEntity);
      setBusinessKey(selectedBusinessKey);
    }
  }

  function renderSubscriptionCell(id: string) {
    return (
      <ClickableContainer
        onClick={() => {
          handleToggleCollapse("subscriptions", id);
        }}
      >
        <Flex justify="flex-start" align="center" gridGap="4">
          <Flex
            height="md"
            padding="2"
            minWidth="md"
            color="text.500"
            borderRadius="md"
            colorScheme="white"
            bg="background.600"
            alignItems="center"
            justifyContent="center"
          >
            {noOfSubscriptions ? (
              <Text>{noOfSubscriptions}</Text>
            ) : (
              <AddSubType />
            )}
          </Flex>
          <Text fontSize="md" color="text.600">
            Subscriptions
            <Icon
              color="altText.500"
              cursor="pointer"
              as={
                openCollapse === "subscriptions"
                  ? IoMdArrowDropup
                  : IoMdArrowDropdown
              }
            ></Icon>
          </Text>
        </Flex>
      </ClickableContainer>
    );
  }

  const renderAssignedSuperAdmins = (id: string) => {
    return (
      <ClickableContainer
        onClick={() => {
          handleToggleCollapse("superAdmin", id);
        }}
      >
        <Flex justify="flex-start" align="center" gridGap="4">
          <Box
            p="2"
            width="md"
            height="md"
            color="text.500"
            borderRadius="md"
            colorScheme="white"
            bg="background.600"
          >
            {customerSuperAdminUsers?.length ?? 0}
          </Box>
          <Text fontSize="md" color="text.600">
            Customer admin
            <Icon
              color="altText.500"
              cursor="pointer"
              as={
                openCollapse === "superAdmin"
                  ? IoMdArrowDropup
                  : IoMdArrowDropdown
              }
            ></Icon>
          </Text>
        </Flex>
      </ClickableContainer>
    );
  };

  function renderBusinessNameCell() {
    return (
      <Flex align="center" gridGap="30px">
        <>
          <Checkbox
            bg="white"
            paddingLeft="24px"
            isChecked={checked}
            borderRadius="12px"
            borderColor="primary.500"
            onChange={handleChangeCheck}
          />
          <Flex onClick={onNameClick} alignItems="center" gridGap="16px">
            <Avatar name={businessName} src={imageUrl} />
            {businessName?.length >= 38 ? (
              <Tooltip label={upperCaseLetter(businessName)}>
                <Flex flexWrap="wrap" width="50%">
                  <Text>{upperCaseLetter(businessName)}</Text>
                </Flex>
              </Tooltip>
            ) : (
              <Text isTruncated>{upperCaseLetter(businessName)}</Text>
            )}
            {!enabled && <DisabledIcon />}
          </Flex>
        </>
      </Flex>
    );
  }

  async function handleAddSubscription() {
    await onAddSubscription();
  }

  return (
    <STableRow
      checked={checked}
      onChangeCheck={handleChangeCheck}
      collapseOpen={!!openCollapse}
      collapseData={
        businessKey &&
        openCollapse && (
          <Collapse
            selectedBusiness={business}
            addSubcription={handleAddSubscription}
            selectSubscription={selectSubscription}
            selectCustomer={selectCustomer}
            subscriptions={subscriptions}
            selectedSubscriptions={selectedSubscriptions}
            superAdminUsers={customerSuperAdminUsers ?? []}
            openCollapse={openCollapse}
            selectedCustomers={selectedCustomers}
          />
        )
      }
    >
      <Td cursor="pointer">{renderBusinessNameCell()}</Td>
      <Td>{businessAddress}</Td>

      {/* //TODO: check permissions */}
      {<Td>{businessID && renderAssignedSuperAdmins(businessID)}</Td>}
      {<Td>{businessID && renderSubscriptionCell(businessID)}</Td>}
    </STableRow>
  );
};

export default CustomRow;
