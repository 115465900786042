import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  useQuery,
  useMutation,
  useQueryCache,
  MutateFunction,
} from "react-query";

import network from "./network";
import { objectToFormData } from "../utils/helpers";
import { objectsToArrays } from "../packages/helpers/extra";
import { GetRolesResponse } from "../packages/interfaces/role";
import { Customer, UserBusiness } from "../packages/interfaces/customer";
import {
  User,
  UpdateUser,
  ResendInviteToUser,
} from "../packages/interfaces/user";

const userUri = "usermgmt/users";
const rolesUri = "usermgmt/roles";


const resendInvitationUrl = `${userUri}/resendInvite`;
const getLocationUsersURL = `${userUri}/getLocationusers`;

interface GetUserResponse {
  success: boolean;
  data: {
    users: User[];
  };
}

async function getEntityUsers(entityType: string, entityKeys: string[]) {
  const resp: AxiosResponse<GetUserResponse> = await (
    await network()
  ).post(`/${userUri}/filterBy`, {
    entityKeys,
    entityType,
    productId: "superadmin",
  });
  return resp.data.data.users;
}

export function useEntityUsers(
  entityKeys: string[],
  entityType: "business" | "role"
) {
  const queryCache = useQueryCache();
  const entType = entityType ?? "business";

  return useQuery(
    ["users", entType, { entityKeys }],
    async () => {
      const usersList = await getEntityUsers(entType, entityKeys);
      return usersList;
    },
    {
      onSuccess: (data) => {
        const usersList: User[] = data ?? [];
        usersList.map((user: User) =>
          queryCache.setQueryData(["users", entType, user.userKey], user)
        );
      },
      enabled: entityKeys.length > 0,
    }
  );
}

async function enableOrDisableUser(
  userKey: string,
  status: boolean,
  updatedBy: string
) {
  return (await network()).post(`/${userUri}/setEnabled`, {
    enabled: status,
    userKeys: [userKey],
    updatedBy,
  });
}

export function useEnableOrDisableUser() {
  const queryCache = useQueryCache();

  return useMutation(
    async (details: { id: string; status: boolean; updatedBy: string }) =>
      enableOrDisableUser(details.id, details.status, details.updatedBy),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["users"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

async function editUser(userKey: string, user: UpdateUser) {
  const formData = new FormData();

  Object.keys(user).forEach((field) => {
    // @ts-ignore
    formData.append(field, user[field]);
  });

  return (await network()).put(`/${userUri}/${userKey}`, formData);
}

interface EditUser extends UpdateUser {
  removeImage: boolean;
}

export function useEditUser() {
  const queryCache = useQueryCache();

  return useMutation(
    async (details: { id: string; updatedUser: EditUser }) => {
      await editUser(details.id, details.updatedUser);
      return details.id;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["users"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

const editProfileOfUser = async (userKey: string, user: UpdateUser) => {
  const formattedFormData = objectToFormData(user);

  return (await network()).put(
    `/${userUri}/${userKey}/profile`,
    formattedFormData
  );
};

export function useEditProfile() {
  const queryCache = useQueryCache();
  return useMutation(
    async (details: { id: string; updatedUser: EditUser }) => {
      await editProfileOfUser(details.id, details.updatedUser);
      return details.id;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["users"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

async function deleteUser(userKey: string) {
  return (await network()).delete(`/${userUri}/${userKey}`);
}

export function useDeleteUser() {
  const queryCache = useQueryCache();

  return useMutation(
    async (userKeys: string[]) =>
      Promise.all(userKeys.map((userKey) => deleteUser(userKey))),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["users"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

async function createUser(details: any) {
  return (await network()).post(`/${userUri}/`, details);
}

export function useCreateUser() {
  const queryCache = useQueryCache();

  return useMutation(async (details: any) => createUser(details), {
    onSuccess: () => {
      queryCache.invalidateQueries(["users"]);
    },
    onError: (e) => {
      throw e;
    },
  });
}

interface AssignRoleToUser {
  roleKey: string;
  userKeys: string[];
}

export async function assignRoleToUsers(roleKey: string, userKeys: string[]) {
  return (await network()).post(`/${userUri}/addRoleToUsers`, {
    roleKey,
    userKeys,
  });
}

export async function removeRoleFromUsers(roleKey: string, userKeys: string[]) {
  return (await network()).post(`/${userUri}/removeRoleFromUsers`, {
    roleKey,
    userKeys,
  });
}

export function useAssignRoleToUser() {
  const queryCache = useQueryCache();
  return useMutation(
    async ({ roleKey, userKeys }: AssignRoleToUser) =>
      assignRoleToUsers(roleKey, userKeys),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["users"]);
      },
    }
  );
}

export function useRemoveRoleFromUsers() {
  const queryCache = useQueryCache();
  return useMutation(
    async ({ roleKey, userKeys }: AssignRoleToUser) =>
      removeRoleFromUsers(roleKey, userKeys),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["users"]);
      },
      onError: (e: any) => {
        throw e;
      },
    }
  );
}

interface ChangeRoleUser {
  oldRoleKey: string;
  newRoleKey: string;
  userKeys: string[];
}

export function useChangeRoleOfUsers() {
  const queryCache = useQueryCache();
  return useMutation(
    async ({ oldRoleKey, newRoleKey, userKeys }: ChangeRoleUser) => {
      await removeRoleFromUsers(oldRoleKey, userKeys);
      await assignRoleToUsers(newRoleKey, userKeys);
    },
    {
      onSuccess: () => {
        setTimeout(() => {
          queryCache.invalidateQueries(["users"]);
        }, 5000);
      },
      onError: (e: any) => {
        throw e;
      },
    }
  );
}

const resendInvitation = async (params: ResendInviteToUser) => {
  const axiosConfig: AxiosRequestConfig = {
    method: "POST",
  };
  const res = await (
    await network()
  ).post(resendInvitationUrl, params, axiosConfig);
  return res.data;
};

export const useReInvitation = () => {
  return useMutation(
    (params: ResendInviteToUser) => {
      const res = resendInvitation(params);
      return res;
    },
    {
      onError: (e) => {
        throw e;
      },
    }
  );
};

const getLocationsUsers = async (productId: string, businessKey: string) => {
  const data = {
    productId,
    business: [
      {
        businessKey,
        locationKeys: [],
      },
    ],
  };
  const resp = await (await network()).post(getLocationUsersURL, data);

  return resp.data;
};

export const useGetLocationUsers = () => {
  return useMutation(
    (params: { productId: string; businessKey: string }) => {
      const { productId, businessKey } = params;
      return getLocationsUsers(productId, businessKey);
    },
    {
      onError: (e) => {
        throw e;
      },
    }
  );
};

async function getRolesByBusiness(
  business: string[]
): Promise<GetRolesResponse> {
  const resp = await (
    await network()
  ).post(`/${rolesUri}/filterByBusiness`, {
    business,
    productId: "superadmin",
  });
  return resp.data;
}

export function useGetRolesByBusiness(business: string[]) {
  const queryCache = useQueryCache();

  return useQuery(["roles"], () => getRolesByBusiness(business), {
    onSuccess: (data) => {
      const roles = data.data.businesRoles ?? {};
      Object.keys(roles).map((businessKey: string) =>
        queryCache.setQueryData(["roles", businessKey], roles[businessKey])
      );
    },
    onError: (e: any) => {
      throw e;
    },
    enabled: business.length,
  });
}

const eachProductInvitation = ({
  userKey,
  productId,
  businessKey,
  resendInvite,
  loggedInUserKey,
}: {
  userKey: string;
  productId: string;
  businessKey: string;
  loggedInUserKey?: string;
  resendInvite: MutateFunction<any, unknown, ResendInviteToUser, unknown>;
}) => {
  return resendInvite({
    userKey,
    updatedBy: loggedInUserKey ?? "",
    productId,
    businessKey,
  });
};

const eachBusinessInvitation = (
  eachUserBusiness: UserBusiness | any,
  userKey: string,
  resendInvite: MutateFunction<any, unknown, ResendInviteToUser, unknown>,
  loggedInUserKey?: string
) => {
  const { _id, businessKey, ...products } = eachUserBusiness;

  const productIds = objectsToArrays(products, "key");

  const productInvitationPromises = productIds?.map((productId: any) => {
    if (eachUserBusiness?.[productId]?.roleKey) {
      return eachProductInvitation({
        productId,
        businessKey,
        userKey,
        loggedInUserKey,
        resendInvite,
      });
    }
    const promise = new Promise((resolve, reject) => resolve(null));
    return promise;
  });

  return Promise.all(productInvitationPromises);
};

export const resendInviteToUser = (
  user: Customer | User,
  resendInvite: MutateFunction<any, unknown, ResendInviteToUser, unknown>,
  loggedInUserKey?: string
) => {
  const resendInvitationPromises = user.business?.map((eachBusiness) =>
    eachBusinessInvitation(
      eachBusiness,
      user.userKey ?? "",
      resendInvite,
      loggedInUserKey
    )
  );

  return Promise.all(resendInvitationPromises ?? []);
};
