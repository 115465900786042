import { useEffect, useState } from "react";

import GoogleMapReact from "google-map-react";
import { Stack, Text, Image, Tooltip, Box } from "@chakra-ui/react";

import activeMarker from "../../assets/blueMarker.png";
import inactiveMarker from "../../assets/redMarker.png";
import { Business } from "../../packages/interfaces/business";
import { objectsToArrays } from "../../packages/helpers/extra";

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

interface MapsProps {
  business: Business[];
}

interface Map {
  loaded: boolean;
  map: any;
  maps: any;
}

interface LatLongBusiness {
  [latLong: string]: Business[];
}

const defaultZoom = 6;
// Fallback co-ordinate
const defaultCenter = {
  lat: 45.347767,
  lng: -75.90983,
};

export const Maps = (props: MapsProps) => {
  const { business } = props;

  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);
  const [mapInfo, setMapInfo] = useState<Map>({
    loaded: false,
    map: null,
    maps: null,
  });

  useEffect(() => {
    if (mapInfo?.map && business.length > 0) {
      const { lat, long } = business[0];
      if (lat && long) {
        setCenter({
          lat,
          lng: long,
        });
        setZoom(14);
      }
    }
  }, [business]);

  function onApiLoaded({
    map,
    maps,
    ref,
  }: {
    map: any;
    maps: any;
    ref: Element | null;
  }) {
    const initialLatLongBusiness = business.find(
      (business) => business.lat && business.long
    );
    if (initialLatLongBusiness) {
      const { lat, long } = initialLatLongBusiness as Business;
      if (lat && long) {
        setCenter({
          lat,
          lng: long,
        });
      }
    }
    setMapInfo({
      loaded: true,
      map: map,
      maps: maps,
    });
  }

  function mapOptions() {
    return {
      mapTypeControl: true,
      disableDefaultUI: false,
      streetViewControl: true,
    };
  }

  function renderMarkers() {
    const latLongMappedBusinesses = business.reduce(
      (latLongBusiness: LatLongBusiness, currentBusiness) => {
        const latLong = `${currentBusiness?.lat}-${currentBusiness?.long}`;
        latLongBusiness[latLong] = [
          ...(latLongBusiness[latLong] || []),
          currentBusiness,
        ];
        return latLongBusiness;
      },
      {}
    );
    return objectsToArrays(latLongMappedBusinesses)?.map(
      (businesses: Business[]) => {
        const { lat, long } = businesses[0];

        if (!lat || !long) {
          return null;
        }

        return (
          <Marker
            lat={lat}
            lng={long}
            key={`${lat}${long}`}
            businesses={businesses}
          />
        );
      }
    );
  }

  return (
    <div style={{ height: "70vh", paddingTop: "2em", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: googleMapsApiKey ?? "",
        }}
        center={center}
        defaultZoom={defaultZoom}
        zoom={zoom}
        draggable={true}
        options={mapOptions}
        onGoogleApiLoaded={onApiLoaded}
      >
        {renderMarkers()}
      </GoogleMapReact>
    </div>
  );
};

interface MarkerProps {
  lat: number;
  lng: number;
  businesses: Business[];
}

const Marker = (props: MarkerProps) => {
  const { businesses } = props;
  let image = "";

  if (businesses?.length < 2) {
    const { enabled } = businesses[0];
    image = enabled ? inactiveMarker : activeMarker;
  } else {
    image = inactiveMarker;
  }

  const Content = () => {
    return (
      <Box>
        {businesses?.map((business) => {
          const { enabled } = business;
          const disabled = enabled ? "" : "(Disabled)";
          return (
            <Stack spacing="1" p="2">
              <Text color="white" fontWeight="semibold">
                {`${business?.businessName} ${disabled}`}
              </Text>
              <Text color="white">
                Subscriptions : {business?.subscriptions?.length}
              </Text>
            </Stack>
          );
        })}
      </Box>
    );
  };

  return (
    <div role="button" tabIndex={0} style={{ position: "relative" }}>
      <Tooltip label={<Content />} placement="top" hasArrow>
        <Image src={image} />
      </Tooltip>
    </div>
  );
};
