import { Flex, Text, CircularProgress } from "@chakra-ui/react";

import { AddSubType } from "../../utils/Icons";

import ClickableContainer from "./ClickableContainer";

interface CountViewProps {
  title: string;
  subtitle: string;
  onClick: () => void;
  arrowModifier: boolean;
  ArrowUpIcon: JSX.Element;
  ArrowDownIcon: JSX.Element;
}

const CountView = (props: CountViewProps) => {
  const {
    title,
    onClick,
    subtitle,
    ArrowUpIcon,
    arrowModifier,
    ArrowDownIcon,
  } = props;

  const renderTitleContent = () => {
    if (title === undefined) {
      return <CircularProgress isIndeterminate size="vs" />;
    }

    if (!title && subtitle !== "business") {
      return <AddSubType />;
    }

    return <Text fontSize="16px">{title}</Text>;
  };

  return (
    <ClickableContainer onClick={onClick}>
      <Flex justify="flex-start" align="center" gridGap="16px">
        <Flex
          p="2"
          height="md"
          minWidth="md"
          color="text.500"
          borderRadius="md"
          alignItems="center"
          colorScheme="white"
          bg="background.600"
          justifyContent="center"
        >
          {renderTitleContent()}
        </Flex>
        <Flex gridGap="8px">
          <Text fontSize="md" color="text.600">
            {subtitle}
          </Text>
          {arrowModifier ? ArrowUpIcon : ArrowDownIcon}
        </Flex>
      </Flex>
    </ClickableContainer>
  );
};

export default CountView;
