import { useState, useRef, useContext } from "react";

import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import creditcardutils from "creditcardutils";
import {
  Box,
  Flex,
  Stack,
  Button,
  Heading,
  Checkbox,
  useToast,
} from "@chakra-ui/react";

import { AuthContext } from "../Login/AuthContext";
import { SInput } from "../../packages/components/SInput";
import { toastConfigs } from "../../packages/helpers/extra";
import { Business } from "../../packages/interfaces/business";
import ErrorMessage from "../../packages/components/ErrorMessage";
import { regExpForEmail } from "../../packages/helpers/strings";
import ImageContainer from "../../packages/components/ImageContainer";
import { AddressSearch } from "../../packages/components/AddressSearch";
import { useCreateBusiness, useUpdateBusiness } from "../../apis/business";

interface BusinessFormProps {
  business?: Business;
  stratosfyBusinessKey: string;
  handleCancelAction?: Function | undefined;
  checkDuplicateBusinessName: (selectedBusiness: Business) => boolean;
}

const initialBusiness = {
  businessName: "",
  businessAddress: "",
  remarks: "",
};

export const BusinessForm = (props: BusinessFormProps) => {
  const { userKey: loggedInUserKey } = useContext(AuthContext);
  const {
    business,
    stratosfyBusinessKey,
    checkDuplicateBusinessName,
    handleCancelAction = () => {},
  } = props;

  const [image, setImage] = useState<any>();
  const [removeImage, setRemoveImage] = useState(false);
  const [imagePath, setImagePath] = useState(business?.imageUrl);
  const fileUpload = useRef<any>();
  const [newBusiness, setNewBusiness] = useState<Business>({
    ...(business || initialBusiness),
  });
  const [showBillingDetails, setShowBillingDetails] = useState(
    !!business?.billingDetails?.billingName
  );

  const [addBusiness, addBusinessInfo] = useCreateBusiness();
  const [updateBusiness, updateBusinessInfo] = useUpdateBusiness();
  const [showPassword, setShowPassword] = useState(false);

  const { businessName, remarks, billingDetails } = newBusiness;
  const toast = useToast(toastConfigs);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: newBusiness,
  });

  function hasBillingDetails() {
    if (business && business?.billingDetails) {
      const hasBillingName = business?.billingDetails?.billingName?.length > 0;
      return hasBillingName;
    }
    return false;
  }

  function handleCheckboxChange() {
    if (showBillingDetails) {
      const { billingDetails, ...remainingBusinessDetails } = newBusiness;
      setNewBusiness(remainingBusinessDetails);
    }
    setShowBillingDetails(!showBillingDetails);
  }

  function handleInputChange(field: string, value: string, extra?: string) {
    if (extra === "billing") {
      const { billingDetails } = newBusiness;
      const updatedBillingDetails = {
        ...(billingDetails ?? {}),
        [field]: value,
      };
      setNewBusiness({
        ...newBusiness,
        // @ts-ignore
        billingDetails: updatedBillingDetails,
      });
      return;
    }
    if (extra === "payment") {
      if (field === "ccNumber") {
        value = creditcardutils.formatCardNumber(value);
      }
      const paymentInfo = {
        ...(newBusiness?.billingDetails?.paymentInfo ?? {}),
        [field]: value,
      };

      setNewBusiness({
        ...newBusiness,
        billingDetails: {
          ...(newBusiness.billingDetails ?? {}),
          // @ts-ignore
          paymentInfo: { ...(paymentInfo ?? {}) },
        },
      });
      return;
    }

    setNewBusiness({ ...newBusiness, [field]: value });
  }

  function doesPaymentInfoExist() {
    return !!newBusiness?.billingDetails?.paymentInfo?.ccNumber?.length;
  }

  async function handleSubmitAction() {
    try {
      if (checkDuplicateBusinessName(newBusiness)) {
        return toast({
          duration: 3000,
          status: "error",
          description: `Can't have duplicate business name.`,
        });
      }
      if (business) {
        const {
          _id: businessId = "",
          businessName,
          businessAddress,
          remarks,
          billingDetails,
        } = newBusiness;

        if (!businessAddress) {
          return toast({
            duration: 3000,
            status: "warning",
            description: "Business address is not allowed to be empty.",
          });
        }
        let updatedBusiness: any = {
          businessName,
          businessAddress,
          remarks,
          updatedBy: loggedInUserKey,
          removeImage,
        };
        if (showBillingDetails && billingDetails) {
          updatedBusiness = { ...updatedBusiness, billingDetails };
          if (!doesPaymentInfoExist()) {
            const { paymentInfo, ...rest } = updatedBusiness?.billingDetails;
            updatedBusiness.billingDetails = rest;
          }
        }
        if (image) {
          updatedBusiness.image = image;
        }
        await updateBusiness({
          businessId,
          updatedBusiness,
        });
      } else {
        let businessDetails = { ...newBusiness };
        if (!businessDetails?.businessAddress) {
          return toast({
            status: "warning",
            title: "Address",
            description: "Address is not allowed to be empty.",
          });
        }
        if (businessDetails?.billingDetails && !doesPaymentInfoExist()) {
          const { paymentInfo, ...rest } = businessDetails?.billingDetails;
          businessDetails.billingDetails = rest;
        }
        if (image) {
          businessDetails = { ...newBusiness, image };
        }
        await addBusiness({ ...businessDetails, updatedBy: loggedInUserKey });
      }
    } catch (err: any) {
      const status = business ? "update" : "add";
      addBusinessInfo.reset();
      updateBusinessInfo.reset();
      return toast({
        duration: 3000,
        status: "error",
        description:
          err?.response?.data?.message ?? `Cannot ${status} business.`,
      });
    }
    const renderingLabel = business ? "updated" : "created";
    toast({
      status: "success",
      description: `Business ${renderingLabel} successfully!`,
    });
    handleCancelAction();
  }

  const validateCcExpiryDate = () => {
    const ccExpiry = billingDetails?.paymentInfo?.ccExpiry;
    const monthYear = ccExpiry?.split("/");
    const month = Number(monthYear?.[0]);
    const year = Number(monthYear?.[1]);

    const currentYear = Number(dayjs().format("YY"));

    const currentMonth = Number(dayjs().format("MM"));

    if (!billingDetails?.paymentInfo?.ccNumber) {
      return;
    }

    if (!month || !year) {
      return "Please specify month & year of expiration.";
    } else if (year === currentYear && month < currentMonth) {
      return "Month must be greater than or equal to current month.";
    } else if (month < 0 || month > 12) {
      return "Month must be in the range [1-12].";
    } else if (year < currentYear) {
      return "Year must be greater than or equal to current year.";
    }
  };

  function uploadImage(file: FileList | null) {
    if (file) {
      setImage(file[0]);
      setImagePath(URL.createObjectURL(file[0]));
    }
  }

  function handleRemoveImage() {
    setImage(null);
    setRemoveImage(true);
    setImagePath(undefined);
  }
  const validateBillingAddress = () => {
    if (!newBusiness?.billingDetails?.billingAddress) {
      return "Billing address is not allowed to be empty.";
    }
  };

  function renderBiilingDetails() {
    const onClickPasswordIcon = () => {
      setShowPassword(!showPassword);
    };

    const validateCVV = () => {
      const ccNumber = newBusiness?.billingDetails?.paymentInfo?.ccNumber;
      const cvv = newBusiness?.billingDetails?.paymentInfo?.ccCVV;

      if (!ccNumber) {
        return;
      }

      if (cvv === "000") {
        return "Must be 3 digits other than 0's.";
      }

      const notAnumber = isNaN(Number(cvv));

      if (cvv?.length !== 3 || notAnumber) {
        return "Must be 3 digits.";
      }

      if (ccNumber && cvv?.length === 3 && notAnumber === false) return;
    };

    const validateEmailInForm = (email: string) => {
      if (!regExpForEmail.test(String(email)?.toLowerCase())) {
        return "Enter a valid email.";
      }
    };

    if (showBillingDetails) {
      return (
        <>
          <Heading as="h4" size="sm">
            Billing Details
          </Heading>
          <SInput
            type="text"
            isRequired
            name="billingName"
            placeholder="Name *"
            error={errors?.billingDetails?.billingName}
            value={billingDetails?.billingName}
            register={{
              ...register("billingDetails.billingName", {
                validate: (_) =>
                  (newBusiness?.billingDetails?.billingName?.length || 0) < 80,
              }),
            }}
            errorMessage={
              <ErrorMessage
                message={errors?.billingDetails?.billingName?.message}
              />
            }
            onChange={(e) =>
              handleInputChange(e.target.name, e.target.value, "billing")
            }
          />
          <SInput
            type="text"
            isRequired
            name="billingEmail"
            placeholder="Email *"
            value={billingDetails?.billingEmail}
            register={{
              ...register("billingDetails.billingEmail", {
                validate: (_) =>
                  validateEmailInForm(billingDetails?.billingEmail ?? ""),
              }),
            }}
            error={errors?.billingDetails?.billingEmail}
            errorMessage={<ErrorMessage message="Enter a valid email." />}
            onChange={(e) =>
              handleInputChange(e.target.name, e.target.value, "billing")
            }
          />
          <SInput
            type="number"
            isRequired
            name="billingPhone"
            placeholder="Phone Number *"
            value={newBusiness.billingDetails?.billingPhone}
            error={errors?.billingDetails?.billingPhone}
            register={{
              ...register("billingDetails.billingPhone", {
                validate: (_) => {
                  if (
                    (newBusiness?.billingDetails?.billingPhone?.length || 0) !==
                    10
                  ) {
                    return "Invalid phone number. Phone number should of 10 digits";
                  }
                },
              }),
            }}
            errorMessage={
              <ErrorMessage
                message={errors?.billingDetails?.billingPhone?.message}
              />
            }
            onChange={(e) =>
              handleInputChange(e.target.name, e.target.value, "billing")
            }
          />
          <SInput
            type="children"
            isRequired
            placeholder="Billing Address *"
            name="billingAddress"
            error={errors?.billingDetails?.billingAddress}
            value={billingDetails?.billingAddress}
            register={{
              ...register("billingDetails.billingAddress", {
                validate: validateBillingAddress,
              }),
            }}
            errorMessage={
              <ErrorMessage
                message={errors?.billingDetails?.billingAddress?.message}
              />
            }
            onChange={() => {}}
          >
            <AddressSearch
              defaultValue={newBusiness?.billingDetails?.billingAddress ?? ""}
              onAddressChange={(value: string, latLong: any) =>
                handleInputChange("billingAddress", value, "billing")
              }
            />
          </SInput>
          <SInput
            type="text"
            maxLength={15}
            name="ccNumber"
            placeholder="Credit/Debit Card Number"
            value={billingDetails?.paymentInfo?.ccNumber}
            register={{
              ...register("billingDetails.paymentInfo.ccNumber", {
                validate: (_) => {
                  const ccNumber =
                    newBusiness?.billingDetails?.paymentInfo?.ccNumber ?? "";

                  if (ccNumber) {
                    return creditcardutils.validateCardNumber(ccNumber);
                  }
                },
              }),
            }}
            error={errors?.billingDetails?.paymentInfo?.ccNumber}
            errorMessage={
              <ErrorMessage message="Credit Card no. must be valid." />
            }
            onChange={(e) =>
              handleInputChange(e.target.name, e.target.value, "payment")
            }
          />
          <Stack direction="row">
            <Box minW="60%">
              <SInput
                type="text"
                maxLength={5}
                name="ccExpiry"
                placeholder="Expiry Date ( MM / YY )"
                value={billingDetails?.paymentInfo?.ccExpiry}
                register={{
                  ...register("billingDetails.paymentInfo.ccExpiry", {
                    validate: validateCcExpiryDate,
                  }),
                }}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value, "payment")
                }
                error={errors?.billingDetails?.paymentInfo?.ccExpiry}
                errorMessage={
                  <ErrorMessage
                    message={
                      errors?.billingDetails?.paymentInfo?.ccExpiry?.message ??
                      ""
                    }
                  />
                }
              />
            </Box>
            <SInput
              name="ccCVV"
              type={showPassword ? "text" : "password"}
              maxLength={3}
              placeholder="CVV"
              register={{
                ...register("billingDetails.paymentInfo.ccCVV", {
                  validate: validateCVV,
                }),
              }}
              value={billingDetails?.paymentInfo?.ccCVV}
              onChange={(e) =>
                handleInputChange(e.target.name, e.target.value, "payment")
              }
              error={errors?.billingDetails?.paymentInfo?.ccCVV}
              errorMessage={
                <ErrorMessage
                  message={errors?.billingDetails?.paymentInfo?.ccCVV?.message}
                />
              }
              onClickIcon={onClickPasswordIcon}
              showPassword={showPassword}
              showPasswordIcon
            />
          </Stack>
        </>
      );
    }
    return null;
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitAction)}>
      <Stack spacing={4}>
        <Flex gridGap="8" alignItems="center">
          <ImageContainer
            size="100px"
            imageURL={imagePath}
            externalRef={fileUpload}
            onChange={(event) => uploadImage(event.target.files)}
          />
          <Button
            variant="outline"
            colorScheme="blue"
            onClick={() => fileUpload.current.click()}
          >
            Choose Image
          </Button>
          {imagePath && (
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => handleRemoveImage()}
            >
              Remove Image
            </Button>
          )}
        </Flex>
        <SInput
          type="text"
          isRequired
          name="businessName"
          value={businessName}
          placeholder="Business name *"
          error={errors?.businessName}
          isDisabled={business?._id === stratosfyBusinessKey}
          register={{
            ...register("businessName", {
              validate: (_) => (newBusiness?.businessName?.length || 0) < 80,
            }),
          }}
          errorMessage={
            <ErrorMessage message="Business name should not exceed 80 characters." />
          }
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
        />
        <SInput
          type="children"
          isRequired
          placeholder="Address *"
          name="businessAddress"
          value={newBusiness?.businessAddress}
          error={errors?.businessAddress}
          register={{
            ...register("businessAddress", {
              validate: (_) => (newBusiness?.businessAddress?.length || 0) < 80,
            }),
          }}
          errorMessage={<ErrorMessage message="Invalid value." />}
          onChange={() => {}}
        >
          <AddressSearch
            defaultValue={newBusiness?.businessAddress}
            onAddressChange={(value: string, latLong: any) =>
              handleInputChange("businessAddress", value)
            }
          />
        </SInput>
        <SInput
          type="textarea"
          value={remarks ?? ""}
          name="remarks"
          placeholder="Remarks"
          error={errors?.remarks}
          register={{
            ...register("remarks", {
              validate: (_) => (newBusiness?.remarks?.length || 0) < 80,
            }),
          }}
          errorMessage={
            <ErrorMessage message="Remarks should be less than 80 characters." />
          }
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
        />
        {hasBillingDetails() ? null : (
          <Checkbox
            isChecked={showBillingDetails}
            // {...register("billingDetails")}
            onChange={handleCheckboxChange}
          >
            Add Billing Details
          </Checkbox>
        )}
        {renderBiilingDetails()}
        <Flex justify="flex-end">
          <Button
            mr={3}
            variant="ghost"
            fontWeight="600"
            color="primary.500"
            onClick={() => handleCancelAction()}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            colorScheme="primary"
            isLoading={
              addBusinessInfo.isLoading || updateBusinessInfo.isLoading
            }
          >
            {business ? "Update" : "Create Business"}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};
