import React from "react";
import {
  Tr,
  Th,
  Td,
  Box,
  Icon,
  Text,
  Flex,
  Thead,
  Table,
  Tbody,
  Checkbox,
  Collapse,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";

export interface HeaderInterface {
  name: string;
  sort?: "asc" | "desc";
}

interface STableProps {
  checked?: boolean;
  headers: HeaderInterface[];
  children?: React.ReactNode;
  onChangeCheckBox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleHeaderClick?: (header: HeaderInterface, headerIndex: number) => void;
}

interface Row {
  checked?: boolean;
  collapseOpen?: boolean;
  onChangeCheck?: () => void;
  children?: React.ReactNode;
  collapseData?: React.ReactNode;
}

export const STableRow = (props: Row) => {
  const { collapseData, collapseOpen, checked, onChangeCheck } = props;

  function renderCheckbox() {
    if (checked !== undefined) {
      return (
        <Td paddingLeft="24px" paddingRight="30px">
          <Checkbox
            isChecked={checked}
            onChange={onChangeCheck}
            bg="white"
            borderColor="primary.500"
            borderRadius="12px"
          />
        </Td>
      );
    }
  }

  return (
    <>
      <Tr>
        {/* {renderCheckbox()} */}
        {props.children}
      </Tr>
      {
        <td colSpan={100}>
          <Collapse in={collapseOpen} unmountOnExit animateOpacity>
            <Box bg="background.500">{collapseData}</Box>
          </Collapse>
        </td>
      }
    </>
  );
};

export const STable = (props: STableProps) => {
  const {
    headers,
    checked,
    onChangeCheckBox = () => {},
    handleHeaderClick = () => {},
  } = props;

  function renderCheckBox() {
    if (checked !== undefined) {
      return (
        <Th
          top="0"
          zIndex="10"
          bg="background.500"
          paddingLeft="24px"
          paddingRight="30px"
        >
          <Checkbox
            bg="white"
            borderRadius="4px"
            isChecked={checked}
            marginBottom="16px"
            borderColor="primary.500"
            onChange={onChangeCheckBox}
          />
        </Th>
      );
    }
  }

  const renderSortingIcon = (sort?: "desc" | "asc") => {
    if (!sort) {
      return null;
    }

    if (sort === "desc") {
      return <Icon as={TriangleDownIcon} />;
    }

    return <Icon as={TriangleUpIcon} />;
  };

  function renderHeader() {
    return (
      <Tr>
        {headers.map((header, index) => (
          <Th
            top="0"
            zIndex="10"
            cursor="pointer"
            key={header.name}
            bg="background.500"
            textTransform="none"
          >
            <Flex alignItems="center" gridGap="20px" marginBottom="16px">
              <Flex gridGap="30px" alignItems="center">
                {index === 0 && checked !== undefined && (
                  <Checkbox
                    bg="white"
                    marginLeft="24px"
                    borderRadius="4px"
                    isChecked={checked}
                    borderColor="primary.500"
                    onChange={onChangeCheckBox}
                  />
                )}
                <Flex
                  gridGap="20px"
                  alignItems="center"
                  onClick={() => handleHeaderClick(header, index)}
                >
                  <Text
                    fontSize="18px"
                    fontWeight="500"
                    textTransform="none"
                    color="primary.800"
                  >
                    {header.name}
                  </Text>
                  {renderSortingIcon(header.sort)}
                </Flex>
              </Flex>
            </Flex>
          </Th>
        ))}
      </Tr>
    );
  }

  return (
    <Box shadow="lg" borderRadius="md">
      <Table size="sm" boxShadow="0px 12px 24px #ECEEF5">
        <Thead marginBottom="16px">{renderHeader()}</Thead>
        <Tbody colorScheme="white" bg="white">
          {props.children}
        </Tbody>
      </Table>
    </Box>
  );
};
