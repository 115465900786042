import { SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Stack,
  Switch,
  Text,
  useToast,
  Wrap,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MultiSelect from "react-select";
import makeAnimated from "react-select/animated";
import {
  useGetDeviceListWithDetails,
  useSetDeviceAssignment,
} from "../../apis/devices";
import {
  useCreateSubscription,
  useEditSubscription,
  useGetSubscriptionByIds,
  useProductsForSubscription,
} from "../../apis/subscriptions";
import { SInput } from "../../packages/components/SInput";
import {
  differenceSets,
  epochToDateFormat,
  getCurrentDate,
  toastConfigs,
  changeIntoEpochTime,
  getFormattedStringDate,
} from "../../packages/helpers/extra";
import { Business } from "../../packages/interfaces/business";
import {
  Device,
  deviceAssignmentTypes,
  DeviceIdsMultiSelectable,
  DeviceIdsWithDeviceType,
  DeviceTreeAssignment,
  DeviceType,
  DeviceTypeSelectionInterface,
  isGateway,
} from "../../packages/interfaces/device";
import {
  featureTypeEnum,
  INTEGER_TYPE_FEATURES,
  Product,
  ProductFeature,
} from "../../packages/interfaces/product";
import {
  NotificationInSubscription,
  Subscription,
} from "../../packages/interfaces/subscription";
import { Notifications } from "./Notifications";

interface SubscriptionProps {
  modalMode: string;
  business: Business;
  subscription?: Subscription;
  handleCancelAction?: Function;
}

const animatedComponents = makeAnimated();

const initialSubscription: Subscription = {
  features: {},
  productKey: "",
  deviceList: {},
  businessKey: "",
  startDate: getCurrentDate("number") as number,
  endDate: getCurrentDate("number", 1) as number,
};

const AddSubscription = (props: SubscriptionProps) => {
  const {
    subscription: selectedSubscription,
    handleCancelAction = () => {},
    business,
    modalMode,
  } = props;

  const { NUMBER, SIZE, BOOLEAN , DROPDOWN} = featureTypeEnum;
  const sizeTypes = ["GB", "MB", "KB"];
  const isEditSubscription = !!Object.values(props?.subscription ?? {}).length;

  const toast = useToast(toastConfigs);

  const { data: productsList } = useProductsForSubscription();

  const [editSubscription, editSubscriptionInfo] = useEditSubscription();
  const [addSubscription, addSubscriptionInfo] = useCreateSubscription();

  const [deviceAssignment, deviceAssignmentInfo] = useSetDeviceAssignment();
  const { data: subscriptionsList = [] } = useGetSubscriptionByIds(
    business.subscriptions ?? []
  );

  const alreadySubscribedProductKeys = new Set(
    subscriptionsList.map((subscription) => subscription.productKey) ?? []
  );

  const [selectedProduct, setSelectedProduct] = useState<Product>();

  const { _id: businessKey = "", businessName } = props?.business;
  const [selectedDevices, setSelectedDevices] = useState<
    DeviceTypeSelectionInterface[]
  >([]);

  const [subscription, setSubscription] = useState<any>({
    ...(!isEditSubscription
      ? {
          ...initialSubscription,
          startDate: getCurrentDate("number") as number,
          endDate: getCurrentDate("number", 1) as number,
        }
      : selectedSubscription),
    businessKey,
  });

  useEffect(() => {
    const startDate = getCurrentDate("number") as number;
    const endDate = getCurrentDate("number", 1) as number;
    if (!isEditSubscription) {
      const updatedSubscription: Subscription = {
        ...initialSubscription,
        endDate,
        startDate,
        businessKey,
      };
      setSubscription({ ...updatedSubscription });
    } else {
      const {
        features,
        deviceList,
        productKey = "",
        endDate: selectedEndDate,
        startDate: selectedStartDate,
      } = selectedSubscription ?? {};
      setSubscription({
        ...(selectedSubscription ?? {}),
        productKey,
        features: features ?? {},
        deviceList: deviceList ?? {},
        endDate: selectedEndDate ?? endDate,
        startDate: selectedStartDate ?? startDate,
        businessKey: businessKey ?? "",
      });
    }
  }, [modalMode, isEditSubscription]);

  const deviceIDsWithDeviceType = Object.keys(
    selectedSubscription?.deviceList ?? {}
  ).reduce((acc: any, currKey) => {
    // @ts-ignore
    const devices = selectedSubscription?.deviceList?.[currKey]?.map(
      (deviceID: string) => ({
        label: deviceID,
        value: deviceID,
      }),
      {}
    );

    acc[currKey] = devices;
    return acc;
  }, {});

  const [selectedDeviceIDsWithDeviceType, setSelectedDeviceIDsWithDeviceType] =
    useState<any>(deviceIDsWithDeviceType);

  const deviceNamesWithLabels = Object.keys(
    subscription?.deviceList ?? {}
  ).reduce((acc: any[], deviceType: string) => {
    const value = deviceAssignmentTypes.find(
      (type) => type.value.deviceType === deviceType
    )?.value;
    if (value) {
      acc.push({
        label: value.deviceName,
        value: value.deviceType,
      });
    }
    return acc;
  }, []);

  const [devicesThatSelectForDevices, setDevicesThatSelectForDevices] =
    useState<DeviceTypeSelectionInterface[]>(deviceNamesWithLabels);

  const products =
    productsList?.data.filter((product) => {
      if (product._id === selectedSubscription?.productKey) {
        return true;
      }
      if (!alreadySubscribedProductKeys.has(product._id ?? "")) {
        return true;
      }
    }) ?? [];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: initialSubscription,
  });

  function handleInputChange(
    field: string,
    value: string | boolean | number | string[] | NotificationInSubscription[],
    featureKey?: string
  ) {
    if (featureKey) {
      const updatedFeaturesList = {
        ...(subscription?.features ?? {}),
        [featureKey]: value,
      };

      setSubscription({
        ...(subscription ?? {}),
        features: updatedFeaturesList,
      });
      return;
    }

    setSubscription({ ...(subscription ?? {}), [field]: value });
  }

  function hanldeSizeTypeChange(event: any, featureId: string, size: string) {
    setSubscription({
      ...subscription,
      features: {
        ...(subscription.features ?? {}),
        [featureId]: `${size}_${event.target.value.toLowerCase()}`,
      },
    });
  }

  function handleNumberInputChange(value: number, featureId: string) {
    setSubscription({
      ...subscription,
      features: {
        ...(subscription.features ?? {}),
        [featureId]: value,
      },
    });
  }

  function featuresList(feature: ProductFeature) {
    const { featureId, defaultValue, type, dropdownValues } = feature;
    const featureValue = subscription?.features?.[featureId] ?? defaultValue;

    if (type === NUMBER) {
      const { minValue, maxValue } = feature;
      const onlyIntegerAllowed = INTEGER_TYPE_FEATURES[featureId]; // if the feature is categorised under integer only, then we allow only integers.
      return (
        <Flex align="center" justify="space-between">
          <Text size="sm" maxW="60%" color="altText.500" fontWeight="semibold">
            {featureId?.toUpperCase()}
          </Text>
          <Input
            isRequired
            step="0.10"
            type="number"
            width="28"
            onKeyDown={(event) => {
              if (onlyIntegerAllowed && /\./.test(event.key)) event.preventDefault(); //Additional Check for UX improvement(user cannot enter . also)
            }}
            max={maxValue}
            min={minValue}
            value={featureValue?.toString()}
            onChange={(event) => {
              const value = onlyIntegerAllowed ? parseInt(event.target.value) : parseFloat(event.target.value)
              handleInputChange("", value, featureId);
            }}
          ></Input>
        </Flex>
      );
    }

    if (type === BOOLEAN) {
      return (
        <Flex justify="space-between" align="center">
          <Text psize="sm" maxW="60%" color="altText.500" fontWeight="semibold">
            {featureId.toUpperCase()}
          </Text>
          <Switch
            // @ts-ignore
            isChecked={featureValue}
            onChange={() => handleInputChange("", !featureValue, featureId)}
            height="20px"
          />
        </Flex>
      );
    }

    const size = featureValue;

    if (type === SIZE) {
      return (
        <Flex justifyContent="space-between" width="100%" alignItems="center">
          <Text size="sm" maxW="50%" color="altText.500" fontWeight="semibold">
            {featureId.toUpperCase()}
          </Text>
          <Flex gridGap="8px">
            <InputGroup>
              <Input
                type="number"
                isRequired
                width="24"
                value={size}
                onChange={(event) =>
                  handleNumberInputChange(
                    parseInt(event.target.value),
                    featureId
                  )
                }
              />
              <InputRightAddon children="MB" maxWidth="20" />
            </InputGroup>
          </Flex>
        </Flex>
      );
    }

    if (type === DROPDOWN) {
      return <Flex justifyContent="space-between" width="100%" alignItems="center">
        <Text size="sm" maxW="50%" color="altText.500" fontWeight="semibold">
          {featureId.toUpperCase()}
        </Text>
        <Flex gridGap="8px">
          <Select
            multiple={false}
            value={featureValue}
            onChange={(event) =>
              handleInputChange("", event.target.value, featureId)
            }
          >
            {dropdownValues?.map((value) => {
              return (
                <option value={value} key={value}>
                  {value}
                </option>
              );
            })}
          </Select>
        </Flex>
      </Flex>;
    }
  }

  const getDeviceIdsAccordingToTheGatewayOrSensor = (
    devicesByDeviceType: any
  ) => {
    return Object.keys(devicesByDeviceType).reduce(
      (
        deviceIdsByType: { [gatewayOrSensor: string]: string[] },
        deviceType: any
      ) => {
        const gatewayOrSensor = isGateway(deviceType) ? "gateway" : "sensor";

        const deviceIds = [...devicesByDeviceType[deviceType]];

        deviceIdsByType[gatewayOrSensor] =
          deviceIdsByType[gatewayOrSensor] ?? [];

        const devices = deviceIds?.map(
          (deviceId: any) => deviceId.value ?? deviceId
        );

        deviceIdsByType[gatewayOrSensor].push(...(devices ?? []));

        return deviceIdsByType;
      },
      {}
    );
  };

  async function handleDeviceAssignment() {
    const devices = getDeviceIdsAccordingToTheGatewayOrSensor(
      selectedDeviceIDsWithDeviceType
    );

    const previouslySelectedDevices = getDeviceIdsAccordingToTheGatewayOrSensor(
      selectedSubscription?.deviceList ?? {}
    );

    const unassigningGatewayDevices = differenceSets(
      previouslySelectedDevices?.["gateway"],
      devices?.["gateway"]
    );

    const unassigningSensorDevices = differenceSets(
      previouslySelectedDevices?.["sensor"],
      devices?.["sensor"]
    );

    const assigningGatewayDevices = differenceSets(
      devices?.["gateway"],
      previouslySelectedDevices?.["gateway"]
    );

    const assigningSensorDevices = differenceSets(
      devices?.["sensor"],
      previouslySelectedDevices?.["sensor"]
    );

    const deviceAssignmentToBusiness: DeviceTreeAssignment = {
      action: "businessAssignment",
      businessKey: business?._id ?? "",
    };

    if (assigningSensorDevices?.length > 0) {
      deviceAssignmentToBusiness.sensor = assigningSensorDevices;
    }

    if (assigningGatewayDevices?.length > 0) {
      deviceAssignmentToBusiness.gateway = assigningGatewayDevices;
    }

    const deviceUnassignmentFromBusiness: DeviceTreeAssignment = {
      action: "businessUnassign",
      businessKey: business?._id ?? "",
    };

    if (unassigningSensorDevices?.length > 0) {
      deviceUnassignmentFromBusiness.sensor = unassigningSensorDevices;
    }

    if (unassigningGatewayDevices?.length > 0) {
      deviceUnassignmentFromBusiness.gateway = unassigningGatewayDevices;
    }

    if (
      (deviceAssignmentToBusiness?.sensor?.length ?? 0) > 0 ||
      (deviceAssignmentToBusiness?.gateway?.length ?? 0) > 0
    ) {
      await deviceAssignment(deviceAssignmentToBusiness);
    }

    if (
      (deviceUnassignmentFromBusiness?.sensor?.length ?? 0) > 0 ||
      (deviceUnassignmentFromBusiness?.gateway?.length ?? 0) > 0
    ) {
      await deviceAssignment(deviceUnassignmentFromBusiness);
    }
  }

  async function handleSubmitAction() {
    const isOutOfBoundary = Object.keys(subscription?.features ?? {}).some(
      (featureId) => {
        const featureValue = subscription?.features?.[featureId];
        const feature = selectedProduct?.features?.find(
          (feature) => feature.featureId === featureId
        );

        const { minValue = 0, maxValue = 0 } = feature ?? {};

        if (
          feature?.type === "number" &&
          !(featureValue >= minValue && featureValue <= maxValue)
        ) {
          return true;
        }
        return false;
      }
    );
    const notifs = subscription?.notifications || [];
    if (notifs.length <= 0) {
      toast({
        description: "Atleast one notifications is mandatory",

        status: "warning",

        duration: 3000,
      });

      return;
    }
    if (isOutOfBoundary) {
      toast({
        description:
          "Feature value should be in boundary of minimum & maximum value of the selected product.",
        status: "warning",
        duration: 3000,
      });

      return;
    }

    const deviceNames = Object.keys(selectedDeviceIDsWithDeviceType);
    const uniqueDeviceNames = new Set(deviceNames);

    if (
      devicesThatSelectForDevices?.some(
        (deviceDetails) => !uniqueDeviceNames.has(deviceDetails.value)
      )
    ) {
      toast({
        description: "At least one device id mandatory.",
        duration: 3000,
        status: "error",
      });
      return;
    }

    const { __v, updatedAt, createdAt, ...updatedSubscription } = subscription;

    const deviceIDsWithDeviceName = Object.keys(
      selectedDeviceIDsWithDeviceType
    ).reduce((acc: { [deviceName: string]: string[] }, currKey) => {
      const devices = selectedDeviceIDsWithDeviceType[currKey]?.map(
        (device: any) => device.label
      );
      if (devices?.length) {
        acc[currKey] = devices;
      }
      return acc;
    }, {});

    try {
      await handleDeviceAssignment();
      if (isEditSubscription) {
        const { productKey, businessKey, ...editedSubscription } =
          updatedSubscription;
        await editSubscription({
          ...editedSubscription,
          deviceList: deviceIDsWithDeviceName,
        });
      } else {
        await addSubscription({
          ...updatedSubscription,
          deviceList: deviceIDsWithDeviceName,
        });
      }
      const renderingLabel = isEditSubscription ? "updated" : "created";
      handleCancelAction();
      return toast({
        duration: 3000,
        status: "success",
        description: `Subscription ${renderingLabel} successfully.`,
      });
    } catch (err: any) {
      addSubscriptionInfo.reset();
      editSubscriptionInfo.reset();
      deviceAssignmentInfo.reset();
      return toast({
        title: "Error Creating subscription.",
        description:
          err?.response?.data?.message ??
          "Error occured while creating subscription.",
        status: "error",
      });
    }
  }

  function mapSelectedProductsFeatures() {
    const features = selectedProduct?.features.reduce((acc, feature) => {
      return { ...acc, [feature.featureId]: feature.defaultValue };
    }, {});

    return setSubscription({
      ...subscription,
      features: features ?? {},
    });
  }

  function handleProductChange(productId: string) {
    setSelectedDeviceIDsWithDeviceType({});
    setDevicesThatSelectForDevices([]);
    handleInputChange("productKey", productId);
    const assignedProduct = products.find(
      (product) => product._id === productId
    );
    setSelectedProduct(assignedProduct);
  }

  const handleDeviceRemove = (device: { label: string; value: DeviceType }) => {
    const { value } = device;
    const updatedDevicesSelected = devicesThatSelectForDevices?.filter(
      (d) => d.label !== device.label
    );

    const { [value]: removedDevice, ...remainingDevices } =
      selectedDeviceIDsWithDeviceType;

    setSelectedDeviceIDsWithDeviceType(remainingDevices);

    setDevicesThatSelectForDevices(updatedDevicesSelected);
  };

  function renderDevicesSelection() {
    return (
      <Stack mt="4" spacing="4">
        <Heading as="h4" size="md">
          Devices
        </Heading>

        <ShowDevicesInput
          options={selectedDevices}
          placeholder="Device Types"
          handleDelete={handleDeviceRemove}
          selectedEntities={devicesThatSelectForDevices}
          setSelectedEntities={setDevicesThatSelectForDevices}
        />

        {/* {showDevicesInput({
          placeholder: "Device Types",
          options: selectedDevices,
          selectedEntities: devicesThatSelectForDevices,
        })} */}
      </Stack>
    );
  }

  useEffect(() => {
    if (!isEditSubscription) {
      mapSelectedProductsFeatures();
    }
  }, [selectedProduct]);

  useEffect(() => {
    setSelectedProduct(
      products.find((product) => product._id === subscription?.productKey)
    );
  }, [isEditSubscription]);

  useEffect(() => {
    if (selectedProduct?.deviceAssignmentTypes) {
      const devicesThatAreSelectedInProduct =
        selectedProduct?.deviceAssignmentTypes.map((deviceDetails) => ({
          label: deviceDetails?.deviceName,
          value: deviceDetails?.deviceType,
        }));

      setSelectedDevices(devicesThatAreSelectedInProduct);
    }
  }, [selectedProduct]);

  const handleDeviceIDChange = (
    deviceType: DeviceType,
    devicesDetails: Device[]
  ) => {
    setSelectedDeviceIDsWithDeviceType({
      ...selectedDeviceIDsWithDeviceType,
      [deviceType]: devicesDetails,
    });
  };

  const handleDeviceIdRemove = (deviceType: string, deviceID: any) => {
    const updatedDevicesIds = selectedDeviceIDsWithDeviceType?.[
      deviceType
    ]?.filter((oldDeviceID: any) => oldDeviceID !== deviceID);

    setSelectedDeviceIDsWithDeviceType({
      ...selectedDeviceIDsWithDeviceType,
      [deviceType]: updatedDevicesIds,
    });
  };

  const loading =
    addSubscriptionInfo.isLoading ||
    editSubscriptionInfo.isLoading ||
    deviceAssignmentInfo.isLoading;

  return (
    <form onSubmit={handleSubmit(handleSubmitAction)}>
      <Box>
        <Stack spacing="4">
          <Heading as="h4" size="md">
            Details
          </Heading>
          <SInput
            type="text"
            name="subscriptionName"
            value={businessName}
            placeholder="Business name *"
            isDisabled
            onChange={() => {}}
          />
          <SInput
            type="date"
            name="startDate"
            defaultValue={getFormattedStringDate(
              new Date(subscription?.startDate)
            )}
            value={undefined}
            placeholder="Subscription start date*"
            onChange={(e) =>
              handleInputChange(
                "startDate",
                changeIntoEpochTime(e.target.value)
              )
            }
            register={{
              ...register("startDate", {
                validate: (_) => {
                  if (!subscription?.startDate) {
                    return false;
                  }
                  if (subscription?.startDate) {
                    return subscription?.startDate <= subscription?.endDate;
                  }
                  return true;
                },
              }),
            }}
            error={errors.startDate}
            errorMessage=""
          />

          <SInput
            type="date"
            name="endDate"
            minLength={getCurrentDate("formatted", 1)}
            defaultValue={getFormattedStringDate(
              new Date(subscription?.endDate)
            )}
            value={undefined}
            placeholder="Subscription end date*"
            onChange={(e) => {
              handleInputChange("endDate", changeIntoEpochTime(e.target.value));
            }}
            register={{
              ...register("endDate", {
                validate: (_) => {
                  if (!subscription?.endDate) {
                    return false;
                  }
                  if (subscription?.endDate) {
                    return subscription?.startDate < subscription?.endDate;
                  }
                  return true;
                },
              }),
            }}
            error={errors.endDate}
            errorMessage="End Date should be greater than start date."
          />
          <SInput
            type="children"
            name="select"
            value="select"
            onChange={() => {}}
            placeholder="Products*"
            isDisabled={isEditSubscription}
          >
            <Select
              isRequired
              defaultValue={subscription?.productKey}
              isDisabled={!selectedSubscription}
              onChange={(e) => {
                handleProductChange(e.target.value);
              }}
              disabled={isEditSubscription}
              variant="unstyled"
            >
              <option disabled selected value="">
                -- Select a product --
              </option>
              {products.map((product, index) => (
                <option key={index} value={product?._id}>
                  {product?.productId?.toUpperCase()}
                </option>
              ))}
            </Select>
          </SInput>
        </Stack>
        {(selectedProduct?.features?.length ?? 0) > 0 && (
          <Stack mt="4" spacing="4">
            <Heading as="h4" size="md">
              Features
            </Heading>
            {(selectedProduct?.features ?? []).map((feature) =>
              featuresList(feature)
            )}
          </Stack>
        )}

        {selectedProduct?.notifications?.length ? (
          <Stack>
            <Heading as="h4" size="md">
              Notifications
            </Heading>

            <Notifications
              handleInputChange={handleInputChange}
              notifications={selectedProduct.notifications}
              selectedNotifications={subscription?.notifications ?? []}
            />
          </Stack>
        ) : null}

        <Flex direction="column" gridGap={4}>
          {(selectedProduct?.deviceAssignmentTypes?.length ?? 0) > 0 && (
            <>{renderDevicesSelection()}</>
          )}

          {devicesThatSelectForDevices?.map((selectedDeviceType) => {
            return (
              <RenderDevicesByDeviceIds
                key={selectedDeviceType?.label}
                deviceDetails={selectedDeviceType}
                setSelectedEntities={handleDeviceIDChange}
                handleDeviceIdRemove={handleDeviceIdRemove}
                selectedDeviceIDWithDeviceType={selectedDeviceIDsWithDeviceType}
              />
            );
          })}
        </Flex>

        <Wrap mt="8" justify="flex-end">
          <Button variant="ghost" onClick={() => handleCancelAction()}>
            Cancel
          </Button>
          <Button
            variant="solid"
            colorScheme="blue"
            type="submit"
            isLoading={loading}
          >
            {isEditSubscription ? "Edit subscription" : "Add subscription"}
          </Button>
        </Wrap>
      </Box>
    </form>
  );
};

export default AddSubscription;

function ShowDevicesInput({
  options,
  placeholder,
  handleDelete,
  selectedEntities,
  setSelectedEntities,
}: {
  options: any;
  placeholder: string;
  selectedEntities: any;
  handleDelete: (param: any) => void;
  setSelectedEntities: (selected: any) => void;
}) {
  return (
    <>
      <SInput
        type="children"
        name="select"
        value="select"
        onChange={() => {}}
        placeholder={placeholder}
        isRequired
      >
        <Box mt="2">
          <MultiSelect
            styles={{
              control: (base) => ({
                ...base,
                border: 0,
                boxShadow: "none",
              }),
            }}
            isMulti
            options={options}
            menuPlacement="top"
            value={selectedEntities}
            closeMenuOnSelect={false}
            noOptionsMessage={() => null}
            onChange={setSelectedEntities}
            components={animatedComponents}
            controlShouldRenderValue={false}
            isClearable={false}
          />
        </Box>
      </SInput>

      <Wrap>
        {selectedEntities?.map((device: any) => (
          <Text
            fontSize="xs"
            border="1px"
            borderRadius="md"
            p="1"
            key={device.label}
          >
            <>
              {device.label}
              <Button
                size="xs"
                variant="unstyled"
                _focus={{ outline: "none" }}
                onClick={() => handleDelete(device)}
              >
                <SmallCloseIcon />
              </Button>
            </>
          </Text>
        ))}
      </Wrap>
    </>
  );
}

const RenderDevicesByDeviceIds = ({
  deviceDetails,
  selectedDeviceIDWithDeviceType,
  setSelectedEntities,
  handleDeviceIdRemove,
}: {
  deviceDetails: DeviceTypeSelectionInterface;
  selectedDeviceIDWithDeviceType: DeviceIdsWithDeviceType;
  setSelectedEntities: (deviceType: DeviceType, data: Device[]) => void;
  handleDeviceIdRemove: (deviceType: DeviceType, device: Device) => void;
}) => {
  const { data, isLoading } = useGetDeviceListWithDetails(deviceDetails.value);

  if (isLoading) {
    return (
      <Flex justifyContent="center">
        <CircularProgress size="40px" isIndeterminate />
      </Flex>
    );
  }

  const options = data?.data?.reduce(
    (acc: DeviceIdsMultiSelectable[], device: Device) => {
      if (!device.businessKey) {
        acc.push({
          label: device.deviceID,
          value: device.deviceID,
        });
      }
      return acc;
    },
    []
  );

  const { value: deviceType, label } = deviceDetails;

  return (
    <ShowDevicesInput
      options={options}
      placeholder={label}
      selectedEntities={selectedDeviceIDWithDeviceType?.[deviceType]}
      setSelectedEntities={(e) => setSelectedEntities(deviceType, e)}
      handleDelete={(device) => handleDeviceIdRemove(deviceType, device)}
    />
  );
};
