import axios from "axios";
import { getToken } from "./auth";

// this file will act as an interface between our app and the APIs.
export const API_URL = process.env.REACT_APP_API_URL || "";
// export const API_URL = "https://superadmin.api.test.genius.stratosfy.io/api";
// export const API_URL = "http://localhost:3000";
export const API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL || "";
export const geniusLoginWebURL = process.env.REACT_APP_GENIUS_LOGIN;

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export default async function caller() {
  const token = await getToken();

  return axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      // todo: add application headers to the existing headers here, like access tokens
    },
  });
}
