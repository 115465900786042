import { useEffect, useState } from "react";

import { Box, Divider, Heading, Stack, Text } from "@chakra-ui/layout";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";

import { LoginMethod } from "./SuperAdminAddUpdateCard";
import { getBusinessFromId } from "../../apis/business";
import { Business } from "../../packages/interfaces/user";

interface DetailsProps {
  superAdmin: any;
}

export const Details = (props: DetailsProps) => {
  const { email, address, business, phoneNumber, loginMethod } =
    props?.superAdmin;
  const [busins, setbusins] = useState<any>([]);

  useEffect(() => {
    Promise.all(
      business.map((businessObject: Business) =>
        getBusinessFromId(businessObject?.businessKey)
      )
    )
      .then((e1) => {
        setbusins(e1);
      })
      .catch((e) => {
        console.error({ e });
      });
  }, []);

  return (
    <Tabs isFitted>
      <TabList>
        <Tab fontWeight="bold" color="altText.500">
          Details
        </Tab>
        <Tab fontWeight="bold" color="altText.500">
          Assigned Business
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Stack spacing="4">
            {/* <Image w="30%" my="2" src={(imageUrl? imageUrl:"")} borderRadius="md" /> */}
            <Box>
              <Heading as="h4" size="sm" color="blue.600">
                Address
              </Heading>
              <Text>{address ?? "Unknown"}</Text>
            </Box>
            <Divider />
            <Box>
              <Heading as="h4" size="sm" color="blue.600">
                Log in Method
              </Heading>
              <Text fontWeight="semibold" fontSize="sm">
                {loginMethod.charAt(0).toUpperCase() +
                  loginMethod.substring(1, loginMethod.length)}
              </Text>

              <Text fontSize="sm">
                {loginMethod === LoginMethod.EMAIL ? email : phoneNumber}
              </Text>
            </Box>
            <Divider />
          </Stack>
        </TabPanel>
        <TabPanel>
          <Stack spacing="4">
            {business?.length ? (
              business?.map((b: Business, i: number) => {
                return (
                  <Box>
                    <Text mx="2" fontSize="md" fontWeight="semibold">
                      {busins[i]?.businessName ?? "loading..."}
                    </Text>
                    <Text mx="2" mb={2} fontSize="sm">
                      {busins[i]?.businessAddress ?? "loading..."}
                    </Text>
                    <Divider />
                  </Box>
                );
              })
            ) : (
              <Text mx={10}>No business assigned</Text>
            )}
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
