import { Box, Flex, Heading, Text, Image } from "@chakra-ui/react";
interface DeviceCardProps {
  title: string;
  icon: string;
  description: string;
  onClick?: Function;
}

export const DeviceCard = (props: DeviceCardProps) => {
  const { title, description, onClick = () => {}, icon } = props;

  return (
    <Box
      boxShadow="xl"
      p="6"
      rounded="2xl"
      bg="white"
      cursor="pointer"
      onClick={() => onClick(title)}
    >
      <Flex direction="row" gridGap="8">
        <Box
          bg="#E4F1F8"
          minW="130px"
          borderRadius="10px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image src={icon} alt="icon" fallback={<></>} />
        </Box>
        <Flex direction="column" justify="space-between" gridGap="4">
          <Heading flex="1" size="lg">
            {title}
          </Heading>
          <Text>{description}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};
