import React from "react";

import {
  Divider,
  Drawer,
  Button,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";

interface SDrawerProps {
  action?: any;
  open: boolean;
  title: string;
  size?: string;
  positiveLabel?: string;
  negativeLabel?: string;
  negativeAction: () => void;
  children?: React.ReactNode;
  showActionButtons?: boolean;
  positiveAction?: () => void;
}

export const SDrawer = (props: SDrawerProps) => {
  const {
    open,
    size,
    title,
    action,
    positiveLabel,
    negativeLabel,
    positiveAction,
    negativeAction,
    showActionButtons = false,
  } = props;

  return (
    <Drawer
      isOpen={open}
      placement="right"
      size={size ?? "md"}
      onClose={negativeAction}
    >
      <DrawerOverlay>
        <DrawerContent px="4">
          <DrawerHeader>
            <Flex direction="column" marginBottom="32px">
              <Flex
                display="flex"
                justify="space-between"
                alignItems="center"
                marginTop="60px"
                marginBottom="16px"
              >
                <Text
                  as="h6"
                  fontSize="30px"
                  display="flex"
                  flex="1"
                  color="primary.500"
                  fontWeight="bold"
                >
                  {title}
                </Text>
                <Flex alignItems="center" gridGap="24px">
                  {action}
                  <DrawerCloseButton
                    position="relative"
                    color="text.500"
                    top="0"
                    right="0"
                    bg="white"
                    autoFocus={false}
                    shadow=""
                    _focus={{
                      border: 0,
                    }}
                  ></DrawerCloseButton>
                </Flex>
              </Flex>
              <Divider py={2} color="highlight.500"></Divider>
            </Flex>
          </DrawerHeader>

          <DrawerBody>{props.children}</DrawerBody>

          <DrawerFooter>
            {showActionButtons && (
              <Flex>
                <Button
                  variant="ghost"
                  mr={3}
                  onClick={negativeAction}
                  color="primary.500"
                >
                  {negativeLabel}
                </Button>
                <Button colorScheme="blue" onClick={positiveAction}>
                  {positiveLabel}
                </Button>
              </Flex>
            )}
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
