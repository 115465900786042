import { useContext, useEffect, useRef, useState } from "react";

import Select from "react-select";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import countriesInfo from "countries-information";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  Flex,
  Text,
  Radio,
  Stack,
  Button,
  Heading,
  useToast,
  RadioGroup,
} from "@chakra-ui/react";

import { MenuBarName } from "../../enums/menu";
import { AuthContext } from "../Login/AuthContext";
import { SInput } from "../../packages/components/SInput";
import { AssignBusiness, ErrorInterface } from "./AssignBusiness";
import ImageContainer from "../../packages/components/ImageContainer";
import { BusinessObject, Customer } from "../../packages/interfaces/customer";
import {
  useCreateCustomer,
  useUpdateCustomer,
  useAddCustomerToBusiness,
  useRemoveCustomerFromBusiness,
} from "../../apis/customers";
import {
  toastConfigs,
  differenceSets,
  objectsToArrays,
} from "../../packages/helpers/extra";

interface SuperAdminCardProps {
  user?: Customer;
  handleClose: Function;
}
export enum LoginMethod {
  EMAIL = "email",
  PHONE = "phoneNumber",
}

const { CustomerAdmin } = MenuBarName;

const initialCustomerSuperadmin = {
  email: "",
  address: "",
  remarks: "",
  lastName: "",
  fullName: "",
  firstName: "",
  phoneNumber: "",
  loginMethod: LoginMethod.EMAIL,
};

interface SelectOption {
  label: string;
  value: string;
}

const allCountries = countriesInfo.getAllCountries();

const countrySelectOptions = allCountries.reduce((all, country) => {
  const countryCode = country.countryCallingCodes[0] ?? "";
  if (!countryCode || !country.emoji) {
    return all;
  }
  all.push({
    value: countryCode,
    label: `${country.emoji ?? ""} ${country.name} (${countryCode})`,
  });
  return all;
}, []);

const defaultCountry = countrySelectOptions?.find(
  (country) => country.value === "+1"
);

export const SuperAdminCard = (props: SuperAdminCardProps) => {
  const { user, handleClose } = props;

  const { userKey } = useContext(AuthContext);

  const [file, setFile] = useState<File>();
  const [loadings, setLoadings] = useState(false);
  const [image, setImage] = useState(user?.imageURL);
  const [removeImage, setRemoveImage] = useState(false);

  const fileUpload = useRef<any>();

  const [superadmin, setSuperadmin] = useState({
    updatedBy: userKey,
    ...(user ?? initialCustomerSuperadmin),
    loginMethod: user?.loginMethod ?? LoginMethod.EMAIL,
  });

  const [fullName, setFullName] = useState("");
  const [businesses, setBusinesses] = useState<BusinessObject[]>([]);
  // const [countryCode, setCountryCode] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] =
    useState<SelectOption | null>(defaultCountry);
  const [disabledPhoneNumber, setDisabledPhoneNumber] = useState("");
  const [error, setError] = useState<ErrorInterface>();
  const isEditUser = !!objectsToArrays(user ?? {}).length;
  const [addCustomer, newCustomerInfo] = useCreateCustomer();
  const [updateCustomer, updatedCustomerInfo] = useUpdateCustomer();
  const [addCustomerToBusiness, addCustomerToBusinessInfo] =
    useAddCustomerToBusiness();
  const [removeCustomerFromBusiness, removeCustomerFromBusinessInfo] =
    useRemoveCustomerFromBusiness();

  const toast = useToast(toastConfigs);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>({
    defaultValues: superadmin,
  });

  useEffect(() => {
    if (isEditUser) {
      const fullName = `${user?.firstName ?? ""} ${user?.lastName ?? ""}`;
      setFullName(fullName);
    }
  }, []);

  useEffect(() => {
    if (isEditUser && user?.phoneNumber) {
      const { phoneNumber, countryCode } = user;
      // setCountryCode(countryCode);
      const selectedCountry = allCountries?.find((country) => {
        return countryCode === country.countryCallingCodes[0];
      });

      setSelectedCountryCode({
        label: `${selectedCountry?.emoji ?? ""} ${
          selectedCountry?.name ?? ""
        } (${countryCode})`,
        value: countryCode ?? "",
      });

      setDisabledPhoneNumber(phoneNumber);
    }
  }, []);

  function loading() {
    const { isLoading: isCreatingCustomer } = newCustomerInfo;
    const { isLoading: isUpdatingCustomer } = updatedCustomerInfo;
    const { isLoading: isAddingBusiness } = addCustomerToBusinessInfo;
    const { isLoading: isRemovingBusiness } = removeCustomerFromBusinessInfo;

    return (
      isAddingBusiness ||
      isUpdatingCustomer ||
      isRemovingBusiness ||
      isCreatingCustomer
    );
  }

  function differenceOfObjects(first: ProductObject, second: ProductObject) {
    let fields = new Set();
    objectsToArrays(first, "key").forEach((productId) => fields.add(productId));
    objectsToArrays(second, "key").forEach((productId) =>
      fields.add(productId)
    );

    const productIds = Array.from(fields) as string[];
    const result = productIds.reduce(
      (differenced: ProductObject, productId) => {
        const firstSet = first[productId] ?? [];
        const secondSet = second[productId] ?? [];
        const businessKeys = differenceSets(firstSet, secondSet);
        if (businessKeys?.length !== 0) {
          differenced[productId] = businessKeys;
        }
        return differenced;
      },
      {}
    );

    return result;
  }

  async function handleBusiness(customerKey: string) {
    const currentProductBusiness = businesses.reduce(
      (productObject: ProductObject, business) => {
        business.productIds.forEach((productId) => {
          productObject[productId] = [
            ...(productObject[productId] ?? []),
            business.businessKey,
          ];
        });
        return productObject;
      },
      {}
    );
    const oldProductBusiness =
      user?.business?.reduce((productObject: ProductObject, business) => {
        const { _id, businessKey, ...products } = business;
        objectsToArrays(products, "key").forEach((productId) => {
          //@ts-ignore
          if (products[productId]?.roleKey) {
            productObject[productId] = [
              ...(productObject[productId] ?? []),
              businessKey,
            ];
          }
        });
        return productObject;
      }, {}) ?? ({} as ProductObject);
    const assigningBusiness = differenceOfObjects(
      currentProductBusiness,
      oldProductBusiness
    );
    const unAssigningBusiness = differenceOfObjects(
      oldProductBusiness,
      currentProductBusiness
    );

    if (objectsToArrays(assigningBusiness).length > 0)
      await addCustomerToBusiness({
        customerKey,
        updatedBy: userKey!,
        productBusiness: assigningBusiness,
      });

    if (objectsToArrays(unAssigningBusiness).length > 0)
      await removeCustomerFromBusiness({
        customerKey,
        updatedBy: userKey!,
        productBusiness: unAssigningBusiness,
      });
  }

  function someBusinessHasNoProducts() {
    return businesses.some((business) => business.productIds.length === 0);
  }

  const isThereNoBuinessSelected = () => {
    return businesses.some((business) => !business.businessKey);
  };

  function resetLoaders() {
    newCustomerInfo.reset();
    updatedCustomerInfo.reset();
    addCustomerToBusinessInfo.reset();
    removeCustomerFromBusinessInfo.reset();
  }

  async function handleSubmitAction() {
    if (!isEditUser) {
      if (!businesses.length) {
        return toast({
          duration: 3000,
          status: "warning",
          description: "Please select at least one business.",
        });
      } else if (isThereNoBuinessSelected()) {
        setError({
          ...error,
          business: {
            isError: true,
            errorMessage: "Please select at least one business.",
          },
        });
        return;
      } else {
        setError(undefined);
      }
    }
    if (someBusinessHasNoProducts()) {
      return toast({
        duration: 3000,
        status: "error",
        description: "At least one subscription of a business is required.",
      });
    }

    const { firstName, lastName } = superadmin;

    if (`${firstName}${lastName}`.length > 30) {
      return toast({
        duration: 3000,
        status: "warning",
        description: "Super admin name cannot be too long",
      });
    }

    // @ts-ignore

    if (isEditUser) {
      const {
        firstName,
        email = "",
        address = "",
        remarks = "",
        lastName,
        phoneNumber = "",
        loginMethod,
      } = superadmin;
      try {
        await updateCustomer({
          _id: user?.userKey ?? "",
          firstName,
          email,
          address,
          remarks,
          lastName,
          loginMethod,
          phoneNumber: phoneNumber === "undefined" ? "" : phoneNumber,
          image: file,
          updatedBy: userKey ?? "",
          removeImage,
        });
        //@ts-ignore
        const customerKey: string = superadmin?.userKey ?? "";
        await handleBusiness(customerKey);
        toast({
          duration: 3000,
          status: "success",
          description: `Super admin ${superadmin?.firstName} ${
            superadmin?.lastName ?? ""
          } has been updated.`,
        });
      } catch (error: any) {
        resetLoaders();
        const message =
          typeof error.response?.data?.message === "string"
            ? error.response?.data?.message
            : "Error while updating the super admin.";

        return toast({
          duration: 3000,
          status: "error",
          description: message,
        });
      }
    } else {
      try {
        await addCustomer({
          ...superadmin,
          image: file,
          business: businesses,
          phoneNumber: superadmin.phoneNumber ?? "",
          countryCode: selectedCountryCode?.value ?? "",
        });
        toast({
          duration: 3000,
          status: "success",
          description: `Super admin ${firstName ?? ""} ${
            lastName ?? ""
          } created successfully`,
        });
      } catch (error: any) {
        let errorMessage;
        let e = error?.response?.data?.message?.slice(0, 7);
        if (e === '"email"') {
          errorMessage = "Email is not in correct format.";
        } else {
          errorMessage = error?.response?.data?.message;
        }
        resetLoaders();
        return toast({
          duration: 3000,
          status: "error",
          description: errorMessage ?? "Cannot create super admin.",
        });
      }
    }
    resetLoaders();
    handleClose();
  }

  const getLastNameOfCustomer = (
    arrayOfNames: string[],
    numberOfItems: number
  ) => {
    if (numberOfItems === 1) {
      return "";
    }
    return arrayOfNames[numberOfItems - 1];
  };

  const getFirstNameOfCustomer = (
    arrayOfNames: string[],
    numberOfItems: number
  ) => {
    if (numberOfItems === 1) {
      return arrayOfNames[0];
    }
    return arrayOfNames.slice(0, numberOfItems - 1).join(" ") ?? "";
  };

  function handleInputChange(field: string, value: string) {
    const arrayOfNames = value.split(" ");

    const numberOfItems = arrayOfNames.length;

    let updateSuperadmin = { ...superadmin };

    if (field === "fullName") {
      const firstName = getFirstNameOfCustomer(arrayOfNames, numberOfItems);
      const lastName = getLastNameOfCustomer(arrayOfNames, numberOfItems);

      updateSuperadmin = {
        ...updateSuperadmin,
        firstName,
        lastName,
      };
      setFullName(value);
    } else {
      updateSuperadmin = { ...updateSuperadmin, [field]: value };
    }

    setSuperadmin(updateSuperadmin);
  }

  function handleChangeBusiness(values: BusinessObject[]) {
    setBusinesses(values);
  }

  function changeProfilePicture(file: FileList | null) {
    if (file) {
      setFile(file[0]);
      setImage(URL.createObjectURL(file[0]));
    }
  }

  function handleRemoveImage() {
    setFile(undefined);
    setImage(undefined);
    setRemoveImage(true);
  }

  function validatePhoneNumber() {
    if (!isEditUser) {
      const { phoneNumber } = superadmin;
      if (!selectedCountryCode?.value) return "Please select country.";
      if (!phoneNumber) return "Please enter phone number";
      if (phoneNumber?.length !== 10 && !isValidPhoneNumber(phoneNumber)) {
        return "Invalid phone number.";
      }
    }
    return;
  }

  function renderLoginMethod() {
    const { email, loginMethod, phoneNumber } = superadmin;

    if (loginMethod === LoginMethod.EMAIL) {
      return (
        <SInput
          isRequired
          type="email"
          name="email"
          value={email}
          placeholder="Email*"
          isDisabled={isEditUser}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
        />
      );
    }

    return (
      <Flex
        gridGap="2"
        alignItems={errors?.phoneNumber?.message ? "top" : "center"}
      >
        <Select
          options={countrySelectOptions}
          styles={{
            menu: (provided, state) => ({
              ...provided,
              width: 400,
            }),
            control: () => ({
              width: 100,
              display: "flex",
              border: "solid 1px #ECEEF5",
              borderRadius: 5,
            }),
          }}
          isDisabled={isEditUser}
          value={selectedCountryCode}
          onChange={(selectedValue) => setSelectedCountryCode(selectedValue)}
        />

        <SInput
          type="number"
          name="phoneNumber"
          isDisabled={isEditUser || !selectedCountryCode?.value}
          placeholder="Enter Phone Number *"
          value={isEditUser ? disabledPhoneNumber : phoneNumber}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          register={{
            ...register("phoneNumber", {
              validate: validatePhoneNumber,
            }),
          }}
          error={errors?.phoneNumber}
          errorMessage={errors?.phoneNumber?.message}
          countryCode={selectedCountryCode?.value}
        />
      </Flex>
    );
  }

  function renderImageUpload() {
    return (
      <>
        <Text color="gray">{CustomerAdmin} photo</Text>
        <Flex align="center" gridGap="4">
          <ImageContainer
            size="100px"
            imageURL={image}
            externalRef={fileUpload}
            onChange={(e) => changeProfilePicture(e.target.files)}
          />
          <Button
            variant="outline"
            colorScheme="blue"
            onClick={() => fileUpload?.current.click()}
          >
            Choose Image
          </Button>
          {!!image && (
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => handleRemoveImage()}
            >
              Remove Image
            </Button>
          )}
        </Flex>
      </>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitAction)}>
        <Stack spacing="8">
          {renderImageUpload()}
          <SInput
            isRequired
            type="text"
            name="fullName"
            placeholder="Name*"
            // @ts-ignore
            value={fullName}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          />
          <SInput
            isRequired
            type="text"
            name="address"
            placeholder="Address*"
            value={superadmin.address}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          />
          <SInput
            type="textarea"
            name="remarks"
            placeholder="Remarks"
            value={superadmin.remarks}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          />
          <Heading data-testid="loginMethodHeading" as="h3" size="22px">
            Log in method
          </Heading>
          <RadioGroup
            color="gray"
            onChange={(e) => handleInputChange("loginMethod", e)}
            value={superadmin.loginMethod}
          >
            <Stack direction="row">
              <Radio value={LoginMethod.EMAIL} isDisabled={isEditUser}>
                Email
              </Radio>
              <Radio value={LoginMethod.PHONE} isDisabled={isEditUser}>
                Phone Number
              </Radio>
            </Stack>
          </RadioGroup>
          {renderLoginMethod()}
        </Stack>
        <Heading data-testid="loginMethodHeading" as="h3" size="md" mt="8">
          Assign to
        </Heading>
        <AssignBusiness
          userKey="user"
          business={user?.business}
          onChange={(values) => handleChangeBusiness(values)}
          setLoadings={setLoadings}
          error={error}
          setError={setError}
        />
        <Stack spacing="4" mt="4">
          <Flex justify="flex-end">
            <Button
              variant="ghost"
              mr={3}
              onClick={() => handleClose()}
              color="primary.500"
              fontWeight="600"
            >
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              disabled={loadings}
              type="submit"
              isLoading={loading()}
            >
              {isEditUser
                ? `Update ${CustomerAdmin}`
                : `Create ${CustomerAdmin}`}
            </Button>
          </Flex>
        </Stack>
      </form>
    </>
  );
};
export interface User {
  _id?: string;
  keycloakUserId?: string;
  isVerified?: boolean;
  firstName: string;
  lastName: string;
  loginMethod: LoginMethod;
  isCustomerSuperAdmin?: boolean;
  isInternalUser?: boolean;
  createdBy?: string;
  createdAt?: Date;
  updatedBy?: string;
  updatedAt?: Date;
  entityType?: string;
  settingsTempGenie?: SettingsTempGenie;
  verificationCode?: string;
  business?: string[];
  isDisabled: boolean;
  phoneNumber?: string;
  email: string;
  address?: string;
  remarks?: string;
  imageURL?: string;
  lastLoginAt: number;
}
export interface SettingsTempGenie {
  temperatureUnit: TemperatureUnit;
  language: string;
  notificationModes: NotificationModes[];
  notificationSubscriptions: string[];
}
export enum TemperatureUnit {
  celsius = "celsius",
  fahrenheit = "fahrenheit",
}
export enum NotificationModes {
  sms = "sms",
  email = "email",
  push = "push",
}
export function getNameFromUser(user: Customer) {
  if (!user) return "";
  return user?.firstName + " " + (!user.lastName ? "" : user.lastName);
}

interface ProductObject {
  [productId: string]: string[];
}
