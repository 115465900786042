import { WarningIcon, Icon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/layout";

interface ErrorMessagePropsInterface {
  message: string;
}
function ErrorMessage(props: ErrorMessagePropsInterface) {
  const { message } = props;
  return (
    <Flex alignItems="center" gridGap={1}>
      <WarningIcon mb={1} />

      <Text color="red">{message}</Text>
    </Flex>
  );
}

export default ErrorMessage;
