import { useState, useContext, useEffect } from "react";

import {
  Box,
  Flex,
  Text,
  Wrap,
  Stack,
  Button,
  Drawer,
  Center,
  Heading,
  useToast,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

import { CustomRow } from "./CustomRow";
import { ProductForm } from "./AddProduct";
import { AddResource } from "./AddResource";
import Page from "../../packages/components/Page";
import { ActionInterface } from "../../apis/role";
import { AuthContext } from "../Login/AuthContext";
import { sortByEntity } from "../../utils/helpers";
import { AddNotification } from "./AddNotification";
import { AddProductFeature } from "./AddUpdateFeature";
import { SLoader } from "../../packages/components/SLoader";
import { SearchBar } from "../../packages/components/SearchBar";
import { HeaderInterface, STable } from "../../packages/components/STable";
import { PlusIcon, EditIcon, SearchIcon, DelRedIcon } from "../../utils/Icons";
import { useDebouncedCallback } from "../../packages/hooks/useDebouncedCallback";
import {
  Notification,
  ProductFeature,
  Product as ProductType,
  Product as ProductInterface,
} from "../../packages/interfaces/product";
import {
  useProducts,
  useDeleteFeatures,
  useDeleteProducts,
  useDeleteResources,
  useDeleteNotifications,
} from "../../apis/product";
import SDialog from "../../packages/components/SDialog";
import {
  toastConfigs,
  objectsToArrays,
  getAccessDeniedErrorMessage,
} from "../../packages/helpers/extra";
import {
  Business,
  Features,
  Resources,
  Notifications,
  Product as ProductString,
} from "../../enums/product";

interface Resource {
  actions: string[];
  isActive: string;
  resourceId: string;
}

const addProductId = "addProductId";

export const Product = () => {
  const { userRoles } = useContext(AuthContext);

  const type = "products";

  const canUserCreateProduct = userRoles?.[type]?.some(
    (action: ActionInterface) => action.create
  );

  const canUserDeleteTheProduct = userRoles?.[type]?.some(
    (action: ActionInterface) => action.delete
  );

  const canUserEditTheProduct = userRoles?.[type]?.some(
    (action: ActionInterface) => action.edit
  );

  const toast = useToast(toastConfigs);
  const productHeaders: HeaderInterface[] = [
    { name: ProductString, sort: "desc" },
    { name: Features, sort: "desc" },
    { name: Notifications, sort: "desc" },
    { name: Resources, sort: "desc" },
    { name: Business, sort: "desc" },
  ];

  const [headers, setHeaders] = useState(productHeaders);

  const [openModal, setOpenModal] = useState<string>("opr");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState({
    title: "",
    body: "",
  });
  const [searchText, setSearchText] = useState<string>("");
  const debouncedOnChange = useDebouncedCallback(
    (e) => setSearchText(e.target.value),
    400
  );
  let [selectedProducts, setSelectedProducts] = useState<any>({});
  let [selectedProduct, setSelectedProduct] = useState<
    ProductType | undefined
  >();
  let [selectedFeature, setSelectedFeature] = useState<
    ProductFeature | undefined
  >();
  const [selectedResources, setSelectedResources] = useState<any>({});
  const [selectedResource, setSelectedResource] = useState<any>();
  const [selectedNotification, setSelectedNotification] =
    useState<Notification>();
  const [selectedNotifications, setSelectedNotifications] = useState<any>({});
  const [selectedFeatures, setSelectedFeatures] = useState<any>({});
  const [selectedProductKey, setSelectedProductKey] = useState("");

  const [deleteFeatures, deleteFeaturesInfo] = useDeleteFeatures();
  const [deleteProducts, deleteProductsInfo] = useDeleteProducts();
  const [deleteResources, deleteResourcesInfo] = useDeleteResources();
  const [deleteNotification, deleteNotificationInfo] = useDeleteNotifications();
  const { data, isLoading: isProductFetching } = useProducts();

  const [products, setProducts] = useState<ProductInterface[]>();

  useEffect(() => {
    const filteredProducts = (data?.data ?? []).filter((product) => {
      return product.productId
        .toLowerCase()
        .includes(searchText.trim().toLowerCase());
    });

    const { sort = "desc" } =
      headers?.find((header) => header.name === "Product") ?? {};

    const sortedProducts = filteredProducts?.sort(
      (firstBusiness, secondBusiness) =>
        sortByEntity(firstBusiness.productId, secondBusiness.productId, sort)
    );

    setProducts(sortedProducts);
  }, [data, searchText]);

  const valuesOfSelectedProducts = objectsToArrays(selectedProducts);
  const valuesOfSelectedResources = objectsToArrays(selectedResources);
  const valuesOfSelectedNotifications = objectsToArrays(selectedNotifications);
  const valuesOfSelectedFeatures = objectsToArrays(selectedFeatures);

  function openDetailsModal() {
    setOpenModal("product");
  }

  function handleOnNameClick(product: ProductType) {
    openDetailsModal();
    setSelectedProduct(product);
  }

  function selectProduct(product: ProductType) {
    setSelectedProductKey("");
    if (selectedProducts[product.productId]) {
      delete selectedProducts[product.productId];
    } else {
      setSelectedFeatures({});
      selectedProducts[product.productId] = product;
    }
    setSelectedProducts({ ...selectedProducts });
  }

  function selectFeature(feature: ProductFeature, product: ProductType) {
    if (feature._id) {
      setSelectedProduct(product);
      if (selectedFeatures[feature._id]) {
        delete selectedFeatures[feature._id];
      } else {
        setSelectedProducts({});
        selectedFeatures[feature._id] = feature;
      }
      setSelectedFeatures({ ...selectedFeatures });
    }
  }

  function selectResource(resource: Resource, product: ProductType) {
    setSelectedProduct(product);
    if (selectedResources[resource.resourceId]) {
      delete selectedResources[resource.resourceId];
    } else {
      selectedResources[resource.resourceId] = resource;
    }
    setSelectedResources({ ...selectedResources });
  }

  function selectNotification(
    notification: Notification,
    product: ProductType
  ) {
    const { notificationId } = notification;
    if (selectedNotifications[notificationId]) {
      delete selectedNotifications[notificationId];
    } else {
      selectedNotifications[notificationId] = notification;
    }
    setSelectedProduct(product);
    setSelectedNotifications({ ...selectedNotifications });
  }

  function newFeature({
    product,
    feature,
  }: {
    product: ProductType | undefined;
    feature?: any;
  }) {
    const action = feature ? "edit" : "add";
    if (
      (action === "add" && !canUserCreateProduct) ||
      (action === "edit" && !canUserEditTheProduct)
    ) {
      toast(getAccessDeniedErrorMessage(action, "feature"));
      return;
    }
    setSelectedProduct(product);
    setSelectedFeature(feature);
    setOpenModal("feature");
    setSelectedProducts({});
    setSelectedFeatures({});
  }

  function clearState() {
    setSelectedFeatures({});
    setSelectedResources({});
    setSelectedProducts({});
    setSelectedNotifications({});
    setSelectedFeature(undefined);
    setSelectedResource(undefined);
    setSelectedNotification(undefined);
  }

  async function removeFeatures({
    product,
    features,
  }: {
    product: ProductType | undefined;
    features?: any;
  }) {
    const featuresValues = objectsToArrays(features);

    const featureIds = featuresValues?.map((feature) => feature.featureId);

    await deleteFeatures({
      productKey: product?._id ?? "",
      featureIds,
    });
    setSelectedFeatures({});
  }

  function newNotification(product: ProductType) {
    if (!canUserCreateProduct) {
      toast(getAccessDeniedErrorMessage("add", "notification"));
      return;
    }
    setOpenModal("notification");
    setSelectedProduct(product);
  }

  function removeResources(resources: Resource[]) {
    const resourceKeys = objectsToArrays(resources).map(
      (resource) => resource._id ?? ""
    );
    const productKey = selectedProduct?._id ?? "";
    return deleteResources({ productKey, resourceKeys });
  }

  function editProduct(product: ProductType) {
    if (!canUserEditTheProduct) {
      toast(getAccessDeniedErrorMessage("edit", "product"));
      return;
    }
    setSelectedProduct({ ...product });
    setOpenModal("product");
    setSelectedProducts({});
  }

  async function removeProducts(products: ProductType[]) {
    const productKeys = objectsToArrays(products).map(
      (product) => product._id ?? ""
    );
    await deleteProducts(productKeys);
  }

  function handleAddNotification(product: ProductType) {
    if (!canUserCreateProduct) {
      toast(getAccessDeniedErrorMessage("add", "notification"));
      return;
    }
    setSelectedProduct(product);
    setSelectedNotification(undefined);
    setOpenModal("notification");
    setSelectedProducts({});
  }

  function handleAddResource(product: any) {
    if (!canUserCreateProduct) {
      toast(getAccessDeniedErrorMessage("add", "resource"));
      return;
    }
    setSelectedProduct(product);
    setSelectedResource(undefined);
    setOpenModal("resource");
  }

  function handleEditResource() {
    if (!canUserEditTheProduct) {
      toast(getAccessDeniedErrorMessage("edit", "resource"));
      return;
    }
    const resource = Object.values(selectedResources)[0];
    setSelectedResource(resource);
    setOpenModal("resource");
    setSelectedResources({});
  }

  function handleEditNotification() {
    if (!canUserEditTheProduct) {
      toast(getAccessDeniedErrorMessage("edit", "notification"));
      return;
    }
    const notification = objectsToArrays(
      selectedNotifications
    )[0] as Notification;
    setSelectedNotification(notification);
    setOpenModal("notification");
    setSelectedNotifications({});
  }

  function anyProductHasBusiness() {
    const products = objectsToArrays(selectedProducts);
    const doesAnyProductHaveSubscription = products.some(
      (product) => product.subscriptions.length > 0
    );
    return doesAnyProductHaveSubscription;
  }

  async function handleDeleteAction() {
    try {
      if (valuesOfSelectedProducts.length) {
        if (anyProductHasBusiness()) {
          setSelectedProducts({});
          setOpenDialog(false);
          return toast({
            duration: 3000,
            status: "error",
            description: "Products have active subscriptions.",
          });
        }
        await removeProducts(selectedProducts);
        setSelectedProducts({});
      } else if (valuesOfSelectedResources.length) {
        await removeResources(valuesOfSelectedResources);
        setSelectedResources({});
      } else if (valuesOfSelectedNotifications.length) {
        const notifications: Notification[] =
          objectsToArrays(selectedNotifications) ?? [];
        const notificationKeys = notifications.map(
          (notification) => notification._id ?? ""
        );
        const { _id: productKey = "" } = selectedProduct as ProductType;
        await deleteNotification({ productKey, notificationKeys });

        setSelectedNotifications({});
      } else {
        await removeFeatures({
          features: selectedFeatures,
          product: selectedProduct,
        });
        setSelectedFeatures({});
      }
    } catch (err: any) {
      return toast({
        duration: 3000,
        status: "error",
        description: err?.response?.data?.message ?? "Cannot delete.",
      });
    }
    toast({
      duration: 3000,
      status: "info",
      description: "Deleted successfully.",
    });
    setOpenDialog(false);
  }

  function showButtons() {
    const resources = objectsToArrays(selectedResources).length;
    const products = objectsToArrays(selectedProducts).length;
    const notifications = objectsToArrays(selectedNotifications).length;

    if (products) {
      return (
        <>
          {products < 2 && (
            <>
              <Button
                disabled={!canUserCreateProduct}
                p="4"
                leftIcon={<PlusIcon />}
                colorScheme="blue"
                variant="outline"
                size="sm"
                onClick={() => {
                  newFeature({
                    product: selectedProducts[Object.keys(selectedProducts)[0]],
                  });
                }}
              >
                Add Features
              </Button>
              <Button
                disabled={!canUserCreateProduct}
                p="4"
                leftIcon={<PlusIcon />}
                colorScheme="blue"
                variant="outline"
                size="sm"
                onClick={() => {
                  handleAddNotification(
                    selectedProducts[Object.keys(selectedProducts)[0]]
                  );
                }}
              >
                Add Notification
              </Button>
              <Button
                disabled={!canUserCreateProduct}
                p="4"
                leftIcon={<PlusIcon />}
                colorScheme="blue"
                variant="outline"
                size="sm"
                onClick={() => {
                  handleAddResource(
                    selectedProducts[Object.keys(selectedProducts)[0]]
                  );
                }}
              >
                Add Resource
              </Button>
              <Button
                disabled={!canUserEditTheProduct}
                p="4"
                leftIcon={<EditIcon />}
                colorScheme="blue"
                variant="outline"
                size="sm"
                onClick={() => {
                  editProduct(
                    selectedProducts[Object.keys(selectedProducts)[0]]
                  );
                }}
              >
                Edit
              </Button>
            </>
          )}
        </>
      );
    } else if (resources !== 0 && resources < 2) {
      return (
        <Button
          leftIcon={<EditIcon />}
          colorScheme="blue"
          variant="outline"
          size="sm"
          onClick={() => {
            handleEditResource();
          }}
        >
          Edit
        </Button>
      );
    } else if (notifications !== 0 && notifications < 2) {
      return (
        <Button
          leftIcon={<EditIcon />}
          colorScheme="blue"
          variant="outline"
          size="sm"
          onClick={() => {
            handleEditNotification();
          }}
        >
          Edit
        </Button>
      );
    }
  }

  const getSelectedItem = (
    products: any[],
    resources: any[],
    features: any[]
  ) => {
    if (products?.length > 0) {
      return "product";
    }
    if (resources?.length > 0) {
      return "resource";
    }
    if (features?.length > 0) {
      return "feature";
    }
    return "notification";
  };

  function handleOpenDialog() {
    const selectedProductsArray = objectsToArrays(selectedProducts);
    const selectedResourceArray = objectsToArrays(selectedResources);
    const selectedFeaturesArray = objectsToArrays(selectedFeatures);
    const selectedNotificationsArray = objectsToArrays(selectedNotifications);

    if (!canUserDeleteTheProduct) {
      const selectedItem = getSelectedItem(
        selectedProductsArray,
        selectedResourceArray,
        selectedFeaturesArray
      );
      toast(getAccessDeniedErrorMessage("delete", selectedItem));
      return;
    }
    let selectedItemName;

    let title = "";
    let count = 0;

    if (selectedProductsArray?.length) {
      count = selectedProductsArray.length;
      const selectedItem = selectedProductsArray[0] as ProductType;
      selectedItemName = selectedItem?.productId;
      title = "product";
    } else if (selectedResourceArray?.length) {
      count = selectedResourceArray.length;
      const selectedItem = selectedResourceArray[0] as Resource;
      selectedItemName = selectedItem?.resourceId;
      title = "resource";
    } else if (selectedNotificationsArray?.length) {
      count = selectedNotificationsArray.length;
      const selectedItem = selectedNotificationsArray[0] as Notification;
      selectedItemName = selectedItem?.notificationId;
      title = "notification";
    } else {
      count = selectedFeaturesArray.length;
      const selectedItem = selectedFeaturesArray[0] as ProductFeature;
      selectedItemName = selectedItem?.featureId;
      title = "feature";
    }

    const bodyText = count > 1 ? "" : `- ${selectedItemName}`;

    setDialogText({
      title: `Delete ${count} ${title}`,
      body: `Are you sure want to delete ${title} ${bodyText}.`,
    });
    setOpenDialog(true);
  }

  function renderDrawerTitle() {
    return `${selectedItems} ${getSelectedItem(
      valuesOfSelectedProducts,
      valuesOfSelectedResources,
      valuesOfSelectedFeatures
    )} selected`;
  }

  const selectedItems =
    objectsToArrays(selectedProducts).length ||
    objectsToArrays(selectedResources).length ||
    objectsToArrays(selectedNotifications).length;

  const deleteActionLoader =
    deleteProductsInfo.isLoading ||
    deleteResourcesInfo.isLoading ||
    deleteFeaturesInfo.isLoading;

  const handleAddProductButton = () => {
    if (!canUserCreateProduct) {
      if (!toast.isActive(addProductId)) {
        toast({
          id: addProductId,
          ...getAccessDeniedErrorMessage("create", "product"),
        });
      }
      return;
    }
    setSelectedProduct(undefined);
    setOpenModal("product");
  };

  const handleHeaderClick = (header: HeaderInterface, headerIndex: number) => {
    let sortedProducts: ProductInterface[] = [...(products ?? [])];
    const { sort } = header;
    const newSort = sort === "desc" ? "asc" : "desc";

    switch (header.name) {
      case ProductString:
        sortedProducts =
          sortedProducts?.sort((firstProduct, secondProduct) =>
            sortByEntity(
              firstProduct.productId,
              secondProduct.productId,
              newSort
            )
          ) ?? [];
        break;

      case Features:
        sortedProducts =
          sortedProducts?.sort((firstProduct, secondProduct) =>
            sortByEntity(
              firstProduct.features.length ?? 0,
              secondProduct.features.length ?? 0,
              newSort
            )
          ) ?? [];
        break;

      case Notifications:
        sortedProducts =
          sortedProducts?.sort((firstProduct, secondProduct) =>
            sortByEntity(
              firstProduct.notifications?.length ?? 0,
              secondProduct.notifications?.length ?? 0,
              newSort
            )
          ) ?? [];
        break;

      case Resources:
        sortedProducts =
          sortedProducts?.sort((firstProduct, secondProduct) =>
            sortByEntity(
              firstProduct.resources?.length ?? 0,
              secondProduct.resources?.length ?? 0,
              newSort
            )
          ) ?? [];
        break;

      case Business:
        sortedProducts =
          sortedProducts?.sort((firstProduct, secondProduct) =>
            sortByEntity(
              firstProduct.subscriptions?.length ?? 0,
              secondProduct.subscriptions?.length ?? 0,
              newSort
            )
          ) ?? [];
        break;

      default:
        sortedProducts = [...(products ?? [])];
        break;
    }

    let updatedHeaders = [...headers];

    updatedHeaders.splice(headerIndex, 1, {
      sort: newSort,
      name: header.name,
    });

    setHeaders([...updatedHeaders]);
    setProducts(sortedProducts);
  };

  if (isProductFetching) {
    return (
      <Center h="100%">
        <SLoader size="lg" />
      </Center>
    );
  }

  return (
    <Page>
      <Flex justify="space-between">
        <Heading as="h1" size="lg">
          Product
        </Heading>
        <Button
          leftIcon={<PlusIcon />}
          size="md"
          colorScheme="blue"
          onClick={() => handleAddProductButton()}
        >
          Add Product
        </Button>
      </Flex>
      <Box maxW="40%">
        <SearchBar
          value={searchText}
          title="Search Product, Business and Features"
          rightIcon={<SearchIcon />}
          onChange={(e) => {
            clearState();
            setSearchText(e.target.value);
            debouncedOnChange(e);
          }}
          onClose={() => setSearchText("")}
        />
      </Box>
      <STable
        checked={selectedItems === products?.length}
        headers={headers}
        onChangeCheckBox={(e: any) => {
          if (e.target.checked) {
            products?.forEach((product: any) => {
              selectedProducts[product.productId] = product;
            });
            setSelectedProducts({ ...selectedProducts });
          } else {
            setSelectedProducts({});
          }
        }}
        handleHeaderClick={handleHeaderClick}
      >
        {products?.map((product) => (
          <CustomRow
            product={product}
            key={product.productId}
            newFeature={newFeature}
            selectProduct={selectProduct}
            selectFeature={selectFeature}
            selectResource={selectResource}
            selectedFeatures={selectedFeatures}
            selectedResources={selectedResources}
            selectedProductKey={selectedProductKey}
            setSelectedFeatures={setSelectedFeatures}
            setSelectedProductKey={setSelectedProductKey}
            onNameClick={() => handleOnNameClick(product)}
            checked={!!selectedProducts[product.productId]}
            onAddResource={() => handleAddResource(product)}
            selectedNotifications={selectedNotifications}
            selectNotification={(notification, product) =>
              selectNotification(notification, product)
            }
            newNotification={() => newNotification(product)}
          />
        ))}
      </STable>

      {openModal === "product" && (
        <ProductForm
          setOpenModal={setOpenModal}
          open={openModal === "product"}
          product={selectedProduct}
        ></ProductForm>
      )}

      {openModal === "feature" && (
        <AddProductFeature
          setOpenModal={setOpenModal}
          open={openModal === "feature"}
          product={selectedProduct}
          feature={selectedFeature}
        ></AddProductFeature>
      )}

      {openModal === "resource" && (
        <AddResource
          setOpenModal={setOpenModal}
          open={openModal === "resource"}
          product={selectedProduct}
          resource={selectedResource}
        ></AddResource>
      )}

      {openModal === "notification" && (
        <AddNotification
          clearState={clearState}
          setOpenModal={setOpenModal}
          open={openModal === "notification"}
          selectedNotification={selectedNotification}
          product={selectedProduct}
        ></AddNotification>
      )}
      <Drawer
        isOpen={!!selectedItems}
        variant="alwaysOpen"
        placement="bottom"
        blockScrollOnMount={false}
        trapFocus={false}
        onClose={() => clearState()}
      >
        <DrawerContent
          p="6"
          w="60%"
          mx="auto"
          shadow="0px 0px 50px 2px rgba(50,58,69,0.47)"
          borderTopLeftRadius="12"
          borderTopRightRadius="12"
        >
          <DrawerBody>
            <Stack direction="row" justify="space-between">
              <Flex>
                <DrawerCloseButton position="relative" top="0" />
                <Text display="flex" alignItems="center">
                  {renderDrawerTitle()}
                </Text>
              </Flex>
              <Wrap>{showButtons()}</Wrap>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Drawer
        isOpen={Object.keys(selectedFeatures).length > 0}
        variant="alwaysOpen"
        placement="bottom"
        blockScrollOnMount={false}
        trapFocus={false}
        onClose={() => setSelectedFeatures({})}
      >
        <DrawerContent
          p="6"
          w="60%"
          mx="auto"
          shadow="0px 0px 50px 2px rgba(50,58,69,0.47)"
          borderTopLeftRadius="12"
          borderTopRightRadius="12"
        >
          <DrawerBody>
            <Stack direction="row" justify="space-between">
              <Flex>
                <DrawerCloseButton position="relative" top="0" />
                <Text display="flex" alignItems="center">
                  {Object.keys(selectedFeatures).length} feature selected
                </Text>
              </Flex>
              <Wrap>
                {Object.keys(selectedFeatures).length < 2 && (
                  <>
                    <Button
                      p="4"
                      leftIcon={<EditIcon />}
                      colorScheme="blue"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        newFeature({
                          feature:
                            selectedFeatures[Object.keys(selectedFeatures)[0]],
                          product: selectedProduct,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  </>
                )}

                <Button
                  p="4"
                  leftIcon={<DelRedIcon />}
                  colorScheme="red"
                  variant="outline"
                  size="sm"
                  onClick={() => handleOpenDialog()}
                >
                  Delete
                </Button>
              </Wrap>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <SDialog
        size="lg"
        open={openDialog}
        body={dialogText.body}
        positiveLabel="Delete"
        title={dialogText.title}
        positiveLabelColor="red"
        loading={deleteActionLoader}
        handlePositiveAction={handleDeleteAction}
        subTitle="Remember, the action cannot be undone"
        handleNegativeAction={() => setOpenDialog(false)}
      />
    </Page>
  );
};
