import jwtDecode from "jwt-decode";
import axios, { AxiosRequestConfig } from "axios";
import { queryCache, useMutation, useQuery, useQueryCache } from "react-query";

import network from "./network";
import { API_URL } from "../apis/network";
import { EntityUser } from "../packages/interfaces/auth";
// import { geniusLogoutURL, superAdminApiURL } from "../config";
import { minToMilliSeconds } from "../packages/helpers/extra";

const authUrl = `${process.env.REACT_APP_API_URL}/usermgmt/auth`;
const geniusLogWebUrl = `${process.env.REACT_APP_GENIUS_LOGIN ?? ""}/logout`;

const verifyCodeUrl = `${authUrl}/verifyCode`;
const checkIfNewUserUrl = `${authUrl}/checkNewUser`;

let token: any = null;
let token_expires_in = 0;
//registered on auth changed function
let onAuthChanged: Function;

export function logOut() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("userKey");
  window.location.replace(geniusLogWebUrl ?? "");
  if (onAuthChanged) {
    onAuthChanged(null);
  }
}

export async function getToken() {
  if (token) {
    if (token_expires_in < Date.now()) {
      await tryToRefreshToken();
      return token;
    } else {
      return token;
    }
  } else {
    return "";
  }
}

export function updateToken(tkn: string, expires_in = 0) {
  token = tkn;
  token_expires_in = expires_in;
}

export function getAuthenticatedUser(onAuth: Function) {
  onAuthChanged = onAuth;
  let access_token;
  let userKey: string | null = "";
  try {
    access_token = JSON.parse(localStorage.getItem("access_token") || "");
    userKey = localStorage.getItem("userKey");
  } catch (e) {
    onAuthChanged(null);
    return;
  }

  if (!userKey) {
    logOut();
    onAuthChanged(null);
    return;
  }

  if (access_token && access_token.expires_in > Date.now()) {
    updateToken(access_token.token, access_token.expires_in);
    onAuthChanged(userKey);
  } else {
    tryToRefreshToken().then((success) => {
      try {
        onAuthChanged(userKey);
      } catch (error) {
        onAuthChanged(null);
      }
      onAuthChanged(userKey);
      if (!success) {
        logOut();
      }
    });
  }
}

export async function tryToRefreshToken() {
  let rt;
  try {
    rt = JSON.parse(localStorage.getItem("refresh_token") || "");
  } catch (e) {
    return false;
  }
  if (rt && rt.expires_in > Date.now()) {
    try {
      const response = await refreshToken(rt.token);
      saveToken(response);

      return true;
    } catch (e) {
      console.log("Error refreshing token");
      return false;
    }
  }
  return false;
}

async function refreshToken(refresh_token: string) {
  const response = await axios.post(`${API_URL}/usermgmt/auth/refreshToken`, {
    refreshToken: refresh_token,
  });
  return response;
}

export async function login(loginData: { username: string; password: string }) {
  return axios.post(`${API_URL}/usermgmt/auth/login`, {
    ...loginData,
    loginMethod: "email",
  });
}

export function useLogin() {
  return useMutation(
    async (loginData: { username: string; password: string }) => {
      const response = await login(loginData);
      return response;
    },
    {
      onSuccess: (response: any) => {
        saveToken(response);
        return response;
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

const checkIfNewUser = (email: string) => {
  const data = {
    loginMethod: "email",
    email,
  };
  const configAxios: AxiosRequestConfig = {
    method: "POST",
  };
  return axios.post(checkIfNewUserUrl, data, configAxios);
};

export const useCheckNewUser = () => {
  return useMutation(
    async (email: string) => {
      const response = await checkIfNewUser(email);
      return response.data;
    },
    {
      onError: (e) => {
        throw e;
      },
    }
  );
};

const getEntityUser = (params: EntityUser) => {
  const { entityKey, entityType } = params;
  const data = {
    entityKey,
    entityType,
  };
  const entityUserURL = `${authUrl}/entityUser`;
  return axios.post(entityUserURL, data);
};

export const useEntityUser = () => {
  return useMutation(getEntityUser);
};

const verifyCode = async (verificationCode: string, email: string) => {
  const data = {
    email,
    verificationCode,
  };
  const resp = await axios.post(verifyCodeUrl, data);
  return resp.data;
};

export const useVerifyCode = () => {
  return useMutation(
    async (userKeyAndVerificationCode: {
      verificationCode: string;
      email: string;
    }) => {
      const res = await verifyCode(
        userKeyAndVerificationCode.verificationCode,
        userKeyAndVerificationCode.email
      );
      return res;
    },
    {
      onError: (e) => {
        throw e;
      },
    }
  );
};

async function getUserDetails(userKey: string) {
  if (!userKey) {
    return {};
  }
  const productId = "superadmin";
  return (await network()).get(
    `${API_URL}/usermgmt/users/${userKey}/${productId}`
  );
}

export function useUserDetails(userKey: string) {
  return useQuery(
    ["users", userKey],
    async () => {
      const resp: any = await getUserDetails(userKey);
      return resp?.data?.data;
    },
    {
      onSuccess: (data) => {
        queryCache.setQueryData(["users", userKey], data);
      },
      onError: async (e) => {
        await logOutFromAPI();
        throw e;
      },
      enabled: userKey.length > 0,
      refetchInterval: minToMilliSeconds(5),
    }
  );
}

export async function getRoleByRoleKey(roleKey: string) {
  const url = `${API_URL}/usermgmt/roles/${roleKey}`;
  const response = await (await network()).get(url);
  return response.data.data;
}

export function useRoleByRoleKey() {
  return useQuery(["roles"], getRoleByRoleKey, {
    onSuccess: (data) => {
      const role = data.data ?? {};
      queryCache.setQueryData(["roles", role._id], role);
    },
  });
}

function saveToken(response: any) {
  if (response.data.data.access_token) {
    localStorage.setItem(
      "refresh_token",
      JSON.stringify({
        token: response.data.data.refresh_token,
        expires_in: Date.now() + response.data.data.refresh_expires_in * 1000,
      })
    );
    localStorage.setItem(
      "access_token",
      JSON.stringify({
        token: response.data.data.access_token,
        expires_in: Date.now() + response.data.data.expires_in * 1000,
      })
    );
    updateToken(
      response?.data?.data?.access_token,
      Date.now() + response?.data?.data?.expires_in * 1000
    );
  }
}

const updatePassword = async (userKey: string, newPassword: string) => {
  const updatePasswordUrl = `${authUrl}/${userKey}/updatePassword`;

  const data = {
    password: newPassword,
  };

  const res = await axios.post(updatePasswordUrl, data);

  return res.data;
};

export const saveTokenFromQuery = (
  accessToken: string,
  refreshToken: string,
  userKey: string
) => {
  try {
    const decodedAccessToken: any = jwtDecode(accessToken);
    const decodedRefreshToken: any = jwtDecode(refreshToken);
    localStorage.setItem(
      "access_token",
      JSON.stringify({
        token: accessToken,
        expires_in: decodedAccessToken.exp * 1000,
      })
    );

    localStorage.setItem(
      "refresh_token",
      JSON.stringify({
        token: refreshToken,
        expires_in: decodedRefreshToken?.exp * 1000,
      })
    );
    localStorage.setItem("userKey", userKey);
    updateToken(accessToken, decodedAccessToken.exp * 1000);
  } catch (error) {
    console.error(error);
  }
};

export const useUpdatePassword = () => {
  return useMutation(
    async (userInfo: { userKey: string; newPassword: string }) => {
      const res = await updatePassword(userInfo.userKey, userInfo.newPassword);
      return res;
    },

    {
      onError: (e) => {
        throw e;
      },
    }
  );
};

export const logOutFromAPI = async () => {
  try {
    const refreshTokenDetails = localStorage.getItem("refresh_token");
    const parsedRefreshToken = JSON.parse(refreshTokenDetails ?? "");
    await axios.post(`${authUrl}/logout`, {
      refreshToken: parsedRefreshToken.token,
    });
    queryCache.clear();
    logOut();
  } catch (error) {
    console.error({ error });
  }
};

interface ChangePassword {
  userKey: string;
  password: string;
}

async function changePassword({ userKey, password }: ChangePassword) {
  return axios.post(`${authUrl}/${userKey}/updatePassword`, {
    password,
  });
}

export function useChangePassword() {
  const queryCache = useQueryCache();
  return useMutation(
    async ({ userKey, password }: ChangePassword) =>
      changePassword({ userKey, password }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["users"]);
      },
      onError: (e: any) => {
        throw e;
      },
    }
  );
}
