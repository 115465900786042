import { Avatar, Text, Divider, Wrap, WrapItem } from "@chakra-ui/react";

import { useGetCustomerByIds } from "../../apis/customers";
import { SLoader } from "../../packages/components/SLoader";
import { Customer } from "../../packages/interfaces/customer";

interface BusinessSuperadminProps {
  businessKey: string;
  superadmins: string[] | undefined;
}

interface ListProps {
  customer: Customer;
}

export const BusinessSuperadmin = (props: BusinessSuperadminProps) => {
  const { superadmins = [] } = props;

  const { data: customers, isLoading } = useGetCustomerByIds(superadmins ?? []);

  const List = (props: ListProps) => {
    const { firstName, lastName, imageURL } = props?.customer;
    const name = `${firstName} ${lastName}`;
    return (
      <Wrap py="2" align="center">
        <WrapItem>
          <Avatar size="sm" name={name} src={imageURL} />
        </WrapItem>
        <Text size="sm">{name}</Text>
      </Wrap>
    );
  };

  if (isLoading) {
    return <SLoader size="sm" />;
  }

  if (!superadmins?.length) {
    return <Text>No superadmins</Text>;
  }

  return (
    <div>
      {customers?.map((customer: Customer) => (
        <div>
          <List customer={customer} />
          <Divider />
        </div>
      ))}
    </div>
  );
};
