import { useContext, useEffect, useState } from "react";

import { Divider } from "@chakra-ui/layout";
import {
  Box,
  Flex,
  Text,
  Wrap,
  Modal,
  Stack,
  Button,
  Center,
  Drawer,
  Heading,
  useToast,
  ModalBody,
  DrawerBody,
  IconButton,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  DrawerContent,
  CircularProgress,
  ModalCloseButton,
  DrawerCloseButton,
} from "@chakra-ui/react";

import { Details } from "./Details";
import { CustomTable } from "./CustomTable";
import { MenuBarName } from "../../enums/menu";
import { ActionInterface } from "../../apis/role";
import Page from "../../packages/components/Page";
import { AuthContext } from "../Login/AuthContext";
import { sortByEntity } from "../../utils/helpers";
import SDialog from "../../packages/components/SDialog";
import { SDrawer } from "../../packages/components/SDrawer";
import { SearchBar } from "../../packages/components/SearchBar";
import { lowerCaseLetter } from "../../packages/helpers/strings";
import { HeaderInterface } from "../../packages/components/STable";
import {
  useBusiness,
  getBusinessFromId,
  useDeleteBusinesses,
} from "../../apis/business";
import {
  useReInvitation,
  resendInviteToUser,
  useGetLocationUsers,
} from "../../apis/users";
import { useDebouncedCallback } from "../../packages/hooks/useDebouncedCallback";
import {
  Customer,
  SelectedCustomerBusiness,
} from "../../packages/interfaces/customer";
import {
  toastConfigs,
  objectsToArrays,
  getAccessDeniedErrorMessage,
} from "../../packages/helpers/extra";
import {
  ProductBusiness,
  useCustomers,
  useDeleteCustomers,
  useEnableDisableCustomer,
  useRemoveCustomerFromBusiness,
} from "../../apis/customers";
import {
  DelRedIcon,
  DelIcon,
  EditIcon,
  PlusIcon,
  EnableIcon,
  SearchIcon,
  DisableIcon,
} from "../../utils/Icons";
import {
  User,
  LoginMethod,
  SuperAdminCard,
  getNameFromUser,
} from "./SuperAdminAddUpdateCard";
import { Business } from "../../packages/interfaces/business";
import { BusinessForm } from "../Business/AddEditBusiness";
import {
  stratosfyBusinessName,
  checkDuplicateBusinessName,
} from "../Business/Business";

const superAdminHeaders: HeaderInterface[] = [
  {
    name: "Customer admin",
    sort: "desc",
  },
  {
    name: "Login ID",
    sort: "desc",
  },
  {
    name: "Business",
    sort: "desc",
  },
];

const keyForBusinessRolePermissions = "business";

const superAdminKeyForRoleAccess = "superadmin";

const editToastId = "edit";
const deleteToastId = "delete";
const createToastId = "create";

const { CustomerAdmin } = MenuBarName;

export const SuperAdmin = () => {
  const toast = useToast(toastConfigs);

  const [headers, setHeaders] = useState(superAdminHeaders);

  const { userRoles, userKey: loggedInUserKey } = useContext(AuthContext);

  const isSuperAdminUsersCreatable = userRoles?.[
    superAdminKeyForRoleAccess
  ]?.some((superAdminAction: ActionInterface) => superAdminAction.create);

  const isSuperAdminusersDeletable = userRoles?.[
    superAdminKeyForRoleAccess
  ]?.some((superAdminAction: ActionInterface) => superAdminAction.delete);

  const canSuperAdminusersEnableDisable = userRoles?.[
    superAdminKeyForRoleAccess
  ]?.some((superAdminAction: ActionInterface) => superAdminAction.disable);

  const isSuperAdminUsersEditable = userRoles?.[
    superAdminKeyForRoleAccess
  ]?.some((superAdminAction: ActionInterface) => superAdminAction.edit);

  const canUserDeleteTheBusiness =
    userRoles?.[keyForBusinessRolePermissions]?.some(
      (action: ActionInterface) => action.delete
    ) ?? false;

  const canUserEditBusiness =
    userRoles?.[keyForBusinessRolePermissions]?.some(
      (action: ActionInterface) => action.edit
    ) ?? false;

  const { data: allCustomersRes, isLoading: customerLoading } = useCustomers();

  const [getLocationsUser, { isLoading, reset }] = useGetLocationUsers();

  const [deleteBusinesses, deleteBusinessInfo] = useDeleteBusinesses();
  const [gettingBusinessInfo, setGettingBusinessInfo] = useState(false);

  const [removeCustomerFromBusiness, removeCustomerInfo] =
    useRemoveCustomerFromBusiness();

  const [deleteCustomer] = useDeleteCustomers();
  const [disableCustomer] = useEnableDisableCustomer();
  const [resendInvite, resendInviteInfo] = useReInvitation();

  const allCustomers = allCustomersRes?.data ?? [];

  const [searchText, setSearchText] = useState("");

  const debouncedOnChange = useDebouncedCallback(
    (e) => setSearchText(e.target.value),
    400
  );

  const [openModal, setOpenModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletingBusiness, setDeletingBusiness] = useState(false);
  const [openBottomDrawer, setOpenBottomDrawer] = useState(false);
  const [editUser, seteditUser] = useState<any>({});
  const [isDisabled, setdisabled] = useState(false);
  const [isDisableding, setdisableding] = useState(false);
  const [openbottonCount, setbottomOpencount] = useState(0);
  const [checkboxSuperAdminUsers, setCheckboxSuperadminUsers] =
    useState<CheckBoxSuperAdminUsers>({});
  const [openDeletemodal, setOpendelete] = useState<boolean>(false);
  const [openbulkDeletemodal, setbulkOpendelete] = useState<boolean>(false);
  const [openDeleteBusinessmodal, setOpendeleteBusiness] =
    useState<boolean>(false);
  const [checkBoxBusiness, setcheckBoxBusiness] = useState<any>({});
  const [superAdmins, setSuperadmins] = useState<Customer[] | undefined>(
    undefined
  );
  const [editingBusiness, setEditingBusiness] = useState<Business>();

  const [selectedCustomerBusiness, setSelectedCustomerBusiness] =
    useState<SelectedCustomerBusiness>({});

  const [allSuperAdmins, setAllSuperadmins] = useState<Customer[]>([]);
  const [selectedSuperadminUser, setSelectedSuperadminUser] = useState<any>();
  const [modalMode, setModalMode] = useState("form");

  const [stratosfyBusinessKey, setStratosfyBusinessKey] = useState("");

  const { data } = useBusiness();

  const [businesses, setBusinesses] = useState<Business[]>();

  useEffect(() => {
    const { data: businessList } = data ?? {};

    setBusinesses(businessList);

    const stratosfyBusiness = businessList?.find(
      (business) => business.businessName === stratosfyBusinessName
    );

    setStratosfyBusinessKey(stratosfyBusiness?._id ?? "");
  }, [data]);

  useEffect(() => {
    const { sort = "desc" } =
      headers.find((header) => header.name === "Customer admin") ?? {};

    const lowerCasedSearchText = lowerCaseLetter(searchText);
    const filteredSuperadmins = (allCustomersRes?.data ?? []).filter((val) => {
      const lowerCaseFirstName = lowerCaseLetter(val.firstName);
      const lowerCaseLastName = lowerCaseLetter(val.lastName ?? "");
      const fullName = `${lowerCaseFirstName} ${lowerCaseLastName}`;
      return fullName.includes(lowerCasedSearchText.trim());
    });

    const usersSortedByName = filteredSuperadmins?.sort(
      (firstUser, secondUser) =>
        sortByEntity(firstUser.firstName, secondUser.firstName, sort)
    );

    let updatedHeaders = [...headers];

    const indexOfName = 0;

    updatedHeaders.splice(indexOfName, 1, {
      name: "Customer admin",
      sort,
    });

    setHeaders(updatedHeaders);

    setSuperadmins(usersSortedByName);
    setAllSuperadmins(usersSortedByName);
  }, [allCustomersRes, searchText]);

  function handleCheckboxSuperAdmin(user: Customer) {
    setSelectedCustomerBusiness({});
    let remainingUsers = { ...checkboxSuperAdminUsers };
    const { email = "", phoneNumber = "", loginMethod } = user;
    if (loginMethod === LoginMethod.EMAIL) {
      if (checkboxSuperAdminUsers?.[email]) {
        const { [email]: deletingUser, ...otherUsers } =
          checkboxSuperAdminUsers;
        remainingUsers = { ...otherUsers };
      } else {
        remainingUsers = { ...remainingUsers, [email]: user };
        if (openbottonCount === 0) setdisabled(user.isDisabled as boolean);
      }
    } else {
      if (checkboxSuperAdminUsers[phoneNumber]) {
        const { [phoneNumber]: deletingUser, ...otherUsers } =
          checkboxSuperAdminUsers;
        remainingUsers = { ...otherUsers };
      } else {
        remainingUsers = { ...remainingUsers, [phoneNumber]: user };
        if (openbottonCount === 0) setdisabled(user.isDisabled as boolean);
      }
    }
    setCheckboxSuperadminUsers({ ...remainingUsers });
    setOpenBottomDrawer(Object.keys(remainingUsers).length > 0);
    setbottomOpencount(Object.keys(remainingUsers).length);
    setcheckBoxBusiness({});
  }

  function handleCheckboxBsiness(businessKey: string) {
    const selectedUserKey = Object.keys(selectedCustomerBusiness)?.[0] ?? "";

    const selectedBusinessKeys = selectedCustomerBusiness[selectedUserKey];

    let uniqueBusinessKeys = new Set(selectedBusinessKeys);

    if (uniqueBusinessKeys.has(businessKey)) {
      uniqueBusinessKeys.delete(businessKey);
    } else {
      uniqueBusinessKeys.add(businessKey);
    }

    const updatedBusinessKeys = [...uniqueBusinessKeys];

    setSelectedCustomerBusiness({ [selectedUserKey]: updatedBusinessKeys });

    setcheckBoxBusiness({ ...checkBoxBusiness });
    setCheckboxSuperadminUsers({});
    setOpenBottomDrawer(false);
    setbottomOpencount(0);
  }
  function customDrawerContent() {
    switch (modalMode) {
      case "details":
        return <Details superAdmin={selectedSuperadminUser} />;
      case "subscription":
        return <div />;
    }
  }
  function onSelected(selectedSuperadminUser: User) {
    setSelectedSuperadminUser(selectedSuperadminUser);
    setModalMode("details");
    setCheckboxSuperadminUsers({});
    setOpenBottomDrawer(false);
    setbottomOpencount(0);
    setcheckBoxBusiness({});
  }

  function handleCancelAction() {
    setOpenModal(false);
    seteditUser({});
  }
  function handleDeleteModal(position?: string) {
    if (position) {
      const numberOfBusinesses: number =
        selectedSuperadminUser?.business?.length ?? 0;
      if (numberOfBusinesses) {
        return getErrorMessageForBusinessAssignedUsser();
      }
    }
    setOpendelete(!openDeletemodal);
  }

  function handleDeleteBusinessModal() {
    setOpendeleteBusiness(!openDeleteBusinessmodal);
  }

  async function deleteUser() {
    setDeleting(true);
    try {
      await deleteCustomer(selectedSuperadminUser.userKey);
      setSuperadmins(allCustomers);
      setAllSuperadmins(allCustomers);
      toast({
        status: "success",
        duration: 2000,
        description: `${CustomerAdmin} ${selectedSuperadminUser.firstName} deleted successfully.`,
      });
      setOpendelete(!openDeletemodal);
      setCheckboxSuperadminUsers({});
      setOpenBottomDrawer(false);
      setbottomOpencount(0);
      setModalMode("form");
      setDeleting(false);
    } catch (error) {
      setDeleting(false);
      toast({
        description: "Cannot delete the user.",
        duration: 3000,
        status: "error",
      });
    }
  }

  async function handleDisableAction() {
    const user = Object.values(checkboxSuperAdminUsers)[0];

    if (!user.userKey) return;

    setdisableding(true);
    const enableOrDisable = user?.isDisabled ? "enabled" : "disabled";

    try {
      await disableCustomer({
        customerKey: user.userKey,
        enabled: !user.isDisabled,
        updatedBy: loggedInUserKey!,
      });
      setSuperadmins(allCustomers);
      setAllSuperadmins(allCustomers);
      setdisableding(false);
      setOpenBottomDrawer(false);
      handleCheckboxSuperAdmin(user);
      toast({
        status: "success",
        description: `${user.firstName} ${user.lastName} is ${enableOrDisable}.`,
        duration: 3000,
      });
    } catch (error) {
      toast({
        duration: 3000,
        status: "error",
        description: `${user.firstName} ${user.lastName} cannot be ${enableOrDisable}.`,
      });
      setdisableding(false);
    }
  }

  function handleEdit() {
    const user = selectedSuperadminUser;
    setModalMode("form");
    handleCancelAction();
    setOpenModal(true);
    seteditUser({ ...user, fullName: user.firstName + user?.lastName ?? "" });
  }
  function handleEditFromBottom() {
    if (!isSuperAdminUsersEditable) {
      if (!toast.isActive(editToastId)) {
        toast({
          duration: 3000,
          id: editToastId,
          status: "warning",
          description: `Not Allow to edit the ${CustomerAdmin}.`,
        });
        return;
      }
    }

    const user: any = Object.values(checkboxSuperAdminUsers)[0];
    setOpenBottomDrawer(false);
    setbottomOpencount(0);
    setCheckboxSuperadminUsers({});
    setOpenModal(true);
    seteditUser({
      ...user,
      fullName: user.firstName + " " + (user?.lastName ?? ""),
    });
  }
  function assignBusinessFromTable() {
    setbottomOpencount(0);
    setOpenBottomDrawer(false);
    setCheckboxSuperadminUsers({});
  }

  async function handleDelete() {
    setDeleting(true);
    await Promise.all(
      Object.values(checkboxSuperAdminUsers).map((e: any) => {
        return deleteCustomer(e.userKey);
      })
    );
    setDeleting(false);
    setSuperadmins(allCustomers);
    setAllSuperadmins(allCustomers);
    setbulkOpendelete(!openbulkDeletemodal);
    Object.values(checkboxSuperAdminUsers).map((e: any) => {
      return toast({
        status: "success",
        duration: 1000,
        description: `${CustomerAdmin} ${e.firstName} deleted successfully`,
      });
    });

    setOpenBottomDrawer(false);
    setbottomOpencount(0);
    setCheckboxSuperadminUsers({});
  }
  async function deleteBusiness() {
    setDeletingBusiness(true);
    try {
      // await unAssignBusiness({
      //   customerKey,
      //   businessKeys,
      // });

      toast({
        status: "success",
        duration: 3000,
        description: "Business unassigned successfully.",
      });
    } catch (error) {
      toast({
        status: "error",
        description:
          error?.response?.data?.message ?? "Error while unassigning business.",
        duration: 3000,
      });
    }

    setSuperadmins(allCustomers);
    setAllSuperadmins(allCustomers);
    handleDeleteBusinessModal();
    setcheckBoxBusiness({});
    setDeletingBusiness(false);
  }

  const handleHeaderClick = (header: HeaderInterface, headerIndex: number) => {
    const { sort } = header;

    const newSort = sort === "desc" ? "asc" : "desc";

    let sortedByEntity;

    switch (header.name) {
      case "Customer admin":
        sortedByEntity = superAdmins?.sort((a, b) =>
          sortByEntity(a.firstName, b.firstName, newSort)
        );
        break;

      case "Login ID":
        sortedByEntity = superAdmins?.sort((firstUser, secondUser) => {
          const firstEntity =
            firstUser?.loginMethod === "email"
              ? firstUser.email
              : firstUser.phoneNumber;
          const secondEntity =
            secondUser?.loginMethod === "email"
              ? secondUser.email
              : secondUser.phoneNumber;
          return sortByEntity(firstEntity, secondEntity, newSort);
        });
        break;

      case "Business":
        sortedByEntity = superAdmins?.sort((firstUser, secondUser) => {
          const firstEntity = firstUser.business?.length ?? 0;
          const secondEntity = secondUser?.business?.length ?? 0;
          return sortByEntity(firstEntity, secondEntity, newSort);
        });
        break;

      default:
        sortedByEntity = [...(superAdmins ?? [])];
        break;
    }

    let updatedHeaders = [...headers];

    updatedHeaders.splice(headerIndex, 1, {
      name: header.name,
      sort: newSort,
    });

    setHeaders(updatedHeaders);

    setSuperadmins([...(sortedByEntity as Customer[])]);
  };

  function handleSelectAll(e: any) {
    if (!e.target.checked) {
      setCheckboxSuperadminUsers({});
      setOpenBottomDrawer(false);
      setbottomOpencount(0);
      setcheckBoxBusiness({});
      return;
    }

    const allSuperAdmins = superAdmins?.reduce(
      (acc: { [loginMethod: string]: Customer }, e) => {
        const contact =
          e.loginMethod === LoginMethod.EMAIL ? e.email : e.phoneNumber;
        acc[contact ?? ""] = {
          ...e,
        };
        return acc;
      },
      {}
    );

    setCheckboxSuperadminUsers(allSuperAdmins ?? {});
    setOpenBottomDrawer(Object.keys(allSuperAdmins ?? {})?.length > 0);
    setbottomOpencount(Object.keys(allSuperAdmins ?? {})?.length);
    setcheckBoxBusiness({});
  }

  function renderSearch() {
    return (
      <Box maxW="40%">
        <SearchBar
          value={searchText}
          rightIcon={<SearchIcon />}
          title={`Search ${CustomerAdmin} User`}
          onChange={(e) => {
            setCheckboxSuperadminUsers({});
            setOpenBottomDrawer(false);
            setSearchText(e.target.value);
            debouncedOnChange(e);
          }}
          onClose={() => {
            setSearchText("");
          }}
        />
      </Box>
    );
  }
  function renderSuperadminCreateEditDrawer() {
    return (
      <SDrawer
        open={openModal}
        title={
          Object.values(editUser).length === 0
            ? `Add ${CustomerAdmin}`
            : `Edit ${CustomerAdmin}`
        }
        negativeLabel="Cancel"
        positiveLabel="Submit"
        showActionButtons={false}
        negativeAction={handleCancelAction}
      >
        <Heading as="h3" size="md">
          {CustomerAdmin}
        </Heading>
        <Box mt="8" borderRadius={12}>
          <SuperAdminCard user={editUser} handleClose={handleCancelAction} />
        </Box>
      </SDrawer>
    );
  }

  function renderSuperAdminDetailDrawer() {
    return (
      <SDrawer
        open={modalMode === "details"}
        action={
          <Stack direction="row" justify="space-between" gridGap="24px">
            <IconButton
              aria-label="edit"
              icon={<EditIcon />}
              onClick={handleEdit}
              backgroundColor={"white"}
            />
            <IconButton
              aria-label="delete"
              icon={<DelIcon />}
              backgroundColor={"white"}
              onClick={() => handleDeleteModal("fromRightDrawer")}
            />
          </Stack>
        }
        title={getNameFromUser(selectedSuperadminUser)}
        positiveLabel={modalMode === "details" ? "User Details" : "Update"}
        negativeAction={() => {
          setModalMode("form");
          handleCancelAction();
        }}
        showActionButtons={false}
      >
        {customDrawerContent()}
      </SDrawer>
    );
  }

  function deleteAdminModal() {
    const selectedSuperAdmin = Object.values(
      checkboxSuperAdminUsers
    )?.[0] as Customer;

    const superAdminThatsSelected =
      selectedSuperAdmin ?? selectedSuperadminUser;

    return (
      <Modal isOpen={openDeletemodal} isCentered onClose={handleDeleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete 1 {CustomerAdmin}</ModalHeader>
          <ModalCloseButton />
          <Flex>
            <Divider marginRight={5} marginLeft={5} />
          </Flex>
          <ModalBody>
            <Flex>
              <Text color="CaptionText" fontSize="small">
                Are you sure do you want to delete {CustomerAdmin}
              </Text>
              <Text fontWeight="bold" fontSize="small" mx={1}>
                {` - ${superAdminThatsSelected?.firstName ?? ""}`}
              </Text>
            </Flex>
            <Text fontSize="small" fontWeight="light" color="grey">
              {`(${
                superAdminThatsSelected?.business?.length ?? 0
              } business assigned)`}
            </Text>
            <Text my={3} fontWeight="light" color="grey" fontSize="small">
              Remember this action cannot be undone. Also it removes data such
              as login and business.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={() => handleDeleteModal()}>
              Close
            </Button>
            <Button isLoading={deleting} onClick={deleteUser} colorScheme="red">
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  function deletebulkAdminModal() {
    const selectedSuperAdminUsers: Customer[] =
      objectsToArrays(checkboxSuperAdminUsers) ?? [];
    const noOfSuperadminUsers = selectedSuperAdminUsers?.length ?? 0;
    const title = `Delete ${noOfSuperadminUsers} ${CustomerAdmin} ?`;
    const subtitle =
      "Remember, this action cannot be undone. Also it removes data such as Login and details";
    let body = "";

    if (noOfSuperadminUsers < 2) {
      const { firstName, lastName = "" } = selectedSuperAdminUsers[0] ?? {};
      const fullName = `${firstName} ${lastName}`;
      body =
        `Are you sure you want to delete these ${CustomerAdmin} - ` + fullName;
    } else {
      body = `Are you sure you want to delete these ${CustomerAdmin}`;
    }

    return (
      <SDialog
        size="lg"
        open={openbulkDeletemodal}
        loading={deleting}
        title={title}
        body={body}
        subTitle={subtitle}
        positiveLabel="Delete"
        positiveLabelColor="red"
        handlePositiveAction={() => handleDelete()}
        handleNegativeAction={() => setbulkOpendelete(!openbulkDeletemodal)}
      />
    );
  }

  function deleteBusinessModal() {
    return (
      <Modal
        isOpen={openDeleteBusinessmodal}
        isCentered
        onClose={handleDeleteBusinessModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unassign Business</ModalHeader>
          <ModalCloseButton />
          <Flex>
            <Divider marginRight={5} marginLeft={5} />
          </Flex>
          <ModalBody>
            <Text color="CaptionText" fontSize="small">
              Are you sure do you want to unassign business
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleDeleteBusinessModal}>
              Close
            </Button>
            <Button
              isLoading={deletingBusiness}
              onClick={deleteBusiness}
              colorScheme="red"
            >
              Unassign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  const getErrorMessageForBusinessAssignedUsser = () => {
    return toast({
      duration: 3000,
      status: "error",
      description: "Not allowed, selected user has business assigned.",
    });
  };

  const handleUserSelected = () => {
    if (!isSuperAdminusersDeletable) {
      if (!toast.isActive(deleteToastId)) {
        toast({
          id: deleteToastId,
          ...getAccessDeniedErrorMessage("delete", "superadmin"),
        });
        return;
      }
    }

    const selectedUsers = Object.values(checkboxSuperAdminUsers ?? {});

    const isBusinessAssignedToOneOfTheUser = selectedUsers?.some(
      (user) => (user?.business?.length ?? 0) > 0
    );
    if (isBusinessAssignedToOneOfTheUser) {
      getErrorMessageForBusinessAssignedUsser();
      return;
    }
    setbulkOpendelete(!openbulkDeletemodal);
  };

  function handleCloseBottomDrawer() {
    setCheckboxSuperadminUsers({});
    setOpenBottomDrawer(false);
    setbottomOpencount(0);
  }

  async function handleInviteUserButton(user: Customer) {
    if (!isSuperAdminUsersEditable) {
      if (!toast.isActive(editToastId)) {
        return toast({
          id: editToastId,
          ...getAccessDeniedErrorMessage("invite", "superadmin"),
        });
      }
    }
    let userThatsLoggedIn = loggedInUserKey ?? "";
    try {
      await resendInviteToUser(user, resendInvite, userThatsLoggedIn);
      toast({
        description: "Invitation sent successfully.",
        duration: 3000,
        status: "success",
      });
    } catch (err: any) {
      resendInviteInfo.reset();
      return toast({
        description: err?.response?.data?.message ?? "Cannot send invitaion.",
        status: "error",
      });
    }
  }

  const showInviteButton = () => {
    const superadmin = objectsToArrays(checkboxSuperAdminUsers)[0] as Customer;
    if (superadmin?.lastLoginAt || superadmin?.isDisabled) {
      return null;
    }

    return (
      <Button
        variant="outline"
        colorScheme="blue"
        onClick={() => handleInviteUserButton(superadmin)}
        isLoading={resendInviteInfo.isLoading && !resendInviteInfo.isSuccess}
      >
        Re-invite Customer
      </Button>
    );
  };

  function renderAdminSelectbottomDrawer() {
    return (
      <Drawer
        isOpen={openBottomDrawer}
        variant="alwaysOpen"
        placement="bottom"
        blockScrollOnMount={false}
        trapFocus={false}
        onClose={() => handleCloseBottomDrawer()}
      >
        <DrawerContent
          p="6"
          w="60%"
          mx="auto"
          shadow="0px -12px 24px #ECEEF5"
          borderTopLeftRadius="12"
          borderTopRightRadius="12"
        >
          <DrawerBody>
            <Stack direction="row" justify="space-between">
              <Flex>
                <DrawerCloseButton position="relative" top="0" />
                <Text display="flex" alignItems="center">
                  {openbottonCount} superadmin selected
                </Text>
              </Flex>
              <Wrap gridGap="20px">
                {openbottonCount === 1 ? (
                  <>
                    {showInviteButton()}
                    <Button
                      p="8px 16px 8px 8px"
                      isLoading={isDisableding}
                      leftIcon={!isDisabled ? <DisableIcon /> : <EnableIcon />}
                      colorScheme="blue"
                      variant="outline"
                      onClick={() => handleDisableAction()}
                      isDisabled={!canSuperAdminusersEnableDisable}
                    >
                      {!isDisabled ? "Disable" : "Enable"}
                    </Button>
                    <Button
                      p="8px 16px 8px 8px"
                      isLoading={false}
                      leftIcon={<EditIcon />}
                      colorScheme="blue"
                      variant="outline"
                      onClick={() => handleEditFromBottom()}
                      isDisabled={!isSuperAdminUsersEditable || isDisabled}
                    >
                      Edit
                    </Button>
                  </>
                ) : null}
                <Button
                  p="8px 16px 8px 8px"
                  isLoading={deleting}
                  leftIcon={<DelRedIcon />}
                  colorScheme="red"
                  variant="outline"
                  onClick={() => handleUserSelected()}
                  isDisabled={!isSuperAdminusersDeletable}
                >
                  Delete
                </Button>
              </Wrap>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  const getSelectedUserAndBusiness = () => {
    const selectedUserKey = objectsToArrays(
      selectedCustomerBusiness,
      "key"
    )?.[0];

    const selectedBusinesses = selectedCustomerBusiness[selectedUserKey];

    return {
      selectedUserKey,
      selectedBusinesses,
    };
  };

  const unAssignBusinessesFromCustomer = async () => {
    const { selectedBusinesses, selectedUserKey } =
      getSelectedUserAndBusiness();

    let productBusiness: ProductBusiness = {};

    const promises = selectedBusinesses?.map((businessKey) => {
      const customer = allCustomers.find(
        (customer) => selectedUserKey === customer.userKey
      );

      const currentBusiness = customer?.business?.find(
        (business) => business.businessKey === businessKey
      );

      const { businessKey: keyOfTheBusiness, ...products } =
        currentBusiness ?? {};

      const productIds = objectsToArrays(products, "key");

      const promises = productIds.reduce(
        (acc: Promise<any>[], productId: string) => {
          if (currentBusiness?.[productId]?.roleKey) {
            productBusiness[productId] = [
              ...(productBusiness[productId] ?? []),
              businessKey,
            ];
            const promise = getLocationsUser({ productId, businessKey });
            acc.push(promise);
          }
          return acc;
        },
        []
      );

      return Promise.all(promises);
    });

    try {
      const res: any = await Promise.all(promises);

      const areThereNoOtherUsersInBusinesses = res.some((businessUsers) => {
        return businessUsers?.some((r) => (r.data.users.length ?? 0) <= 1);
      });

      if (!areThereNoOtherUsersInBusinesses) {
        await removeCustomerFromBusiness({
          productBusiness,
          updatedBy: loggedInUserKey!,
          customerKey: selectedUserKey,
        });

        toast({
          duration: 3000,
          description: "Business unassigned successfully.",
        });
        setSelectedCustomerBusiness({});
      } else {
        toast({
          duration: 3000,
          status: "warning",
          description: "Business does not have other customer admin",
        });
      }
    } catch (error) {
      toast({
        description:
          error?.response?.data?.message ?? "Cannot unassign business.",
        duration: 3000,
      });
    }
    removeCustomerInfo.reset();
    reset();
  };

  const deleteBusinessFromDatabase = async () => {
    setGettingBusinessInfo(true);
    const { selectedBusinesses } = getSelectedUserAndBusiness();

    const businessesPromises = selectedBusinesses?.map((businessKey) => {
      return getBusinessFromId(businessKey);
    });

    let res;
    let doesAnyOneOfTheBusinessesHasSubscription: boolean;

    try {
      res = await Promise.all(businessesPromises);
      doesAnyOneOfTheBusinessesHasSubscription = res?.some(
        (business) => !!business?.subscriptions?.length
      );
    } catch (error) {
      setGettingBusinessInfo(false);
      return toast({
        description:
          error?.response?.data?.message ?? "Cannot delete business.",
        duration: 3000,
      });
    }

    if (doesAnyOneOfTheBusinessesHasSubscription) {
      setGettingBusinessInfo(false);
      return toast({
        duration: 3000,
        status: "warning",
        description: "Cannot delete, business has subscriptions.",
      });
    }

    try {
      await deleteBusinesses(selectedBusinesses);
    } catch (error) {
      toast({
        duration: 3000,
        description:
          error?.response?.data?.message ?? "Cannot delete business.",
      });
    }

    deleteBusinessInfo.reset();
    setGettingBusinessInfo(false);
  };

  const handleCloseBusinessModal = () => {
    setSelectedCustomerBusiness({});
  };

  const disableWhileAction = () => {
    return (
      removeCustomerInfo.isLoading ||
      isLoading ||
      isDisabled ||
      gettingBusinessInfo ||
      deleteBusinessInfo.isLoading ||
      !canUserDeleteTheBusiness
    );
  };

  const editBusiness = async (businessKey: string) => {
    setGettingBusinessInfo(true);
    try {
      const business = await getBusinessFromId(businessKey);
      setEditingBusiness(business);
      setSelectedCustomerBusiness({});
    } catch (error) {
      toast({
        description:
          error?.response?.data?.message ?? "Error while getting business info",
        duration: 3000,
      });
    }
    setGettingBusinessInfo(false);
  };

  const closeBusinessModal = () => {
    setEditingBusiness(undefined);
  };

  const renderBusinessEditionModal = () => {
    return (
      <SDrawer
        open={!!editingBusiness}
        title="Edit business"
        showActionButtons={false}
        size={modalMode === "details" ? "lg" : "md"}
        positiveLabel={modalMode === "form" ? "Create Business" : "Update"}
        negativeAction={() => {
          closeBusinessModal();
        }}
      >
        <>
          <Heading as="h3" size="md" mb="4">
            Details
          </Heading>
          <BusinessForm
            handleCancelAction={closeBusinessModal}
            stratosfyBusinessKey={stratosfyBusinessKey}
            checkDuplicateBusinessName={(business) =>
              checkDuplicateBusinessName(businesses ?? [], business)
            }
            business={editingBusiness}
          />
        </>
      </SDrawer>
    );
  };

  const renderBusinessModal = () => {
    const selectedUserKey = Object.keys(selectedCustomerBusiness)?.[0] ?? "";

    const selectedBusinesses = selectedCustomerBusiness[selectedUserKey];

    const numberOfBusinessesSelected = selectedBusinesses?.length;

    return (
      <Drawer
        trapFocus={false}
        placement="bottom"
        variant="alwaysOpen"
        blockScrollOnMount={false}
        isOpen={!!numberOfBusinessesSelected}
        onClose={() => handleCloseBusinessModal()}
      >
        <DrawerContent
          p="6"
          w="50%"
          mx="auto"
          shadow="0px 0px 50px 2px rgba(50,58,69,0.47)"
          borderTopLeftRadius="12"
          borderTopRightRadius="12"
        >
          <DrawerBody>
            <Stack direction="row" justify="space-between">
              <Flex>
                <DrawerCloseButton position="relative" top="0" />
                <Text display="flex" alignItems="center">
                  {numberOfBusinessesSelected} business selected
                </Text>
              </Flex>
              <Wrap>
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="blue"
                  leftIcon={<DisableIcon />}
                  isDisabled={disableWhileAction()}
                  onClick={() => unAssignBusinessesFromCustomer()}
                  isLoading={removeCustomerInfo.isLoading || isLoading}
                >
                  Unassign
                </Button>
                {numberOfBusinessesSelected === 1 ? (
                  <>
                    <Button
                      p="4"
                      isLoading={gettingBusinessInfo}
                      leftIcon={<EditIcon />}
                      colorScheme="blue"
                      variant="outline"
                      size="sm"
                      isDisabled={disableWhileAction() || !canUserEditBusiness}
                      onClick={() => editBusiness(selectedBusinesses?.[0])}
                    >
                      Edit
                    </Button>
                  </>
                ) : null}
              </Wrap>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  };

  if (customerLoading) {
    return (
      <Center h="100%">
        <CircularProgress isIndeterminate color="blue.500" />
      </Center>
    );
  }

  function handleAddSuperAdmin() {
    if (!isSuperAdminUsersCreatable) {
      if (!toast.isActive(createToastId)) {
        toast({
          duration: 3000,
          status: "warning",
          id: createToastId,
          description: `${CustomerAdmin} create not allowed.`,
        });
      }
      return;
    }
    setOpenModal(true);
    setbottomOpencount(0);
    setOpenBottomDrawer(false);
    setCheckboxSuperadminUsers({});
  }

  // if (!isSuperAdminUsersViewable) {
  //   return (
  //     <Center h="100%">
  //       <Text color="red" fontWeight="bold">
  //         Not Allowed to view.
  //       </Text>
  //     </Center>
  //   );
  // }

  if (!superAdmins) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <CircularProgress isIndeterminate color="blue.500" />{" "}
      </Flex>
    );
  }

  return (
    <Page>
      <Flex justify="space-between">
        <Heading as="h1" size="lg">
          {CustomerAdmin}
        </Heading>
        <Button
          size="md"
          color="white"
          colorScheme="blue"
          leftIcon={<PlusIcon />}
          boxShadow="0px 3px 6px #006DB85C"
          onClick={() => handleAddSuperAdmin()}
        >
          <Text color="white" fontSize="16px">
            Add {CustomerAdmin}
          </Text>
        </Button>
      </Flex>
      <Flex flexDirection="column" gridGap="30px">
        {renderSearch()}
        {renderSuperadminCreateEditDrawer()}
        {renderSuperAdminDetailDrawer()}
        {deleteAdminModal()}
        {deleteBusinessModal()}
        {deletebulkAdminModal()}
        {renderAdminSelectbottomDrawer()}
        {renderBusinessModal()}
        {renderBusinessEditionModal()}

        {(superAdmins?.length ?? 0) > 0 ? (
          <CustomTable
            checked={Object.keys(superAdmins).length === openbottonCount}
            headers={headers}
            data={superAdmins ?? []}
            onSelected={onSelected}
            onSelectAll={handleSelectAll}
            checkBoxBusiness={checkBoxBusiness}
            handleHeaderClick={handleHeaderClick}
            checkedUsers={checkboxSuperAdminUsers}
            onAssignAdmin={assignBusinessFromTable}
            onCheckBoxClicked={handleCheckboxSuperAdmin}
            onCheckBoxBusinessClicked={handleCheckboxBsiness}
            selectedCustomerBusiness={selectedCustomerBusiness}
            setSelectedCustomerBusiness={setSelectedCustomerBusiness}
          />
        ) : (
          <Flex height="100vh" align="center" justify="center">
            <Text fontWeight="bold">No {CustomerAdmin}</Text>
          </Flex>
        )}
      </Flex>
    </Page>
  );
};

interface CheckBoxSuperAdminUsers {
  [loginMethod: string]: Customer;
}
