import { useMutation, useQuery, useQueryCache } from "react-query";

import network from "./network";
import { objectToFormData } from "../utils/helpers";
import { objectsToArrays } from "../packages/helpers/extra";
import { CreateCustomer, Customer } from "../packages/interfaces/customer";

const customerUrl = "customer";
// const customerUrl = "superadmin";

const getAllCustomersUrl = `/usermgmt/${customerUrl}/getAllCustomers`;


interface GetCustomerResponse {
  success: boolean;
  data: Array<Customer>;
}

// this should fetch a list of customers based on the received parameters and return a list asynchronously
export async function getCustomers(): Promise<GetCustomerResponse> {
  const result = await (await network()).get(getAllCustomersUrl);
  return result.data;
}

export function sanitizeObject(data: any) {
  const keys: any[] = Object.keys(data);
  keys.map((e) => {
    if (data[e] === undefined) delete data[e];
    if (data[e]?.length === 0) delete data[e];
    if (e === "isDisabled") delete data[e];
    if (e === "isVerified") delete data[e];
    if (e === "_id") delete data[e];
    if (e === "__v") delete data[e];
    if (e === "fullName") delete data[e];
  });
  data.isCustomerSuperAdmin = true;
  return data;
}

interface EnableDisableCustomer {
  enabled: boolean;
  updatedBy: string;
  customerKey: string;
}

export async function enableDisableCustomer({
  enabled,
  updatedBy,
  customerKey,
}: EnableDisableCustomer) {
  const result = await (
    await network()
  ).post(`usermgmt/customer/${customerKey}/setEnabled`, {
    updatedBy,
    enabled: !enabled,
    userKeys: [customerKey],
  });
  return result.data;
}
export async function assignBusiness(
  customerKey: any,
  businessKeys: string[]
): Promise<GetCustomerResponse> {
  const result = await (
    await network()
  ).put(`customermgmt/business/${customerKey}/assignCustomer`, {
    businessKeys,
  });
  return result.data;
}

export async function unAssignBusiness(
  customerKey: string,
  businessKeys: string[]
): Promise<GetCustomerResponse> {
  const res = await (
    await network()
  ).post(`customermgmt/business/${customerKey}/unassignCustomer`, {
    businessKeys,
  });

  return res.data;
}

interface AddCustomer {
  success: boolean;
  data: {
    customerKey: string;
  };
}

export async function addCustomer(
  customer: CreateCustomer
): Promise<AddCustomer> {
  const updatedCustomer = sanitizeObject({ ...customer });

  const formData = objectToFormData(updatedCustomer);

  const result = await (
    await network()
  ).post(`usermgmt/customer`, formData);
  return result.data;
}

interface UpdateUser {
  _id: string;
  image?: File;
  email: string;
  address: string;
  remarks: string;
  firstName: string;
  updatedBy: string;
  lastName?: string;
  loginMethod: string;
  phoneNumber: string;
  removeImage?: boolean;
}

export function sanitizeObjectEdit(data: any) {
  const keys: any[] = Object.keys(data);
  keys.forEach((e) => {
    if (data[e]?.length === 0) delete data[e];
    if (e === "isDisabled") delete data[e];
    if (e === "isVerified") delete data[e];
    if (e === "_id") delete data[e];
    if (e === "__v") delete data[e];
    if (e === "loginMethod") delete data[e];
    if (e === "isCompanySuperAdmin") delete data[e];
    if (e === "keycloakUserId") delete data[e];
    if (e === "business") delete data[e];
    if (e === "enabled") delete data[e];
    if (e === "fullName") delete data[e];
    if (data[e] === undefined) delete data[e];
    if (data[e] === null) delete data[e];
  });
  return data;
}

export async function updateCustomer(
  customer: UpdateUser
): Promise<GetCustomerResponse> {
  const id = customer._id;
  const formData = new FormData();
  let updateUser = sanitizeObjectEdit({ ...customer });

  updateUser = { ...updateUser, lastName: customer.lastName ?? "" };

  objectsToArrays(updateUser, "key").forEach((field) => {
    // @ts-ignore
    formData.append(field, updateUser[field]);
  });

  const result = await (
    await network()
  ).put(`usermgmt/customer/${id}`, formData);
  return result.data;
}

export async function deleteCustomer(customerKey: string) {
  const id = customerKey;
  await (await network()).delete(`usermgmt/customer/${id}`);
  return customerKey;
}

// This should fetch  customer detail based on the customerId and return a Customeer asynchronously
async function getCustomer(customerId: String): Promise<Customer> {
  const res = await (
    await network()
  ).get(`/usermgmt/${customerUrl}/${customerId}`, {});

  return res.data?.data;
}

export function useCustomers() {
  const queryCache = useQueryCache();

  return useQuery(["customers"], getCustomers, {
    onSuccess: (data) => {
      const customersList = data.data ?? [];
      customersList.forEach((customer) => {
        queryCache.setQueryData(
          ["customers", customer.keycloakUserId],
          customer
        );
      });
    },
  });
}

export function useGetCustomerByIds(customerIds: string[]) {
  const queryCache = useQueryCache();

  return useQuery(
    ["customers", customerIds],
    async () => {
      const customers = await Promise.allSettled(
        customerIds?.map((customerId) => {
          return getCustomer(customerId);
        })
      );

      return customers.reduce((result: Customer[], customer) => {
        if (customer.status === "fulfilled") {
          result.push(customer.value);
        }

        return result;
      }, []);
    },
    {
      onSuccess: (response) => {
        const customerDetails = response;

        customerDetails?.forEach((customer) => {
          queryCache.setQueryData(["customers", customer.userKey], customer);
        });
      },
    }
  );
}

export function useUnassignBusinessFromCustomer() {
  const queryCache = useQueryCache();

  return useMutation(
    (keys: { customerKey: string; businessKeys: string[] }) => {
      return unAssignBusiness(keys.customerKey, keys.businessKeys);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["customers"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

export function useDeleteCustomers() {
  const queryCache = useQueryCache();

  return useMutation(deleteCustomer, {
    onSuccess: (customerKey) => {
      queryCache.removeQueries(["customers", customerKey]);
      queryCache.invalidateQueries(["customers"]);
    },
    onError: (e) => {
      throw e;
    },
  });
}

export function useCreateCustomer() {
  const queryCache = useQueryCache();

  return useMutation(
    (newCustomer: CreateCustomer) => addCustomer(newCustomer),
    {
      onSuccess: (response) => {
        queryCache.invalidateQueries(["customers"]);
        queryCache.invalidateQueries(["businesses"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

export function useUpdateCustomer() {
  const queryCache = useQueryCache();

  return useMutation(
    (updatedCustomer: UpdateUser) => updateCustomer(updatedCustomer),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["customers"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

export function useEnableDisableCustomer() {
  const queryCache = useQueryCache();
  return useMutation(
    ({ updatedBy, customerKey, enabled }: EnableDisableCustomer) =>
      enableDisableCustomer({ updatedBy, customerKey, enabled }),
    {
      onSettled: () => {
        queryCache.invalidateQueries(["customers"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

export function useAssignBusinessToCustomer() {
  const queryCache = useQueryCache();
  return useMutation(
    (keys: { customerKey: string; businessKeys: string[] }) =>
      assignBusiness(keys.customerKey, keys.businessKeys),
    {
      onSettled: () => {
        queryCache.invalidateQueries(["customers"]);
        queryCache.invalidateQueries(["businesses"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

export interface ProductBusiness {
  [productId: string]: string[];
}

interface AddOrRemoveCustomerToBusiness {
  updatedBy: string;
  customerKey: string;
  productBusiness: ProductBusiness;
}

async function addCustomerToBusiness({
  customerKey,
  productBusiness,
  updatedBy,
}: AddOrRemoveCustomerToBusiness): Promise<GetCustomerResponse> {
  const result = await (
    await network()
  ).put(`customermgmt/business/${customerKey}/assignCustomer`, {
    updatedBy,
    productBusiness,
  });
  return result.data;
}

export function useAddCustomerToBusiness() {
  const queryCache = useQueryCache();
  return useMutation(
    ({
      updatedBy,
      customerKey,
      productBusiness,
    }: AddOrRemoveCustomerToBusiness) =>
      addCustomerToBusiness({ customerKey, productBusiness, updatedBy }),
    {
      onSettled: () => {
        queryCache.invalidateQueries(["customers"]);
        queryCache.invalidateQueries(["businesses"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

async function removeCustomerFromBusiness({
  customerKey,
  productBusiness,
  updatedBy,
}: AddOrRemoveCustomerToBusiness) {
  const result = await (
    await network()
  ).post(`customermgmt/business/${customerKey}/unassignCustomer`, {
    updatedBy,
    productBusiness,
  });
  return result.data;
}

export function useRemoveCustomerFromBusiness() {
  const queryCache = useQueryCache();
  return useMutation(
    ({
      customerKey,
      updatedBy,
      productBusiness,
    }: AddOrRemoveCustomerToBusiness) =>
      removeCustomerFromBusiness({ customerKey, updatedBy, productBusiness }),
    {
      onSettled: () => {
        queryCache.invalidateQueries(["customers"]);
        queryCache.invalidateQueries(["businesses"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}
