import { AddIcon } from "@chakra-ui/icons";
import { Text, Button, Checkbox, Flex, Divider, Box } from "@chakra-ui/react";

import { useBusiness } from "../../apis/business";
import { Subscription } from "../../packages/interfaces/subscription";
import {
  Product,
  ProductFeature,
  Resource,
} from "../../packages/interfaces/product";
import { concatStrings } from "../../packages/helpers/strings";

interface CollapseProps {
  open: string | undefined;
  product: Product;
  newFeature: Function;
  selectedFeatures: any;
  resources: Resource[];
  selectedResources: any;
  selectFeature: Function;
  onAddResource: Function;
  subscription: Subscription[];
  selectResource: Function;
  features: ProductFeature[];
  selectedNotifications: any;
  selectNotification: Function;
  newNotification: Function;
}

const Collapse = (props: CollapseProps) => {
  const {
    open,
    product,
    features,
    resources,
    newFeature,
    subscription,
    selectFeature,
    onAddResource,
    selectResource,
    selectedFeatures,
    selectedResources,
    selectedNotifications,
    selectNotification,
    newNotification,
  } = props;

  const { data } = useBusiness();
  const businessList = data?.data;

  function showBusinessName(businessKey: string) {
    const selecteedBusiness = businessList?.find(
      (business) => business._id === businessKey
    );

    return selecteedBusiness?.businessName;
  }

  const doesBusinessExist = (businessKey: string) => {
    return businessList?.some((business) => business._id === businessKey);
  };

  if (open === "feature")
    return (
      <Flex direction="column">
        <Button
          style={{ outline: 0, boxShadow: "none" }}
          pl="6"
          mt="0"
          variant="solid"
          borderRadius="0"
          bg="white"
          textColor="blue.500"
          w="100%"
          leftIcon={<AddIcon />}
          justifyContent="flex-start"
          size="sm"
          onClick={() => {
            newFeature({
              product,
            });
          }}
        >
          Add Feature
        </Button>
        {features.map((feature) => {
          const checked = selectedFeatures[feature._id ?? ""] ? true : false;
          return (
            <Flex key={feature._id} direction="column">
              <Flex p={2} px={10}>
                <Checkbox
                  pr="4"
                  onChange={(e) => {
                    selectFeature(feature, product);
                  }}
                  isChecked={checked}
                  borderColor="primary.500"
                ></Checkbox>
                <Text fontWeight="400" fontSize="sm">
                  {feature.featureId}
                </Text>
              </Flex>
              <Divider></Divider>
            </Flex>
          );
        })}
      </Flex>
    );

  if (open === "resource")
    return (
      <Flex direction="column">
        <Button
          style={{ outline: 0, boxShadow: "none" }}
          pl="6"
          mt="0"
          variant="solid"
          borderRadius="0"
          textColor="blue.500"
          w="100%"
          leftIcon={<AddIcon />}
          justifyContent="flex-start"
          size="sm"
          bg="white"
          onClick={() => onAddResource()}
        >
          Add resource
        </Button>
        {resources.map((resource) => {
          const { resourceId } = resource;
          const checked = selectedResources[resourceId ?? ""] ? true : false;
          return (
            <Flex key={resource._id} direction="column">
              <Flex p={2} px={6}>
                <Checkbox
                  pr="4"
                  size="sm"
                  onChange={(e) => {
                    selectResource(resource, product);
                  }}
                  isChecked={checked}
                  borderColor="primary.500"
                ></Checkbox>
                <Flex gridGap="2">
                  <Text fontWeight="400" fontSize="sm">
                    {resourceId}
                  </Text>
                  <Text fontWeight="400" fontSize="sm">
                    ({concatStrings(" , ", ...resource.actions)})
                  </Text>
                </Flex>
              </Flex>
              <Divider></Divider>
            </Flex>
          );
        })}
      </Flex>
    );

  if (open === "business") {
    return (
      <>
        {subscription.map((subscription) => {
          if (!doesBusinessExist(subscription.businessKey)) {
            return null;
          }
          return (
            <Flex key={subscription._id} direction="column">
              <Flex p={2} px={6}>
                <Text fontWeight="400" fontSize="sm">
                  {showBusinessName(subscription.businessKey)}
                </Text>
              </Flex>
              <Divider></Divider>
            </Flex>
          );
        })}
      </>
    );
  }

  if (open === "notifications") {
    return (
      <>
        <Button
          style={{ outline: 0, boxShadow: "none" }}
          pl="6"
          mt="0"
          variant="solid"
          borderRadius="0"
          textColor="blue.500"
          w="100%"
          leftIcon={<AddIcon />}
          justifyContent="flex-start"
          size="sm"
          bg="white"
          onClick={() => newNotification()}
        >
          Add Notification
        </Button>
        {product?.notifications?.map((notification, index: number) => (
          <>
            <Flex key={index} p={2} px={6}>
              <Checkbox
                key={index}
                pr="4"
                size="sm"
                onChange={(e) => selectNotification(notification, product)}
                isChecked={
                  !!selectedNotifications[notification?.notificationId]
                }
                borderColor="primary.500"
              ></Checkbox>
              <Text>{notification.notificationId}</Text>
            </Flex>

            <Divider />
          </>
        ))}
      </>
    );
  }

  return null;
};

export default Collapse;
