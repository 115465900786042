import MultiSelect from "react-select";
import { SmallCloseIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Stack,
  Heading,
  useToast,
  Box,
  Wrap,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useState } from "react";

import { SDrawer } from "../../packages/components/SDrawer";
import { Product } from "../../packages/interfaces/product";
import { useCreateProduct, useUpdateProduct } from "../../apis/product";
import { SInput } from "../../packages/components/SInput";
import { CSSObject } from "@emotion/react";
import { useEffect } from "react";
import {
  deviceAssignmentTypes,
  DeviceTypeMultSelectInterface,
} from "../../packages/interfaces/device";
import { toastConfigs } from "../../packages/helpers/extra";
import ErrorMessage from "../../packages/components/ErrorMessage";

interface ProductFormProps {
  open: boolean;
  setOpenModal: Function;
  product?: Product;
}

export const defaultProduct: Product = {
  productId: "",
  isActive: true,
  features: [],
  resources: [],
  productDescription: "",
  deviceAssignmentTypes: [],
};

export const ProductForm = ({
  product,
  open,
  setOpenModal,
}: ProductFormProps) => {
  const [newProduct, setNewProduct] = useState<Product>({
    ...(product || defaultProduct),
  });
  const [addProduct, addProductInfo] = useCreateProduct();
  const [updateProduct, updateProductInfo] = useUpdateProduct();
  const [selectedDeviceTypes, setSelectedDeviceTypes] =
    useState<Array<DeviceTypeMultSelectInterface>>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: newProduct,
  });

  const toast = useToast(toastConfigs);
  useEffect(() => {
    if (product) {
      const selectedDevices = product?.deviceAssignmentTypes?.map((device) => {
        const { _id, ...remainingDevices } = device;
        return {
          label: remainingDevices?.deviceName,
          value: remainingDevices,
        };
      });

      setSelectedDeviceTypes(selectedDevices);
    }
  }, [product]);

  function handleCancelAction() {
    setOpenModal("");
    setNewProduct({
      ...defaultProduct,
    });
  }

  async function handleSubmitAction() {
    const devicesThatAreSelected = selectedDeviceTypes?.map(
      (deviceType) => deviceType?.value
    );
    if (!devicesThatAreSelected?.length) {
      return toast({
        duration: 3000,
        status: "error",
        description: "Device type is mandatory.",
      });
    }
    if (product) {
      try {
        const { _id, productId, productDescription, isActive } = newProduct;
        await updateProduct({
          _id,
          isActive,
          productId,
          productDescription,
          deviceAssignmentTypes: devicesThatAreSelected ?? [],
        });
        toast({
          status: "success",
          description: `Product updated successfully. `,
        });
      } catch (e: any) {
        toast({
          status: "error",
          description: e?.response?.data?.message ?? "Cannot update product.",
        });
      }
    } else {
      try {
        await addProduct({
          ...newProduct,
          deviceAssignmentTypes: devicesThatAreSelected ?? [],
        });
        toast({
          status: "success",
          description: `Product created successfully. `,
        });
      } catch (e) {
        toast({
          description: "Cannot create product.",
          status: "error",
        });
      }
    }
    setOpenModal("");
  }

  function handleInputChange(field: string, value: string) {
    setNewProduct({ ...newProduct, [field]: value });
  }

  function cssControls(base: CSSObject) {
    return {
      ...base,
      border: 0,
      boxShadow: "none",
    };
  }

  const handleRemoveDeviceType = (
    deviceType: DeviceTypeMultSelectInterface
  ) => {
    const newSelectedDeviceTypes = selectedDeviceTypes?.filter(
      (selectedDeviceType) => selectedDeviceType.label !== deviceType.label
    );

    setSelectedDeviceTypes(newSelectedDeviceTypes);
  };

  function showSelectedSubscriptions() {
    return (
      <Wrap>
        {selectedDeviceTypes?.map((deviceType) => (
          <Flex
            p="1"
            border="1px"
            borderRadius="md"
            alignItems="center"
            key={deviceType.label}
          >
            <Text fontSize="xs">{deviceType.label}</Text>
            <Button
              size="xs"
              variant="unstyled"
              _focus={{ outline: "none" }}
              onClick={() => handleRemoveDeviceType(deviceType)}
            >
              <SmallCloseIcon />
            </Button>
          </Flex>
        ))}
      </Wrap>
    );
  }

  const getOptions = () => {
    const setOfDeviceTypes = new Set(
      selectedDeviceTypes?.map((deviceDetails) => deviceDetails?.label)
    );
    const options = deviceAssignmentTypes.filter(
      (deviceTypes) => !setOfDeviceTypes.has(deviceTypes.label)
    );
    return options;
  };

  const getTitle = (product?: Product) => {
    const action = product?.productId ? "Edit" : "Add";

    return `${action} Product`;
  };

  const validateProductName = () => {
    if (!newProduct?.productId?.length) {
      return "Please enter product name.";
    }
    if (newProduct?.productId?.length > 80) {
      return "Product name is too long.";
    }
  };

  const validateProductDescription = () => {
    if (!newProduct.productDescription) {
      return "Please enter product description.";
    }
  };

  const validateProductDevices = () => {
    if (!selectedDeviceTypes?.length) {
      return "At least one device is required.";
    }
  };

  return (
    <SDrawer
      open={open}
      title={getTitle(product)}
      showActionButtons={false}
      negativeAction={handleCancelAction}
    >
      <>
        <form onSubmit={handleSubmit(handleSubmitAction)}>
          <Heading as="h6" size="md" py={2}>
            Details
          </Heading>
          <Stack spacing={4}>
            <SInput
              type="text"
              placeholder="Product Name *"
              name="productId"
              value={newProduct.productId}
              register={{
                ...register("productId", {
                  validate: validateProductName,
                }),
              }}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              error={errors?.productId}
              errorMessage={
                <ErrorMessage message={errors?.productId?.message} />
              }
            />

            <SInput
              type="children"
              name="select"
              value={selectedDeviceTypes}
              onChange={() => {}}
              placeholder="Device Types*"
              isRequired={!selectedDeviceTypes?.length}
              register={{
                ...register("select", {
                  validate: validateProductDevices,
                }),
              }}
              error={errors?.select}
              errorMessage={<ErrorMessage message={errors?.select?.message} />}
            >
              <Box mt="1">
                <MultiSelect
                  styles={{
                    control: cssControls,
                  }}
                  noOptionsMessage={() => null}
                  value={selectedDeviceTypes}
                  closeMenuOnSelect={false}
                  options={getOptions()}
                  controlShouldRenderValue={false}
                  isMulti
                  onChange={(e) => setSelectedDeviceTypes([...(e ?? [])])}
                  isClearable={false}
                  placeholder="Device Types*"
                />
              </Box>
            </SInput>
            {showSelectedSubscriptions()}

            <SInput
              type="textarea"
              register={{
                ...register("productDescription", {
                  validate: validateProductDescription,
                }),
              }}
              name="productDescription"
              placeholder="Description"
              error={errors.productDescription}
              value={newProduct.productDescription}
              errorMessage={
                <ErrorMessage message={errors?.productDescription?.message} />
              }
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            />

            <Flex justify="flex-end">
              <Button
                variant="ghost"
                mr={3}
                onClick={handleCancelAction}
                color="primary.500"
                fontWeight="600"
              >
                Cancel
              </Button>
              <Button
                colorScheme="primary"
                type="submit"
                isLoading={
                  addProductInfo.isLoading || updateProductInfo.isLoading
                }
              >
                {product ? "Update product" : "Create Product"}
              </Button>
            </Flex>
          </Stack>
        </form>
      </>
    </SDrawer>
  );
};
