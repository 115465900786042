import { useContext, useEffect } from "react";

import { Helmet } from "react-helmet";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import {
  Box,
  Grid,
  Text,
  Flex,
  Center,
  Drawer,
  Button,
  GridItem,
  MenuItem,
  MenuList,
  useToast,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  CircularProgress,
  DrawerCloseButton,
  useBreakpointValue,
} from "@chakra-ui/react";

import logo from "./assets/logo.svg";
import { routes, Routes } from "./routes/Routes";
import { SideBar } from "./packages/components/SBar";
import { useQuery } from "./packages/hooks/useQuery";
import { Header } from "./packages/components/Header";
import { toastConfigs } from "./packages/helpers/extra";
import { LogoutIcon, ProfileIcon } from "./utils/Icons";
// import { geniusLogoutURL } from "./config";
import { AuthContext } from "./components/Login/AuthContext";
import { UnAuthorized } from "./components/Login/UnAuthorized";
import { firstLetterUpperCase } from "./packages/helpers/strings";
import { useCustomScript } from "./packages/hooks/useCustomScript";
import { AuthenticateUser } from "./components/Login/AuthenticateUser";
import { MenuItemProps, RouteProps } from "./packages/interfaces/routes";
import {
  logOutFromAPI,
  saveTokenFromQuery,
  getAuthenticatedUser,
} from "./apis/auth";

const userDoesnotHaveAccessId = "noAccess";

const menuItems: Array<MenuItemProps> = [
  { name: "Edit Profile", path: "/settings" },
];

export const App = () => {
  const query = useQuery();

  const { pathname } = useLocation();

  const userKeyFromQuery = query.get("userKey");
  const accessTokenFromQuery = query.get("accessToken");
  const refreshTokenFromQuery = query.get("refreshToken");

  const { userKey, setUserKey } = useContext(AuthContext);

  const googleSearchAddressURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`;
  useCustomScript(googleSearchAddressURL);

  useEffect(() => {
    if (userKeyFromQuery && accessTokenFromQuery && refreshTokenFromQuery) {
      if (pathname === "/authenticate") {
        saveTokenFromQuery(
          accessTokenFromQuery,
          refreshTokenFromQuery,
          userKeyFromQuery
        );
        setUserKey(userKeyFromQuery);
      } else {
        setUserKey(null);
      }
    } else {
      getAuthenticatedUser((userKeyFromLocalStorage: string) => {
        if (userKeyFromLocalStorage) {
          setUserKey(userKeyFromLocalStorage);
        } else {
          setUserKey(null);
        }
      });
    }
    //eslint-disable-next-line
  }, []);

  if (userKey === undefined) {
    return (
      <Center h="100%">
        <CircularProgress isIndeterminate color="blue.500" />
      </Center>
    );
  }

  return (
    <Switch>
      <Route path="/authenticate" component={AuthenticateUser} />
      <Route path="/unauthorized" component={UnAuthorized} />
      <PrivateRoutes />
      <Redirect to="/unauthorized" />
    </Switch>
  );
};

const PrivateRoutes = () => {
  const { userKey, userRoles } = useContext(AuthContext);

  if (userKey === undefined && !userRoles) {
    return (
      <Center h="100vh">
        <CircularProgress isIndeterminate />
      </Center>
    );
  }

  if (userKey === null) {
    window.location.replace(
      `${process.env.REACT_APP_GENIUS_LOGIN ?? ""}/logout`
    );
  }

  if (!userRoles) {
    return (
      <Center h="100vh">
        <CircularProgress isIndeterminate />
      </Center>
    );
  }

  return <LoggedIn />;
};

const LoggedIn = () => {
  const query = useQuery();

  query.delete("userKey");
  query.delete("accessToken");
  query.delete("refreshToken");

  const { push: pushToHistory } = useHistory();

  const toast = useToast(toastConfigs);

  const { userDetails, userRoles, userKey, setUserRoles, setUserKey } =
    useContext(AuthContext);

  useEffect(() => {
    if (userKey && userRoles && Object.values(userRoles).length < 1) {
      let description = "User doesn't have access.";

      if (userDetails?.isDisabled) {
        description = "User is disabled.";
      }

      if (!toast.isActive(userDoesnotHaveAccessId)) {
        toast({
          description,
          duration: 3000,
          title: "Cannot login",
          id: userDoesnotHaveAccessId,
        });
      }

      logOutFromAPI()
        .then(() => {
          setUserKey(null);
          setUserRoles(undefined);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [userRoles, userKey]);

  const { firstName = "", lastName = "" } = userDetails ?? {};

  const smVariant = { navigation: "drawer" };
  const mdVariant = { navigation: "sidebar" };

  const variants = useBreakpointValue({ base: smVariant, md: mdVariant });

  const { pathname } = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  //Dynamic title
  const pathName = pathname.split("/")[1];
  const title = firstLetterUpperCase(pathName);
  const dynamicTitle =
    pathname === "/" ? "Dashboard - Super Admin" : `${title} - Super Admin`;

  const onItemClick = (route: RouteProps) => {
    pushToHistory(route.path);
    onClose();
  };

  const getColSpan = () => {
    if (variants?.navigation === "sidebar") {
      return 5;
    }
    return 6;
  };

  const openDrawer = () => {
    onOpen();
  };

  const handleMenuClick = (menuItem: any) => {
    if (menuItem.path === "/settings") {
      pushToHistory("/settings", {
        toProfile: true,
      });
    }
  };

  const handleLogOutButton = async () => {
    try {
      await logOutFromAPI();
      setUserKey(null);
      setUserRoles(undefined);
      toast({
        status: "success",
        description: "Logged out successfully",
      });
    } catch (err: any) {
      toast({
        status: "error",
        description: err.response?.data?.message ?? "Cannot logout the user.",
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
      </Helmet>
      <Grid
        templateRows="repeat(2, 1fr)"
        templateColumns="minmax(250px, 12%) repeat(5, 1fr)"
        minH="100vh"
        maxH="100vh"
      >
        {variants?.navigation === "sidebar" && (
          <GridItem zIndex="11" rowSpan={2} colSpan={1}>
            <SideBar
              routes={routes}
              companyLogo={logo}
              activePath={pathname}
              buttonColor="lightBlue"
              onItemClick={onItemClick}
            />
          </GridItem>
        )}
        <GridItem
          rowSpan={2}
          display="flex"
          overflow="auto"
          colSpan={getColSpan()}
          flexDirection="column"
        >
          <Box top="0" bg="background.600" zIndex="10" position="sticky">
            <Header
              profileImage={userDetails?.imageURL ?? ""}
              userName={`${firstName} ${lastName}`}
              showSideBarButton={variants?.navigation === "drawer"}
              openSideBarDrawer={openDrawer}
            >
              <MenuList>
                {menuItems.map((menuItem) => {
                  return (
                    <MenuItem
                      onClick={() => handleMenuClick(menuItem)}
                      key={menuItem.path}
                    >
                      <Flex alignItems="center" gridGap="14px">
                        <ProfileIcon />
                        <Text fontSize="l">{menuItem.name}</Text>
                      </Flex>
                    </MenuItem>
                  );
                })}
                <MenuItem onClick={() => handleLogOutButton()}>
                  <Button variant="ghost" fontWeight="400" padding="0">
                    <Flex gridGap="14px" alignItems="center">
                      <LogoutIcon /> <Text>Log out</Text>
                    </Flex>
                  </Button>
                </MenuItem>
              </MenuList>
            </Header>
          </Box>
          <Routes />
        </GridItem>
      </Grid>
      <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="xs">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <SideBar
              routes={routes}
              companyLogo={logo}
              buttonColor="lightBlue"
              activePath={pathname}
              onItemClick={onItemClick}
            />
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
