export interface Device {
  count: number;
  deviceID: string;
  gateway?: string;
  isActive: boolean;
  deviceName: string;
  deviceType: string;
  businessKey: string;
  deviceFamily: string;
}

export type DeviceIdsWithDeviceType = {
  [deviceType in DeviceType]?: Device[] | string[];
};

export interface DeviceIdsMultiSelectable {
  label: string;
  value: string;
}

export type DeviceType =
  | "sensor_marker"
  | "sensor_beacon_t"
  | "sensor_marker_i"
  | "sensor_marker_x"
  | "gateway_bridge_t"
  | "gateway_environ_t"
  | "gateway";

export enum DeviceEnum {
  BridgeT = "Bridge-T",
  BeaconT = "Beacon-T",
  MarkerI = "Marker-I",
  MarkerIO = "Marker-IO",
  EnvironT = "Environ-T",
}

export type DeviceName =
  | "Marker-IO"
  | "Bridge-T"
  | "Beacon-T"
  | "Marker-I"
  | "Environ-T";

export interface DeviceTypeSelectionInterface {
  label: string;
  value: DeviceType;
}

export interface DeviceTypeMultSelectInterface {
  label: string;
  value: DeviceDetails;
}

export interface DeviceDetails {
  _id?: string;
  isGateway: boolean;
  deviceType: DeviceType;
  deviceName: DeviceName;
}

export const isGateway = (deviceType: DeviceType) => {
  return deviceAssignmentTypes?.some(
    (device) => device.value.deviceType === deviceType && device.value.isGateway
  );
};

export const deviceAssignmentTypes: Array<DeviceTypeMultSelectInterface> = [
  {
    label: "Bridge-T",
    value: {
      deviceType: "gateway_bridge_t",
      deviceName: "Bridge-T",
      isGateway: true,
    },
  },
  {
    label: "Environ-T",
    value: {
      deviceType: "gateway_environ_t",
      deviceName: "Environ-T",
      isGateway: true,
    },
  },
  {
    label: "Beacon-T",
    value: {
      deviceType: "sensor_beacon_t",
      deviceName: "Beacon-T",
      isGateway: false,
    },
  },
  {
    label: "Marker-IO",
    value: {
      deviceType: "sensor_marker",
      deviceName: "Marker-IO",
      isGateway: false,
    },
  },
  {
    label: "Marker-I",
    value: {
      deviceType: "sensor_marker_i",
      deviceName: "Marker-I",
      isGateway: false,
    },
  },
];

export interface DeviceTreeAssignment {
  action: string;
  businessKey: string;
  gateway?: string[];
  sensor?: string[];
}
