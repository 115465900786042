import Marker from "../../assets/marker.svg";
import BridgeT from "../../assets/bridgeT.svg";
import EnvironT from "../../assets/environT.svg";
import MarkerBeacon from "../../assets/markerAndBeaconT.svg";

const deviceCategory = {
  gateway: "Gateways",
  sensor: "Sensors",
};

export const filters = {
  gateways: {
    bridge: "gateway",
    environ: "gateway_environ_t",
  },
  sensors: {
    beacon: "sensor_beacon_t",
    marker: "sensor_marker",
    marker_I: "sensor_marker_i",
    marker_X: "sensor_marker_x",
  },
};

export const details = [
  {
    title: "Bridge-T",
    description:
      "Hardware unit that takes measurements from Beacon-T/BeaconX-T and sends data to Cloud via Wi-Fi. The unit also contains a built-in ambient temperature sensor and surface temperature sensor (via temperature probe). So, it can autonomously transmit data to the cloud too. This hardware unit is AC-powered with a backup battery(18650 model) to support a power outage.",
    filterBy: filters.gateways.bridge,
    category: deviceCategory.gateway,
    icon: BridgeT,
  },
  {
    title: "Environ-T",
    description:
      "Hardware unit that takes measurements from Beacon-T/BeaconX-T and sends data to Cloud via 5G (NB-IoT) connection. The unit also contains a built-in ambient temperature sensor and surface temperature sensor (via temperature probe). So, it can autonomously transmit data to the cloud too. So, it can autonomously transmit data to the cloud too. This hardware unit is AC-powered with a backup battery pack (3 18650 model batteries) to support a power outage.",
    filterBy: filters.gateways.environ,
    category: deviceCategory.gateway,
    icon: EnvironT,
  },
  {
    title: "Beacon-T",
    description:
      "Sensor hardware that measures ambient temperature and transmits the measurement to Gateway. This is a battery-powered hardware unit that works on a CR2477 battery.",
    filterBy: filters.sensors.beacon,
    category: deviceCategory.sensor,
    icon: MarkerBeacon,
  },
  {
    title: "Marker-IO",
    description:
      "Sensor hardware that transmits Bluetooth identity messages. This is a battery-powered hardware unit that works on a CR2477 battery. The unit can work outdoors or Indoors and has a waterproof enclosure.",
    filterBy: filters.sensors.marker,
    category: deviceCategory.sensor,
    icon: MarkerBeacon,
  },
  {
    title: "Marker-I",
    description:
      "Sensor hardware that transmits Bluetooth identity messages. This is a battery-powered hardware unit that works on a CR2477 battery. 'I' signifies that the unit can work only indoors and does not have any waterproof enclosure.",
    filterBy: filters.sensors.marker_I,
    category: deviceCategory.sensor,
    icon: Marker,
  },
  //TODO:uncomment later when icon is available
  // {
  //   title: "Marker-X",
  //   description:
  //     "Sensor hardware that transmits Bluetooth identity messages. This is a battery-powered hardware unit that works on 2 * AA batteries. The unit can work outdoors or Indoors and has a waterproof enclosure.",
  //   filterBy: filters.sensors.marker_X,
  //   category: deviceCategory.sensor,
  //   icon: "",
  // },
];
