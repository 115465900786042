import { useEffect, useState, useContext } from "react";

import {
  Td,
  Box,
  Flex,
  Text,
  Wrap,
  Stack,
  Button,
  Drawer,
  Avatar,
  Heading,
  Tooltip,
  useToast,
  Checkbox,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

import { AddRole } from "../Settings/AddRole";
import Page from "../../packages/components/Page";
import { ActionInterface } from "../../apis/role";
import { useBusiness } from "../../apis/business";
import { AuthContext } from "../Login/AuthContext";
import { sortByEntity } from "../../utils/helpers";
import { AddUser, superAdminProduct } from "./AddUser";
import SDialog from "../../packages/components/SDialog";
import { Role, User } from "../../packages/interfaces/user";
import { SDrawer } from "../../packages/components/SDrawer";
import { Business } from "../../packages/interfaces/business";
import { SearchBar } from "../../packages/components/SearchBar";
import { concatStrings, lowerCaseLetter } from "../../packages/helpers/strings";
import { useDebouncedCallback } from "../../packages/hooks/useDebouncedCallback";
import {
  STable,
  STableRow,
  HeaderInterface,
} from "../../packages/components/STable";
import {
  toastConfigs,
  objectsToArrays,
  getAccessDeniedErrorMessage,
} from "../../packages/helpers/extra";
import {
  useDeleteUser,
  useEntityUsers,
  useReInvitation,
  resendInviteToUser,
  useGetRolesByBusiness,
  useEnableOrDisableUser,
} from "../../apis/users";
import {
  DelRedIcon,
  EditIcon,
  PlusIcon,
  SearchIcon,
  EnableIcon,
  DisableIcon,
  DisabledIcon,
} from "../../utils/Icons";

const superadminBusiness = "stratosfy";

const usersHeaders: HeaderInterface[] = [
  {
    name: "Name",
    sort: "desc",
  },
  {
    name: "Login ID",
    sort: "desc",
  },
  {
    name: "Role",
    sort: "desc",
  },
];

const addUserId = "addUserId";

export const Users = () => {
  const { userRoles, userKey: keyOfLoggedInUser } = useContext(AuthContext);
  const type = "users";

  const canUserCreateTheUser = userRoles?.[type].some(
    (action: ActionInterface) => action.create
  );
  const canUserEditTheUser = userRoles?.[type]?.some(
    (action: ActionInterface) => action.edit
  );

  const canUserDeleteTheUser = userRoles?.[type]?.some(
    (action: ActionInterface) => action.delete
  );
  const canUserEnableDisableTheUser = userRoles?.[type]?.some(
    (action: ActionInterface) => action.disable
  );
  const [headers, setHeaders] = useState(usersHeaders);

  const [openModal, setOpenModal] = useState<"opr" | "add" | "edit" | "role">(
    "opr"
  );
  const [dialogText, setDialogText] = useState({
    subTitle: "",
    body: "",
  });
  const [searchText, setSearchText] = useState<string>("");
  const debouncedOnChange = useDebouncedCallback(
    (e) => setSearchText(e.target.value),
    400
  );
  const [superadminBusinessKey, setSuperadminBusinessKey] = useState<string>();
  const [openDialog, setOpenDialog] = useState(false);
  const [openUserEnableDisbaleDialog, setOpenUserEnableDisbaleDialog] =
    useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any>({});
  const [selectedUser, setSelectedUser] = useState<User>();

  const selectedItems = Object.values(selectedUsers ?? {}).length;

  const toast = useToast(toastConfigs);
  const { data: businesses } = useBusiness();

  const [businessList, setBusinessList] = useState<Business[]>([]);
  const [businessKeys, setBusinessKeys] = useState<string[]>([]);

  useEffect(() => {
    setBusinessList(businesses?.data ?? []);
    const keys = (businesses?.data ?? []).map((business) => business._id ?? "");
    setBusinessKeys(keys);
  }, [businesses]);

  const { data } = useEntityUsers(
    superadminBusinessKey ? [superadminBusinessKey] : [],
    "business"
  );
  const [enableDisableUser, enableDisableUserInfo] = useEnableOrDisableUser();
  const [resendInvite, resendInviteInfo] = useReInvitation();
  const [deleteUser, deleteUserInfo] = useDeleteUser();

  const [users, setUsers] = useState<User[]>();

  useEffect(() => {
    const filteredUsers = (data ?? []).filter((user: User) => {
      const { firstName, lastName } = user;
      const fullName = concatStrings(" ", firstName, lastName);
      return lowerCaseLetter(fullName).includes(
        lowerCaseLetter(searchText.trim())
      );
    });

    const { sort = "desc" } =
      headers?.find((header) => header.name === "Name") ?? {};

    const sortedUsers = filteredUsers?.sort((firstUser, secondUser) => {
      const firstUserName = `${firstUser.firstName} ${firstUser.lastName}`;
      const secondUserName = `${secondUser.firstName} ${secondUser.lastName}`;
      return sortByEntity(firstUserName, secondUserName, sort);
    });

    setUsers(sortedUsers);
  }, [data, searchText]);

  useEffect(() => {
    const firstUserKey = Object.keys(selectedUsers ?? {})[0];
    if (users && firstUserKey) {
      const updatedUser = users?.find((user) => firstUserKey === user.userKey);

      if (updatedUser) {
        const updatedUsers = { ...selectedUsers };

        updatedUsers[firstUserKey] = updatedUser;

        setSelectedUsers({ ...updatedUsers });
      }
    }
  }, [users]);

  const { data: rolesList } = useGetRolesByBusiness(businessKeys ?? []);
  const businessRoles = rolesList?.data.businessRoles ?? {};
  const roles = Object.values(businessRoles).reduce((acc: any, roles: any) => {
    return [...acc, ...roles];
  }, []) as Role[];

  useEffect(() => {
    const superadminBusinessDetail = businessList?.find(
      (business) => business.businessName === superadminBusiness
    );
    setSuperadminBusinessKey(superadminBusinessDetail?._id);
  }, [businessList]);

  useEffect(() => {
    const userKeyThatsSelected = Object.keys(selectedUsers)[0];

    if (userKeyThatsSelected) {
      const user = users?.find((user) => userKeyThatsSelected === user.userKey);
      if (user) {
        setSelectedUsers({
          ...selectedUsers,
          [userKeyThatsSelected]: { ...user },
        });
      }
    }
  }, [data]);

  function getRoleKey(user: User) {
    const superadminBusiness = user.business.find(
      (business) => business.businessKey === superadminBusinessKey
    );
    const roleKey = superadminBusiness?.[superAdminProduct]?.roleKey ?? "";
    return roleKey;
  }

  function selectUser(user: User) {
    const id: any = user.userKey;
    if (selectedUsers[id]) {
      delete selectedUsers[id];
    } else {
      selectedUsers[id] = user;
    }
    setSelectedUsers({ ...selectedUsers });
  }

  function editUser(user: User) {
    if (!canUserEditTheUser) {
      toast(getAccessDeniedErrorMessage("edit", "user"));
      return;
    }
    setSelectedUser({ ...user });
    setSelectedUsers({});
    setOpenModal("edit");
  }

  async function handleEnableOrDisableUser(user: User) {
    if (user?.isCompanySuperAdmin) {
      return toast(
        getAccessDeniedErrorMessage("disable/enable", "company super admin")
      );
    }

    if (user?.userKey === keyOfLoggedInUser) {
      return toast(getAccessDeniedErrorMessage("disable", "you own account."));
    }

    setSelectedUser({ ...user });
    try {
      const { userKey = "", isDisabled } = user;
      await enableDisableUser({
        id: userKey,
        status: isDisabled ?? false,
        updatedBy: keyOfLoggedInUser ?? "",
      });
      selectUser(user);
      setOpenUserEnableDisbaleDialog(false);
      enableDisableUserInfo.reset();
    } catch (err: any) {
      enableDisableUserInfo.reset();
      const status = user.isDisabled ? "enable" : "disable";
      return toast({
        status: "error",
        description: err?.response?.data?.message ?? `Cannot ${status} user.`,
      });
    }
  }

  async function handleDeleteUsers() {
    const userIds = Object.keys(selectedUsers);
    try {
      await deleteUser(userIds);
      return;
    } catch (err: any) {
      deleteUserInfo.reset();
      if (err.response.status === 403) {
        return toast(getAccessDeniedErrorMessage("delete", "user"));
      }
      return toast({
        duration: 3000,
        status: "error",
        description: err?.response?.data.message ?? "Cannot delete user.",
      });
    }
  }

  function handleAddRole() {
    setOpenModal("role");
  }

  function customDrawerContent() {
    if (openModal === "role")
      return <AddRole handleCancel={() => setOpenModal("add")} />;
    return (
      <AddUser
        user={selectedUser}
        handleAddRole={handleAddRole}
        handleCancelAction={() => {
          setOpenModal("opr");
          setSelectedUser(undefined);
          setSelectedUsers({});
        }}
      />
    );
  }

  function renderTitle() {
    switch (openModal) {
      case "add":
        return "Invite User";
      case "edit":
        return "Edit People";
      default:
        return "";
    }
  }

  const handleInviteUserButton = async (selectedUser: User) => {
    if (!canUserEditTheUser) {
      toast(getAccessDeniedErrorMessage("invite", "user"));
      return;
    }
    try {
      let loggedInUserKey = keyOfLoggedInUser ?? "";
      await resendInviteToUser(selectedUser, resendInvite, loggedInUserKey);
      toast({
        duration: 3000,
        status: "success",
        description: "Invitation sent successfully.",
      });
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message ?? "Cannot send error messsage.";
      toast({
        duration: 3000,
        description: errorMessage,
        status: "error",
      });
    }
  };

  const doShowInviteButton = (user: User) => {
    if (user?.isVerified || user?.isDisabled) {
      return null;
    }

    return (
      <Button
        size="sm"
        variant="outline"
        colorScheme="blue"
        isDisabled={isTakingAction()}
        onClick={() => handleInviteUserButton(user)}
        isLoading={resendInviteInfo.isLoading && !resendInviteInfo.isSuccess}
      >
        Re-invite User
      </Button>
    );
  };

  const isTakingAction = () => {
    return (
      enableDisableUserInfo.isLoading ||
      resendInviteInfo.isLoading ||
      deleteUserInfo.isLoading
    );
  };
  function handleEnableDisableButton(
    isDisabledUser: boolean | undefined,
    user: User
  ) {
    const subTitle = `Are you sure you want to ${
      isDisabledUser ? "enable" : "disable"
    }  ${user.firstName} ${user.lastName} ?`;
    setDialogText({ ...dialogText, subTitle });
    setOpenUserEnableDisbaleDialog(true);
  }
  function showButtons() {
    const isDisabledUser = Object.values(selectedUsers as User[])[0]
      ?.isDisabled;
    const EnableOrDisableIcon = isDisabledUser ? EnableIcon : DisableIcon;
    const keysOfSelectedUsers = Object.keys(selectedUsers);
    const user = selectedUsers[keysOfSelectedUsers[0]];
    return (
      <>
        {keysOfSelectedUsers.length === 1 && (
          <>
            {doShowInviteButton(user)}
            <Button
              size="sm"
              variant="outline"
              disabled={!canUserEnableDisableTheUser}
              colorScheme="blue"
              isDisabled={isTakingAction()}
              leftIcon={<EnableOrDisableIcon />}
              isLoading={enableDisableUserInfo.isLoading}
              onClick={() =>
                handleEnableDisableButton(
                  isDisabledUser,
                  Object.values(selectedUsers)[0] as User
                )
              }
            >
              {isDisabledUser ? "Enable" : "Disable"}
            </Button>
            <Button
              size="sm"
              variant="outline"
              colorScheme="blue"
              leftIcon={<EditIcon />}
              isDisabled={
                isTakingAction() || isDisabledUser || !canUserEditTheUser
              }
              onClick={() => {
                editUser(selectedUsers[keysOfSelectedUsers[0]]);
              }}
            >
              Edit
            </Button>
            <SDialog
              open={openUserEnableDisbaleDialog}
              loading={enableDisableUserInfo.isLoading}
              title={` ${isDisabledUser ? "Enable" : "Disable"} user ? `}
              body=""
              subTitle=""
              handleNegativeAction={() => setOpenUserEnableDisbaleDialog(false)}
              handlePositiveAction={() => {
                handleEnableOrDisableUser(
                  Object.values(selectedUsers)[0] as User
                );
              }}
              positiveLabelColor="red"
              positiveLabel={isDisabledUser ? "Enable" : "Disable"}
              size="lg"
            >
              <Box mt="2">
                <Flex align="center">
                  <Text fontSize="sm">{dialogText.subTitle}</Text>
                </Flex>
              </Box>
            </SDialog>
          </>
        )}
      </>
    );
  }

  function nameCell(user: User) {
    const disabledIcon = user.isDisabled ? <DisabledIcon /> : null;
    const fullName = `${user.firstName} ${user.lastName}`;
    return (
      <Flex align="center" gridGap="30px" width="50%">
        <Checkbox
          bg="white"
          borderRadius="4px"
          paddingLeft="24px"
          marginBottom="16px"
          borderColor="primary.500"
          onChange={() => selectUser(user)}
          isChecked={!!selectedUsers[user.userKey ?? ""]}
        />
        <Avatar name={user?.firstName} src={user?.imageURL} />
        <Flex gridGap="1" flexWrap="wrap">
          {fullName?.length > 35 ? (
            <Tooltip label={fullName}>
              <Text isTruncated width="20em">
                {fullName}
              </Text>
            </Tooltip>
          ) : (
            <Text>{fullName}</Text>
          )}
          <Text color="text.900">
            {user.isVerified || user.userKey === keyOfLoggedInUser
              ? null
              : "(Invited)"}
          </Text>
          {disabledIcon}
        </Flex>
      </Flex>
    );
  }

  function loginMethodCell(user: User) {
    if (user.loginMethod === "email") {
      return user.email ?? null;
    }
    return user.phoneNumber ?? null;
  }

  function rolesCell(user: User) {
    const roleKey = getRoleKey(user);
    const role = roles.find((role) => role.roleKey === roleKey);
    return role?.name ?? "";
  }

  const handleAddUser = () => {
    if (!canUserCreateTheUser) {
      if (!toast.isActive(addUserId)) {
        toast({ ...getAccessDeniedErrorMessage("add", "user") });
      }
      return;
    }
    setSelectedUser(undefined);
    setOpenModal("add");
  };

  const handleDeleteButton = () => {
    const users = Object.values(selectedUsers);

    const isUserLoggedInUser = users?.some(
      (user: any) => user?.userKey === keyOfLoggedInUser
    );
    if (isUserLoggedInUser) {
      return toast(getAccessDeniedErrorMessage("delete", "your own account"));
    }
    if (!canUserDeleteTheUser) {
      toast(getAccessDeniedErrorMessage("delete", "user"));
      return;
    }

    const isThereAnyCompanySuperAdmin = users?.some(
      (user: any) => user.isCompanySuperAdmin
    );

    if (isThereAnyCompanySuperAdmin) {
      return toast(
        getAccessDeniedErrorMessage("delete", "company super admin")
      );
    }

    let subTitle = "";
    if (selectedItems < 2) {
      subTitle = "Are you sure you want to delete Stratosfy User -";
    } else {
      subTitle = "Are you sure want to delete these Stratosfy User";
    }
    setDialogText({ ...dialogText, subTitle });
    setOpenDialog(true);
  };

  function showUserFullName() {
    const user = objectsToArrays(selectedUsers)[0] ?? ({} as User);
    const { firstName = "", lastName = "" } = user;
    return `${firstName} ${lastName}`;
  }

  const handleHeaderClick = (header: HeaderInterface, headerIndex: number) => {
    let sortedUsers: User[];
    const { sort } = header;
    const newSort = sort === "desc" ? "asc" : "desc";
    switch (header.name) {
      case "Name": {
        sortedUsers =
          users?.sort((firstUser, secondUser) => {
            const firstUserName = `${firstUser.firstName} ${firstUser.lastName}`;
            const secondUserName = `${secondUser.firstName} ${secondUser.lastName}`;
            return sortByEntity(firstUserName, secondUserName, newSort);
          }) ?? [];
        break;
      }

      case "Login ID":
        sortedUsers =
          users?.sort((firstUser, secondUser) => {
            const firstUserLogin =
              firstUser?.loginMethod === "email"
                ? firstUser.email
                : firstUser.phoneNumber;
            const secondUserLogin =
              secondUser?.loginMethod === "email"
                ? secondUser.email
                : secondUser.phoneNumber;

            return sortByEntity(firstUserLogin, secondUserLogin, newSort);
          }) ?? [];
        break;

      case "Role":
        sortedUsers = [...(users ?? [])];
        sortedUsers?.sort((firstUser, secondUser) => {
          const firstUserRoleKey = getRoleKey(firstUser);
          const secondUserRoleKey = getRoleKey(secondUser);
          const firstUserRoleName =
            roles.find((role) => role.roleKey === firstUserRoleKey)?.name ?? "";
          const secondUserRoleName =
            roles.find((role) => role.roleKey === secondUserRoleKey)?.name ??
            "";

          return sortByEntity(firstUserRoleName, secondUserRoleName, newSort);
        });
        break;

      default:
        sortedUsers = [...(users ?? [])];
        break;
    }

    let updatedHeaders = [...headers];

    updatedHeaders.splice(headerIndex, 1, {
      name: header.name,
      sort: newSort,
    });

    setHeaders([...updatedHeaders]);

    setUsers(sortedUsers);
  };

  return (
    <Page>
      <Flex justify="space-between">
        <Heading as="h1" size="lg">
          Users
        </Heading>
        <Button
          leftIcon={<PlusIcon />}
          size="md"
          colorScheme="blue"
          onClick={() => handleAddUser()}
        >
          Add User
        </Button>
      </Flex>
      <Box maxW="40%">
        <SearchBar
          value={searchText}
          title="Search Users"
          rightIcon={<SearchIcon />}
          onChange={(e) => {
            setSelectedUsers({});
            setSearchText(e.target.value);
            debouncedOnChange(e);
          }}
          onClose={() => setSearchText("")}
        />
      </Box>
      <STable
        checked={selectedItems === users?.length}
        headers={headers}
        onChangeCheckBox={(e: any) => {
          if (e.target.checked) {
            users?.forEach((user) => {
              const id: any = user.userKey ?? "";
              selectedUsers[id] = user;
            });
            setSelectedUsers({ ...selectedUsers });
          } else {
            setSelectedUsers({});
          }
        }}
        handleHeaderClick={handleHeaderClick}
      >
        {users?.map((user) => (
          <STableRow
            checked={!!selectedUsers[user.userKey ?? ""]}
            onChangeCheck={() => selectUser(user)}
          >
            <Td>{nameCell(user)}</Td>
            <Td>{loginMethodCell(user)}</Td>
            <Td>{rolesCell(user)}</Td>
          </STableRow>
        ))}
      </STable>
      <SDrawer
        open={openModal !== "opr"}
        title={renderTitle()}
        negativeAction={() => {
          setSelectedUser(undefined);
          setOpenModal("opr");
        }}
        showActionButtons={false}
      >
        {customDrawerContent()}
      </SDrawer>
      <Drawer
        isOpen={!!selectedItems}
        variant="alwaysOpen"
        placement="bottom"
        blockScrollOnMount={false}
        trapFocus={false}
        onClose={() => {
          setSelectedUsers({});
        }}
      >
        <DrawerContent
          p="6"
          w="60%"
          mx="auto"
          shadow="0px 0px 50px 2px rgba(50,58,69,0.47)"
          borderTopLeftRadius="12"
          borderTopRightRadius="12"
        >
          <DrawerBody>
            <Stack direction="row" justify="space-between">
              <Flex>
                <DrawerCloseButton position="relative" top="0" />
                <Text display="flex" alignItems="center">
                  <Text>{selectedItems} stratosfy users selected</Text>
                </Text>
              </Flex>
              <Wrap>
                {showButtons()}
                <Button
                  size="sm"
                  colorScheme="red"
                  disabled={!canUserDeleteTheUser}
                  variant="outline"
                  leftIcon={<DelRedIcon />}
                  isDisabled={isTakingAction()}
                  isLoading={deleteUserInfo.isLoading}
                  onClick={() => handleDeleteButton()}
                >
                  Delete
                </Button>
              </Wrap>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <SDialog
        open={openDialog}
        loading={deleteUserInfo.isLoading}
        title={`Delete ${selectedItems} users ? `}
        body=""
        subTitle=""
        handleNegativeAction={() => setOpenDialog(false)}
        handlePositiveAction={() => {
          handleDeleteUsers();
          setOpenDialog(false);
          setSelectedUsers({});
        }}
        positiveLabel="Delete"
        positiveLabelColor="red"
        size="lg"
      >
        <Box mt="2">
          <Flex align="center">
            <Text fontSize="sm">{dialogText.subTitle}</Text>
            {selectedItems < 2 && (
              <Text ml="2" fontWeight="semibold">
                {showUserFullName()}
              </Text>
            )}
          </Flex>
          <Text mt="2" fontSize="xs" color="altText.500">
            Remember, the action cannot be undone and also it removes data such
            as login and business
          </Text>
        </Box>
      </SDialog>
    </Page>
  );
};
