import {
  Flex,
  Icon,
  Image,
  ResponsiveValue,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent, MutableRefObject, useRef, useState } from "react";
import { AiFillCamera } from "react-icons/ai";
import dummyImage from "../../assets/dummyImage.png";

interface ImageContainerProps {
  imageURL: string | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  size?: string;
  externalRef?: MutableRefObject<any>;
  objectFit?: ResponsiveValue<any>;
}

const ImageContainer = (props: ImageContainerProps) => {
  const { imageURL, onChange, size, externalRef, objectFit } = props;
  const [hover, setHover] = useState(false);
  const ref = useRef<any>();

  function handleClick() {
    if (externalRef) {
      externalRef?.current?.click();
    } else {
      ref?.current?.click();
    }
  }

  return (
    <>
      <Flex justifyContent="center">
        <Stack
          w={size}
          h={size}
          position="relative"
          alignContent="center"
          justifyContent="center"
          onClick={() => handleClick()}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Image
            size={size}
            height={size}
            width={size}
            position="absolute"
            alt="Profile Picture"
            onClick={() => handleClick()}
            objectFit={objectFit ?? "cover"}
            src={imageURL ? imageURL : dummyImage}
          />

          {hover && (
            <Flex
              w={size}
              h={size}
              position="absolute"
              alignItems="center"
              margin="0 !important"
              flexDirection="column"
              justifyContent="center"
              background="blackAlpha.500"
            >
              <Icon as={AiFillCamera} color="white" h={25} w={25} />
              <Text color="white" textAlign="center" fontSize="s">
                CHANGE PROFILE PHOTO
              </Text>
            </Flex>
          )}
        </Stack>
        <input
          type="file"
          name="image"
          ref={externalRef ?? ref}
          style={{ display: "none" }}
          onChange={onChange}
        />
      </Flex>
    </>
  );
};

export default ImageContainer;
