import { useEffect, useState } from "react";
import {
  Td,
  Box,
  Text,
  Wrap,
  Flex,
  Avatar,
  Tooltip,
  WrapItem,
  Checkbox,
} from "@chakra-ui/react";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";

import { getBusinessFromId } from "../../apis/business";
import CountView from "../../packages/components/CountView";
import { SLoader } from "../../packages/components/SLoader";
import { Business } from "../../packages/interfaces/business";
import { ArrowDownIcon, ArrowUpIcon } from "../../utils/Icons";
import { getNameFromUser, LoginMethod } from "./SuperAdminAddUpdateCard";
import { ReactComponent as DisabledIcon } from "../../assets/disable.svg";
import {
  HeaderInterface,
  STable,
  STableRow,
} from "../../packages/components/STable";
import {
  Customer,
  UserBusiness,
  SelectedCustomerBusiness,
} from "../../packages/interfaces/customer";
import {
  useGetSubscriptionByIds,
  useProductsForSubscription,
} from "../../apis/subscriptions";

interface Row {
  onSelected: any;
  checkedUsers: any;
  rowDetails: Customer;
  onCheckBoxClicked: any;
  selectedCustomerBusiness: SelectedCustomerBusiness;
  onCheckBoxBusinessClicked: (businessKey: string) => void;
  setSelectedCustomerBusiness: (params: SelectedCustomerBusiness) => void;
}

interface STableProps {
  data: any[];
  onSelectAll: any;
  checked: boolean;
  checkedUsers: any;
  onSelected: Function;
  checkBoxBusiness: any;
  onAssignAdmin: Function;
  headers: HeaderInterface[];
  onCheckBoxClicked: Function;
  selectedCustomerBusiness: SelectedCustomerBusiness;
  onCheckBoxBusinessClicked: (businessKey: string) => void;
  setSelectedCustomerBusiness: (params: SelectedCustomerBusiness) => void;
  handleHeaderClick: (header: HeaderInterface, indexOfHeader: number) => void;
}

interface NameCellProps {
  name: string;
  onClick: any;
  checked: boolean;
  imageUrl?: string;
  isDisabled: boolean;
  isVerified: boolean;
  onChangeCheck: () => void;
}

interface LoginMethodCellProps {
  loginMethod: string;
  loginValue: string | undefined;
}

interface BusinessCellProps {
  collapse: boolean;
  totalBusiness: number;
  handleToggleCollapse: () => void;
}

const NameCell = (props: NameCellProps) => {
  const {
    name,
    onClick,
    checked,
    imageUrl,
    isDisabled,
    isVerified,
    onChangeCheck,
  } = props;
  return (
    <Td cursor="pointer">
      <Flex gridGap="30px">
        <Checkbox
          bg="white"
          marginLeft="24px"
          isChecked={checked}
          borderRadius="12px"
          onChange={onChangeCheck}
          borderColor="primary.500"
        />
        <Flex align="center" onClick={onClick}>
          <WrapItem>
            <Avatar name={name} src={imageUrl} margin="11px 16px 10px 0px" />
          </WrapItem>
          <Flex gridGap="4px">
            {name?.length >= 38 ? (
              <Tooltip label={name}>
                <Flex width="40%" flexWrap="wrap">
                  <Text noOfLines={[1, 2, 3]}>{name}</Text>
                </Flex>
              </Tooltip>
            ) : (
              <Text>{name}</Text>
            )}
            <Text color="text.900">{isVerified ? null : "(Invited)"}</Text>
            {isDisabled && <DisabledIcon />}
          </Flex>
        </Flex>
      </Flex>
    </Td>
  );
};

const LoginMethodCell = (props: LoginMethodCellProps) => {
  const { loginMethod, loginValue } = props;

  const leftIcon =
    loginMethod === LoginMethod.PHONE ? (
      <PhoneIcon color="grey" />
    ) : (
      <EmailIcon color="gray" />
    );
  return (
    <Td>
      <Wrap align="center" spacing="4" fontSize="16px">
        {leftIcon}
        {loginValue ?? ""}
      </Wrap>
    </Td>
  );
};

const BusinessCell = (props: BusinessCellProps) => {
  const { handleToggleCollapse, collapse, totalBusiness } = props;

  return (
    <Td>
      <CountView
        subtitle="Business"
        arrowModifier={collapse}
        title={totalBusiness.toString()}
        onClick={() => handleToggleCollapse()}
        ArrowUpIcon={<ArrowUpIcon />}
        ArrowDownIcon={<ArrowDownIcon />}
      />
    </Td>
  );
};

const CustomRow = (props: Row) => {
  const {
    onSelected,
    rowDetails,
    checkedUsers,
    onCheckBoxClicked,
    selectedCustomerBusiness,
    onCheckBoxBusinessClicked,
    setSelectedCustomerBusiness,
  } = props;
  const {
    email,
    userKey,
    business,
    imageURL,
    isDisabled,
    loginMethod,
    phoneNumber,
    lastLoginAt,
    countryCode,
  } = rowDetails;
  const name = getNameFromUser(props.rowDetails);
  const loginValue =
    loginMethod === LoginMethod.PHONE ? `${countryCode}${phoneNumber}` : email;

  const selectedUserKey = Object.keys(selectedCustomerBusiness)?.[0] ?? "";

  function handleToggleCollapse() {
    if (userKey === selectedUserKey) {
      setSelectedCustomerBusiness({});
    } else {
      setSelectedCustomerBusiness({ [userKey ?? ""]: [] });
    }
  }

  return (
    <STableRow
      checked={
        checkedUsers[
          loginMethod === LoginMethod.EMAIL ? email! : phoneNumber!
        ] ?? false
      }
      onChangeCheck={() => onCheckBoxClicked({ ...props.rowDetails })}
      collapseOpen={userKey === selectedUserKey}
      collapseData={
        userKey === selectedUserKey && (
          <BusinessList
            customerDetails={rowDetails}
            selectedCustomerBusiness={selectedCustomerBusiness}
            onCheckBoxBusinessClicked={onCheckBoxBusinessClicked}
          />
        )
      }
    >
      {name && (
        <NameCell
          checked={
            checkedUsers[
              loginMethod === LoginMethod.EMAIL ? email! : phoneNumber!
            ] ?? false
          }
          onChangeCheck={() => onCheckBoxClicked({ ...props.rowDetails })}
          name={name}
          onClick={() => onSelected(props.rowDetails)}
          imageUrl={imageURL}
          isVerified={!!lastLoginAt}
          isDisabled={isDisabled ?? false}
        />
      )}
      {loginMethod && (
        <LoginMethodCell loginMethod={loginMethod} loginValue={loginValue} />
      )}
      {business && (
        <BusinessCell
          collapse={userKey === selectedUserKey}
          handleToggleCollapse={handleToggleCollapse}
          totalBusiness={rowDetails?.business?.length ?? 0}
        />
      )}
    </STableRow>
  );
};

export const CustomTable = (props: STableProps) => {
  const {
    data,
    headers,
    checked,
    onSelected,
    onSelectAll,
    checkedUsers,
    handleHeaderClick,
    onCheckBoxClicked,
    selectedCustomerBusiness,
    onCheckBoxBusinessClicked,
    setSelectedCustomerBusiness,
  } = props;

  return (
    <STable
      checked={checked}
      onChangeCheckBox={onSelectAll}
      headers={headers}
      handleHeaderClick={handleHeaderClick}
    >
      {data.map((row) => {
        if (row?.isCompanySuperAdmin) {
          return null;
        }
        return (
          <CustomRow
            key={row._id}
            rowDetails={{ ...row }}
            onSelected={onSelected}
            checkedUsers={checkedUsers}
            onCheckBoxClicked={onCheckBoxClicked}
            selectedCustomerBusiness={selectedCustomerBusiness}
            onCheckBoxBusinessClicked={onCheckBoxBusinessClicked}
            setSelectedCustomerBusiness={setSelectedCustomerBusiness}
          />
        );
      })}
    </STable>
  );
};

interface BusinessListProps {
  customerDetails: Customer;
  selectedCustomerBusiness: SelectedCustomerBusiness;
  onCheckBoxBusinessClicked: (businessKey: string) => void;
}

const BusinessList = (props: BusinessListProps) => {
  const {
    customerDetails,
    onCheckBoxBusinessClicked,
    selectedCustomerBusiness,
  } = props;

  const selectedUserKey = Object.keys(selectedCustomerBusiness)?.[0] ?? "";

  const selectedBusiness = selectedCustomerBusiness[selectedUserKey];

  const uniqueBusinessKeys = new Set(selectedBusiness);

  const { business } = customerDetails;

  const [loading, setLoading] = useState(true);
  const [busi, setBusi] = useState<Business[]>();
  const [filteredBusinessSet, setFilteredBusinessSet] = useState<string[]>([]);

  const set = new Set(business?.map((business: any) => business.businessKey));
  const businessSet = Array.from(set);

  useEffect(() => {
    Promise.all(businessSet.map((e: any) => getBusinessFromId(e)))
      .then((e1: any) => {
        setBusi(e1);
        setLoading(false);
      })
      .catch((e) => {
        console.error({ e });
        setLoading(false);
      });
  }, [business]);

  useEffect(() => {
    const existingBusinessKeys = businessSet.filter((businessKey) =>
      busi?.some((business: any) => business._id === businessKey)
    );
    setFilteredBusinessSet(existingBusinessKeys);
  }, [busi]);

  if (loading) {
    return (
      <Box w="100%" align="center">
        <SLoader size="sm" />
      </Box>
    );
  }

  return (
    <Box pt="0" bg="background.600">
      {Object.values(filteredBusinessSet)?.map((item: string, i: any) => {
        const currentBusiness = business?.find((b) => b.businessKey === item);
        return (
          <CustomerAssignedBusinesses
            index={i}
            item={item}
            business={busi?.[i] ?? {}}
            _id={busi?.[i]?._id ?? ""}
            key={busi?.[i]?._id ?? ""}
            selectedBusiness={currentBusiness}
            uniqueBusinessKeys={uniqueBusinessKeys}
            onCheckBoxBusinessClicked={onCheckBoxBusinessClicked}
          />
        );
      })}
    </Box>
  );
};

interface CustomerAssignedBusinessesInterface {
  _id: string;
  item: string;
  index: number;
  business: any;
  uniqueBusinessKeys: Set<string>;
  selectedBusiness?: UserBusiness;
  onCheckBoxBusinessClicked: (businessKey: string) => void;
}

const CustomerAssignedBusinesses = ({
  _id,
  item,
  business,
  selectedBusiness,
  uniqueBusinessKeys,
  onCheckBoxBusinessClicked,
}: CustomerAssignedBusinessesInterface) => {
  const [productNames, setProductNames] = useState<string[]>();

  useEffect(() => {
    const nameOfProducts = Object.keys(selectedBusiness ?? {}).reduce(
      (acc: string[], key: any) => {
        if (
          typeof selectedBusiness?.[key] === "object" &&
          selectedBusiness?.[key]?.roleKey
        ) {
          acc.push(key);
        }
        return acc;
      },
      []
    );

    setProductNames(nameOfProducts);
  }, [selectedBusiness]);

  const getProductName = () => {
    if (!productNames) return "";
    return `(${productNames?.join(", ") ?? ""})`;
  };

  return (
    <Wrap key={item} pl="10" py="2" spacing="8">
      <Checkbox
        isChecked={uniqueBusinessKeys.has(item)}
        onChange={(e) => onCheckBoxBusinessClicked(_id)}
        colorScheme="blue"
        borderColor="primary.500"
      />
      <Text fontWeight="400" fontSize="md">
        {business?.businessName ?? ""} {getProductName()}
      </Text>
    </Wrap>
  );
};
