import { createContext, useState, useEffect } from "react";

import { User } from "../../packages/interfaces/user";
import { getRoleByRoleKey, useUserDetails } from "../../apis/auth";

interface AuthProps {
  userRoles: null | any;
  userDetails: null | User;
  userKey: string | null | undefined;
  setUserKey: (userKey: string | null) => void;
  setUserRoles: (userRole: any) => void;
  setUserDetails: (userDetails: User) => void;
}
export const AuthContext = createContext<AuthProps>({
  userKey: null,
  userRoles: null,
  userDetails: null,
  setUserKey: () => {},
  setUserRoles: () => {},
  setUserDetails: () => {},
});

export function AuthProvider(props: any) {
  const [userRoles, setUserRoles] = useState<any>();
  const [userDetails, setUserDetails] = useState<User | null>(null);
  const [userKey, setUserKey] = useState<string | null | undefined>(undefined);

  const { data, isError } = useUserDetails(userKey ?? "");

  useEffect(() => {
    if (data) {
      setUserDetails(data);
    }
  }, [data]);

  useEffect(() => {
    const businesses = userDetails?.business;

    const productId = "superadmin";

    if (businesses && !userDetails?.isDisabled) {
      const rolesKeys = businesses?.reduce((roleKeys: string[], business) => {
        const { roleKey } = business?.[productId] ?? {};
        if (roleKey) roleKeys.push(roleKey);
        return roleKeys;
      }, []);

      Promise.all(rolesKeys.map((roleKey) => getRoleByRoleKey(roleKey))).then(
        (res) => {
          const allPermissions = res.reduce((accumulator, r) => {
            const permissions = r?.roleDocument?.permissions;
            const menus = Object.keys(permissions);
            menus.forEach((menu) => {
              accumulator[menu?.toLowerCase()] = [
                ...(accumulator[menu] ?? []),
                permissions[menu],
              ];
            });
            return accumulator;
          }, {});
          setUserRoles(allPermissions);
        }
      );
    } else if (userDetails) {
      setUserRoles({});
    }
  }, [userDetails]);

  if (isError) {
    setUserKey(null);
  }

  return (
    <AuthContext.Provider
      value={{
        userKey,
        userRoles,
        setUserKey,
        userDetails,
        setUserRoles,
        setUserDetails,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
