import { useState } from "react";

import { useForm } from "react-hook-form";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Flex,
  Icon,
  Text,
  Stack,
  Button,
  Heading,
  useToast,
} from "@chakra-ui/react";

import { SInput } from "../../packages/components/SInput";
import { SDrawer } from "../../packages/components/SDrawer";
import { toastConfigs } from "../../packages/helpers/extra";
import { lowerCaseLetter } from "../../packages/helpers/strings";
import { useAddResource, useUpdateResoure } from "../../apis/product";
import { Product, Resource } from "../../packages/interfaces/product";
import ErrorMessage from "../../packages/components/ErrorMessage";

interface ResourceFormProps {
  open: boolean;
  setOpenModal: Function;
  product: Product | undefined;
  resource?: Resource;
}

const resourceSkeleton = {
  actions: [],
  resourceId: "",
  isActive: true,
};

export const AddResource = ({
  open,
  resource,
  product,
  setOpenModal,
}: ResourceFormProps) => {
  const [newResource, setNewResource] = useState(resource || resourceSkeleton);

  const [addResource, addResourceInfo] = useAddResource();
  const [updateResoure, updateResoureInfo] = useUpdateResoure();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: newResource,
  });

  const toast = useToast(toastConfigs);
  function handleCancelAction() {
    setOpenModal("");
  }

  function loading() {
    const { isLoading: isCreatingResource } = addResourceInfo;
    const { isLoading: isUpdatingResource } = updateResoureInfo;

    return isCreatingResource || isUpdatingResource;
  }

  function resetLoaders() {
    addResourceInfo.reset();
    updateResoureInfo.reset();
  }

  function checkDuplicateResoureId(resourceId: string) {
    const isDuplicateExist = product?.resources?.some(
      (resource) =>
        lowerCaseLetter(resource?.resourceId ?? "") ===
        lowerCaseLetter(resourceId)
    );
    const isSameResourceId = resource?.resourceId === resourceId;

    return isDuplicateExist && !isSameResourceId;
  }

  async function handleSubmitAction() {
    if (resource) {
      try {
        const resourceKey = resource._id ?? "";
        delete newResource.isActive;
        // @ts-ignore
        delete newResource?._id;
        await updateResoure({
          productKey: product?._id ?? "",
          resourceKey,
          resource: newResource,
        });
      } catch (e: any) {
        resetLoaders();
        return toast({
          status: "error",
          description: e?.response?.data?.message ?? "Cannot update resource.",
        });
      }
    } else {
      try {
        delete newResource.isActive;
        await addResource({
          productKey: product?._id ?? "",
          resource: newResource,
        });
      } catch (e: any) {
        resetLoaders();
        return toast({
          status: "error",
          description: e?.response?.data?.message ?? "Cannot add resource.",
        });
      }
    }
    setOpenModal("");
    const label = resource ? "updated" : "created";
    toast({
      status: "success",
      description: `Resource ${label} successfully `,
    });
  }

  function handleInputChange(field: string, value: string) {
    setNewResource({ ...newResource, [field]: value });
  }

  function addAction() {
    const updatedActions = [...newResource.actions, ""];
    setNewResource({ ...newResource, actions: updatedActions });
  }

  function removeAction(index: number) {
    const updatedActions = [...newResource.actions];
    updatedActions.splice(index, 1);
    setNewResource({ ...newResource, actions: updatedActions });
  }

  function handleChangeAction(index: number, value: string) {
    const updatedActions = [...newResource.actions];
    updatedActions[index] = value;
    setNewResource({ ...newResource, actions: updatedActions });
  }

  function validateResourceId() {
    if (newResource?.actions?.length === 0) {
      return "At least 1 action is required.";
    }
    if (checkDuplicateResoureId(newResource?.resourceId ?? "")) {
      return "Name already exists.";
    }
    return (newResource?.resourceId?.length || 0) < 80;
  }

  return (
    <SDrawer
      open={open}
      title={resource?.resourceId ? "Edit Resource" : "Add Resource"}
      showActionButtons={false}
      negativeAction={handleCancelAction}
    >
      <>
        <form onSubmit={handleSubmit(handleSubmitAction)}>
          <Heading as="h6" size="md" py={2}>
            Details
          </Heading>
          <Stack spacing={4}>
            <SInput
              isDisabled={resource?.resourceId ? true : false}
              type="text"
              name="resourceId"
              placeholder="Resource Name *"
              value={newResource?.resourceId}
              isRequired
              register={{
                ...register("resourceId", {
                  validate: validateResourceId,
                }),
              }}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              error={errors?.resourceId}
              errorMessage={
                <ErrorMessage message={errors?.resourceId?.message} />
              }
            />

            {newResource.actions.map((action: any, index: number) => {
              return (
                <Flex alignItems="center" gridGap="2">
                  <SInput
                    isRequired
                    type="text"
                    value={action}
                    errorMessage={"Error"}
                    name={`Action ${index + 1}`}
                    error={errors[`action${index}`]}
                    placeholder={`Action ${index + 1}`}
                    isDisabled={new Set(resource?.actions).has(action)}
                    onChange={(e) => handleChangeAction(index, e.target.value)}
                    register={{
                      ...register(`action${index}`, {
                        validate: (_) =>
                          (newResource?.actions[index].length || 0) < 80,
                      }),
                    }}
                  />
                  <Button variant="ghost" onClick={() => removeAction(index)}>
                    <Icon as={MinusIcon} color="altText.500" />
                  </Button>
                </Flex>
              );
            })}
            <Flex justify="space-between">
              <Flex
                align="center"
                color="primary.500"
                cursor="pointer"
                onClick={addAction}
              >
                <Icon as={AddIcon} mr="4"></Icon>
                <Text pr={2} fontWeight="500" fontSize="14" color="primary.500">
                  Add action
                </Text>
              </Flex>
            </Flex>
            <Flex justify="flex-end">
              <Button
                variant="ghost"
                mr={3}
                onClick={() => setOpenModal(false)}
                color="primary.500"
                fontWeight="600"
              >
                Cancel
              </Button>
              <Button colorScheme="primary" type="submit" isLoading={loading()}>
                {resource ? "Update" : "Add Resource"}
              </Button>
            </Flex>
          </Stack>
        </form>
      </>
    </SDrawer>
  );
};
