import { Button } from "@chakra-ui/react";

interface ClickableContainerProps {
  onClick: () => void;
  isDisabled?: boolean;
  children: JSX.Element;
}

const ClickableContainer = (props: ClickableContainerProps) => {
  const { onClick, isDisabled } = props;

  return (
    <Button
      variant="unstyled"
      isDisabled={isDisabled}
      onClick={() => onClick()}
      _focus={{ outline: "none" }}
    >
      {props.children}
    </Button>
  );
};

export default ClickableContainer;
