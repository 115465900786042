import { useState, useEffect } from "react";

import { Text, Td, Checkbox, Flex } from "@chakra-ui/react";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";

import Collapse from "./Collapse";
import { useProduct } from "../../apis/product";
import CountView from "../../packages/components/CountView";
import { STableRow } from "../../packages/components/STable";
import { useBusinessesForAProduct } from "../../apis/business";
import {
  Product,
  Notification,
  ProductFeature,
} from "../../packages/interfaces/product";

interface CustomRowProps {
  checked: boolean;
  product: Product;
  newFeature: Function;
  selectedFeatures: any;
  selectedResources: any;
  selectResource: Function;
  onNameClick?: () => void;
  onAddResource: () => void;
  selectedNotifications: any;
  selectedProductKey: string;
  newNotification: () => void;
  setSelectedFeatures: Function;
  selectProduct?: (product: Product) => void;
  setSelectedProductKey: (productKey: string) => void;
  selectFeature: (feature: ProductFeature, product: Product) => void;
  selectNotification: (notification: Notification, product: Product) => void;
}

export const CustomRow = ({
  product,
  newFeature,
  onNameClick,
  selectFeature,
  onAddResource,
  selectResource,
  checked = false,
  newNotification,
  selectedFeatures,
  selectedResources,
  selectNotification,
  selectedProductKey,
  setSelectedFeatures,
  selectedNotifications,
  setSelectedProductKey,
  selectProduct = () => {},
}: CustomRowProps) => {
  const { _id } = product;
  const [openCollapse, setOpenCollapse] = useState<string>();
  const businesses = useBusinessesForAProduct(_id || "");
  const productInfo: any = useProduct(_id || "");
  const {
    features = [],
    resources = [],
    productId,
    notifications = [],
  } = productInfo.data || product;

  useEffect(() => {
    if (!selectedProductKey) {
      setOpenCollapse("");
    }
  }, [selectedProductKey]);

  function handleToggleCollapse(tag: string) {
    setSelectedProductKey(_id ?? "");

    if (tag === openCollapse) {
      setOpenCollapse("");
    } else {
      if (tag === "resource") {
      } else if (tag === "business") {
      } else {
        setSelectedFeatures({});
      }

      switch (tag) {
        case "feature":
          if (!features.length) {
            newFeature({ product });
          } else {
            setOpenCollapse(tag);
          }
          break;
        case "resource":
          if (!resources.length) {
            onAddResource();
          } else {
            setOpenCollapse(tag);
          }
          break;
        case "notifications":
          if (!notifications.length) {
            newNotification();
          } else {
            setOpenCollapse(tag);
          }
          break;

        default:
          setOpenCollapse(tag);
      }
    }
  }

  const checkIfProductSelected = () => {
    return selectedProductKey === _id;
  };

  function renderBusinessCell() {
    return (
      <CountView
        subtitle="business"
        title={businesses?.data?.length}
        ArrowUpIcon={<IoMdArrowDropup />}
        ArrowDownIcon={<IoMdArrowDropdown />}
        arrowModifier={checkIfProductSelected() && openCollapse === "business"}
        onClick={() => handleToggleCollapse("business")}
      />
    );
  }

  function renderFeaturesCell() {
    return (
      <CountView
        subtitle="Features"
        title={features?.length}
        ArrowUpIcon={<IoMdArrowDropup />}
        ArrowDownIcon={<IoMdArrowDropdown />}
        arrowModifier={checkIfProductSelected() && openCollapse === "feature"}
        onClick={() => handleToggleCollapse("feature")}
      />
    );
  }

  function renderResourcesCell() {
    return (
      <CountView
        subtitle="Resources"
        title={resources?.length}
        ArrowUpIcon={<IoMdArrowDropup />}
        ArrowDownIcon={<IoMdArrowDropdown />}
        arrowModifier={checkIfProductSelected() && openCollapse === "resource"}
        onClick={() => handleToggleCollapse("resource")}
      />
    );
  }

  function renderNotificationCell() {
    return (
      <CountView
        subtitle="Notifications"
        title={notifications?.length}
        ArrowUpIcon={<IoMdArrowDropup />}
        ArrowDownIcon={<IoMdArrowDropdown />}
        arrowModifier={
          checkIfProductSelected() && openCollapse === "notifications"
        }
        onClick={() => handleToggleCollapse("notifications")}
      />
    );
  }

  return (
    <STableRow
      key={_id}
      checked={checked}
      onChangeCheck={() => selectProduct(product)}
      collapseOpen={openCollapse !== ""}
      collapseData={
        selectedProductKey === _id && (
          <Collapse
            product={product}
            open={openCollapse}
            features={features}
            resources={resources}
            newFeature={newFeature}
            onAddResource={onAddResource}
            selectFeature={selectFeature}
            subscription={businesses.data}
            selectResource={selectResource}
            newNotification={newNotification}
            selectedFeatures={selectedFeatures}
            selectedResources={selectedResources}
            selectNotification={selectNotification}
            selectedNotifications={selectedNotifications}
          />
        )
      }
    >
      <Td cursor="pointer">
        <Flex gridGap="30px">
          {checked !== undefined && (
            <Checkbox
              bg="white"
              paddingLeft="24px"
              isChecked={checked}
              borderRadius="12px"
              borderColor="primary.500"
              onChange={() => selectProduct(product)}
            />
          )}

          <Text onClick={onNameClick} fontSize="md">
            {productId}
          </Text>
        </Flex>
      </Td>
      <Td>{renderFeaturesCell()}</Td>
      <Td>{renderNotificationCell()}</Td>
      <Td>{renderResourcesCell()}</Td>
      <Td>{renderBusinessCell()}</Td>
    </STableRow>
  );
};
