import { useEffect, useState } from "react";

import { CSSObject } from "@emotion/react";
import Select, { OptionsType } from "react-select";
import { AddIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Wrap,
  Flex,
  Stack,
  Button,
  Center,
  Heading,
  useToast,
  CircularProgress,
} from "@chakra-ui/react";

import { useBusiness } from "../../apis/business";
import { sortByEntity } from "../../utils/helpers";
import { useGetLocationUsers } from "../../apis/users";
import { SInput } from "../../packages/components/SInput";
import { Business } from "../../packages/interfaces/business";

import {
  useGetSubscriptionByIds,
  useProductsForSubscription,
} from "../../apis/subscriptions";
import ClickableContainer from "../../packages/components/ClickableContainer";
import { getCurrentDate, objectsToArrays } from "../../packages/helpers/extra";
import {
  Product,
  UserBusiness,
  BusinessObject,
} from "../../packages/interfaces/customer";
import { showProductName } from "../../apis/product";
import { DelRedIcon, DelIcon, PlusIcon } from "../../utils/Icons";
import ErrorMessage from "../../packages/components/ErrorMessage";

export interface ErrorInterface {
  business: Record<string, string | boolean>;
}

interface AssignBusinessProps {
  userKey: string;
  error?: ErrorInterface;
  business?: UserBusiness[];
  setError: (value?: ErrorInterface) => void;
  setLoadings?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (business: typeof initialAssignedBusiness[]) => void;
}

const initialAssignedBusiness: BusinessObject = {
  businessKey: "",
  productIds: [],
};

export const AssignBusiness = (props: AssignBusinessProps) => {
  const { onChange, business, setLoadings, error, setError } = props;
  const structuredBusiness = business?.map((business) => {
    const { businessKey, _id, ...products } = business;
    const productIds = Object.keys(products).reduce((ids, currentProduct) => {
      //@ts-ignore string can't be used
      if (products[currentProduct]?.roleKey) {
        ids.push(currentProduct);
      }

      return ids;
    }, [] as string[]);

    return { businessKey, productIds };
  });

  const [assignedBusinesses, setAssignedBusinesses] = useState(
    structuredBusiness ?? [initialAssignedBusiness]
  );

  const { data, isLoading } = useBusiness();
  const businesses = data?.data ?? [];

  const [filteredBusiness, setFilteredBusiness] = useState(businesses);

  useEffect(() => {
    setFilteredBusiness(businesses);
  }, [data]);

  const assignedBusinessKeys = new Set(
    assignedBusinesses.map((business) => business.businessKey)
  );

  function handleBusinessChange(
    selectedBusiness: BusinessSelectOptions,
    index: number
  ) {
    const { value } = selectedBusiness;
    let updateBusiness = [...assignedBusinesses];
    updateBusiness[index] = { businessKey: value, productIds: [] };
    setAssignedBusinesses(updateBusiness);
  }

  function addOneMoreBusiness() {
    let updateBusiness = [...assignedBusinesses];
    updateBusiness.push(initialAssignedBusiness);
    setAssignedBusinesses(updateBusiness);
  }

  async function removeBusiness(businessKey: string, index?: number) {
    let updatedBusinesses = [...assignedBusinesses];

    if (businessKey) {
      updatedBusinesses = assignedBusinesses?.filter(
        (business) => business.businessKey !== businessKey
      );
    } else if (
      typeof index === "number" &&
      (assignedBusinesses?.length > 1 || (!businessKey && business))
    ) {
      updatedBusinesses.splice(index, 1);
    }
    setAssignedBusinesses(updatedBusinesses);
  }

  function handleSubscriptionsChange(
    businessKey: string,
    subscriptions: string[],
    businessIndex?: number
  ) {
    if (!subscriptions?.length) {
      removeBusiness(businessKey, businessIndex);
    } else {
      const updateBusiness = assignedBusinesses?.map((business) => {
        if (business.businessKey === businessKey) {
          return {
            ...business,
            productIds: subscriptions,
          };
        }
        return business;
      });
      setError(undefined);
      setAssignedBusinesses(updateBusiness);
    }
  }

  function filterBusiness() {
    const filterBusiness = businesses.filter(
      (business) => !assignedBusinessKeys.has(business._id ?? "")
    );
    setFilteredBusiness(filterBusiness);
  }

  useEffect(() => {
    onChange(assignedBusinesses);
    filterBusiness();
  }, [assignedBusinesses]);

  if (isLoading) {
    return (
      <Center>
        <CircularProgress isIndeterminate size={10} />
      </Center>
    );
  }

  return (
    <Box>
      {assignedBusinesses.map((eachBusinessAssigned, index) => (
        <EachSelectedBusiness
          index={index}
          error={error}
          setError={setError}
          setLoadings={setLoadings}
          existingBusiness={business}
          business={eachBusinessAssigned}
          filteredBusiness={filteredBusiness}
          assignedBusinesses={assignedBusinesses}
          handleBusinessChange={handleBusinessChange}
          key={`${eachBusinessAssigned.businessKey}${index}`}
          handleSubscriptionsChange={(
            businessKey: string,
            subscriptionKeys: string[]
          ) => handleSubscriptionsChange(businessKey, subscriptionKeys, index)}
        />
      ))}

      <ClickableContainer onClick={() => addOneMoreBusiness()}>
        <Wrap justify="center" align="center" color="primary.500">
          <PlusIcon />
          <Text color="primary.500">Assign to one more business</Text>
        </Wrap>
      </ClickableContainer>
    </Box>
  );
};

interface SelectSubscriptionsProps {
  products: string[];
  businessKey: string;
  subscriptions: string[];
  onChange: (e: string[]) => void;
  existingBusinesses: UserBusiness[] | undefined;
}

interface MultiSelectMap {
  label: string;
  value: string;
}

interface SelectedBusiness {
  businessKey: string;
  productIds: string[];
}

interface BussinessCard {
  setLoadings?: React.Dispatch<React.SetStateAction<boolean>> | any;
  index: number;
  business: SelectedBusiness;
  filteredBusiness: Business[];
  existingBusiness: UserBusiness[] | undefined;
  assignedBusinesses: SelectedBusiness[];
  handleBusinessChange: (
    selectedBusiness: BusinessSelectOptions,
    index: number
  ) => void;
  error?: ErrorInterface;
  setError: (value?: ErrorInterface) => void;
  handleSubscriptionsChange: (businessKey: string, keys: string[]) => void;
}

interface BusinessSelectOptions {
  label: string;
  value: string;
}

const EachSelectedBusiness = (props: BussinessCard) => {
  const {
    error,
    index,
    business,
    setLoadings,
    filteredBusiness,
    existingBusiness,
    assignedBusinesses,
    handleBusinessChange,
    handleSubscriptionsChange,
  } = props;

  const { data } = useBusiness();

  const [showingBusinesses, setShowingBusinesses] = useState<Business[]>([]);

  const [businessOptions, setBusinessOptions] = useState<
    BusinessSelectOptions[]
  >([]);

  const [getLocationUsers, getLocationUsersInfo] = useGetLocationUsers();
  const toast = useToast();

  useEffect(() => {
    const businesses = [...showingBusinesses];

    businesses?.sort((firstEntity, secondEntity) =>
      sortByEntity(firstEntity.businessName, secondEntity.businessName, "asc")
    );

    const businessListOptions = businesses.reduce(
      (activeBusiness: BusinessSelectOptions[], business) => {
        if (business.enabled === false) {
          return activeBusiness;
        }

        activeBusiness.push({
          label: business?.businessName,
          value: business?._id ?? "",
        });
        return activeBusiness;
      },
      []
    );
    setBusinessOptions(businessListOptions);
  }, [showingBusinesses]);

  useEffect(() => {
    const selectedBusiness =
      data?.data?.find((current) => current._id === business.businessKey) ??
      ({} as Business);

    if (objectsToArrays(selectedBusiness).length) {
      const isBusinessAlreadyExist = filteredBusiness.some(
        (current) => current._id === business.businessKey
      );
      if (!isBusinessAlreadyExist)
        setShowingBusinesses([...filteredBusiness, selectedBusiness]);
    } else setShowingBusinesses([...filteredBusiness]);
  }, [data, filteredBusiness, business]);

  function getSubscriptions(businessKey: string) {
    const business = data?.data?.find(
      (business) => business._id === businessKey
    );

    return business?.subscriptions ?? [];
  }

  const businessThatsSelected = () => {
    const selectedBusiness = showingBusinesses?.find(
      (eachBusiness) => eachBusiness._id === business?.businessKey
    );

    if (selectedBusiness) {
      return {
        label: selectedBusiness.businessName,
        value: selectedBusiness._id,
      };
    }
  };

  async function handleBusinessDelete(business: SelectedBusiness) {
    setLoadings(true);
    try {
      const existingBusinesses: any =
        existingBusiness?.find(
          (busines) => business.businessKey === busines.businessKey
        ) ?? {};

      const existingBusinessProductIds = Object.keys(existingBusinesses).reduce(
        (acc: string[], curr: any) => {
          if (existingBusinesses[curr]?.roleKey) {
            acc.push(curr);
          }
          return acc;
        },
        []
      );

      const resp = await Promise.all(
        existingBusinessProductIds.map((productId) => {
          return getLocationUsers({
            businessKey: business.businessKey,
            productId,
          });
        })
      );
      const areUsersMultiple = resp.some(
        (users) => users.data.data.users.length >= 2
      );

      if (existingBusinessProductIds?.length && !areUsersMultiple) {
        getLocationUsersInfo.reset();
        toast({
          duration: 3000,
          description: "Cannot remove subscription.",
        });
      } else {
        handleSubscriptionsChange(business.businessKey, []);
      }
      setLoadings(false);

      return;
    } catch (e) {
      setLoadings(false);
      throw e;
    }
  }
  return (
    <Box p="4" my="4" borderRadius="4" shadow="lg">
      <Flex justify="space-between">
        <Heading size="sm" color="altText.500" mb="4">
          Business {index + 1}
        </Heading>
        <Button
          size="sm"
          variant="none"
          colorScheme="red"
          leftIcon={
            assignedBusinesses?.length === 1 && !existingBusiness ? (
              <DelIcon />
            ) : (
              <DelIcon />
            )
          }
          isLoading={getLocationUsersInfo.isLoading}
          isDisabled={assignedBusinesses?.length === 1 && !existingBusiness}
          onClick={() => handleBusinessDelete(business)}
        ></Button>
      </Flex>
      <Stack gridGap="16px">
        <SInput
          type="children"
          name="select"
          value={businessThatsSelected() ?? ""}
          onChange={() => {}}
          placeholder="Business*"
          error={!business.businessKey && error?.business?.isError}
          errorMessage={
            <ErrorMessage
              message={
                error?.business?.isError && !business?.businessKey
                  ? String(error?.business?.errorMessage ?? "")
                  : ""
              }
            />
          }
        >
          <Box mt="1">
            <Select
              options={businessOptions}
              styles={{
                control: cssControls,
              }}
              // @ts-ignore
              onChange={(selectedBusiness: BusinessSelectOptions) =>
                handleBusinessChange(selectedBusiness, index)
              }
              value={businessThatsSelected()}
              menuPlacement="top"
              placeholder="Business*"
            />
          </Box>
          {/* <Select
            required={!existingBusiness}
            variant="unstyled"
            value={doesBusinessExist()}
            onChange={(e) => handleBusinessChange(index, e.target.value)}
          >
            <option disabled selected value="">
              Business
            </option>
            {showingBusinesses?.map((business) => {
              return (
                <option value={business._id} key={business._id}>
                  {business?.businessName}
                </option>
              );
            })}
          </Select> */}
        </SInput>
        <SelectSubscriptions
          products={business.productIds}
          businessKey={business.businessKey}
          subscriptions={getSubscriptions(business.businessKey)}
          onChange={(keys) =>
            handleSubscriptionsChange(business.businessKey, keys)
          }
          existingBusinesses={existingBusiness}
        />
      </Stack>
    </Box>
  );
};

const SelectSubscriptions = (props: SelectSubscriptionsProps) => {
  const {
    businessKey,
    subscriptions,
    onChange,
    products: assignedProducts,
    existingBusinesses,
  } = props;

  const toast = useToast();

  const [getLocationUsers, getLocationUsersInfo] = useGetLocationUsers();

  const { data } = useProductsForSubscription();
  const productsList = data?.data;
  const { data: subscriptionsList = [] } = useGetSubscriptionByIds(
    subscriptions ?? []
  );

  const [subscriptionsMap, setSubscriptionsMap] = useState<MultiSelectMap[]>(
    []
  );
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<
    OptionsType<MultiSelectMap>
  >([]);

  useEffect(() => {
    const multiSelectSubscriptionsMap = subscriptionsList?.reduce(
      (multiSelectArray, subscription) => {
        if (
          subscription?.endDate &&
          subscription?.endDate < getCurrentDate("number")
        ) {
          return multiSelectArray;
        }

        const productName = showProductName(
          subscription?.productKey,
          productsList
        );
        if (productName !== "-") {
          multiSelectArray.push({ label: productName, value: productName });
        }
        return multiSelectArray;
      },
      [] as MultiSelectMap[]
    );
    setSubscriptionsMap(multiSelectSubscriptionsMap);
  }, [subscriptionsList.length]);

  const handleSubscriptionChange = (
    subscriptionsSelected: OptionsType<MultiSelectMap>
  ) => {
    setSelectedSubscriptions(subscriptionsSelected);
    const productIds = subscriptionsSelected?.map(
      (subscription) => subscription.value
    );
    onChange(productIds);
  };

  const updateSelectedSubscription = (productId: string) => {
    const updatedSubscriptions = selectedSubscriptions?.filter(
      (subscription) => subscription.value !== productId
    );

    const productIds = updatedSubscriptions?.map(
      (subscription) => subscription.label
    );
    onChange(productIds);

    setSelectedSubscriptions(updatedSubscriptions);
  };

  async function handleDeleteSubscription(productId: Product) {
    const existingBusiness = existingBusinesses?.find(
      (business) => businessKey === business.businessKey
    );

    if (existingBusiness?.[productId]?.roleKey) {
      try {
        const resp = await getLocationUsers({ businessKey, productId });
        const { success, data } = resp ?? {};
        if (success && data.success) {
          const {
            data: { users },
          } = data;
          if (users.length < 2) {
            throw new Error("Cannot remove subscription.");
          }

          updateSelectedSubscription(productId);
        }
      } catch (error) {
        getLocationUsersInfo.reset();
        toast({
          duration: 3000,
          description: "Cannot remove subscription.",
        });
      }
    } else {
      updateSelectedSubscription(productId);
    }
  }

  useEffect(() => {
    if (assignedProducts.length > 0) {
      setSelectedSubscriptions(
        assignedProducts.map((product) => ({ label: product, value: product }))
      );
    }
  }, []);

  function showSelectedSubscriptions() {
    if (getLocationUsersInfo.isLoading) {
      return (
        <Center>
          <CircularProgress isIndeterminate size="24px" />
        </Center>
      );
    }
    return (
      <Wrap>
        {selectedSubscriptions?.map((subscription, index: number) => {
          const { label, value: productId } = subscription;

          return (
            <Flex
              p="1"
              border="1px"
              borderRadius="md"
              alignItems="center"
              key={productId}
            >
              <Text fontSize="xs" margin="4px 4px 4px 8px">
                {label}
              </Text>
              <Button
                size="xs"
                variant="unstyled"
                _focus={{ outline: "none" }}
                onClick={() => handleDeleteSubscription(productId as Product)}
              >
                <SmallCloseIcon />
              </Button>
            </Flex>
          );
        })}
      </Wrap>
    );
  }

  return (
    <>
      <SInput
        type="children"
        name="select"
        value={selectedSubscriptions.length ?? ""}
        onChange={() => {}}
        placeholder="Select subscription"
      >
        <Box mt="1">
          <Select
            styles={{
              control: cssControls,
            }}
            isMulti
            noOptionsMessage={() => null}
            closeMenuOnSelect={false}
            options={subscriptionsMap}
            value={selectedSubscriptions}
            controlShouldRenderValue={false}
            onChange={handleSubscriptionChange}
            isClearable={false}
            placeholder="Subscriptions*"
          />
        </Box>
      </SInput>
      {showSelectedSubscriptions()}
    </>
  );
};

function cssControls(base: CSSObject) {
  return {
    ...base,
    border: 0,
    boxShadow: "none",
  };
}
