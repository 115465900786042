import { useContext, useEffect } from "react";

import { useHistory } from "react-router";
import { Center } from "@chakra-ui/layout";
import { CircularProgress } from "@chakra-ui/progress";

import { AuthContext } from "./AuthContext";
import { useQuery } from "../../packages/hooks/useQuery";
import {
  logOut,
  saveTokenFromQuery,
  getAuthenticatedUser,
} from "../../apis/auth";

export const AuthenticateUser = () => {
  const query = useQuery();

  const { push } = useHistory();

  const userKeyFromQuery = query.get("userKey");
  const accessTokenFromQuery = query.get("accessToken");
  const refreshTokenFromQuery = query.get("refreshToken");

  const { userKey, setUserKey } = useContext(AuthContext);

  useEffect(() => {
    if (userKeyFromQuery && accessTokenFromQuery && refreshTokenFromQuery) {
      saveTokenFromQuery(
        accessTokenFromQuery,
        refreshTokenFromQuery,
        userKeyFromQuery
      );
      setUserKey(userKeyFromQuery);
    } else {
      getAuthenticatedUser((userKeyFromLocalStorage: string) => {
        if (userKeyFromLocalStorage) {
          setUserKey(userKeyFromLocalStorage);
        } else {
          setUserKey(null);
        }
      });
    }
    //eslint-disable-next-line
  }, []);

  if (userKey === undefined) {
    return (
      <Center h="xl">
        <CircularProgress />
      </Center>
    );
  }

  if (!userKey) {
    logOut();
  }

  if (userKey) {
    push("/");
  }

  return null;
};
