import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  sizes: {
    md: "34px",
    vs: "8px",
    s: "12px",
  },
  colors: {
    primary: {
      200: "#65CAF0",
      300: "#006EB8",
      500: "#006DB8",
      700: "#00AEEF",
      800: "#17244f",
    },
    text: {
      500: "#323A45",
      600: "#17244F",
      700: "#A1A2A6",
      800: "#006DB8",
      900: "#17244F80",
    },
    altText: { 500: "#AFB0B4" },
    background: { 500: "#FBF9FB", 600: "#F1F1F1", 700: "#F4F4F4" },
    highlight: { 500: "#162350" },
    icon: {
      500: "#A2A7B9",
      600: "#D6D7D9",
    },
    lightBlue: "#02aeef",
    blue: {
      500: "#006DB8",
    },
  },
  fonts: {
    heading: "montserrat",
    body: "montserrat",
    p: "montserrat",
    text: "montserrat",
  },
  components: {
    Text: {
      baseStyle: {
        color: "highlight.500",
        fontFamily: "Montserrat",
        fontWeight: 400,
      },
    },
    Heading: {
      baseStyle: {
        color: "highlight.500",
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: 20,
      },
    },
    Radio: {
      baseStyle: {
        fontWeight: 400,
        color: "text.500",
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 500,
        fontSize: 14,
      },
    },
    Drawer: {
      variants: {
        alwaysOpen: {
          dialog: {
            pointerEvents: "auto",
          },
          dialogContainer: {
            pointerEvents: "none",
          },
        },
      },
    },
  },
});

export const size = {
  icon: "20px",
};
