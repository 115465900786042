import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { Image } from "@chakra-ui/image";
import { CircularProgress } from "@chakra-ui/progress";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs";
import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/layout";

import logo from "../../assets/logo.svg";
import { MenuBarName } from "../../enums/menu";
import { useBusinessDevices } from "../../apis/devices";
import { BusinessSuperadmin } from "./BusinessSuperadmin";
import { Business } from "../../packages/interfaces/business";
import { DeviceEnum } from "../../packages/interfaces/device";
import { firstLetterUpperCase } from "../../packages/helpers/strings";
import {
  useGetSubscriptionByIds,
  useProductsForSubscription,
} from "../../apis/subscriptions";

interface DetailsProps {
  business: Business;
}

const { CustomerAdmin } = MenuBarName;

interface SubcriptionDateWithProductName {
  productName: string;
  startDate: string;
  endDate: string;
}

const businessDetailsTabs = [
  "Details",
  "Devices",
  CustomerAdmin,
  "Billing details",
];

const { BridgeT, EnvironT } = DeviceEnum;

const GetStartAndEndDate = (subscriptionKey: Array<string>) => {
  const { data } = useProductsForSubscription();
  const products = data?.data;
  const { data: subscriptionLists } = useGetSubscriptionByIds(subscriptionKey);

  const subscriptionDate = subscriptionLists?.reduce(
    (acc: SubcriptionDateWithProductName[], subscriptionList) => {
      const product = products?.find(
        (product) => product._id === subscriptionList.productKey
      );

      const productName = product?.productId ?? "-";

      const startDate = dayjs(subscriptionList.startDate).format("MMM D, YYYY");
      const endDate = dayjs(subscriptionList.endDate).format("MMM D, YYYY");
      acc.push({ productName, startDate, endDate });
      return acc;
    },
    []
  );

  return subscriptionDate;
};

export const Details = (props: DetailsProps) => {
  const {
    businessAddress,
    billingDetails,
    subscriptions,
    _id: businessKey,
    customerSuperAdminUsers,
  } = props?.business;

  const subscriptionStartAndEndDate = GetStartAndEndDate(subscriptions ?? []);

  const Details = () => {
    return (
      <Stack spacing="4">
        <Image w="40%" my="8" src={logo} />
        <Box height="56px">
          <Heading as="h4" size="22px" color="blue.600">
            Address
          </Heading>
          <Text>{businessAddress}</Text>
        </Box>
        <Divider />
        <Box>
          <Heading as="h4" size="22px" color="blue.600">
            Subscriptions
          </Heading>
          {subscriptionStartAndEndDate?.map((date: any) => {
            return (
              <Stack spacing={3} key={date.productName}>
                <Box>
                  <Heading as="h6" size="18px">
                    {firstLetterUpperCase(date.productName)}
                  </Heading>
                  <Text size="16px">
                    Subscription period start date - {date.startDate}
                  </Text>
                  <Text size="16px">
                    Subscription period end date - {date.endDate}
                  </Text>
                </Box>
                <Divider />
              </Stack>
            );
          })}
        </Box>
      </Stack>
    );
  };

  const BillingDetails = () => {
    return (
      <Stack spacing="4">
        <Box>
          <Heading as="h4" size="sm" color="blue.600">
            Name
          </Heading>
          <Text>{billingDetails?.billingName}</Text>
        </Box>
        <Divider />
        <Box>
          <Heading as="h4" size="sm" color="blue.600">
            Address
          </Heading>
          <Text>{billingDetails?.billingAddress}</Text>
        </Box>
        <Divider />
        <Box>
          <Heading as="h4" size="sm" color="blue.600">
            Email
          </Heading>
          <Text>{billingDetails?.billingEmail}</Text>
        </Box>
        <Divider />
        <Box>
          <Heading as="h4" size="sm" color="blue.600">
            Phone Number
          </Heading>
          <Text>{billingDetails?.billingPhone}</Text>
        </Box>
        <Divider />
      </Stack>
    );
  };

  return (
    <Tabs isFitted>
      <TabList>
        {businessDetailsTabs?.map((tab) => (
          <Tab fontWeight="bold" color="altText.500" key={tab}>
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        <TabPanel>
          <Details />
        </TabPanel>
        {businessKey && (
          <TabPanel>
            <BusinessDevices businessKey={businessKey} />
          </TabPanel>
        )}
        <TabPanel>
          <BusinessSuperadmin
            businessKey={businessKey ?? ""}
            superadmins={customerSuperAdminUsers}
          />
        </TabPanel>
        <TabPanel>
          <BillingDetails />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

interface BusinessDevicesProps {
  businessKey: string;
}

interface EachDevice {
  device: string;
  sensors: string[];
}

interface FormattedDevices {
  name: string;
  devices: EachDevice[];
}

const getDeviceName = (name: string) => {
  switch (name) {
    case "bridge_t_devices":
      return BridgeT;

    case "environ_t_devices":
      return EnvironT;

    case "marker_devices":
      return "Marker";

    default:
      return "";
  }
};

const BusinessDevices = (props: BusinessDevicesProps) => {
  const { businessKey } = props;

  const { data } = useBusinessDevices(businessKey);

  const [devicesByDeviceType, setDevicesByDeviceType] = useState<
    FormattedDevices[] | undefined
  >();

  useEffect(() => {
    const formatDocument = () => {
      const allDevices = data?.data?.data?.data;

      const newFormattedDevices = Object.keys(allDevices ?? {}).map(
        (device) => {
          if (device === "marker_devices") {
            return {
              name: device,
              devices: allDevices[device],
            };
          }
          const devices = Object.keys(allDevices?.[device] ?? {}).map(
            (eachDeviceID) => {
              return {
                device: eachDeviceID,
                sensors: allDevices[device][eachDeviceID],
              };
            }
          );
          return {
            name: device,
            devices,
          };
        }
      );

      setDevicesByDeviceType(newFormattedDevices);
    };
    if (data?.data.success) {
      formatDocument();
    }
  }, [data]);

  if (!devicesByDeviceType) {
    return (
      <Center>
        <CircularProgress color="blue.500" isIndeterminate />
      </Center>
    );
  }

  return (
    <Flex flexDirection="column">
      <>
        <Text color="blue.500" fontWeight="bold" fontSize="22px">
          Gateways and Sensors
        </Text>
        {devicesByDeviceType?.map((devices) => {
          return <DeviceDetails devicesByDeviceType={devices} />;
        })}
      </>
    </Flex>
  );
};

const DeviceDetails = ({
  devicesByDeviceType: devices,
}: {
  devicesByDeviceType: FormattedDevices;
}) => {
  const deviceType = getDeviceName(devices?.name ?? "");
  const isDeviceTypeAGateway =
    deviceType === BridgeT || deviceType === EnvironT;

  if (isDeviceTypeAGateway) {
    return (
      <>
        {devices?.devices?.map((device) => {
          return (
            <Flex flexDirection="column" gridGap="2">
              <Flex flexDirection="column">
                <Text fontWeight="500">{deviceType}</Text>
                <Text fontSize="16px">{device.device}</Text>
              </Flex>
              {device?.sensors?.length ? (
                <Flex flexDirection="column">
                  <Text fontWeight="500">Assigned Beacon-T</Text>
                  <Text>{device.sensors?.join(", ")}</Text>
                </Flex>
              ) : (
                <Text>{device.sensors?.join(", ")}</Text>
              )}
              <Divider />
            </Flex>
          );
        })}
      </>
    );
  } else if (devices?.devices?.length) {
    return (
      <Flex>
        <Text>{deviceType}</Text>
        <Text>{devices.devices.join(", ")}</Text>
      </Flex>
    );
  }

  return <div />;
};
