import { useMutation, useQuery, useQueryCache } from "react-query";

import network from "./network";
import { GetRolesResponse } from "../packages/interfaces/role";

const rolesUri = "usermgmt/roles";

export interface ActionInterface {
  [action: string]: boolean;
}

async function createRole(newRole: any) {
  return (await network()).post(`/${rolesUri}/`, newRole);
}

export function useCreateRole() {
  const queryCache = useQueryCache();

  return useMutation(async (newRole: any) => createRole(newRole), {
    onSuccess: () => {
      queryCache.invalidateQueries(["roles"]);
    },
    onError: (e: any) => {
      throw e;
    },
  });
}

async function getRolesByBusiness(
  business: string[]
): Promise<GetRolesResponse> {
  const resp = await (
    await network()
  ).post(`/${rolesUri}/filterByBusiness`, {
    business,
    productId: "superadmin",
  });
  return resp.data;
}

export function useGetRolesByBusiness(business: string[]) {
  const queryCache = useQueryCache();

  return useQuery(["roles"], () => getRolesByBusiness(business), {
    onSuccess: (data) => {
      const roles = data.data.businesRoles ?? {};
      Object.keys(roles).map((businessKey: string) =>
        queryCache.setQueryData(["roles", businessKey], roles[businessKey])
      );
    },
    onError: (e: any) => {
      throw e;
    },
    enabled: business.length,
  });
}

interface EditRole {
  roleKey: string;
  updatedRole: any;
}

async function editRole({ roleKey, updatedRole }: EditRole) {
  return (await network()).put(`/${rolesUri}/${roleKey}`, updatedRole);
}

export function useEditRole() {
  const queryCache = useQueryCache();

  return useMutation(async (update: EditRole) => editRole(update), {
    onSuccess: () => {
      queryCache.invalidateQueries(["roles"]);
    },
    onError: (e: any) => {
      throw e;
    },
  });
}

async function deleteRole(roleKey: string) {
  return (await network()).delete(`/${rolesUri}/${roleKey}`);
}

export function useDeleteRole() {
  const queryCache = useQueryCache();

  return useMutation(async (roleKey: string) => deleteRole(roleKey), {
    onSuccess: () => {
      queryCache.invalidateQueries(["roles"]);
    },
    onError: (e: any) => {
      throw e;
    },
  });
}
