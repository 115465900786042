import React from "react";

import { Flex } from "@chakra-ui/react";

export default function Page({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      flex={3}
      p="60px"
      width="100%"
      gridGap="28px"
      overflow="scroll"
      bg="background.500"
      paddingBottom="150px"
      flexDirection="column"
    >
      {children}
    </Flex>
  );
}
