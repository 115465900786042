import { DeviceDetails } from "./device";

export enum featureTypeEnum {
  NUMBER = "number",
  BOOLEAN = "boolean",
  SIZE = "size",
  DROPDOWN = "dropdown"
}

export enum INTEGER_TYPE_FEATURES {
  FEATURE_MAX_LOCATIONS = "FEATURE_MAX_LOCATIONS",
  FEATURE_MAX_EQUIPMENTS_PER_LOCATION = "FEATURE_MAX_EQUIPMENTS_PER_LOCATION",
  FEATURE_MAX_DEVICES_PER_GATEWAY = "FEATURE_MAX_DEVICES_PER_GATEWAY",
  FEATURE_MAX_USERS_PER_SUBSCRIPTION = "FEATURE_MAX_USERS_PER_SUBSCRIPTION"
}

export type featureType =
  | featureTypeEnum.NUMBER
  | featureTypeEnum.BOOLEAN
  | featureTypeEnum.SIZE
  | featureTypeEnum.DROPDOWN;

export interface ProductFeature {
  _id?: string;
  name: string;
  type: featureType;
  featureId: string;
  isActive: boolean;
  minValue?: number;
  maxValue?: number;
  description?: string;
  defaultValue?: number | boolean | string;
  dropdownValues?: any[]
}

export interface Resource {
  _id?: string;
  isActive?: boolean;
  resourceId?: string;
  actions: Array<string>;
}

export interface Notification {
  name: string;
  _id?: string;
  description?: string;
  notificationId: string;
}

export interface Product {
  isActive: boolean;
  productId: string;
  features: Array<ProductFeature>;
  resources: Array<Resource>;
  notifications?: Array<Notification>;
  productDescription: string;
  deviceAssignmentTypes: Array<DeviceDetails>;
  subscriptions?: string[];

  _id?: string;
}

export const defaultProduct: Product = {
  productId: "",
  isActive: true,
  features: [],
  resources: [],
  productDescription: "",
  notifications: [],
  deviceAssignmentTypes: [],
  _id: undefined,
};

export interface NotificationRequestParams {
  notificationBody: Notification;
  productKey: string;
}


