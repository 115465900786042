import { useEffect, useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  Text,
  Wrap,
  Stack,
  Center,
  Divider,
  Heading,
  TabList,
  TabPanel,
  TabPanels,
  IconButton,
} from "@chakra-ui/react";

import { CustomRow } from "./CustomRow";
import { useDevices, useGetSensorsByGateway } from "../../apis/devices";
import Page from "../../packages/components/Page";
import { useBusiness } from "../../apis/business";
import { sortByEntity } from "../../utils/helpers";
import { SLoader } from "../../packages/components/SLoader";
import { SDrawer } from "../../packages/components/SDrawer";
import { ArrowBackIcon, SearchIcon } from "@chakra-ui/icons";
import { arrayToObjects } from "../../packages/helpers/extra";
import { SearchBar } from "../../packages/components/SearchBar";
import { Device as DeviceType } from "../../packages/interfaces/device";
import { HeaderInterface, STable } from "../../packages/components/STable";
import { useDebouncedCallback } from "../../packages/hooks/useDebouncedCallback";
import {
  firstLetterUpperCase,
  lowerCaseLetter,
} from "../../packages/helpers/strings";
import { filters } from "./devicesDetails";

interface DeviceProps {
  title: string;
  filterBy: string;
  setView: Function;
}

const deviceHeaders: HeaderInterface[] = [
  { name: "id", sort: "desc" },
  { name: "business", sort: "desc" },
];

export const Device = (props: DeviceProps) => {
  const { filterBy, title, setView } = props;

  const [infoCellHeader, setInfoCellHeader] = useState<HeaderInterface>({
    name: "",
  });
  const [headers, setHeaders] = useState(deviceHeaders);

  useEffect(() => {
    if (
      filterBy !== "sensor_marker" &&
      filterBy !== "sensor_marker_i" &&
      filterBy !== "sensor_marker_x"
    ) {
      setHeaders([...deviceHeaders, { ...infoCellHeader, sort: "desc" }]);
    }
  }, [infoCellHeader, filterBy]);

  const { data, isLoading } = useDevices({ filterBy }, true);

  const [openModal, setOpenModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<DeviceType>();
  const [searchText, setSearchText] = useState<string>("");

  const [devices, setDevices] = useState<DeviceType[]>();

  const [businessList, setBusinessList] = useState<any>({});

  const getSortedDevices = (
    devicesAsParam: DeviceType[],
    sort: "desc" | "asc"
  ) => {
    return devicesAsParam?.sort((firstDevice, secondDevice) => {
      const firstBusinessName = getBusinessName(firstDevice.businessKey);
      const secondBusinessName = getBusinessName(secondDevice?.businessKey);

      const lowerCasedFirstBusinessName = lowerCaseLetter(firstBusinessName);
      const lowerCasedSecondBusinessName = lowerCaseLetter(secondBusinessName);

      return sortByEntity(
        lowerCasedFirstBusinessName,
        lowerCasedSecondBusinessName,
        sort
      );
    });
  };
  const getSortedBusinessID = (
    devicesAsParam: DeviceType[],
    sort: "desc" | "asc"
  ) => {
    return devicesAsParam?.sort((firstDevice, secondDevice) => {
      const firstBusinessID = firstDevice.deviceID;
      const secondBusinessID = secondDevice.deviceID;

      const lowerCasedFirstBusinessID = lowerCaseLetter(firstBusinessID);
      const lowerCasedSecondBusinessID = lowerCaseLetter(secondBusinessID);

      return sortByEntity(
        lowerCasedFirstBusinessID,
        lowerCasedSecondBusinessID,
        sort
      );
    });
  };

  const getSortedByTSensors = (
    devicesAsParam: DeviceType[],
    sort: "desc" | "asc"
  ) => {
    devicesAsParam.sort((firstDevice, secondDevice) => {
      const numberOfSensorsInFirst = firstDevice?.count;
      const numberOfSensorsInSecond = secondDevice?.count;

      return sortByEntity(
        numberOfSensorsInFirst,
        numberOfSensorsInSecond,
        sort
      );
    });

    return devicesAsParam;
  };

  useEffect(() => {
    const filteredDevices: DeviceType[] = (data?.data ?? []).filter(
      (device: any) => {
        return device.deviceID
          .toLowerCase()
          .includes(searchText.trim().toLowerCase());
      }
    );

    const { sort = "desc" } =
      headers?.find((header) => header.name === "business") ?? {};

    const sortedDevices = getSortedDevices(filteredDevices, sort);

    setDevices(sortedDevices);
  }, [data, searchText, businessList]);

  const debouncedOnChange = useDebouncedCallback(
    (e) => setSearchText(e.target.value),
    400
  );

  const { data: sensorsResponse } = useGetSensorsByGateway(
    selectedDevice?.deviceID ?? "",
    openModal
  );
  const sensors = sensorsResponse?.data?.sensor ?? [];

  const { data: businesses } = useBusiness();

  useEffect(() => {
    const mappedBusiness = arrayToObjects(businesses?.data ?? []);
    setBusinessList(mappedBusiness);
    showHeaderInfoCell();
  }, [businesses]);

  function showHeaderInfoCell() {
    switch (filterBy) {
      case "sensor_beacon_t":
        setInfoCellHeader({ name: "Assigned Bridge-T" });
        break;
      case "gateway_bridge_t":
        setInfoCellHeader({ name: "Associated T-sensors" });
        break;
      case "gateway_environ_t":
        setInfoCellHeader({ name: "Associated T-Sensors" });
        break;
    }
  }

  function handleOnNameClick(selectedDevice: DeviceType) {
    if (filterBy === filters.gateways.bridge) {
      setSelectedDevice(selectedDevice);
      setOpenModal(true);
    } else if (filterBy === filters.gateways.environ) {
      setSelectedDevice(selectedDevice);
      setOpenModal(true);
    }
  }

  function getBusinessName(businessKey: string) {
    return businessList[businessKey]?.businessName ?? "";
  }

  const handleHeaderClick = (header: HeaderInterface, headerIndex: number) => {
    let sortedDevices: DeviceType[];

    const { sort } = header;

    const newSort = sort === "desc" ? "asc" : "desc";

    switch (header.name) {
      case "business":
        sortedDevices = getSortedDevices([...(devices ?? [])], newSort);
        break;
      case "id":
        sortedDevices = getSortedBusinessID([...(devices ?? [])], newSort);
        break;

      default:
        sortedDevices = getSortedByTSensors([...(devices ?? [])], newSort);
        break;
    }

    let updatedHeaders = [...headers];

    updatedHeaders.splice(headerIndex, 1, {
      name: header.name,
      sort: newSort,
    });

    setHeaders(updatedHeaders);

    setDevices(sortedDevices);
  };

  if (isLoading) {
    return (
      <Center h="100%">
        <SLoader size="lg" />
      </Center>
    );
  }

  return (
    <Page>
      <Wrap spacing="8">
        <IconButton
          aria-label="back"
          variant="ghost"
          onClick={() => setView("")}
        >
          <ArrowBackIcon />
        </IconButton>
        <Heading as="h1" size="lg">
          {title}
        </Heading>
      </Wrap>
      <Box maxW="40%" my={4}>
        <SearchBar
          value={searchText}
          title="Search Devices"
          rightIcon={<SearchIcon color="gray" />}
          onChange={(e) => {
            setSearchText(e.target.value);
            debouncedOnChange(e);
          }}
          onClose={() => setSearchText("")}
        />
      </Box>
      <STable headers={headers} handleHeaderClick={handleHeaderClick}>
        {devices?.map((device, index: number) => (
          <CustomRow
            key={index}
            device={device}
            filterBy={filterBy}
            showBusinessName={getBusinessName}
            onNameClick={() => handleOnNameClick(device)}
          />
        ))}
      </STable>
      <SDrawer
        open={openModal}
        title={selectedDevice?.deviceID ?? ""}
        negativeAction={() => setOpenModal(false)}
      >
        <Tabs isFitted>
          <TabList>
            <Tab fontWeight="bold" color="altText.500">
              Details
            </Tab>
            <Tab fontWeight="bold" color="altText.500">
              {infoCellHeader.name}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Stack spacing="4">
                <Box>
                  <Heading as="h4" size="sm" color="blue.600">
                    Device Family
                  </Heading>
                  <Text>
                    {firstLetterUpperCase(selectedDevice?.deviceFamily ?? "")}
                  </Text>
                </Box>
                <Divider />
                <Box>
                  <Heading as="h4" size="sm" color="blue.600">
                    Device Type
                  </Heading>
                  <Text>
                    {firstLetterUpperCase(selectedDevice?.deviceType ?? "")}
                  </Text>
                </Box>
                <Divider />
                <Box>
                  <Heading as="h4" size="sm" color="blue.600">
                    Wi-Fi SSID
                  </Heading>
                  <Text>In-store</Text>
                </Box>
                <Divider />
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack spacing="4">
                {!sensors.length ? (
                  <Text>No sensors</Text>
                ) : (
                  sensors.map((sensor: string) => <Text>{sensor}</Text>)
                )}
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </SDrawer>
    </Page>
  );
};
