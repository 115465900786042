import { ChangeEvent, ReactElement, MouseEvent } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";

import { CloseIcon } from "../../utils/Icons";

interface SearchBarProps {
  title: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClose?: (event: MouseEvent<HTMLDivElement>) => void;
  rightIcon?: ReactElement;
}

export const SearchBar = (props: SearchBarProps) => {
  const { title, value, onChange, rightIcon, onClose } = props;
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none" children={rightIcon} />
      <Input
        size="sm"
        height="40px"
        value={value}
        variant="filled"
        borderRadius="8px"
        placeholder={title}
        bg="background.600"
        onChange={onChange}
      />
      {value && (
        <InputRightElement mx="4" cursor="pointer" onClick={onClose} w="4">
          <CloseIcon />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
