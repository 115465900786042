import { useEffect, useState } from "react";
import {
  FieldError,
  useForm,
  UseFormRegister,
  UseFormUnregister,
} from "react-hook-form";
import {
  Box,
  Flex,
  Text,
  Input,
  Radio,
  Stack,
  Button,
  Switch,
  useToast,
  RadioGroup,
  FormControl,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";

import { SDrawer } from "../../packages/components/SDrawer";
import { useAddFeature, useUpdateFeature } from "../../apis/product";
import {
  Product,
  ProductFeature,
  featureTypeEnum,
  featureType,
} from "../../packages/interfaces/product";
import { SInput } from "../../packages/components/SInput";
import { isUpperCase, lowerCaseLetter } from "../../packages/helpers/strings";
import ErrorMessage from "../../packages/components/ErrorMessage";

import { toastConfigs } from "../../packages/helpers/extra";
import { DeepMap } from "react-hook-form";

const featureSkeleton: ProductFeature = {
  type: featureTypeEnum.NUMBER,
  featureId: "",
  isActive: true,
  name: "",
};

interface AddFeatureProps {
  product?: Product;
  setOpenModal: Function;
  feature?: ProductFeature;
  open: boolean;
}

export function AddProductFeature({
  product,
  feature,
  setOpenModal,
  open,
}: AddFeatureProps) {
  const {
    unregister,
    register,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: feature,
  });

  const [newFeature, setNewFeature] = useState(feature || featureSkeleton);
  const [addFeature, addFeatureInfo] = useAddFeature();
  const [updateFeature, updateFeatureInfo] = useUpdateFeature();

  const toast = useToast(toastConfigs);
  useEffect(() => {
    if (feature) {
      setNewFeature({ ...feature });
    } else {
      setNewFeature({
        ...featureSkeleton,
      });
    }
  }, [feature, open]);

  function handleInputChange(field: string, value: any) {
    setNewFeature({ ...newFeature, [field]: value });
  }

  function handleSizeChange(size: number) {
    setNewFeature({ ...newFeature, defaultValue: parseInt(size.toString()) });
  }

  function addNewvalueToDropdown(number: any) {
    const existingValues = newFeature.dropdownValues || [];
    let defaultValue = newFeature.defaultValue;
    if (!existingValues.includes(number)) {
      existingValues.push(number);
    }
    if(existingValues.length === 1){
      defaultValue = existingValues[0];
    }
    setNewFeature({ ...newFeature, dropdownValues: existingValues, defaultValue });
  }

  function selectDropdownValue(number: any) {
    setNewFeature({ ...newFeature, defaultValue: number });
  }

  async function submitFeature() {
    if (feature) {
      try {
        await updateFeature({
          productKey: product?._id || "",
          feature: { ...newFeature },
        });
        toast({
          status: "success",
          description: `Feature updated successfully.`,
        });
      } catch (e) {
        toast({
          status: "error",
          description: "Cannot update the selected feature.",
        });
      }
    } else {
      try {
        await addFeature({
          productKey: product?._id || "",
          feature: { ...newFeature },
        });

        toast({
          description: `Feature created successfully.`,
          status: "success",
        });
      } catch (e) {
        toast({
          description: e?.response?.data?.message ?? "Cannot add feature.",
          status: "error",
        });
      }
    }
    setOpenModal("");
  }

  function handleCancelAction() {
    setNewFeature({
      ...featureSkeleton,
    });
    setOpenModal("");
  }

  async function handleRadioButtonChange(value: featureType) {
    let updatedFeature = {} as ProductFeature;
    if (errors.type) {
      await trigger("type");
    }

    switch (value) {
      case "boolean":
        updatedFeature = {
          defaultValue: false,
          type: value,
          isActive: true,
          name: newFeature.name,
          featureId: newFeature.featureId,
          description: newFeature.description,
        };
        break;

      case "size":
        updatedFeature = {
          defaultValue: 1,
          type: value,
          isActive: true,
          featureId: newFeature.featureId,
          name: newFeature.name,
          description: newFeature.description,
        };
        break;

      case "dropdown":
        updatedFeature = {
          defaultValue: '',
          type: value,
          isActive: true,
          featureId: newFeature.featureId,
          name: newFeature.name,
          description: newFeature.description,
          dropdownValues: newFeature.dropdownValues || [],
        };
        break;

      default:
        updatedFeature = {
          type: value,
          isActive: true,
          name: newFeature.name,
          featureId: newFeature.featureId,
          description: newFeature.description,
        };
        break;
    }
    setNewFeature({ _id: newFeature._id, ...updatedFeature });
  }

  const getTitle = (feature?: ProductFeature) => {
    const action = feature?._id ? "Edit" : "Add";
    return `${action} Feature`;
  };

  function checkIfDuplicateExist(featureId: string) {
    const isDuplicateExist = product?.features?.some(
      (feature) =>
        lowerCaseLetter(feature?.featureId) === lowerCaseLetter(featureId)
    );
    const isSameFeatureId = feature?.featureId === featureId;
    return isDuplicateExist && !isSameFeatureId;
  }

  function validateFeatureId() {
    if (!newFeature?.featureId) return "Feature Id is required.";
    if (
      newFeature?.featureId?.includes(" ") ||
      !isUpperCase(newFeature?.featureId)
    ) {
      return "Must be all capital letters with no spaces";
    }
    if (checkIfDuplicateExist(newFeature?.featureId)) {
      return "Name already exists";
    }
  }

  return (
    <SDrawer
      open={open}
      title={getTitle(feature)}
      showActionButtons={false}
      negativeAction={handleCancelAction}
    >
      <>
        <form onSubmit={handleSubmit(submitFeature)}>
          <Stack spacing="4">
            <Text fontWeight="bold" fontSize="22px">
              Details
            </Text>
            <SInput
              type="text"
              isRequired
              name="name"
              value={newFeature.name}
              placeholder="Feature Name*"
              defaultValue={newFeature.name}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            />
            <SInput
              type="text"
              name="featureId"
              isDisabled={!!feature}
              placeholder="Feature Id *"
              value={newFeature.featureId}
              defaultValue={newFeature.featureId}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              register={{
                ...register("featureId", {
                  validate: validateFeatureId,
                }),
              }}
              error={errors.featureId}
              errorMessage={
                <ErrorMessage message={errors?.featureId?.message} />
              }
            />

            <SInput
              value={newFeature.description}
              type="textarea"
              name="description"
              placeholder="Description"
              defaultValue={newFeature.description ?? ""}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            />
          </Stack>
          <FormControl isInvalid={errors.type}>
            <RadioGroup
              name="type"
              fontFamily="montserrat"
              fontSize={18}
              color="gray.400"
              fontWeight="500"
              defaultValue={newFeature.type}
              onChange={handleRadioButtonChange}
              register={{
                ...register("type", {
                  validate: () => !!newFeature.type,
                }),
              }}
              value={newFeature?.type}
              colorScheme="blue"
            >
              <Flex py={3} direction="column">
                <Flex alignItems="center" gap={8}>
                  <Radio isDisabled={!!feature} value="number" size="sm" px={2}>
                    Input Field
                  </Radio>
                  <Radio
                    isDisabled={!!feature}
                    value="boolean"
                    size="sm"
                    px={2}
                  >
                    Toggle
                  </Radio>
                  <Radio isDisabled={!!feature} value="size" size="sm" px={2}>
                    Size
                  </Radio>
                  <Radio isDisabled={!!feature} value="dropdown" size="sm" px={2}>
                    Dropdown
                  </Radio>
                </Flex>
                {errors.type && (
                  <FormErrorMessage px={2}>
                    Please select a value
                  </FormErrorMessage>
                )}
              </Flex>
            </RadioGroup>
          </FormControl>
          <RenderFieldByFeatureType
            newFeature={newFeature}
            handleInputChange={handleInputChange}
            feature={feature}
            handleSizeChange={handleSizeChange}
            register={register}
            errors={errors}
            unregister={unregister}
            addNewvalueToDropdown={addNewvalueToDropdown}
            selectDropdownValue={selectDropdownValue}
            
          />
          <Flex justify="flex-end" py={3}>
            <Button
              variant="ghost"
              mr={3}
              onClick={handleCancelAction}
              color="primary.500"
              fontWeight="600"
            >
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              type="submit"
              isLoading={
                addFeatureInfo.isLoading || updateFeatureInfo.isLoading
              }
            >
              {feature ? "Update Feature" : "Add Feature"}
            </Button>
          </Flex>{" "}
        </form>
      </>
    </SDrawer>
  );
}

function RenderFieldByFeatureType(props: {
  newFeature: ProductFeature;
  handleInputChange: (name: string, value: number | boolean) => void;
  feature?: ProductFeature;
  handleSizeChange: (size: number) => void;
  register: UseFormRegister<any>;
  errors: DeepMap<any, FieldError>;
  unregister: UseFormUnregister<any>;
  addNewvalueToDropdown: (number: any) => void;
  selectDropdownValue: (number: any) => void;
}) {
  const {
    errors,
    feature,
    register,
    newFeature,
    handleSizeChange,
    handleInputChange,
    unregister,
    addNewvalueToDropdown,
    selectDropdownValue
  } = props;

  useEffect(() => {
    if (newFeature.type !== "number") {
      if (newFeature.type === "dropdown") {
        unregister(["maxValue", "minValue"]);
        return;
      }
      unregister(["maxValue", "minValue", "defaultValue"]);
    }
  }, [newFeature]);

  const[newDropdownValue, setNewDropdownvalue] = useState(''); 

  function validateMinValue() {
    if (newFeature?.type !== "number") {
      return;
    }

    if (
      typeof newFeature?.minValue !== "number" &&
      newFeature?.type === "number"
    ) {
      return "Minimum value is required.";
    }
  }

  const validateMaxValue = () => {
    if (!newFeature?.maxValue) return "Maximum value is required.";
    if (newFeature?.maxValue <= (newFeature?.minValue ?? 0)) {
      return "Please select maximum value greater than minimum value.";
    }
  };

  const validateDefaultValue = () => {
    const { defaultValue = 0, minValue = 0, maxValue = 0 } = newFeature;
    if (!defaultValue) return "Default value is required.";
    if (defaultValue < minValue || defaultValue > maxValue) {
      return "Default value should be between minimum & maximum value.";
    }
  };

  const validateDefaultValueForDropdown = () => {
    const { defaultValue } = newFeature;
    if (!!defaultValue) return;
    return "Default value is required.";
  };

  if (newFeature.type === "number") {
    const { minValue, maxValue } = newFeature;
    return (
      <Stack spacing="4">
        <SInput
          step="0.1"
          type="number"
          name="minValue"
          value={minValue?.toString()}
          placeholder="Minimum Value*"
          onChange={(e) =>
            handleInputChange(e.target.name, parseFloat(e.target.value))
          }
          register={{
            ...register("minValue", {
              validate: validateMinValue,
            }),
          }}
          error={errors?.minValue}
          errorMessage={<ErrorMessage message={errors?.minValue?.message} />}
        />
        <SInput
          step="0.1"
          type="number"
          name="maxValue"
          value={maxValue?.toString()}
          placeholder="Maximum Value *"
          onChange={(e) =>
            handleInputChange(e.target.name, parseFloat(e.target.value))
          }
          register={{
            ...register("maxValue", {
              validate: validateMaxValue,
            }),
          }}
          error={errors?.maxValue}
          errorMessage={<ErrorMessage message={errors?.maxValue?.message} />}
        />
        <SInput
          step="0.1"
          type="number"
          key="defaultValue"
          name="defaultValue"
          placeholder="Default Value *"
          isDisabled={newFeature?.type !== "number"}
          value={newFeature?.defaultValue?.toString()}
          onChange={(e) =>
            handleInputChange(e.target.name, parseFloat(e.target.value))
          }
          register={{
            ...register("defaultValue", {
              validate: validateDefaultValue,
            }),
          }}
          error={errors?.defaultValue}
          errorMessage={
            <ErrorMessage message={errors?.defaultValue?.message} />
          }
        />
      </Stack>
    );
  }

  if (newFeature.type === "boolean") {
    return (
      <Flex justify="space-evenly" align="center" my={1}>
        <Input
          isRequired
          border="2px"
          flex={1}
          px={2}
          type="text"
          value="Disable"
          placeholder="Disable"
        />
        <Switch
          mx={2}
          size="md"
          defaultChecked={!!feature?.defaultValue}
          onChange={(e) => handleInputChange("defaultValue", e.target.checked)}
        />
        <Input
          flex={1}
          border="2px"
          isRequired
          px={2}
          type="text"
          value="Enable"
          placeholder="Enable"
        />
      </Flex>
    );
  }

  if (
    newFeature.type === "size" &&
    typeof newFeature.defaultValue === "number"
  ) {
    const size = newFeature?.defaultValue;
    return (
      <Flex gridGap="8px">
        <Box maxW="lg">
          <SInput
            key="size"
            name=""
            isRequired
            value={size}
            type="number"
            placeholder=""
            minLength={1}
            maxLength={1024}
            rightSuffix
            onChange={(e) => handleSizeChange(parseInt(e.target.value))}
          />
        </Box>
      </Flex>
    );
  }

  if (newFeature.type === "dropdown") {
    return (
      <Stack spacing="4">
        <Flex justify="space-evenly" align="center" my={1}>
          <Input
            border="2px"
            flex={1}
            px={2}
            type="text"
            value={newDropdownValue}
            onChange={(e) => setNewDropdownvalue(e.target.value)}
            placeholder="Add new Value to Dropdown"
          />
          <Button
            colorScheme="primary"
            type="button"
            onClick={(e) => {
              addNewvalueToDropdown(newDropdownValue);
              setNewDropdownvalue("");
            }}
          >
            Add
          </Button>
        </Flex>
        <Select
          multiple={false}
          placeholder="Select defaultValue option"
          register={{
            ...register("defaultValue", {
              validate: validateDefaultValueForDropdown,
            }),
          }}
          value={`${newFeature.defaultValue}`}
          onChange={(e) => selectDropdownValue(e.target.value)}
          error={errors?.defaultValue}
        >
          {newFeature.dropdownValues?.map((value) => {
            return <option value={value} key={value}>{value}</option>;
          })}
        </Select>
        {
          (errors?.defaultValue && !newFeature.defaultValue) && <ErrorMessage message={errors?.defaultValue?.message} />
        }
      </Stack>
    );
  }

  return null;
}
