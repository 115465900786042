import { lowerCaseLetter } from "../packages/helpers/strings";

export const validateEmail = (email: string) => {
  const emailTester =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailTester.test(String(email).toLowerCase());
};

export const dateClipper = (date: string) => {
  const requiredDate = date?.split("T")[0];
  return requiredDate;
};

export const sortByEntity = (
  firstEntity: any,
  secondEntity: any,
  sort: "asc" | "desc"
) => {
  const lowerCasedFirstEntity =
    typeof firstEntity === "string"
      ? lowerCaseLetter(firstEntity)
      : firstEntity;
  const lowerCasedSecondEntity =
    typeof secondEntity === "string"
      ? lowerCaseLetter(secondEntity)
      : secondEntity;

  if (lowerCasedFirstEntity < lowerCasedSecondEntity) {
    return sort === "asc" ? -1 : 1;
  }
  return sort === "asc" ? 1 : -1;
};

export const validPhoneNumber = (phoneNumber: string) => {
  const phoneNoValidator = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
  return phoneNoValidator.test(phoneNumber);
};

export const objectToFormData = (
  obj: any,
  form?: FormData,
  namespace?: string
) => {
  const fd = form || new FormData();
  let formKey;

  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};
