import { MdClose, MdSearch } from "react-icons/md";
import { BiMapAlt, BiPencil } from "react-icons/bi";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import {
  FaListUl,
  FaRegUser,
  FaPlusCircle,
  FaSignOutAlt,
} from "react-icons/fa";

import { size } from "./theme";
import { ReactComponent as Enable } from "../assets/enable.svg";
import { ReactComponent as Disabled } from "../assets/disable.svg";
import { ReactComponent as AddType } from "../assets/svgs/add.svg";
import { ReactComponent as Disable } from "../assets/disableBlue.svg";
import { ReactComponent as ChangeRole } from "../assets/changeRole.svg";
import { ReactComponent as RemoveIcon } from "../assets/svgs/deleteGrey.svg";
import { ReactComponent as RemoveBlackIcon } from "../assets/svgs/deleteBlack.svg";
import { ReactComponent as RemoveRedIcon } from "../assets/svgs/deleteRed.svg";
import { ReactComponent as SensorMakerOnline } from "../assets/svgs/sensorOrMarkerOnline.svg";

export const ListIcon = () => {
  return <FaListUl />;
};
export const MapIcon = () => {
  return <BiMapAlt />;
};
export const PlusIcon = () => {
  return <FaPlusCircle size={size.icon} />;
};
export const EditIcon = () => {
  return <BiPencil size={size.icon} />;
};
export const DelIcon = () => {
  return <RemoveBlackIcon height={size.icon} width={size.icon} color="black" />;
};

export const DelRedIcon = () => {
  return <RemoveRedIcon height={size.icon} width={size.icon} color="" />;
};

export const BlackIcon = () => {
  return <RemoveBlackIcon height={size.icon} width={size.icon} color="black" />;
};

export const DisableIcon = () => {
  return <Disable />;
};
export const EnableIcon = () => {
  return <Enable />;
};
export const DisabledIcon = () => {
  return <Disabled />;
};
export const SearchIcon = () => {
  return <MdSearch size={size.icon} />;
};
export const ChangeRoleIcon = () => {
  return <ChangeRole />;
};
export const ArrowUpIcon = () => {
  return <IoMdArrowDropup size={size.icon} color="#D6D7D9" />;
};
export const ArrowDownIcon = () => {
  return <IoMdArrowDropdown size={size.icon} color="#D6D7D9" />;
};
export const CloseIcon = () => {
  return <MdClose />;
};

export const ProfileIcon = () => {
  return <FaRegUser />;
};

export const LogoutIcon = () => {
  return <FaSignOutAlt />;
};

export const AddSubType = () => {
  return <AddType />;
};

export const SensorMakerOnlineIcon = () => {
  return <SensorMakerOnline />;
};
