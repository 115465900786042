import { Flex, Box, Heading, Button, Container } from "@chakra-ui/react";

import { firstLetterUpperCase } from "../helpers/strings";

interface SCardProps {
  title: string;
  link: () => void;
  subtitle: string | number;
}

export default function SCard(props: SCardProps) {
  const { title, subtitle, link } = props;

  return (
    <Box
      bg="white"
      rounded="md"
      cursor="pointer"
      padding="28px 24px"
      onClick={() => link()}
      boxShadow="0px 12px 24px #eceef5"
    >
      <Flex direction="row" justify="space-between" alignItems="center">
        <Flex direction="column" justifyContent="right" gridGap="8px">
          <Heading flex="1" fontSize="26px">
            {firstLetterUpperCase(title)}
          </Heading>
          <Box>
            <Button
              variant="link"
              fontWeight="medium"
              onClick={(e) => link()}
              color="primary.500"
            >
              View All
            </Button>
          </Box>
        </Flex>
        <Flex
          p="4"
          height="4em"
          minWidth="4em"
          padding="0 16px"
          bg="background.600"
          borderRadius="10px"
          alignItems="center"
          justifyContent="center"
        >
          <Heading
            size="lg"
            justify="center"
            alignItems="center"
            data-testid="custom-subtitle"
          >
            {subtitle}
          </Heading>
        </Flex>
      </Flex>
    </Box>
  );
}
