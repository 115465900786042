import { useMutation, useQuery, useQueryCache } from "react-query";

import network from "./network";
import { GetProductResponse } from "./product";
import {
  Subscription,
  UpdateSubscriptionInterface,
} from "../packages/interfaces/subscription";

// const subscriptionUri = "superadmin/businessProductSubscription";
const subscriptionUri = "customermgmt/subscription";

async function getSubscriptionById(
  subscriptionKey: string
): Promise<Subscription> {
  const resp = await (
    await network()
  ).get(`/${subscriptionUri}/${subscriptionKey}`, {});
  return resp.data.data;
}

export function useGetSubscriptionByIds(subscriptionKeys: string[]) {
  const queryCache = useQueryCache();

  return useQuery(
    ["subscriptions", subscriptionKeys],
    async () => {
      const subscriptionsList = await Promise.all(
        subscriptionKeys.map((key) => getSubscriptionById(key))
      );
      return subscriptionsList;
    },
    {
      onSuccess: (data) => {
        const subscriptions = data ?? [];
        subscriptions.forEach((subscription) =>
          queryCache.setQueryData(
            ["subscriptions", subscription._id],
            subscription
          )
        );
      },
      enabled: subscriptionKeys.length,
    }
  );
}

async function createSubscription(newSubscription: Subscription) {
  return (await network()).post(`/${subscriptionUri}/`, newSubscription);
}

export function useCreateSubscription() {
  const queryCache = useQueryCache();

  return useMutation(
    async (newSubscription: any) => createSubscription(newSubscription),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["subscriptions"]);
        queryCache.invalidateQueries(["businesses"]);
        queryCache.invalidateQueries(["products"]);
      },

      onError: (e) => {
        throw e;
      },
    }
  );
}

async function editSubscription(
  newSubscription: Omit<Subscription, "_id" | "productKey" | "businessKey">,
  subscriptionKey: string
) {
  return (await network()).put(
    `/${subscriptionUri}/${subscriptionKey}`,
    newSubscription
  );
}

export function useEditSubscription() {
  const queryCache = useQueryCache();

  return useMutation(
    async (subscription: UpdateSubscriptionInterface) => {
      const { _id: subscriptionKey = "", ...editedSubscription } = subscription;
      return editSubscription(editedSubscription, subscriptionKey);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["subscriptions"]);
        queryCache.invalidateQueries(["businesses"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

async function deleteSubscription(
  businessKey: string,
  subscriptionKeys: string[]
) {
  return (await network()).delete(`/${subscriptionUri}`, {
    data: {
      subscriptionKeys,
      businessKey,
    },
  });
}

interface DeleteSubscriptionProps {
  businessKey: string;
  subscriptionKeys: string[];
}

export function useDeleteSubscription() {
  const queryCache = useQueryCache();

  return useMutation(
    async ({ subscriptionKeys, businessKey }: DeleteSubscriptionProps) =>
      deleteSubscription(businessKey, subscriptionKeys),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["subscriptions"]);
        queryCache.invalidateQueries(["businesses"]);
        queryCache.invalidateQueries(["devices"]);
        queryCache.invalidateQueries(["deviceListWithDetails"]);
      },
      onError: (e) => {
        throw e;
      },
    }
  );
}

const fetchProductsForSubscription = async (): Promise<GetProductResponse> => {
  const resp = await (await network()).get(`/${subscriptionUri}/products`);
  return resp.data;
};

export const useProductsForSubscription = () => {
  const queryCache = useQueryCache();

  return useQuery("products", fetchProductsForSubscription, {
    onSuccess: (data) => {
      const productList = data.data ?? [];
      productList.map((product) =>
        queryCache.setQueryData(["products", product._id], product)
      );
    },
  });
};
