import { useContext } from "react";

import { CircularProgress } from "@chakra-ui/progress";
import { Center, Divider, Flex, Link, Text } from "@chakra-ui/layout";

import { AuthContext } from "./AuthContext";

export const UnAuthorized = () => {
  const { userKey } = useContext(AuthContext);

  if (userKey === undefined) {
    return (
      <Center h="100%">
        <CircularProgress isIndeterminate color="blue.500" />
      </Center>
    );
  }

  if (userKey) {
    return null;
  }

  return (
    <Center marginTop="6">
      <Flex flexDirection="column">
        <Text fontWeight="bold" fontSize="2xl">
          Unauthorized to access the super admin app
        </Text>
        <Divider />
        <Flex justify="center">
          <Text>
            Please login with{" "}
            <Link
              fontWeight="bold"
              color="primary.200"
              colorScheme="primary"
              // href={geniusLoginWebURL}
            >
              Genius Login Web
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Center>
  );
};
