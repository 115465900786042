import { useContext, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import {
  Flex,
  Text,
  Wrap,
  Stack,
  Button,
  Heading,
  useToast,
  Checkbox,
  Center,
  CircularProgress,
} from "@chakra-ui/react";

import { MenuBarName } from "../../enums/menu";
import { useProducts } from "../../apis/product";
import { AuthContext } from "../Login/AuthContext";
import { SInput } from "../../packages/components/SInput";
import { Product } from "../../packages/interfaces/product";
import { useCreateRole, useEditRole } from "../../apis/role";
import { Permission, Role } from "../../packages/interfaces/user";
import { objectsToArrays, toastConfigs } from "../../packages/helpers/extra";
import {
  lowerCaseLetter,
  firstLetterUpperCase,
} from "../../packages/helpers/strings";

interface AddRoleProps {
  role?: Role;
  roles?: Role[];
  handleCancel: Function;
}

const initialRole: any = {
  name: "",
  updatedBy: "",
  permissions: {},
};

const { CustomerAdmin } = MenuBarName;

const noSpecialCharactersRoleNmae = new RegExp(/^[a-zA-Z0-9\s]+$/);

export const AddRole = (props: AddRoleProps) => {
  const { role, handleCancel, roles = [] } = props;
  const { userKey } = useContext(AuthContext);

  const [currentRole, setCurrentRole] = useState({
    ...initialRole,
  });

  const [createRole, createRoleInfo] = useCreateRole();
  const [editRole, editRoleInfo] = useEditRole();

  const [productPermissions, setProductPermissions] = useState<Permission>({});

  useEffect(() => {
    if (role) {
      setCurrentRole({ ...role });
    } else {
      setCurrentRole({
        ...currentRole,
        permissions: { ...productPermissions },
      });
    }
  }, [role, productPermissions]);

  const { data: products } = useProducts();
  const productsList = products?.data;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: initialRole,
  });

  function mapActions(actions: string[]) {
    return actions.reduce((acc, action) => {
      return { ...acc, [action]: action === "view" };
    }, {});
  }

  useEffect(() => {
    function showPermissions(product: Product) {
      const permissions =
        product?.resources.reduce((acc, resource) => {
          const { resourceId = "", actions } = resource;
          return { ...acc, [resourceId]: mapActions(actions) };
        }, {}) ?? {};
      setProductPermissions({ ...permissions });
    }
    if (productsList) {
      const product = productsList?.find(
        (product) => product.productId === "superadmin"
      ) as Product;
      showPermissions(product);
    }
  }, [productsList, role]);

  const toast = useToast(toastConfigs);
  function checkDuplicateUserRoleName(name: string) {
    const isSameRole = role?.name === name;
    const isDuplicateExist = roles?.some(
      (role) => lowerCaseLetter(role?.name) === lowerCaseLetter(name)
    );
    return isDuplicateExist && !isSameRole;
  }

  function atLeastOnePermission() {
    const isOnePermissionExist = objectsToArrays(
      currentRole?.permissions,
      "key"
    ).some((category: string) => {
      const currentCategory = currentRole?.permissions[category];
      const permissionValues: boolean[] = objectsToArrays(currentCategory);
      return permissionValues.some((permission) => permission === true);
    });
    return isOnePermissionExist;
  }

  async function handleSubmitAction() {
    if (!atLeastOnePermission()) {
      return toast({
        duration: 3000,
        status: "error",
        description: "At least one permission is required. ",
      });
    }
    if (role) {
      try {
        const { description, permissions, roleKey } = currentRole as Role;
        const updatedRole = {
          description,
          permissions,
          updatedBy: userKey,
          notificationSubscriptions: [],
        };
        await editRole({
          roleKey,
          updatedRole
        });
        setCurrentRole(initialRole);
        handleCancel();
        setCurrentRole(initialRole);
        return toast({
          duration: 3000,
          status: "success",
          description: "Role updated successfully.",
        });
      } catch (e: any) {
        return toast({
          duration: 3000,
          status: "error",
          description: e?.response?.data?.message ?? "Cannot edit role.",
        });
      }
    } else {
      if (!noSpecialCharactersRoleNmae.test(currentRole.name)) {
        toast({
          duration: 3000,
          status: "error",
          description: "Role name can only be alphanumeric string.",
        });
        return;
      }
      try {
        await createRole({ ...currentRole, updatedBy: userKey });
        setCurrentRole(initialRole);
        handleCancel();
        return toast({
          duration: 3000,
          status: "success",
          description: "Role created successfully!",
        });
      } catch (e: any) {
        createRoleInfo.reset();
        return toast({
          description: e?.response?.data?.message ?? "Cannot create role.",
          status: "error",
        });
      }
    }
  }

  function handleInputChange(field: string, value: string) {
    setCurrentRole({ ...currentRole, [field]: value });
  }

  function handleCheckChange(section: string, field: string, value: boolean) {
    if (field !== "view") {
      setCurrentRole({
        ...currentRole,
        permissions: {
          ...currentRole.permissions,
          [section]: {
            ...currentRole.permissions?.[section],
            [field]: value,
          },
        },
      });
    }
  }

  function mapPermissions() {
    if (!productsList) {
      return (
        <Center>
          <CircularProgress isIndeterminate size="20px" />
        </Center>
      );
    }
    return (
      <Stack spacing="4">
        {Object.entries(productPermissions).map((permission, index) => (
          <Flex direction="column" key={index}>
            <Text fontWeight="semibold">
              {firstLetterUpperCase(
                permission[0] === "superadmin" ? CustomerAdmin : permission[0]
              )}
            </Text>
            <Wrap justify="flex-start">
              {Object.entries(permission[1]).map((permissionSchema) => {
                return (
                  <Flex>
                    <Checkbox
                      isDisabled={permissionSchema[0] === "view"}
                      isChecked={
                        currentRole?.permissions?.[permission[0]]?.[
                          permissionSchema[0]
                        ]
                      }
                      onChange={(e) =>
                        handleCheckChange(
                          permission[0],
                          permissionSchema[0],
                          !currentRole?.permissions?.[permission[0]]?.[
                            permissionSchema[0]
                          ] ?? false
                        )
                      }
                      mr="2"
                    >
                      {firstLetterUpperCase(permissionSchema[0])}
                    </Checkbox>
                  </Flex>
                );
              })}
            </Wrap>
          </Flex>
        ))}
      </Stack>
    );
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitAction)}>
      <Heading as="h6" size="md" py={2}>
        Details
      </Heading>
      <Stack spacing={4}>
        <SInput
          type="text"
          placeholder="Role name"
          name="name"
          value={currentRole.name}
          isRequired
          isDisabled={!!role}
          register={{
            ...register("name", {
              validate: (_) => {
                if (checkDuplicateUserRoleName(currentRole?.name)) {
                  toast({
                    duration: 3000,
                    status: "error",
                    description: "Name already exists.",
                  });
                  return "";
                }
                return (currentRole?.name?.length || 0) < 80;
              },
            }),
          }}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          error={errors?.name}
          errorMessage={errors?.name?.message ?? "Invalid value"}
        />
        <SInput
          type="textarea"
          name="description"
          placeholder="Description"
          value={currentRole.description}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          register={register}
          error={errors?.productDescription}
          errorMessage="Invalid value"
        />

        <Heading as="h6" size="md" py={2}>
          Access Permissions
        </Heading>
        {mapPermissions()}
        <Flex justify="flex-end">
          <Button
            variant="ghost"
            mr={3}
            onClick={() => handleCancel()}
            color="primary.500"
            fontWeight="600"
          >
            Cancel
          </Button>
          <Button
            colorScheme="primary"
            type="submit"
            isLoading={createRoleInfo.isLoading || editRoleInfo.isLoading}
          >
            {role ? "Update Role" : "Create User Role"}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};
