import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Textarea,
  InputRightAddon,
} from "@chakra-ui/react";
import { useEffect, useState, ReactNode } from "react";

interface SInputProps {
  type:
    | "text"
    | "date"
    | "number"
    | "email"
    | "textarea"
    | "children"
    | "password";
  name: string;
  value: string | number | undefined | any;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  error?: any;
  step?: string;
  register?: object;
  placeholder: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  errorMessage?: string | ReactNode;
  defaultValue?: string;
  children?: JSX.Element;
  minLength?: number | string;
  maxLength?: number | string;
  showPassword?: boolean;
  onClickIcon?: () => void;
  showPasswordIcon?: boolean;
  countryCode?: string;
  rightSuffix?: boolean;
  globalFocus?: any;
  setGlobalFocus?: ({}: { [key: string]: boolean }) => void;
}

// TODO: Change it to the proper color name
const inputColor = {
  unfocused: "#ECEEF5",
  focused: "#006DB8",
  error: "#FF0000",
};

export const SInput = (props: SInputProps) => {
  const {
    type,
    name,
    step,
    error,
    value,
    onChange,
    register,
    minLength,
    maxLength,
    isRequired,
    isDisabled,
    onClickIcon,
    placeholder,
    defaultValue,
    errorMessage,
    showPassword,
    showPasswordIcon,
    countryCode,
    rightSuffix,
    setGlobalFocus,
    globalFocus,
  } = props;
  const [color, setColor] = useState("");

  const valueExist = value && value !== "NaN";

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (error) {
      setColor(inputColor.error);
    } else if ((valueExist || countryCode || isFocused) && !isDisabled) {
      setColor(inputColor.focused);
    } else {
      setColor(inputColor.unfocused);
    }
  }, [error, value, isFocused]);

  function content() {
    switch (type) {
      case "textarea":
        return (
          <Textarea
            type={type}
            name={name}
            value={value}
            variant="unstyled"
            onChange={onChange}
            register={register}
            isDisabled={isDisabled}
            isRequired={isRequired}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        );
      case "children":
        return <>{props.children}</>;

      default:
        return (
          <InputGroup value={value}>
            {countryCode && (
              <InputLeftElement
                mt="-8px"
                children={countryCode}
                style={{ zIndex: 1 }}
              />
            )}
            <Input
              type={type}
              name={name}
              step={step}
              value={value}
              min={minLength}
              max={maxLength}
              variant="unstyled"
              onChange={onChange}
              register={register}
              isDisabled={isDisabled}
              isRequired={isRequired}
              defaultValue={defaultValue}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              placeholder={countryCode ? "" : placeholder}
            />

            {showPasswordIcon && (
              <InputRightElement
                mt="-7px"
                mr="-10px"
                cursor="pointer"
                color="primary.300"
                onClick={onClickIcon}
              >
                {showPassword ? <ViewOffIcon /> : <ViewIcon />}
              </InputRightElement>
            )}
            <>{rightSuffix && <InputRightAddon children="MB" />}</>
          </InputGroup>
        );
    }
  }

  const fieldSetPadding = type !== "children" ? "8px 10px" : "0 0 0 8px";
  const fieldSetBorder = valueExist || countryCode || isFocused ? "2px" : "1px";
  return (
    <FormControl isInvalid={error}>
      <fieldset
        style={{
          borderRadius: "5px",
          padding: !rightSuffix ? fieldSetPadding : "0 0 0 4px",
          border: `${fieldSetBorder} solid ${color}`,
        }}
      >
        {(valueExist || countryCode || isFocused) && (
          <legend
            style={{
              color,
              top: -10,
              left: 10,
              margin: 0,
              fontWeight: 600,
              fontSize: "13px",
              padding: "0 4px",
              background: "white",
              position: "absolute",
            }}
          >
            {placeholder}
          </legend>
        )}
        {content()}
      </fieldset>
      {error && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};
