import { useMutation, useQuery, useQueryCache } from "react-query";
import {
  Product,
  Resource,
  Notification,
  ProductFeature,
} from "../packages/interfaces/product";
import network from "./network";

const productUri = "customermgmt/product";
// const productUri = "superadmin/product";

export interface GetProductResponse {
  success: boolean;
  data: Array<Product>;
}

async function getProducts(): Promise<GetProductResponse> {
  const resp = await (await network()).get(`/${productUri}`);
  return resp.data;
}

export function useProducts() {
  const queryCache = useQueryCache();

  return useQuery(["products"], getProducts, {
    onSuccess: (data: any) => {
      const productList: Array<Product> = data.data ?? [];
      productList.map((product) =>
        queryCache.setQueryData(["products", product._id], product)
      );
    },
  });
}

export function useProduct(productKey: string) {
  const queryCache = useQueryCache();
  return useQuery(["products", productKey], () => {
    return queryCache.getQueryData(["products", productKey]);
  });
}

async function createProduct(newProduct: any) {
  delete newProduct.features;
  delete newProduct.resources;
  return (await network()).post(`/${productUri}/`, newProduct);
}

export function useCreateProduct() {
  const queryCache = useQueryCache();

  return useMutation(async (newProduct: Product) => createProduct(newProduct), {
    onSuccess: () => {
      queryCache.invalidateQueries(["products"]);
    },
    onError: (e) => {
      throw e;
    },
  });
}

async function updateProduct({
  isActive,
  productDescription,
  productId,
  notifications,
  deviceAssignmentTypes,
  _id,
}: Product) {
  return (await network()).put(`/${productUri}/${_id}`, {
    productDescription,
    productId,
    notifications,
    deviceAssignmentTypes,
    isActive,
  });
}

export function useUpdateProduct() {
  const queryCache = useQueryCache();

  return useMutation(async (newProduct: any) => updateProduct(newProduct), {
    onSuccess: () => {
      queryCache.invalidateQueries(["products"]);
    },
    onError: (e: any) => {
      throw e;
    },
  });
}

async function addFeatureToProject({
  feature,
  productKey,
}: {
  feature: any;
  productKey: string;
}) {
  if (feature.type === "boolean" && !feature.defaultValue) {
    feature.defaultValue = false;
  }
  await (await network()).post(`${productUri}/${productKey}/feature`, feature);
  return productKey;
}

export function useAddFeature() {
  const queryCache = useQueryCache();

  return useMutation(addFeatureToProject, {
    onSuccess: (productKey) => {
      queryCache.invalidateQueries(["products"]);
    },
    onError: (e) => {
      throw e;
    },
  });
}

async function updateFeatureToProject({
  feature,
  productKey,
}: {
  feature: ProductFeature;
  productKey: string;
}) {
  if (feature.type === "boolean" && !feature.defaultValue) {
    feature.defaultValue = false;
  }
  const {
    type,
    featureId,
    isActive,
    minValue,
    maxValue,
    name,
    description,
    defaultValue,
    dropdownValues,
  } = feature;
  await (
    await network()
  ).put(`${productUri}/${productKey}/feature/${feature._id}`, {
    type,
    featureId,
    isActive,
    minValue,
    maxValue,
    name,
    description,
    defaultValue,
    dropdownValues
  });
  return productKey;
}

export function useUpdateFeature() {
  const queryCache = useQueryCache();

  return useMutation(updateFeatureToProject, {
    onSuccess: (productKey) => {
      queryCache.invalidateQueries(["products"]);
    },
    onError: (e) => {
      throw e;
    },
  });
}

async function deleteFeatures({
  productKey,
  featureIds,
}: {
  productKey: string;
  featureIds: Array<string>;
}) {
  await (
    await network()
  ).delete(`${productUri}/${productKey}/feature`, {
    data: { featureIds },
  });
  return productKey;
}

export function useDeleteFeatures() {
  const queryCache = useQueryCache();

  return useMutation(deleteFeatures, {
    onSuccess: (productKey) => {
      queryCache.invalidateQueries(["products"]);
    },
    onError: (e: any) => {
      throw e;
    },
  });
}

async function deleteProducts(productKeys: Array<string>) {
  await (
    await network()
  ).delete(`${productUri}/`, {
    data: { productKeys },
  });
  return productKeys;
}

export function useDeleteProducts() {
  const queryCache = useQueryCache();

  return useMutation(deleteProducts, {
    onSuccess: (productIds) => {
      productIds.forEach((productId) => {
        queryCache.removeQueries(["products", productId]);
        queryCache.invalidateQueries("products");
      });
    },
    onError: (err: any) => {
      throw err;
    },
  });
}

async function deleteResources({
  productKey,
  resourceKeys,
}: {
  productKey: string;
  resourceKeys: Array<string>;
}) {
  await (
    await network()
  ).delete(`${productUri}/${productKey}/resource`, {
    data: { resourceKeys },
  });
  return productKey;
}

export function useDeleteResources() {
  const queryCache = useQueryCache();

  return useMutation(deleteResources, {
    onSuccess: (productKey) => {
      queryCache.invalidateQueries(["products"]);
    },
    onError: (e: any) => {
      throw e;
    },
  });
}

async function addResource({
  productKey,
  resource,
}: {
  productKey: string;
  resource: Resource;
}) {
  await (
    await network()
  ).post(`${productUri}/${productKey}/resource`, {
    ...resource,
  });
  return productKey;
}

export function useAddResource() {
  const queryCache = useQueryCache();

  return useMutation(addResource, {
    onSuccess: (productKey) => {
      queryCache.invalidateQueries(["products"]);
    },
    onError: (error) => {
      throw error;
    },
  });
}

async function updateResoure({
  productKey,
  resourceKey,
  resource,
}: {
  productKey: string;
  resourceKey: string;
  resource: Resource;
}) {
  await (
    await network()
  ).put(`${productUri}/${productKey}/resource/${resourceKey}`, {
    ...resource,
  });
  return productKey;
}

export function useUpdateResoure() {
  const queryCache = useQueryCache();

  return useMutation(updateResoure, {
    onSuccess: (productKey) => {
      queryCache.invalidateQueries(["products"]);
    },
    onError: (error) => {
      throw error;
    },
  });
}

interface AddNotification {
  productKey: string;
  notification: Notification;
}

async function addNotification({ productKey, notification }: AddNotification) {
  await (
    await network()
  ).post(`${productUri}/${productKey}/notification`, notification);
  return productKey;
}

export function useAddNotification() {
  const queryCache = useQueryCache();

  return useMutation(addNotification, {
    onSuccess: (productKey) => {
      queryCache.invalidateQueries(["products"]);
    },
    onError: (e: any) => {
      throw e;
    },
  });
}

interface EditNotification {
  productKey: string;
  notificationKey: string;
  notification: Notification;
}

async function editNotification({
  productKey,
  notificationKey,
  notification,
}: EditNotification) {
  await (
    await network()
  ).put(
    `${productUri}/${productKey}/notification/${notificationKey}`,
    notification
  );
  return productKey;
}

export function useEditNotification() {
  const queryCache = useQueryCache();

  return useMutation(editNotification, {
    onSuccess: (productKey) => {
      queryCache.invalidateQueries(["products"]);
    },
    onError: (e: any) => {
      throw e;
    },
  });
}

async function deleteNotifications({
  productKey,
  notificationKeys,
}: {
  productKey: string;
  notificationKeys: string[];
}) {
  await (
    await network()
  ).delete(`${productUri}/${productKey}/notification`, {
    data: {
      notificationKeys,
    },
  });
  return productKey;
}

export function useDeleteNotifications() {
  const queryCache = useQueryCache();

  return useMutation(deleteNotifications, {
    onSuccess: (productKey) => {
      queryCache.invalidateQueries(["products"]);
    },
    onError: (e: any) => {
      throw e;
    },
  });
}

export function showProductName(
  productKey: string,
  productsList: Product[] | undefined
) {
  const selectedProduct = productsList?.find(
    (product) => product._id === productKey
  );
  return selectedProduct?.productId ?? "-";
}
