import axios from "axios";
import { useMutation, useQuery, useQueryCache } from "react-query";

import network from "./network";
import { objectToFormData } from "../utils/helpers";
import { Business } from "../packages/interfaces/business";
import { Subscription } from "../packages/interfaces/subscription";

const businessUri = "customermgmt/business";

const getAddressUrl = "https://maps.googleapis.com/maps/api/geocode/json";

interface GetBusinessResponse {
  success: boolean;
  data: Array<Business>;
}

async function getBusinesses(): Promise<GetBusinessResponse> {
  const resp = await (await network()).get(`/${businessUri}/`);
  return resp.data;
}

export function useBusiness() {
  const queryCache = useQueryCache();
  return useQuery(["businesses"], getBusinesses, {
    onSuccess: (data) => {
      const businessList = data.data ?? [];
      businessList.map((business) =>
        queryCache.setQueryData(["businesses", business._id], business)
      );
    },
  });
}

async function createBusiness(newBusiness: Business) {
  const formData = objectToFormData(newBusiness);

  return (await network()).post(`/${businessUri}/`, formData);
}

export function useCreateBusiness() {
  const queryCache = useQueryCache();

  return useMutation(async (newBusiness: any) => createBusiness(newBusiness), {
    onSuccess: () => {
      queryCache.invalidateQueries(["businesses"]);
    },
    onError: (e: any) => {
      throw e;
    },
  });
}

async function enableOrDisableBusiness(businessId: string, status: boolean) {
  return (await network()).post(`/${businessUri}/${businessId}/setEnabled`, {
    enabled: status,
  });
}
export async function getBusinessFromId(businessId: string) {
  return (await (await network()).get(`${businessUri}/${businessId}`)).data
    .data;
}

export const useBusinessById = (businessKey: string) => {
  const queryCache = useQueryCache();
  return useQuery(
    ["businesses", businessKey],
    (businessKey: string) => {
      return getBusinessFromId(businessKey);
    },
    {
      enabled: !!businessKey,
      onSuccess: (business) => {
        queryCache.setQueryData(businessKey, business);
      },
    }
  );
};
export function useEnableOrDisableBusiness() {
  const queryCache = useQueryCache();

  return useMutation(
    async (details: { id: string; status: boolean }) =>
      enableOrDisableBusiness(details.id, details.status),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["businesses"]);
      },
      onError: (e: any) => {
        throw e;
      },
    }
  );
}

async function updateBusiness(businessId: string, updatedBusiness: Business) {
  const formData = objectToFormData(updatedBusiness);

  return (await network()).put(`/${businessUri}/${businessId}`, formData);
}

export function useUpdateBusiness() {
  const queryCache = useQueryCache();

  return useMutation(
    async (details: { businessId: string; updatedBusiness: Business }) =>
      updateBusiness(details.businessId, details.updatedBusiness),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["businesses"]);
      },
      onError: (e: any) => {
        throw e;
      },
    }
  );
}

async function getBusinessesForAProduct(productKey: String) {
  const resp = await (
    await network()
  ).get(`/${businessUri}/filterByProducts/${productKey}`);
  return resp.data.data;
}

export function useBusinessesForAProduct(productKey: string) {
  return useQuery(["businesses", productKey], () =>
    getBusinessesForAProduct(productKey)
  );
}

async function deleteBusinesses(businessIds: string[]) {
  return (await network()).delete(`/${businessUri}/`, {
    data: { businessKeys: businessIds },
  });
}

export function useDeleteBusinesses() {
  const queryCache = useQueryCache();

  return useMutation(
    async (businessIds: string[]) => deleteBusinesses(businessIds),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["customers"]);
        queryCache.invalidateQueries(["businesses"]);
      },
      onError: (e: any) => {
        throw e;
      },
    }
  );
}

async function getSubscriptionsOfBusiness(businessKey: string) {
  const resp = await (
    await network()
  ).get(`/${businessUri}/${businessKey}/getSubscriptions`);
  return resp.data;
}

export function useGetSubscriptionsOfBusiness(businessKey: string) {
  const queryCache = useQueryCache();
  return useQuery(
    ["subscriptions", businessKey],
    () => getSubscriptionsOfBusiness(businessKey),
    {
      onSuccess: (data) => {
        const subscriptions: Array<Subscription> = data.data ?? [];
        subscriptions.map((subscription) =>
          queryCache.setQueryData(["subscriptions", businessKey], subscription)
        );
      },
    }
  );
}

export const getAddress = async (address: string) => {
  const resp = await axios.get(getAddressUrl, {
    params: {
      address,
      key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    },
  });

  const location = resp.data;
  const { lat, lng } = location?.results[0]?.geometry?.location ?? {};
  return { lat, lng };
};

export const isAllowedToTakeAction = (
  stratosfyBusinessKey: string,
  businessKey: string
) => {
  return stratosfyBusinessKey === businessKey;
};
