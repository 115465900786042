import { useState } from "react";

import { Grid, Heading, Stack } from "@chakra-ui/layout";

import { Device } from "./Device";
import { DeviceCard } from "./DeviceCard";
import { details } from "./devicesDetails";
import Page from "../../packages/components/Page";
import { Flex } from "@chakra-ui/react";

export const Devices = () => {
  const [view, setView] = useState("");
  const [selectedType, setSelectedType] = useState({
    title: "",
    filterBy: "gateway_bridge",
  });
  const [sensors, setSensors] = useState(
    details.filter((detail) => detail.category === "Sensors")
  );
  const [gateways, setGateways] = useState(
    details.filter((detail) => detail.category === "Gateways")
  );

  function handleOnClick(detail: {
    title: string;
    description: string;
    filterBy: string;
  }) {
    const { title, filterBy } = detail;
    setSelectedType({
      title,
      filterBy,
    });
    setView(title);
  }

  if (!view) {
    return (
      <Page>
        <Heading size="lg">Devices</Heading>
        <Flex flexDirection="column" gridGap="24px">
          <Heading fontSize="sm">Gateways</Heading>
          <Stack spacing="8">
            {gateways.map((gateway) => (
              <DeviceCard
                icon={gateway.icon}
                key={gateway.title}
                title={gateway.title}
                description={gateway.description}
                onClick={() => handleOnClick(gateway)}
              />
            ))}
          </Stack>
          <Heading size="sm" mt="8" mb="4">
            Sensors
          </Heading>
          <Grid
            gridGap="4"
            templateColumns="repeat(auto-fill, minmax(600px, 1fr))"
          >
            {sensors.map((sensor) => (
              <DeviceCard
                icon={sensor.icon}
                title={sensor.title}
                description={sensor.description}
                onClick={() => handleOnClick(sensor)}
              />
            ))}
          </Grid>
        </Flex>
      </Page>
    );
  }
  return (
    <Device
      setView={setView}
      title={selectedType.title}
      filterBy={selectedType.filterBy}
    />
  );
};
