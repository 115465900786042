import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Flex, Stack, Button, useToast, Text } from "@chakra-ui/react";

import { SInput } from "../../packages/components/SInput";
import { SDrawer } from "../../packages/components/SDrawer";
import { isUpperCase, lowerCaseLetter } from "../../packages/helpers/strings";
import { Notification, Product } from "../../packages/interfaces/product";
import { useAddNotification, useEditNotification } from "../../apis/product";
import { toastConfigs } from "../../packages/helpers/extra";
import ErrorMessage from "../../packages/components/ErrorMessage";

interface AddNotificationProps {
  open: boolean;
  clearState: Function;
  setOpenModal: Function;
  product: Product | undefined;
  selectedNotification?: Notification;
}

const initialNotification = {
  name: "",
  notificationId: "",
};

export const AddNotification = (props: AddNotificationProps) => {
  const {
    open,
    clearState,
    setOpenModal,
    selectedNotification,
    product = {} as Product,
  } = props;
  const [notification, setNotification] =
    useState<Notification>(initialNotification);

  const [addNotification, addNotificationInfo] = useAddNotification();
  const [editNotification, editNotificationInfo] = useEditNotification();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: notification,
  });

  const toast = useToast(toastConfigs);

  useEffect(() => {
    function init() {
      if (selectedNotification) {
        setNotification(selectedNotification);
      } else {
        setNotification(initialNotification);
      }
    }
    init();
  }, [selectedNotification]);

  function handleChange(field: string, value: string) {
    setNotification({ ...notification, [field]: value });
  }

  function handleCancelAction() {
    setOpenModal("opr");
    clearState();
  }

  function resetLoaders() {
    addNotificationInfo.reset();
    editNotificationInfo.reset();
  }

  function loading() {
    const { isLoading: isAddingNotification } = addNotificationInfo;
    const { isLoading: isUpdatingNotification } = editNotificationInfo;

    return isAddingNotification || isUpdatingNotification;
  }

  function checkIfDuplicateExist(notificationId: string) {
    const isDuplicateExist = product?.notifications?.some(
      (notification) =>
        lowerCaseLetter(notification?.notificationId) ===
        lowerCaseLetter(notificationId)
    );
    const isSameNotificationId =
      selectedNotification?.notificationId === notification?.notificationId;
    return isDuplicateExist && !isSameNotificationId;
  }

  function validateNotificationId() {
    if (!notification?.notificationId) return "Notification Id is required.";

    if (checkIfDuplicateExist(notification?.notificationId)) {
      return "Name already exists.";
    }
    if (
      notification.notificationId.includes(" ") ||
      !isUpperCase(notification.notificationId)
    ) {
      return "Must be all capital letters with no spaces.";
    }
  }

  function validateNotificationName() {
    if (!notification?.name) return "Notification name is required.";
  }

  async function handleSubmitAction() {
    const { _id: productKey = "" } = product;
    const { _id: notificationKey = "" } = notification;

    try {
      if (selectedNotification) {
        const { _id, ...remainingNotification } = notification;
        await editNotification({
          productKey,
          notificationKey,
          notification: remainingNotification,
        });
      } else {
        await addNotification({ productKey, notification });
      }
    } catch (err: any) {
      const status = selectedNotification ? "edit" : "add";
      resetLoaders();
      return toast({
        status: "error",
        description:
          err?.response?.data?.message ?? `Cannot ${status} notification.`,
      });
    }

    clearState();
    const label = selectedNotification ? "updated" : "added";
    toast({
      description: `Notification ${label} successfully.`,
      status: "success",
    });
    setOpenModal("opr");
  }

  const getTitle = (notification?: Notification) => {
    const action = notification?._id ? "Edit" : "Add";
    return `${action} Notification`;
  };

  return (
    <SDrawer
      open={open}
      showActionButtons={false}
      negativeAction={handleCancelAction}
      title={getTitle(selectedNotification)}
    >
      <form onSubmit={handleSubmit(handleSubmitAction)}>
        <Stack spacing="4">
          <Text fontWeight="bold" fontSize="22px">
            Details
          </Text>
          <SInput
            type="text"
            name="notificationId"
            placeholder="Notification Id *"
            value={notification.notificationId}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            register={{
              ...register("notificationId", {
                validate: validateNotificationId,
              }),
            }}
            error={errors?.notificationId}
            errorMessage={
              <ErrorMessage message={errors?.notificationId?.message} />
            }
          />

          <SInput
            type="text"
            name="name"
            value={notification.name}
            placeholder="Notification Name *"
            register={{
              ...register("name", {
                validate: validateNotificationName,
              }),
            }}
            error={errors?.name}
            errorMessage={<ErrorMessage message={errors?.name?.message} />}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <SInput
            type="textarea"
            name="description"
            placeholder="Description"
            value={notification?.description ?? ""}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <Flex justify="flex-end">
            <Button
              variant="ghost"
              mr={3}
              onClick={handleCancelAction}
              color="primary.500"
              fontWeight="600"
            >
              Cancel
            </Button>
            <Button colorScheme="primary" type="submit" isLoading={loading()}>
              {selectedNotification
                ? "Update Notification"
                : "Add Notification"}
            </Button>
          </Flex>
        </Stack>
      </form>
    </SDrawer>
  );
};
