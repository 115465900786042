import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Wrap,
  Flex,
  Button,
  Center,
  Checkbox,
  CircularProgress,
} from "@chakra-ui/react";

import { SelectedCustomers } from "./CustomRow";
import { DisabledIcon } from "../../utils/Icons";
import { useGetCustomerByIds } from "../../apis/customers";
import { SLoader } from "../../packages/components/SLoader";
import { Customer } from "../../packages/interfaces/customer";
import { Business } from "../../packages/interfaces/business";
import { Subscription } from "../../packages/interfaces/subscription";
import {
  useGetSubscriptionByIds,
  useProductsForSubscription,
} from "../../apis/subscriptions";

interface CollapseProps {
  id?: string;
  key?: string;
  subscriptions: string[];
  addSubcription: Function;
  superAdminUsers: string[];
  selectedBusiness: Business;
  selectedSubscriptions: any;
  selectedCustomers: SelectedCustomers;
  selectCustomer: (customer: Customer) => void;
  openCollapse: "superAdmin" | "subscriptions" | string;
  selectSubscription: (subscription: Subscription) => void;
}

const Collapse = (props: CollapseProps) => {
  const {
    openCollapse,
    subscriptions,
    addSubcription,
    selectCustomer,
    superAdminUsers,
    selectedBusiness,
    selectedCustomers,
    selectSubscription,
    selectedSubscriptions,
  } = props;

  return (
    <Box pt="0" bg="background.600">
      {openCollapse !== "superAdmin" && (
        <Button
          pl="6"
          mt="0"
          w="100%"
          bg="white"
          outline={0}
          variant="solid"
          borderRadius="0"
          boxShadow="none"
          textColor="blue.500"
          leftIcon={<AddIcon />}
          justifyContent="flex-start"
          onClick={() => addSubcription()}
          isDisabled={!selectedBusiness?.enabled ?? false}
        >
          Add Subscription
        </Button>
      )}
      {openCollapse === "subscriptions" && (
        <RenderSubscriptionListList
          subscriptions={subscriptions}
          selectedBusiness={selectedBusiness}
          selectSubscription={selectSubscription}
          selectedSubscriptions={selectedSubscriptions}
        />
      )}
      {openCollapse === "superAdmin" && (
        <RenderSuperAdmins
          superAdmins={superAdminUsers}
          selectCustomer={selectCustomer}
          selectedCustomers={selectedCustomers}
        />
      )}
    </Box>
  );
};

export default Collapse;

interface SubscriptionListInterface {
  subscriptions: string[];
  selectedBusiness: Business;
  selectedSubscriptions: Record<string, Subscription>;
  selectSubscription: (subscription: Subscription, business?: Business) => void;
}

const RenderSubscriptionListList = (props: SubscriptionListInterface) => {
  const {
    subscriptions,
    selectedBusiness,
    selectSubscription,
    selectedSubscriptions,
  } = props;

  const { data: getSubscriptions = [], isFetched } =
    useGetSubscriptionByIds(subscriptions);

  const { data } = useProductsForSubscription();
  const products = data?.data;

  const subscriptionsList = getSubscriptions ?? [];

  function showSubscribedProductName(productKey: string) {
    const product = products?.find((product) => product._id === productKey);
    return product?.productId;
  }

  if (!isFetched && subscriptions.length) {
    return <SLoader size="sm" />;
  }
  return (
    <>
      {Object.values(subscriptionsList).map((subscription) => (
        <Wrap key={subscription._id} pl="10" py="2" spacing="8">
          <Checkbox
            colorScheme="blue"
            borderColor="primary.500"
            isChecked={!!selectedSubscriptions[subscription?._id ?? ""]}
            onChange={() => selectSubscription(subscription, selectedBusiness)}
          />
          <Text fontWeight="400" fontSize="sm">
            {showSubscribedProductName(subscription.productKey)}
          </Text>
        </Wrap>
      ))}
    </>
  );
};

interface SuperAdminInterface {
  superAdmins: string[];
  selectedCustomers: SelectedCustomers;
  selectCustomer: (customer: Customer) => void;
}

const RenderSuperAdmins = (props: SuperAdminInterface) => {
  const { superAdmins, selectCustomer, selectedCustomers } = props;

  const { data: customers, isLoading } = useGetCustomerByIds(superAdmins);

  if (isLoading) {
    return (
      <Center>
        <CircularProgress isIndeterminate size="20px" />
      </Center>
    );
  }

  return (
    <>
      {customers?.map((customer) => (
        <Wrap key={customer.userKey} pl="10" py="2" spacing="8">
          <Checkbox
            colorScheme="blue"
            borderColor="primary.500"
            onChange={() => selectCustomer(customer)}
            isChecked={!!selectedCustomers?.[customer.userKey ?? ""]}
          />
          <Flex gridGap="3">
            <Text fontWeight="400" fontSize="sm">
              {`${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`}
            </Text>
            {customer.isDisabled && <DisabledIcon />}
          </Flex>
        </Wrap>
      ))}
    </>
  );
};
