import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Stack, useToast } from "@chakra-ui/react";

import SDialog from "../../packages/components/SDialog";
import { SInput } from "../../packages/components/SInput";
import { login, useChangePassword } from "../../apis/auth";
import { LoginResponse } from "../../packages/interfaces/user";
import { toastConfigs } from "../../packages/helpers/extra";

interface PassChangeProps {
  userKey: string;
  username: string;
  handleCancel: () => void;
}

const PassChange = (props: PassChangeProps) => {
  const toast = useToast(toastConfigs);
  const { userKey, handleCancel, username } = props;

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
  });
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [changePass, changePassInfo] = useChangePassword();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: passwords,
  });

  useEffect(() => {
    setError({
      oldPassword: "",
      newPassword: "",
    });
  }, [passwords.newPassword, passwords.oldPassword]);

  async function checkPassword() {
    try {
      const resp: AxiosResponse<LoginResponse> = await login({
        username,
        password: passwords.oldPassword,
      });
      const checkUserKey = resp.data.data.userKey === userKey;
      if (!resp.data.success && !checkUserKey) {
        toast({
          duration: 3000,
          status: "error",
          description: "Wrong password entered.",
        });
        return false;
      }
      return true;
    } catch (err) {
      toast({
        duration: 3000,
        status: "error",
        description: "Wrong password entered.",
      });
      return false;
    }
  }

  async function changePassword() {
    setLoading(true);
    try {
      const isValidPassword = await checkPassword();

      if (isValidPassword) {
        await changePass({
          userKey,
          password: passwords.newPassword,
        });

        toast({
          duration: 3000,
          status: "success",
          description: "Password changed successfully.",
        });
        handleCancel();
      }
    } catch (err) {
      toast({
        duration: 3000,
        status: "error",
        description: "Error occured while changing the password.",
      });
    }
    setLoading(false);
  }

  function handleInputChange(field: string, value: string) {
    setPasswords({ ...passwords, [field]: value });
  }

  function validateOldPassword() {
    const oldPass = passwords?.oldPassword;
    if (oldPass?.length > 0) {
      return true;
    }
    return "Password must not be empty.";
  }

  function validateNewPassword() {
    if (passwords.newPassword.length < 8) {
      return "Password must be at least 8 characters.";
    } else if (passwords.newPassword === passwords.oldPassword) {
      return "New password must be different from old password.";
    } else if (passwords.newPassword.includes(" ")) {
      return "New password must not contain spaces.";
    }
    return true;
  }

  const onClickNewPasswordIcon = () => {
    setShowPassword({
      ...showPassword,
      newPassword: !showPassword.newPassword,
    });
  };
  const onClickOldPasswordIcon = () => {
    setShowPassword({
      ...showPassword,
      oldPassword: !showPassword.oldPassword,
    });
  };
  return (
    <SDialog
      size="lg"
      open={true}
      title="Change Password"
      positiveLabel="Change"
      body="Enter your old password and new password"
      loading={changePassInfo.isLoading || loading}
      handleNegativeAction={handleCancel}
      handlePositiveAction={handleSubmit(changePassword, () => trigger)}
    >
      <form onSubmit={handleSubmit(changePassword)}>
        <Stack mt="4" spacing="4">
          <SInput
            isRequired
            // @ts-ignore
            type={showPassword.oldPassword ? "text" : "password"}
            name="oldPassword"
            placeholder="Old Password * "
            value={passwords.oldPassword}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            register={{
              ...register("oldPassword", {
                validate: validateOldPassword,
              }),
            }}
            error={errors?.oldPassword}
            errorMessage={errors?.oldPassword?.message}
            showPassword={showPassword.oldPassword}
            showPasswordIcon
            onClickIcon={onClickOldPasswordIcon}
          />
          <SInput
            isRequired
            type={showPassword.newPassword ? "text" : "password"}
            name="newPassword"
            placeholder="New Password * "
            value={passwords.newPassword}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            register={{
              ...register("newPassword", {
                validate: validateNewPassword,
              }),
            }}
            error={errors?.newPassword}
            errorMessage={errors?.newPassword?.message}
            showPassword={showPassword.newPassword}
            showPasswordIcon
            onClickIcon={onClickNewPasswordIcon}
          />
        </Stack>
        <Button type="submit" display="none" />
      </form>
    </SDialog>
  );
};

export default PassChange;
