import React, { useContext } from "react";

import { useHistory } from "react-router-dom";
import {
  Tab,
  Tabs,
  Heading,
  TabList,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";

import Profile from "./Profile";
import UserRoles from "./UserRoles";
import Page from "../../packages/components/Page";
import { ActionInterface } from "../../apis/role";
import { AuthContext } from "../Login/AuthContext";

export const Settings = () => {
  const { location } = useHistory();

  const [tabIndex, setTabIndex] = React.useState(0);

  const { state } = location as any;

  React.useEffect(() => {
    if (state?.toProfile) {
      setTabIndex(1);
    }
  }, [state]);

  const { userRoles } = useContext(AuthContext);

  const roleResourcId = "roles";

  const canViewUserRole = userRoles?.[roleResourcId]?.some(
    (action: ActionInterface) => action.view
  );

  return (
    <Page>
      <Heading as="h1" size="lg">
        Settings
      </Heading>
      <Tabs
        isLazy
        variant="line"
        onChange={(index) => setTabIndex(index)}
        index={tabIndex}
      >
        <TabList tabIndex={tabIndex}>
          {canViewUserRole && (
            <Tab
              fontWeight="bold"
              color="altText.500"
              _selected={styles.tabSelected}
            >
              User role
            </Tab>
          )}
          <Tab
            fontWeight="bold"
            color="altText.500"
            _selected={styles.tabSelected}
          >
            Profile
          </Tab>
        </TabList>
        <TabPanels>
          {canViewUserRole && (
            <TabPanel>
              <UserRoles />
            </TabPanel>
          )}
          <TabPanel maxW="40%">
            <Profile />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  );
};

const styles = {
  tabSelected: {
    color: "primary.500",
    borderColor: "primary.500",
    boxShadow: "none !important",
  },
};
