import * as React from "react";

import { BellIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Text,
  Menu,
  Image,
  Divider,
  IconButton,
  MenuButton,
  MenuListProps,
} from "@chakra-ui/react";

export const defaultAvatar =
  "https://iupac.org/wp-content/uploads/2018/05/default-avatar.png";

interface HeaderProps {
  profileImage: string;
  userName: string;
  children: MenuListProps;
  showSideBarButton?: boolean;
  openSideBarDrawer?: () => void;
}

export const Header: React.FC<HeaderProps> = (props) => {
  const {
    profileImage,
    userName,
    showSideBarButton,
    openSideBarDrawer,
    children: headerDropDownContents,
  } = props;

  const getJustifyContent = () => {
    if (showSideBarButton) {
      return "space-between";
    }
    return "flex-end";
  };

  return (
    <Flex
      p="2"
      h="16"
      bg="white"
      boxShadow="0px 12px 24px #eceef5"
      justifyContent={getJustifyContent()}
    >
      {showSideBarButton && (
        <IconButton
          aria-label="hamburger-icon"
          icon={<HamburgerIcon />}
          onClick={openSideBarDrawer}
        />
      )}
      <Flex justifyContent="flex-end" alignItems="center" gridGap="16px">
        <Divider orientation="vertical" height="6" color="highlight.500" />
        <BellIcon color="icon.500" boxSize="24px" />
        <Divider orientation="vertical" height="6" color="highlight.500" />
        <Text fontSize="16px" color="highlight.500">
          {userName}
        </Text>
        <Menu>
          <MenuButton>
            <Image
              src={profileImage}
              boxSize="40px"
              borderRadius="50%"
              fallbackSrc={defaultAvatar}
            />
          </MenuButton>
          {headerDropDownContents}
        </Menu>
        <Box mr="60px" />
      </Flex>
    </Flex>
  );
};
