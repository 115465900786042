import { CircularProgress } from "@chakra-ui/progress";

interface SLoaderProps {
  size?: "sm" | "md" | "lg";
}

const sizes = { sm: "20px", md: "30px", lg: "40px" };

export const SLoader = (props: SLoaderProps) => {
  const { size = "sm" } = props;
  const sizeInPixels = sizes[size];
  return (
    <CircularProgress size={sizeInPixels} isIndeterminate color="blue.500" />
  );
};
