import { useContext, useEffect, useState } from "react";

import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Text,
  Wrap,
  Stack,
  Button,
  Center,
  Drawer,
  Select,
  Divider,
  Heading,
  useToast,
  DrawerBody,
  IconButton,
  DrawerContent,
  CircularProgress,
  DrawerCloseButton,
} from "@chakra-ui/react";

import { Maps } from "./Maps";
import CustomRow from "./CustomRow";
import { Details } from "./Details";
import { MenuBarName } from "../../enums/menu";
import AddSubscription from "./AddSubscription";
import { BusinessForm } from "./AddEditBusiness";
import { ActionInterface } from "../../apis/role";
import Page from "../../packages/components/Page";
import { sortByEntity } from "../../utils/helpers";
import { AuthContext } from "../Login/AuthContext";
import { User } from "../../packages/interfaces/user";
import { useGetLocationUsers } from "../../apis/users";
import SDialog from "../../packages/components/SDialog";
import { SDrawer } from "../../packages/components/SDrawer";
import { Product } from "../../packages/interfaces/product";
import { Customer } from "../../packages/interfaces/customer";
import { SearchBar } from "../../packages/components/SearchBar";
import { useRemoveCustomerFromBusiness } from "../../apis/customers";
import { Subscription } from "../../packages/interfaces/subscription";
import { HeaderInterface, STable } from "../../packages/components/STable";
import { Business as BusinessType } from "../../packages/interfaces/business";
import { useDebouncedCallback } from "../../packages/hooks/useDebouncedCallback";
import {
  useBusiness,
  useDeleteBusinesses,
  isAllowedToTakeAction,
  useEnableOrDisableBusiness,
} from "../../apis/business";

import {
  useDeleteSubscription,
  useProductsForSubscription,
} from "../../apis/subscriptions";
import {
  toastConfigs,
  objectsToArrays,
  getAccessDeniedErrorMessage,
} from "../../packages/helpers/extra";
import {
  DelRedIcon,
  MapIcon,
  EditIcon,
  ListIcon,
  PlusIcon,
  EnableIcon,
  DisableIcon,
} from "../../utils/Icons";

const addBusinessId = "addBusinessId";

const { CustomerAdmin } = MenuBarName;

const initialBusiness = {
  businessName: "",
  businessAddress: "",
  subscriptions: [""],
  isDisabled: false,
  remarks: "",
  billingDetails: {
    billingName: "",
    billingEmail: "",
    billingPhone: "",
    billingAddress: "",
    paymentInfo: {
      ccNumber: "",
      ccExpiry: "",
      ccCVV: "",
    },
  },
};

const byDefaultHeaders: HeaderInterface[] = [
  { name: "Business Name", sort: "desc" },
  { name: "Address", sort: "desc" },
  { name: CustomerAdmin, sort: "desc" },
  { name: "Subscriptions", sort: "desc" },
];

interface ProductKeyIdMap {
  [productKey: string]: string;
}

interface LocationUsers {
  data: {
    success: boolean;
    data: {
      users: User[];
    };
  };
}

export const stratosfyBusinessName = "stratosfy";

export const Business = () => {
  const [headers, setHeaders] = useState(byDefaultHeaders);

  const keyForBusinessRolePermissions = "business";

  const { userRoles, userKey } = useContext(AuthContext);

  const isBusinessCreatable =
    userRoles?.[keyForBusinessRolePermissions]?.some(
      (action: ActionInterface) => action.create
    ) ?? false;

  const canUserEditBusiness =
    userRoles?.[keyForBusinessRolePermissions]?.some(
      (action: ActionInterface) => action.edit
    ) ?? false;
  const canUserEnableDisableBusiness =
    userRoles?.[keyForBusinessRolePermissions]?.some(
      (action: ActionInterface) => action.disable
    ) ?? false;

  const canUserDeleteTheBusiness =
    userRoles?.[keyForBusinessRolePermissions]?.some(
      (action: ActionInterface) => action.delete
    ) ?? false;

  const { data, isLoading: isBusinessLoading } = useBusiness();

  const [stratosfyBusinessKey, setStratosfyBusinessKey] = useState("");

  useEffect(() => {
    const { data: businessList } = data ?? {};

    const stratosfyBusiness = businessList?.find(
      (business) => business.businessName === stratosfyBusinessName
    );

    setStratosfyBusinessKey(stratosfyBusiness?._id ?? "");
  }, [data]);
  const [searchText, setSearchText] = useState<string>("");

  const debouncedOnChange = useDebouncedCallback(
    (e) => setSearchText(e.target.value),
    400
  );

  const [dialogText, setDialogText] = useState({
    title: "",
    body: "",
    subtitle: "",
  });
  const [businesses, setBusinesses] = useState<BusinessType[]>();

  const [selectedBusinessCount, setSelectedBusinessCount] = useState(0);

  useEffect(() => {
    if (data) {
      const filteredBusiness: BusinessType[] = (data.data ?? []).filter(
        (business: any) => {
          return business.businessName
            .toLowerCase()
            .includes(searchText.trim().toLowerCase());
        }
      );

      const { sort = "desc" } =
        headers?.find((header) => header.name === "Business Name") ?? {};

      const sortedBusiness = filteredBusiness?.sort(
        (firstBusiness, secondBusiness) =>
          sortByEntity(
            firstBusiness?.businessName,
            secondBusiness?.businessName,
            sort
          )
      );

      let updatedHeaders = [...headers];

      updatedHeaders?.splice(0, 1, {
        name: "Business Name",
        sort,
      });

      setHeaders(updatedHeaders);

      setBusinesses(sortedBusiness);
    }
  }, [data, searchText]);

  let [selectedBusinesses, setSelectedBusinesses] = useState<any>({});
  const [selectedBusiness, setSelectedBusiness] = useState<BusinessType | any>({
    ...initialBusiness,
  });
  let [selectedSubscriptions, setSelectedSubscriptions] = useState<any>({});
  const [selectedSubscription, setSelectedSubscription] = useState<any>();
  const [selectedCustomers, setSelectedCustomers] = useState<{
    [userKey: string]: Customer;
  }>({});

  const [openModal, setOpenModal] = useState(false);
  const [modalMode, setModalMode] = useState("form");
  const [openDialog, setOpenDialog] = useState(false);
  const [businessKey, setBusinessKey] = useState("");

  const [view, setView] = useState<"maps" | "table">("table");

  const [statusBusiness, statusBusinessInfo] = useEnableOrDisableBusiness();
  const [deleteBusinesses, deleteBusinessesInfo] = useDeleteBusinesses();
  const [deleteSubscription, deleteSubscriptionInfo] = useDeleteSubscription();
  const [getLocationUsers, getLocationUsersInfo] = useGetLocationUsers();

  const { data: productsResponse } = useProductsForSubscription();

  const totalBusinessCount = businesses?.length;

  const products = productsResponse?.data ?? ([] as Product[]);

  const [
    unAssignCustomer,
    { isLoading: isCustomerLoading, reset: resetCustomerUnAssign },
  ] = useRemoveCustomerFromBusiness();

  const toast = useToast(toastConfigs);

  useEffect(() => {
    const count = Object.keys(selectedBusinesses).length;
    if (businesses) {
      setSelectedBusinessCount(count);
    }
  }, [selectedBusinesses]);

  const disabledBusiness = () => {
    const selectedBusiness = objectsToArrays(
      selectedBusinesses
    )[0] as BusinessType;
    return !selectedBusiness?.enabled;
  };

  function handleEditBusiness() {
    if (!canUserEditBusiness) {
      toast(getAccessDeniedErrorMessage("edit", "business"));
      return;
    }
    setModalMode("update");
    setOpenModal(true);
  }

  function handleCancelAction() {
    setOpenModal(false);
    setSelectedBusiness(undefined);
    setSelectedBusinesses({});
    setSelectedSubscription({});
  }

  function openDetailsModal() {
    // Handeling the changing form in modal
    setModalMode("details");
    setOpenModal(true);
  }

  function handleOnNameClick(business: BusinessType) {
    openDetailsModal();
    setSelectedBusiness(business);
  }

  function renderTitle() {
    switch (modalMode) {
      case "form":
        return "Add Business";
      case "details":
        return selectedBusiness?.businessName;
      case "update":
        return "Edit Business";
      case "subscription":
        return !Object.values(selectedSubscription ?? {}).length
          ? "Add Subscription"
          : "Edit Subscription";
      default:
        return "Business";
    }
  }

  function handleAllCheckChange(e: any) {
    if (e.target.checked) {
      businesses?.forEach((business: any) => {
        selectedBusinesses[business._id] = business;
      });
      setSelectedBusinesses({ ...selectedBusinesses });
    } else {
      setSelectedBusinesses({});
    }
  }

  async function handleDisableBusiness() {
    const selectedBusiness: any = Object.values(selectedBusinesses)[0];

    if (stratosfyBusinessKey === selectedBusiness?._id) {
      return toast({
        duration: 3000,
        status: "warning",
        description: "Can't disable stratosfy business.",
      });
    }

    try {
      await statusBusiness({
        id: selectedBusiness?._id ?? "",
        status: !selectedBusiness?.enabled ?? true,
      });
      setSelectedBusinesses({});
    } catch (err) {
      statusBusinessInfo.reset();
      toast({
        status: "error",
        description: "Cannot disable/enable business",
      });
    }
  }

  useEffect(() => {
    if (!businessKey) {
      setSelectedSubscriptions({});
    }
  }, [businessKey]);

  function selectSubscription(
    subscription: Subscription | null,
    business?: BusinessType
  ) {
    setSelectedBusinesses({});

    if (!subscription) {
      setSelectedSubscriptions({});
      return;
    }
    if (business) {
      setSelectedBusiness(business);
    }
    const { _id: selected = "" } = subscription ?? {};
    if (selectedSubscriptions[selected]) {
      delete selectedSubscriptions[selected];
    } else {
      selectedSubscriptions[selected] = subscription;
    }
    setSelectedSubscriptions({ ...selectedSubscriptions });
  }

  const selectCustomer = (customer: Customer) => {
    if (customer.userKey) {
      if (selectedCustomers[customer.userKey]) {
        const { [customer.userKey]: value, ...remainingCustomer } =
          selectedCustomers;
        setSelectedCustomers({ ...remainingCustomer });
      } else {
        setSelectedCustomers({
          ...selectedCustomers,
          [customer.userKey]: { ...customer },
        });
      }
    }
  };

  function renderEnableOrDisable() {
    const currentBusinesss = Object.values(
      selectedBusinesses
    )[0] as BusinessType;

    const enabled = currentBusinesss?.enabled ?? true;
    if (!enabled) {
      return "Enable";
    }
    return "Disable";
  }

  function handleAddSubscription(business: BusinessType) {
    if (isAllowedToTakeAction(stratosfyBusinessKey, business._id ?? "")) {
      return toast({
        duration: 3000,
        status: "warning",
        description: "Can't add subscription to stratosfy business.",
      });
    }
    if (!isBusinessCreatable) {
      toast(getAccessDeniedErrorMessage("add", "subscription"));
      return;
    }
    setSelectedBusiness(business);
    setModalMode("subscription");
    setOpenModal(true);
  }

  function handleEditSubscription(subscription: Subscription) {
    if (!canUserEditBusiness) {
      toast(getAccessDeniedErrorMessage("edit", "subscription"));
      return;
    }
    setModalMode("subscription");
    setSelectedBusiness(
      businesses?.find(
        (business) => business._id === subscription.businessKey
      ) ?? initialBusiness
    );
    setSelectedSubscription(subscription);
    setSelectedSubscriptions({});
    setOpenModal(true);
  }

  function anyBusinessHasSubscription() {
    const businesses = objectsToArrays(selectedBusinesses);
    const doesAnyBusinessHaveSubscription = businesses.some(
      (business) => business?.subscriptions.length > 0
    );
    return doesAnyBusinessHaveSubscription;
  }

  async function handleDeleteSubscription() {
    const ids = Object.keys(selectedSubscriptions);
    const businessKeys = objectsToArrays(selectedSubscriptions).map(
      (subscription) => subscription.businessKey
    );
    const setOfBusinessKeys = new Set(businessKeys);
    const uniqueBusinessKeys = [...setOfBusinessKeys];

    try {
      await deleteSubscription({
        subscriptionKeys: ids,
        businessKey: uniqueBusinessKeys[0],
      });
    } catch (err: any) {
      deleteSubscriptionInfo.reset();
      return toast({
        description:
          err?.response?.data?.message ?? "Cannot delete subscription.",
        status: "error",
      });
    }
    setSelectedSubscriptions({});

    return toast({
      status: "success",
      description: "Deleted successfully.",
    });
  }

  async function handleDeleteBusiness() {
    try {
      const checkedBusiness = Object.keys(selectedBusinesses);
      if (anyBusinessHasSubscription()) {
        return toast({
          description: "Business have active subscriptions.",
          status: "error",
        });
      }
      await deleteBusinesses(checkedBusiness);
      setSelectedBusinesses({});
    } catch (err: any) {
      return toast({
        status: "error",
        description: err?.response?.data?.message ?? "Cannot delete business.",
      });
    }
    setSelectedBusinesses({});

    return toast({
      description: "Deleted successfully",
      status: "info",
    });
  }

  function handleDelete() {
    const businessType = objectsToArrays(selectedBusinesses).length > 0;
    if (businessType) {
      handleDeleteBusiness();
    } else {
      handleDeleteSubscription();
    }
  }

  function customDrawerContent() {
    switch (modalMode) {
      case "details":
        return <Details business={selectedBusiness} />;
      case "subscription":
        return (
          <AddSubscription
            modalMode={modalMode}
            business={selectedBusiness}
            subscription={selectedSubscription}
            handleCancelAction={handleCancelAction}
          />
        );
    }
    return (
      <>
        <Heading as="h3" size="md" mb="4">
          Details
        </Heading>
        <BusinessForm
          handleCancelAction={handleCancelAction}
          stratosfyBusinessKey={stratosfyBusinessKey}
          checkDuplicateBusinessName={(selectedBusiness) =>
            checkDuplicateBusinessName(businesses ?? [], selectedBusiness)
          }
          business={Object.values(selectedBusinesses)[0] as BusinessType}
        />
      </>
    );
  }

  function handleChangeCheck(business: BusinessType) {
    setBusinessKey("");
    const { _id = "" } = business;
    if (selectedBusinesses[_id]) {
      delete selectedBusinesses[_id];
    } else {
      selectedBusinesses[_id] = business;
    }
    setSelectedBusinesses({ ...selectedBusinesses });
  }

  const handleHeaderClick = (header: HeaderInterface, headerIndex: number) => {
    const { sort } = header;

    const newSort = sort === "desc" ? "asc" : "desc";

    let sortedBusiness: BusinessType[];

    switch (header.name) {
      case "Business Name":
        sortedBusiness =
          businesses?.sort((firstBusiness, secondBusiness) =>
            sortByEntity(
              firstBusiness.businessName,
              secondBusiness.businessName,
              newSort
            )
          ) ?? [];
        break;

      case "Address":
        sortedBusiness =
          businesses?.sort((firstBusiness, secondBusiness) =>
            sortByEntity(
              firstBusiness.businessAddress,
              secondBusiness.businessAddress,
              newSort
            )
          ) ?? [];
        break;
      case "Subscriptions":
        sortedBusiness =
          businesses?.sort((firstBusiness, secondBusiness) =>
            sortByEntity(
              firstBusiness.subscriptions?.length ?? 0,
              secondBusiness.subscriptions?.length ?? 0,
              newSort
            )
          ) ?? [];
        break;

      case CustomerAdmin:
        sortedBusiness =
          businesses?.sort((firstBusiness, secondBusiness) =>
            sortByEntity(
              firstBusiness.customerSuperAdminUsers?.length ?? 0,
              secondBusiness.customerSuperAdminUsers?.length ?? 0,
              newSort
            )
          ) ?? [];
        break;

      default:
        sortedBusiness = [...(businesses ?? [])];
        break;
    }

    let updatedHeaders = [...headers];

    updatedHeaders.splice(headerIndex, 1, {
      name: header.name,
      sort: newSort,
    });

    setHeaders([...updatedHeaders]);

    setBusinesses(sortedBusiness);
  };

  function renderView() {
    if (view === "table") {
      if (!businesses?.length) {
        return (
          <Center height="100vh">
            <Text fontWeight="bold">No business.</Text>
          </Center>
        );
      }
      return (
        <STable
          checked={selectedBusinessCount === totalBusinessCount}
          onChangeCheckBox={handleAllCheckChange}
          headers={headers}
          handleHeaderClick={handleHeaderClick}
        >
          {businesses?.map((business) => (
            <CustomRow
              key={business._id}
              business={business}
              businessKey={businessKey}
              selectCustomer={selectCustomer}
              setBusinessKey={setBusinessKey}
              selectedCustomers={selectedCustomers}
              selectSubscription={selectSubscription}
              selectedSubscriptions={selectedSubscriptions}
              onNameClick={() => handleOnNameClick(business)}
              checked={!!selectedBusinesses[business?._id || ""]}
              handleChangeCheck={() => handleChangeCheck(business)}
              onAddSubscription={() => handleAddSubscription(business)}
            />
          ))}
        </STable>
      );
    }
    return <Maps business={businesses as BusinessType[]} />;
  }

  function showButtons() {
    const selectedBusinessCount = Object.values(selectedBusinesses).length;
    const selectedSubscriptionCount = Object.values(
      selectedSubscriptions
    ).length;

    if (selectedBusinessCount) {
      const isEnabled =
        Object.values(selectedBusinesses as BusinessType[])[0]?.enabled ?? true;
      const EnableOrDisableIcon = isEnabled ? DisableIcon : EnableIcon;
      return selectedBusinessCount < 2 ? (
        <>
          <Button
            p="4"
            leftIcon={<PlusIcon />}
            isDisabled={!isBusinessCreatable || disabledBusiness()}
            colorScheme="blue"
            variant="outline"
            size="sm"
            onClick={() =>
              handleAddSubscription(
                Object.values(selectedBusinesses)[0] as BusinessType
              )
            }
          >
            Add subscription
          </Button>
          <Button
            p="4"
            leftIcon={<EnableOrDisableIcon />}
            isDisabled={!canUserEnableDisableBusiness}
            colorScheme="blue"
            variant="outline"
            size="sm"
            isLoading={statusBusinessInfo.isLoading}
            onClick={handleDisableBusiness}
          >
            {renderEnableOrDisable()}
          </Button>
          <Button
            p="4"
            leftIcon={<EditIcon />}
            isDisabled={!canUserEditBusiness || disabledBusiness()}
            colorScheme="blue"
            variant="outline"
            size="sm"
            onClick={() => {
              handleEditBusiness();
            }}
          >
            Edit
          </Button>
        </>
      ) : null;
    }
    if (selectedSubscriptionCount) {
      return selectedSubscriptionCount < 2 ? (
        <Button
          p="4"
          isDisabled={!selectedBusiness?.enabled ?? false}
          leftIcon={<EditIcon />}
          colorScheme="blue"
          variant="outline"
          size="sm"
          onClick={() =>
            handleEditSubscription(
              Object.values(selectedSubscriptions)[0] as Subscription
            )
          }
        >
          Edit Subscription
        </Button>
      ) : null;
    }
  }
  const selectedCustomersCount = Object.values(selectedCustomers).length;
  const openBottomDrawer =
    (!!Object.keys(selectedBusinesses).length ||
      !!Object.values(selectedSubscriptions).length ||
      !!Object.values(selectedCustomers).length) &&
    !openModal;

  const handleAddBusinessButton = () => {
    if (!isBusinessCreatable) {
      if (!toast.isActive(addBusinessId)) {
        toast({
          id: addBusinessId,
          ...getAccessDeniedErrorMessage("create", "business"),
        });
      }
      return;
    }
    setModalMode("form");
    setOpenModal(true);
  };

  function getProductName(productKey: string) {
    const product = products.find((product) => product._id === productKey);
    return product?.productId ?? "";
  }

  function getBusinessName(businessKey: string) {
    const business = businesses?.find(
      (business) => business._id === businessKey
    );
    return business?.businessName ?? "";
  }

  async function checkIfAnyUserInSubscription(subscriptions: Subscription[]) {
    const productKeyIdMap = products.reduce(
      (productkeyIdMap: ProductKeyIdMap, product) => {
        productkeyIdMap[product?._id ?? ""] = product?.productId;
        return productkeyIdMap;
      },
      {}
    );
    const data: LocationUsers[] = await Promise.all(
      subscriptions.map((subscription) =>
        getLocationUsers({
          productId: productKeyIdMap[`${subscription.productKey}`],
          businessKey: subscription.businessKey,
        })
      )
    );
    const checkIfAnyUser = data.some(
      (result) => result?.data?.data?.users?.length > 0
    );
    return checkIfAnyUser;
  }

  const handleDeleteBusinessButton = async () => {
    const selectedBusinessArray = objectsToArrays(selectedBusinesses);
    const selectedSubscriptionsArray = objectsToArrays(selectedSubscriptions);

    let title = "";
    let body = "";
    let subtitle = "";
    let businessName = "";
    let selectedItemName = "";

    if (selectedBusinessArray.length > 0) {
      if (!canUserDeleteTheBusiness) {
        toast(getAccessDeniedErrorMessage("delete", "business"));
        return;
      }
      if (selectedBusinessArray.length < 2) {
        const selectedFirstBusiness = selectedBusinessArray[0] as BusinessType;
        if (
          isAllowedToTakeAction(
            stratosfyBusinessKey,
            selectedFirstBusiness._id ?? ""
          )
        ) {
          return toast({
            duration: 3000,
            status: "warning",
            description: "Can't delete Stratosfy business.",
          });
        }
        const { businessName } = selectedFirstBusiness;
        selectedItemName = `- ${businessName}`;
        body = `Are you sure you want to delete Business ${selectedItemName}`;
      } else {
        body = `Are you sure you want to delete these Business`;
      }
      title = `Delete ${selectedBusinessArray.length} Business ?`;
      subtitle =
        "Remember, this action cannot be undone. Also it removes data such as billing details";
    } else if (selectedSubscriptionsArray.length > 0) {
      if (!canUserDeleteTheBusiness) {
        toast(getAccessDeniedErrorMessage("delete", "subscription"));
        return;
      }
      const selectedFirstSubscription =
        selectedSubscriptionsArray[0] as Subscription;
      if (selectedFirstSubscription?.businessKey === stratosfyBusinessKey) {
        return toast({
          duration: 3000,
          status: "warning",
          description: "Can't delete subscription of Stratosfy business.",
        });
      }
      const productHasActiveUsers = await checkIfAnyUserInSubscription(
        selectedSubscriptionsArray
      );
      if (productHasActiveUsers) {
        return toast({
          duration: 3000,
          status: "error",
          description: "User are assigned under the selected subscriptions.",
        });
      }
      const { productKey, businessKey } = selectedFirstSubscription;
      if (selectedSubscriptionsArray.length < 2) {
        selectedItemName = `- ${getProductName(productKey)}`;
      }
      businessName = getBusinessName(businessKey);
      title = `Delete ${selectedSubscriptionsArray.length} product from ${businessName} ?`;
      body = `Are you sure you want to delete product ${selectedItemName}`;
      subtitle =
        "Remember, this action cannot be undone. Also it removes data such as features";
    }
    setDialogText({ title, body, subtitle });
    setOpenDialog(true);
  };

  if (isBusinessLoading || !businesses) {
    return (
      <Center h="100%">
        <CircularProgress color="blue.500" />
      </Center>
    );
  }

  const getProductBusiness = (businesses: any) => {
    return businesses.reduce(
      (
        productBusiness: { [productId: string]: Array<string> },
        business: any
      ) => {
        const { businessKey: userAssignedBusiness, ...products } = business;
        if (businessKey !== userAssignedBusiness) {
          return productBusiness;
        }
        const productIDs = Object.keys(products);
        productIDs.forEach((productId) => {
          if (products[productId]?.roleKey) {
            productBusiness[productId] = [
              ...(productBusiness[productId] ?? []),
              userAssignedBusiness,
            ];
          }
        });

        return productBusiness;
      },
      {}
    );
  };

  const unAssignCustomers = async (customerDetails: {
    [userKey: string]: Customer;
  }) => {
    try {
      const customerKeys = Object.keys(customerDetails);
      const response = customerKeys.map(async (customerKey) => {
        const productBusiness = getProductBusiness(
          customerDetails[customerKey]?.business
        );

        const isThereLessThanOrEqualToOneUser = Object.keys(
          productBusiness
        ).map(async (productId) => {
          const businessesOfAProduct: string[] = productBusiness[productId];
          const promises = businessesOfAProduct.map((businessKey) => {
            return getLocationUsers({ productId, businessKey });
          });

          const numberOfUsersOfBusinesses = await Promise.all(promises);

          return numberOfUsersOfBusinesses?.some(
            (eachBusinessUsers) =>
              (eachBusinessUsers?.data?.data?.users?.length ?? 0) <= 1
          );
        });

        const res = await Promise.all(isThereLessThanOrEqualToOneUser);

        return res?.some(
          (isThereLessThanOrEqualToOne) => isThereLessThanOrEqualToOne
        );
      });

      const isThereLessUserArray = await Promise.all(response);

      const isThereLessUserSet = new Set(isThereLessUserArray);

      let message = "";

      if (customerKeys.length > 1) {
        message =
          "One of the users cannot be unassigned beacuse one of the subscription doesnot have other user.";
      } else {
        message = "One of the products of the selected user has only one user.";
      }

      if (isThereLessUserSet.has(true)) {
        getLocationUsersInfo.reset();
        resetCustomerUnAssign();
        setSelectedCustomers({});
        return toast({
          status: "warning",
          description: message,
        });
      }

      const unAssignCustomersPromises = customerKeys.map(
        async (customerKey) => {
          const productBusiness = getProductBusiness(
            customerDetails[customerKey]?.business
          );

          return unAssignCustomer({
            customerKey,
            productBusiness,
            updatedBy: userKey!,
          });
        }
      );

      await Promise.all(unAssignCustomersPromises);

      toast({
        status: "success",
        description: "User unassigned  successfully",
      });
      setSelectedCustomers({});
    } catch (err) {
      resetCustomerUnAssign();
      getLocationUsersInfo.reset();
      toast({
        status: "error",
        description: err?.response?.data?.message ?? "Cannot unassign user.",
      });
      setSelectedCustomers({});
    }
  };

  function renderDrawerTitle() {
    let count = 0;
    let type = "";
    const selectedBusiness = objectsToArrays(selectedBusinesses);
    const selectedSubscription = objectsToArrays(selectedSubscriptions);
    const selectedCustomer = objectsToArrays(selectedCustomers);
    if (selectedBusiness?.length > 0) {
      count = selectedBusiness?.length;
      type = "business";
    } else if (selectedCustomer?.length > 0) {
      count = selectedCustomer?.length;
      type = "user";
    } else {
      count = selectedSubscription?.length;
      type = "subscription";
    }

    return `${count} ${type} selected`;
  }

  return (
    <Page>
      <Flex justify="space-between">
        <Heading as="h1" size="lg">
          Business
        </Heading>
        <Button
          leftIcon={<PlusIcon />}
          size="md"
          colorScheme="blue"
          onClick={() => handleAddBusinessButton()}
        >
          Add Business
        </Button>
      </Flex>

      <Flex flexDirection="column" gridGap="30px">
        <Box d="flex" w="100%">
          <Box d="flex" mr="4">
            <IconButton
              size="sm"
              variant="outline"
              borderRadius="0"
              style={{ background: view === "table" ? "#D7D6D9" : undefined }}
              aria-label="table"
              colorScheme="gray"
              onClick={() => setView("table")}
            >
              <ListIcon />
            </IconButton>
            <IconButton
              size="sm"
              variant="outline"
              style={{ background: view === "maps" ? "#D7D6D9" : undefined }}
              borderRadius="0"
              aria-label="maps"
              colorScheme="gray"
              onClick={() => setView("maps")}
            >
              <MapIcon />
            </IconButton>
          </Box>
          <Box>
            <Divider mx="4" orientation="vertical" />
          </Box>
          <Box w="40%">
            <SearchBar
              value={searchText}
              title="Search Business"
              rightIcon={<SearchIcon />}
              onChange={(e) => {
                setSelectedBusinesses({});
                setSearchText(e.target.value);
                debouncedOnChange(e);
              }}
              onClose={() => setSearchText("")}
            />
          </Box>
          <Box ml="auto">
            <FilterSuscriptions
              businesses={data?.data ?? []}
              products={products}
              onChange={(filteredBusinesses) =>
                setBusinesses(filteredBusinesses)
              }
            />
          </Box>
        </Box>
        {renderView()}
      </Flex>
      <SDrawer
        open={openModal}
        title={renderTitle()}
        showActionButtons={false}
        size={modalMode === "details" ? "lg" : "md"}
        positiveLabel={modalMode === "form" ? "Create Business" : "Update"}
        negativeAction={() => {
          handleCancelAction();
          setSelectedSubscription({});
          setSelectedBusiness({ ...initialBusiness });
        }}
      >
        {customDrawerContent()}
      </SDrawer>
      <Drawer
        trapFocus={false}
        placement="bottom"
        variant="alwaysOpen"
        isOpen={openBottomDrawer}
        blockScrollOnMount={false}
        onClose={() => {
          setSelectedBusinesses({});
          setSelectedSubscriptions({});
          setSelectedBusiness({ ...initialBusiness });
          setSelectedCustomers({});
        }}
      >
        <DrawerContent
          p="6"
          w="60%"
          mx="auto"
          shadow="0px 0px 50px 2px rgba(50,58,69,0.47)"
          borderTopLeftRadius="12"
          borderTopRightRadius="12"
        >
          <DrawerBody>
            <Stack direction="row" justify="space-between">
              <Flex>
                <DrawerCloseButton position="relative" top="0" />
                <Text display="flex" alignItems="center">
                  {renderDrawerTitle()}
                </Text>
              </Flex>

              <Wrap>
                {showButtons()}
                {selectedCustomersCount ? (
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    size="sm"
                    onClick={() => unAssignCustomers(selectedCustomers)}
                    isLoading={
                      isCustomerLoading || getLocationUsersInfo.isLoading
                    }
                  >
                    Unassign
                  </Button>
                ) : (
                  <Button
                    leftIcon={<DelRedIcon />}
                    isDisabled={!canUserDeleteTheBusiness}
                    colorScheme="red"
                    variant="outline"
                    size="sm"
                    loading={
                      deleteBusinessesInfo.isLoading ||
                      deleteSubscriptionInfo.isLoading
                    }
                    onClick={() => handleDeleteBusinessButton()}
                  >
                    Delete
                  </Button>
                )}
              </Wrap>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <SDialog
        size="lg"
        open={openDialog}
        loading={
          deleteBusinessesInfo.isLoading || deleteSubscriptionInfo.isLoading
        }
        title={dialogText.title}
        body={dialogText.body}
        subTitle={dialogText.subtitle}
        handleNegativeAction={() => setOpenDialog(false)}
        positiveLabelColor="red"
        handlePositiveAction={() => {
          handleDelete();
          setOpenDialog(false);
        }}
        positiveLabel="Delete"
      />
    </Page>
  );
};
interface FilterSuscriptionsProps {
  businesses: BusinessType[] | undefined;
  onChange: (filteredBusinesses: BusinessType[]) => void;
  products: Product[];
}

const FilterSuscriptions = (props: FilterSuscriptionsProps) => {
  const { businesses = [], onChange, products } = props;

  const [filter, setFilter] = useState("all");
  const [selectedProduct, setSelectedProduct] = useState<Product>();

  useEffect(() => {
    filterBusinessBySubscription();
  }, [selectedProduct]);

  function filterBusinessBySubscription() {
    if (filter === "all") {
      onChange(businesses);
      return;
    }
    const { subscriptions = [] } = selectedProduct ?? ({} as Product);
    const subscriptionKeys = new Set(subscriptions);
    const filteredBusiness: BusinessType[] = businesses?.filter(
      (business: BusinessType) => {
        if (business?.subscriptions) {
          const isSubscriptionExist = business?.subscriptions.some((key) =>
            subscriptionKeys.has(key)
          );
          return isSubscriptionExist;
        }
        return false;
      }
    );
    onChange(filteredBusiness);
  }

  function handleFilterChange(value: string) {
    setFilter(value);
    const subscribedProduct = products?.filter(
      (product) => product?._id === value
    )[0];
    setSelectedProduct(subscribedProduct);
  }
  return (
    <Select
      maxW="3xs"
      fontSize="xs"
      height="32px"
      value={filter}
      fontWeight="medium"
      onChange={(e) => handleFilterChange(e.target.value)}
    >
      <option value="all">All Subscriptions</option>
      {products.map((product) => (
        <option key={product?._id} value={product?._id}>
          {product.productId}
        </option>
      ))}
    </Select>
  );
};

export function checkDuplicateBusinessName(
  businesses: BusinessType[],
  selectedBusiness: BusinessType
) {
  if (selectedBusiness._id) {
    return (
      businesses?.some(
        (business) =>
          business.businessName === selectedBusiness.businessName &&
          business._id !== selectedBusiness._id
      ) ?? false
    );
  }
  return (
    businesses?.some(
      (business) => business.businessName === selectedBusiness.businessName
    ) ?? false
  );
}
