import { useState } from "react";

import { Td, Flex, Text, Divider } from "@chakra-ui/react";

import { filters } from "./devicesDetails";
import { Device } from "../../packages/interfaces/device";
import { useGetSensorsByGateway } from "../../apis/devices";
import { SLoader } from "../../packages/components/SLoader";
import BridgeTOnline from "../../assets/bridgeT_online.svg";
import MarkerIOnline from "../../assets/markerI_online.svg";
import CountView from "../../packages/components/CountView";
import { STableRow } from "../../packages/components/STable";
import BridgeTOffline from "../../assets/bridgeT_offline.svg";
import MarkerIOffline from "../../assets/markerI_offline.svg";
import SensorMarkerOnline from "../../assets/sensor_marker_online.svg";
import SensorMarkerOffline from "../../assets/sensor_marker_offline.svg";
import {
  ArrowUpIcon,
  ArrowDownIcon,
  SensorMakerOnlineIcon,
} from "../../utils/Icons";

interface CustomRowProps {
  device: Device;
  filterBy: string;
  onNameClick?: () => void;
  showBusinessName: (businessKey: string) => string;
}

export const CustomRow = ({
  device,
  filterBy,
  showBusinessName,
  onNameClick = () => {},
}: CustomRowProps) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const initialize = filterBy !== "sensor" && openCollapse;
  const { data, error, isLoading } = useGetSensorsByGateway(
    device.deviceID,
    initialize
  );
  const sensors = data?.data?.sensor ?? [];

  function handleToggleCollapse() {
    setOpenCollapse(!openCollapse);
  }

  function renderSensorsCell() {
    return (
      <CountView
        title={device.count.toString()}
        subtitle="T-Sensors"
        arrowModifier={openCollapse}
        ArrowUpIcon={<ArrowUpIcon />}
        ArrowDownIcon={<ArrowDownIcon />}
        onClick={() => handleToggleCollapse()}
      />
    );
  }

  function renderIdCell(device: Device) {
    let DeviceIcon: any;

    switch (filterBy) {
      case filters.gateways.bridge:
      case filters.gateways.environ:
        DeviceIcon = device.isActive ? BridgeTOnline : BridgeTOffline;
        break;

      case filters.sensors.marker:
      case filters.sensors.beacon:
        DeviceIcon = device.isActive ? SensorMarkerOffline : SensorMarkerOnline;
        break;

      case filters.sensors.marker_I:
        DeviceIcon = device.isActive ? MarkerIOnline : MarkerIOffline;
        break;
      case filters.sensors.marker_X:
        DeviceIcon = device.isActive ? MarkerIOnline : MarkerIOffline;
        break;
    }

    return (
      <Flex gridGap="4" align="center">
        <img src={DeviceIcon} alt="device" />
        {device.deviceID}
      </Flex>
    );
  }

  function collapse() {
    if (isLoading) {
      return <SLoader size="sm" />;
    }
    return (
      <Flex direction="column">
        {sensors?.map((sensor: string) => {
          return (
            <Flex direction="column">
              <Flex p={2} px={6} alignItems="center" gridGap="4">
                <SensorMakerOnlineIcon />
                <Text fontWeight="400" fontSize="sm">
                  {sensor}
                </Text>
              </Flex>
              <Divider />
            </Flex>
          );
        })}
      </Flex>
    );
  }

  function infoCell() {
    switch (filterBy) {
      case filters.sensors.beacon:
        return (
          <Td>
            <Text>{device.gateway}</Text>
          </Td>
        );
      case filters.sensors.marker:
        return null;
      case filters.sensors.marker_I:
        return null;
      case filters.sensors.marker_X:
        return null;
      default:
        return <Td>{renderSensorsCell()}</Td>;
    }
  }

  return (
    <STableRow collapseOpen={openCollapse} collapseData={collapse()}>
      <Td onClick={onNameClick} cursor="pointer">
        {renderIdCell(device)}
      </Td>
      <Td>{showBusinessName(device.businessKey)}</Td>
      {infoCell()}
    </STableRow>
  );
};
