export enum ProductEnum {
  Product = "Product",
  Features = "Features",
  Business = "Business",
  Resources = "Resources",
  Notifications = "Notifications",
}

export const { Product, Features, Business, Resources, Notifications } =
  ProductEnum;
